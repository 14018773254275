import React from 'react';
import { makeStyles, SvgIcon, Theme, Typography } from '@material-ui/core';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';

import { TileData } from './types/Tile';

const useStyles = makeStyles<Theme>((theme) => ({
  tile: {
    width: '100%',
    maxWidth: 288,
    height: 198,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBlock: theme.spacing(1.5),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 16,
    [theme.breakpoints.up('md')]: {
      width: 288,
      marginInline: theme.spacing(1.5),
    },
  },
  tileIcon: {
    height: 40,
    width: 40,
    color: theme.palette.common.white,
  },
  tileNumber: {
    fontSize: 36,
    fontWeight: 700,
  },
  tileDesc: {
    fontSize: 18,
    fontWeight: 300,
  },
}));

interface TilesProps {
  tileData: TileData[];
}

export const Tiles: React.FC<TilesProps> = ({ tileData }) => {
  const classes = useStyles();
  const translate = useCreateTranslate(TranslationNamespace.landingPage);

  return (
    <>
      {tileData.map((tile) => (
        <div className={classes.tile} key={`${tile.text}-${tile.num}`}>
          <SvgIcon className={classes.tileIcon} component={tile.icon} viewBox={tile.viewbox} />
          <Typography className={classes.tileNumber} variant="h4">
            {tile.num}
          </Typography>
          {tile.text &&
          <Typography className={classes.tileDesc}  variant="subtitle1">
            { translate('sentTemplate')}
          </Typography>}

          {tile.text1 &&
          <Typography className={classes.tileDesc} variant="subtitle1">
             {translate('registeredEstablishment')}
          </Typography>}
        </div>
      ))}
    </>
  );
};
