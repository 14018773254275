import { useState, ChangeEvent, useCallback } from 'react';

type PageInfo = {
  page: number;
  pageSize: number;
};

type IsChangeEvent<T> = T extends number ? number : ChangeEvent<HTMLInputElement>;

export const usePageInfo = ({ page, pageSize }: PageInfo) => {
  const [pageInfo, setPageInfo] = useState({
    page,
    pageSize,
  });

  // eslint-disable-next-line @typescript-eslint/comma-dangle
  const changeRowsPerPage = useCallback(<T,>(newValue: IsChangeEvent<T>) => {
    if (typeof newValue === 'number') {
      setPageInfo((pageData) => ({
        ...pageData,
        pageSize: newValue,
      }));
      return;
    }

    setPageInfo((pageData) => ({
      ...pageData,
      pageSize: parseInt(newValue.target.value, 10),
    }));
  }, []);

  const changePage = useCallback((newValue: unknown | number, ...args: number[]) => {
    if (typeof newValue === 'number') {
      setPageInfo((pageData) => ({
        ...pageData,
        page: newValue,
      }));
      return;
    }

    setPageInfo((pageData) => ({
      ...pageData,
      page: args[0],
    }));
  }, []);

  return {
    pageInfo,
    changeRowsPerPage,
    changePage,
  };
};
