import { makeStyles, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import i18n from 'i18next';

import { TranslationNamespace } from 'i18n/config';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { QuestionnaireQuestion } from 'types/QuestionnaireQuestion';
import { QuestionnaireSection } from 'types/QuestionnaireSection';
import { CircleButton } from 'ui/buttons/CircleButton';
import { MoveIcon } from 'ui/icons/icons';
import { useCreateTranslate } from 'utilities/translate.hook';

import { useGetQuestionsBySectionIdQuery, usePatchQuestionOrderMutation } from '../../api/admin.api';
import { getDirection, getReverseDirection } from '../../../../utilities/useLanguage';

interface AdminQuestionnaireReorderQuestionsProps {
  section: QuestionnaireSection;
  setIsReordered: (isReordered: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  section: {
    flip: false,
    padding: '14px 24px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: '#FAFAFA',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  dragIcon: {
    flexShrink: 0,
  },
  sectionTexts: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  sectionNumber: {
    color: theme.palette.text.secondary,
  },
}));

export const AdminQuestionnaireReorderQuestions: React.FC<AdminQuestionnaireReorderQuestionsProps> = ({
  section,
  setIsReordered,
}) => {
  const { data: questions } = useGetQuestionsBySectionIdQuery({ sectionId: section.id });
  const [patchQuestion] = usePatchQuestionOrderMutation();
  const [questionItems, setQuestionItems] = React.useState<QuestionnaireQuestion[]>([]);
  const classes = useStyles();
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const showNotification = useNotifications();
  const direction = getDirection(i18n.language);

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const movedQuestion = questionItems[result.source.index];
    patchQuestion({ id: movedQuestion.id, new_order: result.destination.index + 1 })
      .unwrap()
      .catch((err) =>
        showNotification(NotificationType.Error, {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          errorId: err.data.errorCode,
        })
      );
    const items = reorder(questionItems, result.source.index, result.destination.index);
    setIsReordered(true);

    setQuestionItems(items);
  };

  const reorder: (list: QuestionnaireQuestion[], startIndex: number, endIndex: number) => QuestionnaireQuestion[] = (
    list,
    startIndex,
    endIndex
  ) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  useEffect(() => {
    if (questions) {
      setQuestionItems(questions);
    }
  }, [questions]);
  const reverseDirection = getReverseDirection(i18n.language);

  return (
    questionItems && (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="questionsList">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {questionItems.map((question, index) => (
                <Draggable draggableId={String(question.id)} index={index} key={`reorder-question-${question.id}`}>
                  {(prov) => (
                    <div
                      dir={reverseDirection}
                      className={classes.section}
                      ref={prov.innerRef}
                      {...prov.draggableProps}
                    >
                      <div {...prov.dragHandleProps}>
                        <CircleButton size={40} icon={MoveIcon} className={classes.dragIcon} />
                      </div>
                      <div dir={direction} className={classes.sectionTexts}>
                        <Typography variant="body1" color="primary">
                          {question.textAR}
                        </Typography>
                        <Typography variant="body1" color="primary">
                          {question.textEN}
                        </Typography>
                        <Typography variant="body2" className={classes.sectionNumber}>
                          {`${translate('question')} ${index + 1} ${translate('of')} ${questionItems?.length}`}
                        </Typography>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  );
};
