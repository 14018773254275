import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { SubmitHandler, useForm, Controller, FieldError } from 'react-hook-form';
import i18n from 'i18next';

import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { useAppDispatch, useAppSelector } from 'infrastructure/store/rootStore.hooks';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { isQuestionnaireDraftOrMissing } from 'domain/admin/utils/QuestionnaireUtils';
import { enInputRegex, arInputRegex } from 'infrastructure/constants/regex.constants';

import {
  useCreateQuestionnaireMutation,
  useGetEconomicActivityListQuery,
  useGetQuestionnaireByIdQuery,
  useUpdateQuestionnaireMutation,
} from '../api/admin.api';
import { selectQuestionnaireId, setQuestionaryId } from './AdminQuestionnaire.slice';
import { AssessmentTemplate } from '../types/AssessmentTemplate';
import { AdminQuestionnaireStepTopic } from './AdminQuestionnaireStepTopic';
import { getDirection } from '../../../utilities/useLanguage';

export type AdminQuestionnaireStepProps = {
  goNextStep: (event: React.BaseSyntheticEvent | undefined) => void;
};

type GeneralInfo = Pick<AssessmentTemplate, 'nameAR' | 'nameEN' | 'economicActivityId'>;

const useStyles = makeStyles((theme) => ({
  main: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  formWrapper: {
    marginTop: theme.spacing(3),
    width: '100%',
  },

  field: {
    borderRadius: theme.spacing(0.5),
    textAlign: 'left',
  },

  input: {
    marginBottom: theme.spacing(3),
  },
}));

export const AdminQuestionnaireStepOne: React.FC<AdminQuestionnaireStepProps> = ({ goNextStep }) => {
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const translateCommon = useCreateTranslate(TranslationNamespace.common);
  const classes = useStyles();

  const showNotification = useNotifications();
  const dispatch = useAppDispatch();
  const questionnaireId = useAppSelector(selectQuestionnaireId);
  const { data: questionnaire, isLoading } = useGetQuestionnaireByIdQuery(questionnaireId, {
    skip: !questionnaireId,
  });
  const [createQuestionnaire, { isLoading: isLoadingCreate }] = useCreateQuestionnaireMutation();
  const [updateQuestionnaire] = useUpdateQuestionnaireMutation();
  const { data: economicActivities } = useGetEconomicActivityListQuery();
  const direction = getDirection(i18n.language);

  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm<GeneralInfo>({
    defaultValues: {
      nameAR: questionnaire?.nameAR || '',
      nameEN: questionnaire?.nameEN || '',
      economicActivityId: questionnaire?.economicActivityId || 0,
    },
  });

  React.useEffect(() => {
    !isLoading &&
      reset({
        nameAR: questionnaire?.nameAR,
        nameEN: questionnaire?.nameEN,
        economicActivityId: questionnaire?.economicActivityId,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const onSubmit: SubmitHandler<GeneralInfo> = async ({ nameAR, nameEN, economicActivityId }, event) => {
    try {
      if (!isLoadingCreate && !questionnaireId) {
        const newQuestionnaire = await createQuestionnaire({
          nameAR,
          nameEN,
          economic_activity_id: economicActivityId,
        }).unwrap();
        dispatch(setQuestionaryId(newQuestionnaire.id));
      }

      if (
        questionnaire &&
        (questionnaire.nameAR !== nameAR ||
          questionnaire.nameEN !== nameEN ||
          questionnaire.economicActivityId !== economicActivityId)
      ) {
        await updateQuestionnaire({ ...questionnaire, nameAR, nameEN, economicActivityId }).unwrap();
      }

      goNextStep(event);
    } catch (err) {
      const error = err as Record<'status', number>;
      if (error.status === 409) {
        showNotification(NotificationType.Error, { errorId: '2003' });
      } else {
        showNotification(NotificationType.Error);
      }
    }
  };

  const getHelperText = (error?: FieldError) => {
    if (error) {
      if (error.type === 'required') {
        return translateCommon('fieldRequired');
      }
      return error.message;
    }
    return ' ';
  };

  return (
    <div dir={direction} className={classes.main}>
      <AdminQuestionnaireStepTopic topicLabelName="generalInfo" />
      <form id="stepForm" onSubmit={handleSubmit(onSubmit)}>
        <FormControl className={classes.formWrapper}>
          <Controller
            control={control}
            name="nameAR"
            rules={{
              required: true,
              maxLength: 300,
              minLength: 1,
              pattern: {
                value: arInputRegex,
                message: translate('arInputMassage'),
              },
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                type="text"
                variant="outlined"
                className={`${classes.field} ${classes.input}`}
                placeholder={translate('enterName')}
                label={translateCommon('selfAssessmentName')}
                dir={direction}
                disabled={!isQuestionnaireDraftOrMissing(questionnaire)}
                error={invalid}
                helperText={getHelperText(error)}
              />
            )}
          />
          <Controller
            control={control}
            name="nameEN"
            rules={{
              required: true,
              maxLength: 300,
              minLength: 1,
              pattern: {
                value: enInputRegex,
                message: translate('enInputMassage'),
              },
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                type="text"
                variant="outlined"
                className={`${classes.field} ${classes.input}`}
                placeholder={translate('enterName')}
                label={translateCommon('selfAssessmentEnName')}
                dir={direction}
                disabled={!isQuestionnaireDraftOrMissing(questionnaire)}
                error={invalid}
                helperText={getHelperText(error)}
              />
            )}
          />
          <Controller
            name="economicActivityId"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                select
                type="text"
                variant="outlined"
                className={classes.field}
                error={errors.economicActivityId?.type === 'required'}
                placeholder={translate('selectEconomicActivity')}
                label={translateCommon('economicActivity')}
                disabled={!isQuestionnaireDraftOrMissing(questionnaire)}
              >
                <MenuItem key="0-economicActivity" value={0} disabled></MenuItem>
                {economicActivities &&
                  economicActivities.map((economicActivity) => (
                    <MenuItem key={`${economicActivity.id}-${economicActivity.nameEn}`} value={economicActivity.id}>
                      {economicActivity.nameAr}
                    </MenuItem>
                  ))}
              </TextField>
            )}
          />
        </FormControl>
      </form>
    </div>
  );
};
