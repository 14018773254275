import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useKeycloak } from "@react-keycloak/web";

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';

import { LandingPageButtons } from './LandingPageButtons';
import { CommonLandingProps } from './types/CommonLandingProps';

const useStyles = makeStyles<Theme>((theme) => ({
  hero: {
    height: 'calc(100vh - 72px)',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(8),
    },
    [theme.breakpoints.up('md')]: {
      width: '75%',
      height: 'calc(100vh - 108px)',
      paddingInline: 108,
    },
    [theme.breakpoints.up('lg')]: {
      width: '70%',
    },
  },
  heroDesc: {
    marginBottom: 88,
    lineHeight: 1.4,
    fontWeight: 300,
  },
  text: {
    lineHeight: 1.4,
    fontWeight: 300,
  },
  sectionTopic: {
    marginBottom: theme.spacing(3),
    lineHeight: 1.4,
    fontWeight: 300,
  },
  button: {
    color: theme.palette.common.white,
    width: '100%',
    height: 50,
    [theme.breakpoints.up('md')]: {
      width: 184,
    },
  },

}));

type HeroSectionProps = CommonLandingProps;

export const HeroSection = ({ isUpMd, onClickBelow, onClick }: HeroSectionProps) => {
  const classes = useStyles();
  const { keycloak } = useKeycloak();
  const translate = useCreateTranslate(TranslationNamespace.landingPage);

  return (
    <div className={classes.hero}>
      <Typography className={classes.sectionTopic} variant={!isUpMd ? 'h5' : 'h3'}>
        {translate('heroTopic2')}
      </Typography>
      <Typography className={clsx(classes.heroDesc, classes.text)} variant={!isUpMd ? 'body1' : 'h5'}>
        {translate('heroDesc')}
      </Typography>
      {!isUpMd ? (
        <LandingPageButtons onClick={onClick} onClickBelow={onClickBelow} upperButton={true} />
      ) : (
        <LandingPageButtons  onClick={() => keycloak.login()} onClickBelow={onClickBelow} upperButton={true} />
      )}
    </div>
  );
};
