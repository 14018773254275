import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { AxiosRequestConfig } from 'axios';

import { BaseQueryError } from 'infrastructure/persistence/baseQueryWithReAuth';
import { Results } from 'interfaces/Results';
import {
  GetQuestionQuery,
  PatchQuestionDto,
  PatchSectionDto,
  QuestionnaireQuestion,
  QuestionnaireQuestionDto,
  UpdateQuestionDto,
  toQuestionnaireQuestion,
  CreateQuestionInput,
} from 'types/QuestionnaireQuestion';
import {
  CreateQuestionnaireSectionDto,
  GetSectionsQuery,
  QuestionnaireSection,
  QuestionnaireSectionDto,
  toQuestionnaireSection,
  UpdateSectionDto,
} from 'types/QuestionnaireSection';

import { AdminApiTags, AdminApiTagType } from '../api/admin.tags.api';

export const questionSectionApiEndpoints = (
  build: EndpointBuilder<BaseQueryFn<AxiosRequestConfig, unknown, BaseQueryError, {}, {}>, AdminApiTags, 'adminApi'>
) => ({
  // sections
  getSectionsByQuestionnaireId: build.query<QuestionnaireSection[], GetSectionsQuery>({
    query: ({ pageSize = 100, questionnaireId }) => ({
      url: `/section/?questionnaire__id=${questionnaireId}&page_size=${pageSize}`,
      method: 'GET',
    }),
    providesTags: (result) =>
      result
        ? [...result.map(({ id }) => ({ type: AdminApiTagType.QSection as const, id })), AdminApiTagType.QSection]
        : [AdminApiTagType.QSection],
    transformResponse: (response: Results<QuestionnaireSectionDto>) => response.results.map(toQuestionnaireSection),
  }),
  createSection: build.mutation<QuestionnaireSection, CreateQuestionnaireSectionDto>({
    query: (dto) => ({
      url: '/section/',
      method: 'POST',
      data: dto,
    }),
    transformResponse: (response: QuestionnaireSectionDto) => toQuestionnaireSection(response),
    invalidatesTags: (result, error, { questionnaire_id }) => [
      AdminApiTagType.QSection,
      { type: AdminApiTagType.Questionnaire, id: questionnaire_id },
    ],
  }),
  updateSection: build.mutation<QuestionnaireSection, UpdateSectionDto>({
    query: ({ data, id }) => ({
      url: `/section/${id}/`,
      method: 'PATCH',
      data,
    }),
    transformResponse: (response: QuestionnaireSectionDto) => toQuestionnaireSection(response),
    invalidatesTags: (result, error, { data: { questionnaire_id } }) => [
      AdminApiTagType.QSection,
      { type: AdminApiTagType.Questionnaire, id: questionnaire_id },
    ],
  }),
  deleteSection: build.mutation<void, { id: number; questionnaireId: number }>({
    query: ({ id }) => ({
      url: `/section/${id}/`,
      method: 'DELETE',
    }),
    invalidatesTags: (result, error, { questionnaireId }) => [
      AdminApiTagType.QSection,
      { type: AdminApiTagType.Questionnaire, id: questionnaireId },
    ],
  }),
  patchSectionOrder: build.mutation<() => void, PatchSectionDto>({
    query: (data) => ({
      url: `/section/${data.id}/change_order/`,
      method: 'PATCH',
      data,
    }),
    invalidatesTags: (result, error, arg) => [{ type: AdminApiTagType.QSection, id: arg.id }],
  }),

  // questions

  getQuestionsBySectionId: build.query<QuestionnaireQuestion[], GetQuestionQuery>({
    query: ({ pageSize = 100, sectionId }) => ({
      url: `/question/?section__id=${sectionId}&page_size=${pageSize}`,
      method: 'GET',
    }),
    providesTags: (result, error, arg) =>
      result
        ? [
            ...result.map(({ id }) => ({ type: AdminApiTagType.QSectionQuestion as const, id })),
            AdminApiTagType.QSectionQuestion,
            { type: AdminApiTagType.QSectionQuestions, id: arg.sectionId },
          ]
        : [{ type: AdminApiTagType.QSectionQuestions, id: arg.sectionId }],
    transformResponse: (response: Results<QuestionnaireQuestionDto>) => response.results.map(toQuestionnaireQuestion),
  }),
  createQuestion: build.mutation<QuestionnaireQuestion, CreateQuestionInput>({
    query: ({ data }) => ({
      url: '/question/',
      method: 'POST',
      data,
    }),
    transformResponse: (response: QuestionnaireQuestionDto) => toQuestionnaireQuestion(response),
    invalidatesTags: (result, error, arg) => [
      { type: AdminApiTagType.QSectionQuestions, id: arg.data.section_id },
      AdminApiTagType.QSection,
      { type: AdminApiTagType.Questionnaire, id: arg.questionnaireId },
    ],
  }),
  deleteQuestion: build.mutation<void, { id: number; questionnaireId: number }>({
    query: ({ id }) => ({
      url: `/question/${id}/`,
      method: 'DELETE',
    }),
    invalidatesTags: (result, error, { id, questionnaireId }) => [
      { type: AdminApiTagType.QSectionQuestion, id },
      AdminApiTagType.QSection,
      { type: AdminApiTagType.Questionnaire, id: questionnaireId },
    ],
  }),
  getQuestion: build.query<QuestionnaireQuestion, number>({
    query: (id) => ({
      url: `/question/${id}/`,
      method: 'GET',
    }),
    transformResponse: (response: QuestionnaireQuestionDto) => toQuestionnaireQuestion(response),
  }),
  updateQuestion: build.mutation<QuestionnaireQuestion, UpdateQuestionDto>({
    query: ({ id, data }) => ({
      url: `/question/${id}/`,
      method: 'PATCH',
      data,
    }),
    transformResponse: (response: QuestionnaireQuestionDto) => toQuestionnaireQuestion(response),
    invalidatesTags: (result, error, arg) => [
      { type: AdminApiTagType.QSectionQuestions, id: arg.data.section_id },
      AdminApiTagType.QSection,
      { type: AdminApiTagType.Questionnaire, id: arg.questionnaireId },
    ],
  }),
  patchQuestionOrder: build.mutation<() => void, PatchQuestionDto>({
    query: (data) => ({
      url: `/question/${data.id}/change_order/`,
      method: 'PATCH',
      data,
    }),
    invalidatesTags: (result, error, arg) => [
      { type: AdminApiTagType.QSectionQuestions, id: arg.id },
      { type: AdminApiTagType.QSectionQuestion },
    ],
  }),
});
