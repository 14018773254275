import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import i18n from 'i18next';
import clsx from 'clsx';

import { TranslationNamespace } from 'i18n/config';
import { LoadingWrapper } from 'ui/LoadingWrapper';
import { useCreateTranslate } from 'utilities/translate.hook';
import { Button } from 'ui/Button';
import { BaseQueryError } from 'infrastructure/persistence/baseQueryWithReAuth';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { TypeOfPlatform, Role } from 'infrastructure/services/auth.type';
import { PublicLayout } from 'domain/common/ui/PublicLayout';
import { useNavigate } from 'utilities/navigate';

import { useGetPCRequestQuery, useGetSectionsByAssessmentIdQuery, useApprovedSafetySpecialistMutation, useRejectedSafetySpecialistMutation } from '../../admin/api/admin.api';
import { CompareSectionsBody as CommonCompareSectionsBody } from '../../common/assessment-compare/CompareSectionsBody';
import { Routes } from '../Routes';
import { getReverseDirection as getReverseDirectionForPCRequestAssessmentBar } from '../../../utilities/useLanguage';


type ReviewAnswerProps = {
  getPath: (value: Routes) => string;
  platform?: TypeOfPlatform;
  barId?: string;
  className?: string;
  pcRequestAnswerId?: number;
  currentAssessmentId?: number;
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginTop: theme.spacing(10.5),
  },
  stepper: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  leftButton: {
    flip: false,
    height: '50px',
    width: '187px',
    marginRight: theme.spacing(2),
  },

  saveDraft: {
    flip: false,
    flexShrink: 0,
    textAlign: 'left',
    marginRight: theme.spacing(5),
    marginLeft: theme.spacing(3),
  },

}));

export const CAPcReviewAnswer: React.FC<ReviewAnswerProps> = ({
    getPath,
    platform,
    barId,
    className,
    pcRequestAnswerId,
    currentAssessmentId,

}) => {
  const { pcRequestId } = useParams<{ pcRequestId: string }>();
  const { data: pcRequest, isLoading, isFetching } = useGetPCRequestQuery(+pcRequestId);
  const classes = useStyles();
  const translateCommon = useCreateTranslate(TranslationNamespace.common);
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const showNotification = useNotifications();
  const [approvedSafetySpecialist] = useApprovedSafetySpecialistMutation();
  const [rejectedSafetySpecialist] = useRejectedSafetySpecialistMutation();
  const navigate = useNavigate();

  const handleAuditorAuditor = async (role: Role) =>{
    await approvedSafetySpecialist({ pcRequestId:pcRequestId , userId:role })
      .unwrap()
      .then(() => {
        navigate(`${getPath(Routes.pcRequests)}`)
        showNotification(NotificationType.Success, {
          message: translate('approvedSuccess')
        });
      })
      .catch((error: BaseQueryError) => {
        showNotification(NotificationType.Error, { errorId: error.data.errorCode });
      });
  };


  const handleAuditorRejected = async (role: Role) =>{
    await rejectedSafetySpecialist({ pcRequestId:pcRequestId , userId:role })
      .unwrap()
      .then(() => {
        navigate(`${getPath(Routes.pcRequests)}`)
        showNotification(NotificationType.Success, {
          message: translate('approveRejected')
        });
      })
      .catch((error: BaseQueryError) => {
        showNotification(NotificationType.Error, { errorId: error.data.errorCode });
      });
  }

  const {
    data: { results: sections } = {
      count: 0,
      results: [],
    },
  } = useGetSectionsByAssessmentIdQuery(
    {
      // Sections are the same for each assessment in pc request.
      assessmentId: pcRequest?.assessments[0].id,
    },
    {
      skip: !pcRequest?.assessments[0],
    }
  );
  const reverseDirection = getReverseDirectionForPCRequestAssessmentBar(i18n.language);

  return (
    <PublicLayout>
      <Container className={classes.container} maxWidth="lg">
        <LoadingWrapper isLoading={isLoading || isFetching}>
          {pcRequest && sections &&
          <CommonCompareSectionsBody getPath={getPath} pcRequest={pcRequest} sections={sections} />}
        </LoadingWrapper>
        <Paper id={barId} dir={reverseDirection} className={clsx(classes.stepper, className)}>
          <Button
            variant="contained"
            size="large"
            className={classes.leftButton}
            color="secondary"
            onClick={() => {handleAuditorAuditor(Role.Inspector)}}
            >
              {translateCommon('approvePc')}
          </Button>

          <Button
            variant="contained"
            size="large"
            className={classes.leftButton}
            color="secondary"
            onClick={() => {handleAuditorRejected(Role.Inspector)}}
            data-testid="pc-request-assessment-bar-submit"
            >
              {translateCommon('rejectPc')}
            </Button>
        </Paper>
      </Container>
    </PublicLayout>
  );
};