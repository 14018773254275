import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import { Theme } from '@material-ui/core';
import clsx from 'clsx';

type PropTypes = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  size: number;
  viewBoxSize?: number;
  viewBoxWidth?: number;
  viewBoxHeight?: number;
  icon: React.FC;
  className?: string;
  edge?: false | 'end' | 'start' | undefined;
};

const useStyles = makeStyles<Theme, Pick<PropTypes, 'size'>>((theme) => ({
  button: {
    padding: 0,
    margin: 0,
    borderRadius: '50%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  svgIcon: {
    height: ({ size }) => `${size}px`,
    width: ({ size }) => `${size}px`,
  },
}));

export const CircleButton: React.FC<PropTypes> = ({
  onClick,
  size,
  viewBoxSize = 24,
  viewBoxWidth,
  viewBoxHeight,
  icon,
  className,
  edge = 'start',
}) => {
  const classes = useStyles({
    size,
  });

  return (
    <IconButton data-testid="circle-button" className={clsx(classes.button, className)} edge={edge} onClick={onClick}>
      <SvgIcon
        className={classes.svgIcon}
        component={icon}
        viewBox={`0 0 ${viewBoxWidth || viewBoxSize} ${viewBoxHeight || viewBoxSize}`}
      />
    </IconButton>
  );
};
