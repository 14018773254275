import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import i18n from 'i18next';

import { CollapseButton } from 'ui/buttons/CollapseButton';
import { AssessmentQuestionText } from 'ui/assessment/AssessmentQuestionText';
import { collapseButtonSpaceWidth, labelCardColor } from 'ui/styles';

import { AdminAssessmentCompareAnswers } from './AdminAssessmentCompareAnswers';
import { QuestionAndAnswerCompare } from '../admin-assessment-compare/types/QuestionAndAnswersCompare';
import { getDirection } from '../../../utilities/useLanguage';

type AdminAssessmentCompareQuestionProps = {
  questionNo: number;
  questionAndAnswers: QuestionAndAnswerCompare;
};

const useStyle = makeStyles<Theme, { isOpen: boolean }>((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
  },

  questionSpace: {
    padding: '18px 32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    borderBottom: ({ isOpen }) => `${isOpen ? '1px' : '0px'} solid ${theme.palette.grey[200]}`,
  },

  questionSpaceText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  collapseButtonSpace: {
    flip: false,
    flexShrink: 0,
    width: collapseButtonSpaceWidth,
    alignSelf: 'baseline',
  },

  questionText: {
    flexGrow: 1,
  },

  questionDescription: {
    flip: false,
    color: labelCardColor,
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(6),
  },
}));

export const AdminAssessmentCompareQuestion: React.FC<AdminAssessmentCompareQuestionProps> = ({
  questionNo,
  questionAndAnswers,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const classes = useStyle({ isOpen });

  const handleCollapse = () => {
    setIsOpen(!isOpen);
  };
  const direction = getDirection(i18n.language);

  return (
    <div dir={direction} className={classes.main}>
      <div className={classes.questionSpace}>
        <div className={classes.questionSpaceText}>
          <div className={classes.collapseButtonSpace}>
            <CollapseButton onClick={handleCollapse} />
          </div>
          <div className={classes.questionText}>
            <AssessmentQuestionText
              question={questionAndAnswers.question}
              questionNo={questionNo}
              isVisibleMark={false}
            />
          </div>
        </div>

        <Collapse in={isOpen}>
          <Typography dir={direction} variant="body2" className={classes.questionDescription}>
            {questionAndAnswers.question.descriptionAR}
            {questionAndAnswers.question.descriptionEN}
          </Typography>
        </Collapse>
      </div>
      <Collapse in={isOpen}>
        <AdminAssessmentCompareAnswers questionAndAnswer={questionAndAnswers} />
      </Collapse>
    </div>
  );
};
