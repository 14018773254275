import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useState } from 'react';
import i18n from 'i18next';

import { Role, RoleList } from 'infrastructure/services/auth.type';
import CustomTablePagination from 'ui/CustomTablePagination';
import { ActionIcon } from 'ui/icons/icons';
import { CircleButton } from 'ui/buttons/CircleButton';
import { backButtonBackgroundColor } from 'ui/styles';
import { usePageInfo } from 'utilities/usePageInfo';
import { notAvailableInfo } from 'utilities/notAvailableInfo';
import { User, UserAccountStatus } from 'types/User';
import { LoadingSpinner } from 'ui/LoadingSpinner';

import { UsersTableHeader } from './UsersTableHeader';
import { UserStatus } from './UserStatus';
import { UsersTableMenuDialog } from './UsersTableMenuDialog';
import { DeactivateUserDialog } from './DeactivateUserDialog';
import { UsersEditDialog } from './UsersEditDialog';
import { useGetUsersQuery } from '../api/admin.api';
import { InviteUserDialog } from './InviteUserDialog';
import { TwoFactorAuthenticationDialog } from './TwoFactorAuthenticationDialog';
import { useCreateTranslate } from '../../../utilities/translate.hook';
import { TranslationNamespace } from '../../../i18n/config';
import { UsersTableNoData } from './UsersTableNoData';
import { useGetMeQuery } from '../../../infrastructure/services/user.api';
import { getDirection, getReverseDirection } from '../../../utilities/useLanguage';
const useStyle = makeStyles((theme) => ({
  table: {
    minWidth: theme.breakpoints.values.lg,
  },
  menuButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  actionCell: {
    padding: '6px',
  },
  svgIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    width: '40px',
    backgroundColor: backButtonBackgroundColor,
  },

  cell: {
    padding: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  inactive: {
    color: '#757575',
  },
}));
type UsersSearchProps = {
  searchUser: string;
};

export const UsersTable: React.FC<UsersSearchProps> = ({ searchUser }) => {
  const classes = useStyle();
  const { pageInfo, changePage, changeRowsPerPage } = usePageInfo({ page: 0, pageSize: 5 });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement | undefined>(null);
  const [selectedUser, setSelectedUser] = React.useState<null | User>(null);
  const [isDeactivateOpen, setDeactivateOpen] = React.useState(false);
  const [isEditFormOpen, setEditFormOpen] = useState(false);
  const [isInviteFormOpen, setInviteFormOpen] = useState(false);
  const [isTwoAuthenticationFormOpen, setTwoAuthenticationFormOpen] = useState(false);
  const { data: currentUser } = useGetMeQuery();

  const { data: { count, results } = { count: 0, results: [] }, isLoading } = useGetUsersQuery({
    accountTypes: [Role.Admin, Role.Auditor, Role.Inspector],
    parameters: { page: pageInfo.page + 1, pageSize: pageInfo.pageSize },
    status: [
      UserAccountStatus.Created,
      UserAccountStatus.Invited,
      UserAccountStatus.Activated,
      UserAccountStatus.Deactivated,
    ],
    param: { searchInfo: [searchUser] },
  });
  const handleActionButton = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, user: User) => {
    setAnchorEl(e.currentTarget?.parentElement?.parentElement);
    setSelectedUser(user);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const openDeactivateDialog = () => {
    setDeactivateOpen(true);
    handleCloseMenu();
  };

  const openEditUserDialog = () => {
    setEditFormOpen(true);
    handleCloseMenu();
  };
  const openInviteUserDialog = () => {
    setInviteFormOpen(true);
    handleCloseMenu();
  };
  const openTwoFactorAuthenticationDialog = () => {
    setTwoAuthenticationFormOpen(true);
    handleCloseMenu();
  };

  const isInactive = (status: UserAccountStatus) => status === UserAccountStatus.Deactivated;
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const direction = getDirection(i18n.language);
  const reverseDirection = getReverseDirection(i18n.language);

  return (
    <div dir={reverseDirection}>
      {results.length > 0 ? (
        <TableContainer component={Paper}>
          <Table className={classes.table} size="medium">
            <UsersTableHeader />
            <TableBody>
              {results.map((user) => (
                <TableRow key={`user-${user.email}`}>
                  <TableCell className={classes.actionCell}>
                    {!isInactive(user.status) && (
                      <div className={classes.menuButtonWrapper}>
                        <CircleButton
                          size={20}
                          icon={ActionIcon}
                          className={classes.svgIcon}
                          viewBoxWidth={22}
                          viewBoxHeight={16}
                          onClick={(e) => {
                            handleActionButton(e, user);
                          }}
                        />
                      </div>
                    )}
                  </TableCell>

                  <TableCell className={classes.cell}>
                    <UserStatus status={user.status} />
                  </TableCell>
                  <TableCell className={clsx(classes.cell, isInactive(user.status) && classes.inactive)}>
                    {currentUser
                      ? translate(
                          RoleList(currentUser.currentplatform).filter((role) => role.value === user.accountType)[0]
                            .name
                        )
                      : ''}
                  </TableCell>
                  <TableCell className={clsx(classes.cell, isInactive(user.status) && classes.inactive)}>
                    {notAvailableInfo(user.phoneNumber)}
                  </TableCell>
                  <TableCell className={clsx(classes.cell, isInactive(user.status) && classes.inactive)}>
                    {user.email}
                  </TableCell>
                  <TableCell className={clsx(classes.cell, isInactive(user.status) && classes.inactive)}>
                    {user.lastName}
                  </TableCell>
                  <TableCell className={clsx(classes.cell, isInactive(user.status) && classes.inactive)}>
                    {user.firstName}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <CustomTablePagination
                  dir={direction}
                  count={count}
                  rowsPerPage={pageInfo.pageSize}
                  page={pageInfo.page}
                  SelectProps={{
                    native: true,
                  }}
                  onPageChange={changePage}
                  onRowsPerPageChange={changeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        ) : isLoading ? (
          <LoadingSpinner type='admin' />
        ) : (
        <UsersTableNoData userSearchNotFound={searchUser} />
      )}
      {anchorEl && (
        <UsersTableMenuDialog
          anchorEl={anchorEl}
          handleCloseMenu={handleCloseMenu}
          handleDeactivate={openDeactivateDialog}
          handleEditUser={openEditUserDialog}
          handleInviteUser={openInviteUserDialog}
          handleTwoFactorAuthentication={openTwoFactorAuthenticationDialog}
          user={selectedUser}
        />
      )}
      {isDeactivateOpen && selectedUser && (
        <DeactivateUserDialog
          open={isDeactivateOpen}
          handleClose={() => setDeactivateOpen(false)}
          user={selectedUser}
        />
      )}
      {isEditFormOpen && selectedUser && (
        <UsersEditDialog id={selectedUser.id} isFormOpen={isEditFormOpen} handleClose={() => setEditFormOpen(false)} />
      )}
      {isInviteFormOpen && selectedUser && (
        <InviteUserDialog open={isInviteFormOpen} handleClose={() => setInviteFormOpen(false)} user={selectedUser} />
      )}
      {isTwoAuthenticationFormOpen && selectedUser && (
        <TwoFactorAuthenticationDialog
          open={isTwoAuthenticationFormOpen}
          handleClose={() => setTwoAuthenticationFormOpen(false)}
          user={selectedUser}
        />
      )}
    </div>
  );
};
