/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { MouseEventHandler } from 'react';
import { useForm, SubmitHandler, Controller, FieldError } from 'react-hook-form';
import i18n from 'i18next';

import { TranslationNamespace } from 'i18n/config';
import {
  emailRegex,
  saudiPhoneNumberRegex,
  phoneNumberRegex,
  taxNumberRegex,
  numberPlusRegex,
  numberRegex,
  enNameRegex,
} from 'infrastructure/constants/regex.constants';
import { useNavigate } from 'utilities/navigate';
import { useCreateTranslate } from 'utilities/translate.hook';
import { EconomicActivity } from 'types/EconomicActivity';
import {
  CompanyRepresentativeRoutes,
  getCompanyRepresentativePath,
} from 'domain/company-representative/CompanyRepresentativeRoutes';
import { useFormStyles } from 'styles/form';
import { Map } from 'ui/Map';

import { CrFillMissingInfoCity } from './CrFillMissingInfoCity';
import { FormValues, toEstablishmentInfoDto } from '../types/MissingInfo';
import { Region } from '../types/Localization';
import { useAddEstablishmentInfoMutation } from '../api/companyRepresentative.api';
import { getDirection } from '../../../utilities/useLanguage';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
  },
  wrapper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    [theme.breakpoints.up('md')]: {
      paddingInline: 120,
      paddingBlock: 40,
    },
  },
  inputField: {
    marginBlock: theme.spacing(1.5),
    [theme.breakpoints.up('md')]: {
      marginBlock: theme.spacing(2),
    },
  },
  error: {
    color: theme.palette.error.main,
  },
  terms: {
    fontSize: 14,
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
    },
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'stretch',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row-reverse',
      marginInline: -theme.spacing(1),
    },
  },
  button: {
    [theme.breakpoints.up('md')]: {
      width: '50%',
      flexDirection: 'row-reverse',
      marginInline: theme.spacing(1),
    },
  },
  topic: {
    marginBottom: theme.spacing(1.5),
  },
  topicDescription: {
    color: theme.palette.text.secondary,
  },
  headerWrapper: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(7),
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(7),
    },
  },
  sectionHeader: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  language: {
    color: theme.palette.common.white,
  },
  map: {
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(7),
    },
  },
  field: {
    marginTop: theme.spacing(4),
  },
}));

export interface FillMissingInfoFormProps {
  formValues: Partial<FormValues>;
  economicActivities: EconomicActivity[];
  regionOptions: Region[];
}

export const CrFillMissingInfoForm: React.FC<FillMissingInfoFormProps> = ({
  formValues,
  economicActivities,
  regionOptions,
}) => {
  const classes = useStyles();
  const formClasses = useFormStyles();
  const navigate = useNavigate();
  const [putEstablishmentInfo] = useAddEstablishmentInfoMutation();
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const translateCommon = useCreateTranslate(TranslationNamespace.common);
  const direction = getDirection(i18n.language);

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: formValues,
  });

  const preventDefaultIfEnterClicked = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  const preventDefaultIfNotNumberAndPlusValues = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!numberPlusRegex.test(e.key)) {
      e.preventDefault();
    }
  };

  const preventDefaultIfNotNumberValues = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!numberRegex.test(e.key)) {
      e.preventDefault();
    }
  };

  const onSubmit: SubmitHandler<FormValues> = (v) => {
    putEstablishmentInfo({ establishmentId: v.id, establishmentInfo: toEstablishmentInfoDto(v) })
      .unwrap()
      .then((payload) =>
        navigate(`${getCompanyRepresentativePath(CompanyRepresentativeRoutes.establishment)}/OSH/${v.id}`)
      )
      // eslint-disable-next-line no-console
      .catch((error) => console.error('Submit error: ', error));
  };
  const getErrorText = (type: FieldError['type'] | undefined) => {
    switch (type) {
      case 'required':
        return translate('fieldRequired');
      case 'pattern':
        return translate('invalidFormat');
      default:
        return '';
    }
  };

  const translation = useCreateTranslate(TranslationNamespace.admin);
  const goBack: MouseEventHandler = (e) => {
    navigate(getCompanyRepresentativePath(CompanyRepresentativeRoutes.companySelect));
  };

  const getHelperText = (error?: FieldError) => {
    if (error) {
      if (error.type === 'required') {
        return translateCommon('fieldRequired');
      }

      return error.message;
    }

    return ' ';
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.headerWrapper}>
        <Typography variant="h5" className={classes.topic}>
          {translate('fillCompanyDetails')}
        </Typography>
        <Typography variant="body2" className={classes.topicDescription}>
          {translate('completeInformation')}
        </Typography>
      </div>
      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e: React.KeyboardEvent<HTMLFormElement>) => preventDefaultIfEnterClicked(e)}
      >
        <div className={classes.section}>
          <div className={classes.sectionHeader}>
            <Typography variant="h6">{translate('CRDetails')}</Typography>
          </div>
          <Controller
            name="companyRepresentativeName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!!formValues.companyRepresentativeName}
                variant="outlined"
                className={classes.inputField}
                error={!!errors.companyRepresentativeName?.type}
                helperText={getErrorText(errors.companyRepresentativeName?.type)}
                label={translate('CRName')}
              />
            )}
          />

          <Controller
            name="email"
            control={control}
            rules={{ required: true, pattern: emailRegex }}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!!formValues.email}
                variant="outlined"
                className={classes.inputField}
                error={!!errors.email?.type}
                helperText={getErrorText(errors.email?.type)}
                label={translate('email')}
              />
            )}
          />

          <Controller
            name="phoneNumber"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: saudiPhoneNumberRegex,
                message: translation('invalidPhoneFormat'),
              },
              min: 0,
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                variant="outlined"
                type="text"
                inputMode="numeric"
                InputProps={{
                  classes: {
                    input: formClasses.numberInput,
                  },
                }}
                className={classes.field}
                error={invalid}
                helperText={getHelperText(error)}
                label={translateCommon('phoneNumber')}
                placeholder={translation('formatPhoneField')}
                onKeyPress={preventDefaultIfNotNumberAndPlusValues}
              />
            )}
          />

          <Controller
            name="phoneNumberAdditional"
            control={control}
            rules={{
              pattern: {
                value: saudiPhoneNumberRegex,
                message: translation('invalidPhoneFormat'),
              },
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                variant="outlined"
                type="text"
                inputMode="numeric"
                InputProps={{
                  classes: {
                    input: formClasses.numberInput,
                  },
                }}
                className={classes.field}
                error={invalid}
                helperText={getHelperText(error)}
                label={translate('phoneNumberAdditional')}
                placeholder={translation('formatPhoneField')}
                onKeyPress={preventDefaultIfNotNumberAndPlusValues}
              />
            )}
          />
        </div>

        <div className={classes.section}>
          <div className={classes.sectionHeader}>
            <Typography variant="h6">{translate('companyDetails')}</Typography>
          </div>

          <Controller
            name="extId"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!!formValues.extId}
                variant="outlined"
                className={classes.inputField}
                error={!!errors.extId?.type}
                helperText={getErrorText(errors.extId?.type)}
                label={translate('establishmentNumber')}
              />
            )}
          />

          <Controller
            name="nameAr"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!!formValues.nameAr}
                variant="outlined"
                className={classes.inputField}
                error={!!errors.nameAr?.type}
                helperText={getErrorText(errors.nameAr?.type)}
                label={translate('companyNameAr')}
              />
            )}
          />

          <Controller
            name="nameEn"
            control={control}
            rules={{ required: true, pattern: enNameRegex }}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!!formValues.nameEn}
                variant="outlined"
                className={classes.inputField}
                error={!!errors.nameEn?.type}
                helperText={getErrorText(errors.nameEn?.type)}
                label={translate('companyNameEn')}
              />
            )}
          />

          <Controller
            name="taxNumber"
            control={control}
            rules={{ pattern: taxNumberRegex }}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!!formValues.taxNumber}
                variant="outlined"
                className={classes.inputField}
                error={!!errors.taxNumber?.type}
                helperText={getErrorText(errors.taxNumber?.type)}
                label={translate('taxNumber')}
                onKeyPress={preventDefaultIfNotNumberValues}
              />
            )}
          />

          <Controller
            name="mobile"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: saudiPhoneNumberRegex,
                message: translation('invalidPhoneFormat'),
              },
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                variant="outlined"
                type="text"
                inputMode="numeric"
                dir={direction}
                InputProps={{
                  classes: {
                    input: formClasses.numberInput,
                  },
                }}
                className={classes.field}
                error={invalid}
                helperText={getHelperText(error)}
                label={translateCommon('phoneNumber')}
                placeholder={translation('formatPhoneField')}
                onKeyPress={preventDefaultIfNotNumberAndPlusValues}
              />
            )}
          />

          <Controller
            name="phone"
            control={control}
            rules={{ pattern: phoneNumberRegex }}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!!formValues.phone}
                variant="outlined"
                type="text"
                InputProps={{
                  classes: {
                    input: formClasses.numberInput,
                  },
                }}
                className={classes.inputField}
                error={!!errors.phone?.type}
                helperText={getErrorText(errors.phone?.type)}
                label={translate('companyPhoneNumber')}
                onKeyPress={preventDefaultIfNotNumberAndPlusValues}
              />
            )}
          />

          <Controller
            name="economicActivityNameAr"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!!formValues.economicActivityNameAr}
                variant="outlined"
                className={classes.inputField}
                error={!!errors.economicActivityNameAr?.type}
                helperText={getErrorText(errors.economicActivityNameAr?.type)}
                label={translate('economicActivity')}
              />
            )}
          />
        </div>

        <div className={classes.section}>
          <div className={classes.sectionHeader}>
            <Typography variant="h6">{translate('companyAddress')}</Typography>
            <Typography variant="body2" className={classes.topicDescription}>
              {translate('companyAddressDesc')}
            </Typography>
          </div>

          <Controller
            name="regionId"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                value={regionOptions.find((region) => region.id === field.value)}
                selectOnFocus
                clearOnBlur
                blurOnSelect
                className={classes.inputField}
                onChange={(e, option) => {
                  if (!option) return;
                  setValue('regionId', option.id);
                }}
                getOptionSelected={(option, v) => option?.id === v?.id}
                options={regionOptions}
                getOptionLabel={(region) => region.nameAr || ''}
                renderInput={(props) => (
                  <TextField
                    error={!!errors.regionId?.type}
                    helperText={getErrorText(errors.regionId?.type)}
                    {...props}
                    label={translate('region')}
                    variant="outlined"
                  />
                )}
              />
            )}
          />

          {watch('regionId') ? (
            <Controller
              name="cityId"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <CrFillMissingInfoCity
                  regionId={watch('regionId')!}
                  onChange={(e, option) => {
                    if (!option) return;
                    setValue('cityId', option.id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.cityId?.type}
                      helperText={getErrorText(errors.cityId?.type)}
                      label={translate('city')}
                      variant="outlined"
                    />
                  )}
                />
              )}
            />
          ) : (
            <Controller
              name="cityId"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <Autocomplete
                  selectOnFocus
                  clearOnBlur
                  blurOnSelect
                  className={classes.inputField}
                  options={[]}
                  disabled={!!formValues.cityId || !watch('regionId')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.cityId?.type}
                      helperText={getErrorText(errors.cityId?.type)}
                      label={translate('city')}
                      variant="outlined"
                    />
                  )}
                />
              )}
            />
          )}

          <Controller
            name="district"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!!formValues.district}
                variant="outlined"
                className={classes.inputField}
                error={!!errors.district?.type}
                helperText={getErrorText(errors.district?.type)}
                label={translate('district')}
              />
            )}
          />

          <Controller
            name="street"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!!formValues.street}
                variant="outlined"
                className={classes.inputField}
                error={!!errors.street?.type}
                helperText={getErrorText(errors.street?.type)}
                label={translate('street')}
              />
            )}
          />

          <Controller
            name="googleLocation"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <div className={classes.map}>
                <Map
                  setValue={(obj) => {
                    setValue('googleLocation', obj);
                  }}
                />
                {

                  errors.googleLocation?.type ? (
                    <FormHelperText className={classes.error}>
                      {

                        getErrorText(errors.googleLocation?.type)
                      }
                    </FormHelperText>
                  ) : null
                }
              </div>
            )}
          />
        </div>

        <div className={classes.section}>
          <div className={classes.sectionHeader}>
            <Typography variant="h6">{translate('socialMediaLinks')}</Typography>
          </div>

          <Controller
            name="linkedinUrl"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!!formValues.linkedinUrl}
                variant="outlined"
                className={classes.inputField}
                label={translate('linkedinLink')}
              />
            )}
          />

          <Controller
            name="twitterUrl"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!!formValues.twitterUrl}
                variant="outlined"
                className={classes.inputField}
                label={translate('twitterLink')}
              />
            )}
          />

          <Controller
            name="facebookUrl"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={!!formValues.facebookUrl}
                variant="outlined"
                className={classes.inputField}
                label={translate('facebookLink')}
                margin="normal"
              />
            )}
          />

          <FormControl error={!!errors.areTermsAccepted?.type}>
            <FormControlLabel
              control={
                <Controller
                  name="areTermsAccepted"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Checkbox
                      className={`${classes.terms} ${errors.areTermsAccepted?.type ? classes.error : ''}`}
                      {...field}
                    />
                  )}
                />
              }
              label={
                <span className={`${classes.terms} ${errors.areTermsAccepted?.type ? classes.error : ''}`}>
                  <span>{translate('termsAndConditionsPrefix')}</span>
                  <a href="/terms">{translate('termsAndConditions')}</a>
                  <span>{translate('termsAndConditionsPostfix')}</span>
                </span>
              }
              labelPlacement="end"
            />
          </FormControl>
        </div>

        <div className={classes.buttons}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => {
              const field = document.querySelector('.Mui-error');
              field?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }}
            className={classes.button}
          >
            {translateCommon('save')}
          </Button>
          <Button color="secondary" size="large" onClick={goBack} className={classes.button}>
            {translate('goBack')}
          </Button>
        </div>
      </form>
    </div>
  );
};
