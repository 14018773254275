import Collapse from '@material-ui/core/Collapse';
import Menu from '@material-ui/core/Menu';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import i18n from 'i18next';

import { Hint } from 'ui/Hint';
import { TranslationNamespace } from 'i18n/config';
import { TrashIcon, ActionIcon, EditIcon, SyncIcon } from 'ui/icons/icons';
import { CollapseButton } from 'ui/buttons/CollapseButton';
import { backButtonBackgroundColor, labelCardColor, shdShadow } from 'ui/styles';
import { useCreateTranslate } from 'utilities/translate.hook';
import { QuestionnaireSection } from 'types/QuestionnaireSection';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { CustomMenuItem } from 'ui/CustomMenuItem';
import { AssessmentTemplate } from 'domain/admin/types/AssessmentTemplate';
import { AssessmentTemplateStatus } from 'domain/admin/types/AssessmentTemplateStatus';
import { CircleButton } from 'ui/buttons/CircleButton';

import { useDeleteSectionMutation } from '../../api/admin.api';
import { AdminQuestionnaireQuestions } from './AdminQuestionnaireQuestions';
import { AdminQuestionnaireReorderDialog } from './AdminQuestionnaireReorderDialog';
import { AdminQuestionnaireSectionsDialog } from './AdminQuestionnaireSectionsDialog';
import { getDirection, getReverseDirection } from '../../../../utilities/useLanguage';

type AdminQuestionnaireSectionProps = {
  section: QuestionnaireSection;
  index: number;
  totalCount: number;
  questionnaire: AssessmentTemplate;
};

const useStyles = makeStyles((theme) => ({
  main: {
    boxShadow: shdShadow,
    marginTop: theme.spacing(5),
    padding: `${theme.spacing(4)}px 0`,
    display: 'flex',
    flexDirection: 'column',
  },


  icon: {
    color: theme.palette.info.main,
  },

  elementsWrapper: {
    padding: `0 ${theme.spacing(4)}px`,
    borderBottom: (props: { showQuestions: boolean }) =>
      props.showQuestions ? `1px solid ${theme.palette.grey[200]}` : 'none',
  },

  sectionDetails: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    paddingBottom: theme.spacing(4),
  },

  buttonSpace: {
    width: '176px',
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 0,
  },

  button: {
    display: 'flex',
    height: '48px',
    width: '48px',
    backgroundColor: backButtonBackgroundColor,
    marginRight: theme.spacing(2),
  },

  collapseButton: {
    flip: false,
    marginRight: theme.spacing(3),
  },

  questionsParts: {
    flexShrink: 0,
    width: '250px',
  },

  questionInfoNumber: {
    flip: false,
    flexShrink: 0,
    marginRight: theme.spacing(1.5),
  },

  sectionPart: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(3),
  },

  sectionPartText: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    marginRight: theme.spacing(3),
  },

  sectionPartHeader: {
    textTransform: 'uppercase',
    fontWeight: 700,
    color: labelCardColor,
  },

  sectionPartTopic: {
    marginTop: theme.spacing(1),
  },

  sectionPartTopicEn: {
    marginLeft: theme.spacing(5),
    marginTop: theme.spacing(1),
  },

  sectionPartDescription: {
    marginTop: theme.spacing(2),
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
    color: labelCardColor,
  },

  sectionNumberWrapper: {
    flexShrink: 0,
    marginRight: theme.spacing(3),
    height: '48px',
    width: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%',
  },

   hintWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: theme.spacing(4),
  },


  list: {
    marginLeft: 6,
  },

  left: {
    textAlign: 'left',
  },
  right: {
    textAlign: 'right',
  },
}));

export const AdminQuestionnaireSection: React.FC<AdminQuestionnaireSectionProps> = ({
  section,
  index,
  totalCount,
  questionnaire,
}) => {
  const [showQuestions, setShowQuestions] = React.useState(true);
  const [showReorder, setShowReorder] = React.useState(false);
  const [openSectionEditDialog, setOpenSectionEditDialog] = React.useState(false);

  const classes = useStyles({ showQuestions });
  const showNotification = useNotifications();
  const translateQuestionnaire = useCreateTranslate(TranslationNamespace.admin);
  const translateCommon = useCreateTranslate(TranslationNamespace.common);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [deleteSection, { isLoading }] = useDeleteSectionMutation();
  const isEditable = questionnaire.status === AssessmentTemplateStatus.Draft;
  const direction = getDirection(i18n.language);

  const QuestionsPart: React.FC<{ values: (number | string)[]; titles: string[] }> = ({ values, titles }) => {
    const createRow = (value: number | string, title: string) => (
      <tr key={`${value}-${title}`}>
        <td>
          <Typography variant="h6" className={classes.questionInfoNumber}>
            {value}
          </Typography>
        </td>
        <td>
          <Typography variant="body1">{title}</Typography>
        </td>
      </tr>
    );

    return (
      <table>
        <tbody>{values.map((value, indexV) => createRow(value, titles[indexV]))}</tbody>
      </table>
    );
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenEditSection = () => {
    setOpenSectionEditDialog(true);
  };

  const handleCloseSectionEditDialog = () => {
    setOpenSectionEditDialog(false);
  };

  const handleDeleteSection = async () => {
    if (!isLoading) {
      await deleteSection({ id: section.id, questionnaireId: questionnaire.id });
    }
  };

  const handleShowQuestions = () => {
    setShowQuestions((prev) => !prev);
  };

  const handleReorderOpenFn = (value: boolean, isReordered?: boolean) => {
    setShowReorder(value);

    if (!value && isReordered) {
      showNotification(NotificationType.Success, { message: translateQuestionnaire('changesSavedSuccessfully') });
    }
  };
  const reverseDirection = getReverseDirection(i18n.language);

  return (
    <>
      <Paper dir={reverseDirection} key={`questionnaire_section_${section.id}`} className={classes.main}>
        <div  className={classes.hintWrapper} ><Hint text={translateQuestionnaire('translateText')} type={'info'} /></div>
        <div className={classes.elementsWrapper}>
          <div className={classes.sectionDetails}>
            <div className={classes.buttonSpace}>
              <CollapseButton onClick={handleShowQuestions} />

              <CircleButton
                size={30}
                icon={ActionIcon}
                className={classes.button}
                viewBoxWidth={22}
                viewBoxHeight={16}
                onClick={handleMenuClick}
              />

            </div>
            <div className={classes.questionsParts}>
              <QuestionsPart
                values={[section.questionsNo, section.totalWeight]}
                titles={[translateQuestionnaire('numberOfQuestions'), translateQuestionnaire('overallSectionWeight')]}
              />

            </div>
            <div dir={direction} className={classes.sectionPart}>
              <Typography variant="body2" className={classes.sectionPartHeader}>
                {`${translateQuestionnaire('section')} ${index + 1} ${translateQuestionnaire('of')} ${totalCount}`}
              </Typography>
              <div className={classes.sectionPartText}>
                <div>
                  <Typography variant="h5" className={classes.sectionPartTopic}>
                     {i18n.language === 'en' ? section.nameEN : section.nameAR}
                  </Typography>
                </div>
              </div>
              <Typography variant="body2" className={classes.sectionPartDescription}>
                 {i18n.language === 'en' ? section.descriptionEN : section.descriptionAR}
              </Typography>
            </div>
            <div className={classes.sectionNumberWrapper}>
              <Typography color="secondary" variant="h5">
                {index + 1}
              </Typography>
            </div>
          </div>
        </div>
        <Collapse in={showQuestions}>
          <AdminQuestionnaireQuestions sectionId={section.id} isEditable={isEditable} />
        </Collapse>
        <Menu color="secondary" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
          {isEditable && (
            <div dir={reverseDirection}>
              <CustomMenuItem onClick={handleOpenEditSection}>
                <ListItemText className={classes.list}>{translateCommon('edit')}</ListItemText>
                <SvgIcon component={EditIcon} />
              </CustomMenuItem>
              <CustomMenuItem onClick={handleDeleteSection}>
                <ListItemText className={classes.list}>{translateCommon('delete')}</ListItemText>
                <SvgIcon component={TrashIcon} />
              </CustomMenuItem>
            </div>
          )}
          <div dir={reverseDirection}>
            <CustomMenuItem onClick={() => handleReorderOpenFn(true)}>
              <ListItemText className={classes.list}>{translateQuestionnaire('changeOrder')}</ListItemText>
              <SvgIcon component={SyncIcon} />
            </CustomMenuItem>
          </div>
        </Menu>
        <AdminQuestionnaireReorderDialog
          open={showReorder}
          handleClose={handleReorderOpenFn}
          questionnaireId={questionnaire.id}
        />
      </Paper>
      <AdminQuestionnaireSectionsDialog
        open={openSectionEditDialog}
        handleClose={handleCloseSectionEditDialog}
        questionnaireId={questionnaire.id}
        section={section}
      />
    </>
  );
};