/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { Collapse, Theme } from '@material-ui/core';
import i18n from 'i18next';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { AssessmentAnswerRadioGroup } from 'ui/assessment/AssessmentAnswerRadioGroup';
import { AssessmentAnswer, AssessmentAnswerType, CreateAnswerInput, UpdateAnswerInput } from 'types/AssessmentAnswer';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { BaseQueryError } from 'infrastructure/persistence/baseQueryWithReAuth';
import { AssessmentQuestion } from 'types/AssessmentQuestion';
import { labelCardColor } from 'ui/styles';
import { Hint } from 'ui/Hint';
import { debounceFn } from 'utilities/debounceFn';
import { AssessmentType } from 'types/Assessment';
import { AssessmentAnswerAttachments } from 'ui/assessment/AssessmentAnswerAttachments';

import {
  useCreateSAAnswerMutation,
  useDeleteSelfAssessmentAttachmentMutation,
  useUpdateSAAnswerMutation,
  useUploadSelfAssessmentAttachmentMutation,
} from '../../admin/api/admin.api';
import { getDirection } from '../../../utilities/useLanguage';

type PCRequestAssessmentCurrentAnswerProps = {
  assessmentId: number;
  question: AssessmentQuestion;
  answer?: AssessmentAnswer;
  currentAssessmentType: AssessmentType;
};

const useStyles = makeStyles<Theme, { attachmentShown: boolean }>((theme) => ({
  main: {
    flex: 1,
    padding: '32px 60px 32px 32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  additionalComment: {
    transform: 'scale(1)',
    transition: '0.3s all ease',
    marginTop: ({ attachmentShown }) => (attachmentShown ? theme.spacing(1) : theme.spacing(5)),
  },

  additionalCommentDesc: {
    marginTop: theme.spacing(1),
    color: labelCardColor,
  },

  comment: {
    marginTop: theme.spacing(3),
  },
}));

const debounce = debounceFn(500);

export const PCRequestAssessmentCurrentAnswer: React.FC<PCRequestAssessmentCurrentAnswerProps> = ({
  assessmentId,
  answer,
  question,
  currentAssessmentType,
}) => {
  const [answerType, setAnswerType] = React.useState<AssessmentAnswerType | null>(answer?.type || null);
  const [answerSpecialistComment, setAnswerSpecialistComment] = React.useState(answer?.specialistComment);
  const [needUpdate, setNeedUpdate] = React.useState(false);

  const classes = useStyles({
    attachmentShown: Boolean(
      answer?.attachments.length && answer?.attachments.length > 0 && answerType === AssessmentAnswerType.Yes
    ),
  });
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const translateCommon = useCreateTranslate(TranslationNamespace.common);
  const showNotification = useNotifications();

  // update/create data
  const [createAnswer, { isLoading: isLoadingCreateAnswer }] = useCreateSAAnswerMutation();
  const [updateAnswer, { isLoading: isLoadingUpdateAnswer }] = useUpdateSAAnswerMutation();
  const [uploadSelfAssessmentAttachment, { isLoading: isUploading }] = useUploadSelfAssessmentAttachmentMutation();
  const [delateSelfAssessmentAttachment] = useDeleteSelfAssessmentAttachmentMutation();
  const direction = getDirection(i18n.language);
  const [questionType, setQuestionType] = React.useState(question?.type || null);

  const resetAnswer = () => {
    setAnswerType(answer?.type || null);
    setAnswerSpecialistComment(answer?.specialistComment);
  };

  const createAnswerRef = useRef(
    debounce<CreateAnswerInput>(async (data) => {
      if (!isLoadingCreateAnswer) {
        await createAnswer(data)
          .unwrap()
          .catch((error: BaseQueryError) => {
            showNotification(NotificationType.Error, { errorId: error.data.errorCode });
            resetAnswer();
          });
      }
    })
  );

  const updateAnswerRef = useRef(
    debounce<UpdateAnswerInput>(async (data) => {
      if (!isLoadingUpdateAnswer) {
        await updateAnswer(data)
          .unwrap()
          .catch((error: BaseQueryError) => {
            showNotification(NotificationType.Error, { errorId: error.data.errorCode });
            resetAnswer();
          });
      }
    })
  );

  useEffect(() => {
    if (answerType && needUpdate) {
      if (answer) {
        updateAnswerRef
          .current({
            assessmentSectionId: question.sectionId,
            data: {
              id: answer.id,
              question_id: answer.questionId,
              self_assessment_id: answer.assessmentId,
              specialist_comment: answerSpecialistComment,
              type: answerType,
            },
          })
          ?.then(() => {
            setNeedUpdate(false);
          });
      } else {
        createAnswerRef
          .current({
            assessmentSectionId: question.sectionId,
            data: {
              question_id: question.id,
              self_assessment_id: assessmentId,
              type: answerType,
              specialist_comment: answerSpecialistComment,
            },
          })
          ?.then(() => {
            setNeedUpdate(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerType, answerSpecialistComment]);

  useEffect(() => {
    setQuestionType(question?.type || null);
    return () => {
      updateAnswerRef.current.cancel();
      createAnswerRef.current.cancel();
    };
  }, []);

  const changeAnswerType = async (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setAnswerType(+value);
    setNeedUpdate(true);
  };

  const onChangeText: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = async (event) => {
    if (event.target.value.length <= 500) {
      setAnswerSpecialistComment(event.target.value);
      setNeedUpdate(true);
    }
  };

  return (
    <div dir={direction} className={classes.main}>
      <Typography variant="h6">{translate('yourAnswer')}</Typography>
      <AssessmentAnswerRadioGroup
        questionType={questionType}
        answerType={answerType}
        changeAnswerType={changeAnswerType}
      />
      <Collapse in={currentAssessmentType === AssessmentType.INSPECTOR && answerType === AssessmentAnswerType.Yes}>
        <AssessmentAnswerAttachments
          answer={answer}
          assessmentSectionId={question.sectionId}
          isUploading={isUploading}
          uploadSelfAssessmentAttachment={uploadSelfAssessmentAttachment}
          delateSelfAssessmentAttachment={delateSelfAssessmentAttachment}
          uploadTitle={translate('uploadDocuments')}
          isOptional
        />
      </Collapse>
      <Typography variant="h6" className={classes.additionalComment}>
        {translate('additionalComment')}
      </Typography>
      <Typography variant="body1" className={classes.additionalCommentDesc}>
        {translate('provideAdditionalComment')}
      </Typography>
      <TextField
        className={classes.comment}
        onChange={onChangeText}
        value={answerSpecialistComment}
        type="text"
        variant="outlined"
        multiline
      />
      <Hint text={translateCommon('lengthOfComment')} />
    </div>
  );
};
