/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import i18n from 'i18next';

import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { Hint } from 'ui/Hint';
import { debounceFn } from 'utilities/debounceFn';
import { AssessmentUpdateInput } from 'types/Assessment';
import { BaseQueryError } from 'infrastructure/persistence/baseQueryWithReAuth';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { AssessmentAnswerMark } from 'ui/assessment/AssessmentAnswerMark';

import { useUpdateAssessmentMutation } from '../../admin/api/admin.api';
import { isValidAssessmentComment } from './utils/isValidAssessmentComment';
import { isAssessmentCommentMarkVisible } from './utils/isAssessmentCommentMarkVisible';
import { PCRequestAssessmentCommentHeader } from '../../admin/ui/PCRequestAssessmentCommentHeader';
import { getDirection } from '../../../utilities/useLanguage';

type PCRequestAssessmentCommentProps = {
  assessmentId: number;
  specialistComment: string;
};

const useStyle = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  header: {
    padding: '40px 32px',
    flip: false,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },

  commentWrapper: {
    flip: false,
    padding: '32px 56px 32px 208px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  commentField: {
    flip: false,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  commentText: {
    flexGrow: 1,
  },
  hint: {
    flip: false,
    paddingRight: theme.spacing(6),
  },
}));

const debounce = debounceFn(500);

export const PCRequestAssessmentComment: React.FC<PCRequestAssessmentCommentProps> = ({
  assessmentId,
  specialistComment,
}) => {
  const classes = useStyle();
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const translateCommon = useCreateTranslate(TranslationNamespace.common);
  const showNotification = useNotifications();
  const direction = getDirection(i18n.language);

  const [newSpecialistComment, setNewSpecialistComment] = React.useState(specialistComment);

  const onChangeText: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    if (event.target.value.length <= 500) {
      setNewSpecialistComment(event.target.value);

      updateSpecialistComment.current({
        assessmentId,
        data: {
          specialist_comment: event.target.value,
        },
      });
    }
  };

  const [updateMutation, { isLoading }] = useUpdateAssessmentMutation();

  const updateSpecialistComment = React.useRef(
    debounce<AssessmentUpdateInput>(async (data) => {
      if (!isLoading) {
        await updateMutation(data)
          .unwrap()
          .catch((error: BaseQueryError) => {
            showNotification(NotificationType.Error, { errorId: error.data.errorCode });
          });
      }
    })
  );
  React.useEffect(() => {
    return () => {
      updateSpecialistComment.current.cancel();
    };
  }, []);

  return (
    <Paper className={classes.main}>
      <PCRequestAssessmentCommentHeader
        topicTitle={translate('finalComment')}
        topicDescription={translate('provideFinalComment')}
      />
      <div className={classes.commentWrapper}>
        <div dir="ltr" className={classes.commentField}>
          <TextField
            dir={direction}
            className={classes.commentText}
            onChange={onChangeText}
            value={newSpecialistComment}
            type="text"
            variant="outlined"
            multiline
          />
          <AssessmentAnswerMark
            isVisible={isAssessmentCommentMarkVisible(newSpecialistComment)}
            isValid={isValidAssessmentComment(newSpecialistComment)}
          />
        </div>
        <div className={classes.hint}>
          <Hint text={translateCommon('lengthOfComment')}></Hint>
        </div>
      </div>
    </Paper>
  );
};
