import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useHistory } from 'react-router-dom';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { Button } from 'ui/Button';

import { AdminLayout } from '../ui/AdminLayout';
import { TemplateTable } from './TemplateTable';
import { getAdminPath } from '../AdminRoutes';
import { Routes } from '../../common/Routes';
import { Search } from '../../common/Search';

const useStyles = makeStyles((theme) => ({
  main: {
    padding: 0,
    marginTop: theme.spacing(15),
  },
  pageTitle: {
    marginBottom: theme.spacing(1.5),
  },

  createButton: {
    height: '50px',
    width: '200px',
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(-11),
    float: 'right',
  },

  inputInput: {
    padding: theme.spacing(1.8, 1, 1.8, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '20%',
  },
  header: {
    [theme.breakpoints.down('md')]: {
      padding: `0 ${theme.spacing(6)}px`,
    },
    marginBottom: theme.spacing(),
    marginTop: theme.spacing(10),
  },
}));

export const AdminDashboard: React.FC = () => {
  const history = useHistory();
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const classes = useStyles();
  const [query, setQuery] = useState('');

  const goToCreateQuestionnaire = () => {
    history.push(getAdminPath(Routes.questionnaire));
  };
  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;
    setQuery(value);
  };

  return (
    <AdminLayout>
      <Container className={classes.main} maxWidth="lg">
        <div className={classes.header}>
          <Typography variant="h4" className={classes.pageTitle}>
            {translate('selfAssessmentTemplates')}
          </Typography>
        </div>
        <Search name={translate('searchTemplate')} onChange={(e) => handleSearch(e)} />
        <div className={classes.inputInput} />

        <Button
          onClick={goToCreateQuestionnaire}
          variant="contained"
          color="secondary"
          className={classes.createButton}
          size="large"
        >
          {translate('createNewTemplate')}
        </Button>
        <TemplateTable searchTemplate={query} />
      </Container>
    </AdminLayout>
  );
};
