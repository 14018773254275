import { useTranslation } from 'react-i18next';

import { TranslationNamespace } from 'i18n/config';

export type TranslateFn = (translation: string, params?: Record<string, string>) => string;

export const useCreateTranslate = (namespace: TranslationNamespace): TranslateFn => {
  const { t } = useTranslation(namespace);

  return (translation: string, params?: Record<string, string>) => t(`${namespace}:${translation}`, params);
};
