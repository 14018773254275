import React from 'react';

import { useAppSelector } from 'infrastructure/store/rootStore.hooks';
import { selectRole } from 'infrastructure/services/authSlice.selector';
import { isAdmin as isAdminCheck } from 'infrastructure/services/auth.utils';

import { getAdminPath } from '../AdminRoutes';
import { Authenticate } from '../../common/authenticate/Authenticate';

export const AdminAuthenticate: React.FunctionComponent = () => {
  const isAdmin = isAdminCheck(useAppSelector(selectRole));
  return (
    <Authenticate
      isUser={isAdmin}
      getPath={getAdminPath}
      login={'admin'}
      googleAuthenticatorDescription={'googleAuthenticatorDescriptionAdmin'}
    />
  );
};
