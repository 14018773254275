import { Page } from 'infrastructure/persistence/types';
import { QuestionnaireSection, QuestionnaireSectionDto, toQuestionnaireSection } from 'types/QuestionnaireSection';

export type AssessmentSectionDto = QuestionnaireSectionDto & {
  valid_answers_no: number;
};

export type AssessmentSection = QuestionnaireSection & {
  validAnswersNo: number;
};

export type GetAssessmentSectionQuery = Page & {
  assessmentId?: number;
};

export const toAssessmentSection = (dto: AssessmentSectionDto): AssessmentSection => ({
  ...toQuestionnaireSection(dto),
  validAnswersNo: dto.valid_answers_no,
});
