import React, { useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { FormControl, FormHelperText, MenuItem, Select } from '@material-ui/core';
import i18n from 'i18next';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { CircleButton } from 'ui/buttons/CircleButton';
import { MoveIcon } from 'ui/icons/icons';
import { Button } from 'ui/Button';
import { QuestionnaireSection } from 'types/QuestionnaireSection';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { Dialog } from 'ui/Dialog';

import { useGetSectionsByQuestionnaireIdQuery, usePatchSectionOrderMutation } from '../../api/admin.api';
import { AdminQuestionnaireReorderQuestions } from './AdminQuestionnaireReorderQuestions';
import { getDirection, getReverseDirection } from '../../../../utilities/useLanguage';

type AdminQuestionnaireReorderDialogProps = {
  questionnaireId: number;
  open: boolean;
  handleClose: (open: boolean, isReordered: boolean) => void;
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: 'none',
    backgroundColor: '#FAFAFA',
  },

  description: {
    padding: '24px 24px 16px 24px',
    color: theme.palette.text.secondary,
  },

  section: {
    flip: false,
    padding: '14px 24px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: '#FAFAFA',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },

  dragIcon: {
    flexShrink: 0,
  },

  sectionTexts: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  closeButton: {
    padding: theme.spacing(3),
  },
  sectionNumber: {
    color: theme.palette.text.secondary,
  },
  select: {
    padding: theme.spacing(3),
    paddingBottom: 0,
    width: '100%',
  },
}));

export const AdminQuestionnaireReorderDialog: React.FC<AdminQuestionnaireReorderDialogProps> = ({
  open,
  handleClose,
  questionnaireId,
}) => {
  const classes = useStyles();
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const [sectionItems, setSectionItems] = React.useState<QuestionnaireSection[]>([]);
  const [selectedSection, setSelectedSection] = React.useState<QuestionnaireSection>();
  const [tabIndex, setTabIndex] = React.useState(1);
  const [isReordered, setIsReordered] = React.useState(false);
  const [patchSection, { isLoading }] = usePatchSectionOrderMutation();
  const { data: sections } = useGetSectionsByQuestionnaireIdQuery({ questionnaireId });
  const showNotification = useNotifications();
  const direction = getDirection(i18n.language);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  const reorder: (list: QuestionnaireSection[], startIndex: number, endIndex: number) => QuestionnaireSection[] = (
    list,
    startIndex,
    endIndex
  ) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const movedSection = sectionItems[result.source.index];
    patchSection({ id: movedSection.id, new_order: result.destination.index + 1 })
      .unwrap()
      .catch((err) =>
        showNotification(NotificationType.Error, {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          errorId: err.data.errorCode,
        })
      );
    const items = reorder(sectionItems, result.source.index, result.destination.index);
    setIsReordered(true);

    setSectionItems(items);
  };

  const changeSection = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedSection(sections?.find((section) => section.id === (event.target.value as number)));
  };

  const closeHandler = () => {
    handleClose(false, isReordered);
    setIsReordered(false);
  };

  useEffect(() => {
    if (sections) {
      setSectionItems(sections);
    }
  }, [sections]);
  const reverseDirection = getReverseDirection(i18n.language);

  return (
    <Dialog open={open} handleClose={closeHandler} withPadding={false}>
      <AppBar className={classes.appBar} position="static" color="default">
        <Tabs value={tabIndex} onChange={handleChange} indicatorColor="primary" textColor="primary" variant="fullWidth">
          <Tab label={translate('reorderQuestions')} />
          <Tab label={translate('reorderSections')} />
        </Tabs>
      </AppBar>

      <div hidden={tabIndex === 0}>
        <Typography className={classes.description} variant="subtitle2">
          {translate('dragAndDropSections')}
        </Typography>
        {sectionItems && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="sectionsList">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {sectionItems.map((section, index) => (
                    <Draggable draggableId={String(section.id)} index={index} key={`reorder-section-${section.id}`}>
                      {(prov) => (
                        <div
                          dir={reverseDirection}
                          className={classes.section}
                          ref={prov.innerRef}
                          {...prov.draggableProps}
                        >
                          <div {...prov.dragHandleProps}>
                            <CircleButton size={40} icon={MoveIcon} className={classes.dragIcon} />
                          </div>
                          <div dir={direction} className={classes.sectionTexts}>
                            <Typography variant="body1" color="primary">
                              {section.nameAR}
                            </Typography>
                            <Typography variant="body2" className={classes.sectionNumber}>
                              {`${translate('section')} ${index + 1} ${translate('of')} ${sectionItems?.length}`}
                            </Typography>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        <div className={classes.closeButton}>
          <Button fullWidth size="large" variant="outlined" color="secondary" onClick={closeHandler}>
            {translate('close')}
          </Button>
        </div>
      </div>

      <div hidden={tabIndex === 1}>
        {sections && !isLoading && (
          <>
            <FormControl className={classes.select}>
              <Select
                variant="outlined"
                dir={direction}
                value={selectedSection?.id || sections[0].id}
                onChange={changeSection}
                disabled={!sections?.length}
              >
                {sections.map((section) => (
                  <MenuItem key={`sectionSelect-${section.id}`} value={section.id}>
                    {section.nameAR}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{translate('selectSection')}</FormHelperText>
            </FormControl>
            <Typography dir={direction} className={classes.description} variant="subtitle2">
              {translate('dragAndDropQuestions')}
            </Typography>
            <div>
              <AdminQuestionnaireReorderQuestions
                section={selectedSection || sections[0]}
                setIsReordered={setIsReordered}
              />
            </div>
            <div className={classes.closeButton}>
              <Button fullWidth size="large" variant="outlined" color="secondary" onClick={closeHandler}>
                {translate('close')}
              </Button>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};
