import React from 'react';

import { EstablishmentDetails as CommonEstablishmentDetails } from '../../../common/establishments/establishments-details/EstablishmentDetails';
import { AdminLayout } from '../../ui/AdminLayout';
import { getAdminPath } from '../../AdminRoutes';

export const AdminEstablishmentDetails: React.FC = () => {
  return (
    <AdminLayout>
      <CommonEstablishmentDetails getPath={getAdminPath} />
    </AdminLayout>
  );
};
