import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Container } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { useNavigate } from 'utilities/navigate';
import { AssessmentStatus } from 'types/AssessmentStatus';
import { Establishment } from 'types/Establishment';
import { AssessmentInfo } from 'domain/admin/types/AssessmentInfo';
import { LoadingWrapper } from 'ui/LoadingWrapper';

import { useGetEstablishmentQuery } from '../../../admin/api/admin.api';
import { EstablishmentDetailsBody } from './EstablishmentDetailsBody';
import { EstablishmentDetailsScore } from './EstablishmentDetailsScore';
import { Routes } from '../../../common/Routes';

type EstablishmentDetailsProps = {
  getPath: (value: Routes) => string;
};

const useStyle = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(10),
  },
}));

const getResultSelfAssessmentInfo = (establishmentInfo: Establishment): AssessmentInfo => {
  if (
    (establishmentInfo.assessmentCurrentObjStatusOSH !== AssessmentStatus.Draft &&
      establishmentInfo.assessmentCurrentObjStatusOSH !== AssessmentStatus.NotStarted) ||
    !establishmentInfo.assessmentPreviousObjId
  ) {
    return {
      id: establishmentInfo.assessmentCurrentObjIdCA ||establishmentInfo.assessmentCurrentObjIdOSH ,
      status: establishmentInfo.assessmentCurrentObjStatusOSH,
      score: establishmentInfo.assessmentCurrentObjScore,
    };
  }

  return {
    id: establishmentInfo.assessmentPreviousObjId,
    status: establishmentInfo.assessmentPreviousObjStatus,
    score: establishmentInfo.assessmentPreviousObjScore,
  };
};
export const EstablishmentDetails: React.FC<EstablishmentDetailsProps>  = ({ getPath  }) => {
  const classes = useStyle();
  const { id: establishmentId } = useParams<{ id: string }>();
  const { data: establishment, isLoading } = useGetEstablishmentQuery(Number(establishmentId));
  const navigate = useNavigate();

  const assessmentInfo = establishment && getResultSelfAssessmentInfo(establishment);

  const showAnswersButton =
    assessmentInfo &&
    assessmentInfo.status !== null &&
    [
      AssessmentStatus.Passed,
      AssessmentStatus.Failed,
      AssessmentStatus.AuditorStarted,
      AssessmentStatus.PcIssued,
    ].includes(assessmentInfo.status);

  const navigateToAnswers = () => {
    navigate(
      `${getPath(Routes.establishment)}/${establishment?.id}${Routes.saResults}/${assessmentInfo?.id}${Routes.answers}`
    );
  };

  return (
    <LoadingWrapper isLoading={isLoading}>
      <Container maxWidth="lg" className={classes.container}>

        {establishment && <EstablishmentDetailsBody establishment={establishment} />}
        <EstablishmentDetailsScore
          showAnswersButton={showAnswersButton}
          navigateToAnswers={navigateToAnswers}
          assessmentInfo={assessmentInfo}
        />
      </Container>
    </LoadingWrapper>
  );
};
