import { Action } from '@reduxjs/toolkit';

export enum AuthActionType {
  loginAdmin = 'auth/loginAdmin',
  authenticateAdmin = 'auth/authenticateAdmin',
  loginUser = 'auth/loginUser',
  invalidToken = 'auth/invalidToken',
  refreshToken = 'auth/refreshToken',
  refreshTokenExpired = 'auth/refreshTokenExpired',
  logout = 'auth/logout',
  invitationConfirmation = 'auth/invitationConfirmation',
  checkInvitation = 'auth/checkInvitation',
}

export const invalidTokenAction = (): Action => ({
  type: AuthActionType.invalidToken,
});

export const logoutAction = (): Action => ({
  type: AuthActionType.logout,
});

export const refreshTokenExpiredAction = (): Action => ({
  type: AuthActionType.refreshTokenExpired,
});
