import { makeStyles } from '@material-ui/core';
import React from 'react';
import i18n from 'i18next';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { PaperCard } from 'ui/PaperCard';
import { Establishment } from 'types/Establishment';
import { TypeOfPlatform } from 'infrastructure/services/auth.type';

import { useGetPerformanceCardListQuery } from '../api/companyRepresentative.api';
import { CompanyRepresentativePerformanceCardsTable } from './CompanyRepresentativePerformanceCardsTable';
import { CompanyRepresentativeNewestPc } from './CompanyRepresentativeNewestPc';
import { getDirection } from '../../../utilities/useLanguage';

const useStyle = makeStyles((theme) => ({
  details: {
    flip: false,
    marginTop: theme.spacing(4),
  },

  pcRequestDetailsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: '1fr',
    gridGap: theme.spacing(2),
  },

}));

type CompanyRepresentativePerformanceCardsBodyProps = {
  establishment: Establishment;
  platform: TypeOfPlatform.OSH;
};

export const CompanyRepresentativePerformanceCards: React.FC<CompanyRepresentativePerformanceCardsBodyProps> = ({
  establishment,
  platform,
}) => {
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const classes = useStyle();
  const [pageSize, setPageSize] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const direction = getDirection(i18n.language);

  const { data: pcRequests } = useGetPerformanceCardListQuery({
    establishmentId: establishment.id,
    page: page + 1,
    pageSize,
    platform:platform,
  });

  const newestPcRequests = pcRequests?.results.slice(0, 2);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <div dir={direction} className={classes.pcRequestDetailsContainer}>
        {newestPcRequests && <CompanyRepresentativeNewestPc newestPcRequests={newestPcRequests} />}
      </div>
      {pcRequests && pcRequests.count > 0 && (
        <PaperCard title={translate('pcRequestsHistory')} withBodyPadding={false}>
          <CompanyRepresentativePerformanceCardsTable
            data={pcRequests.results}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            pageSize={pageSize}
            count={pcRequests.count}
          />
        </PaperCard>
      )}
    </>
  );
};
