import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core';
import i18n from 'i18next';

import { AssessmentAnswer } from 'types/AssessmentAnswer';
import { AssessmentType } from 'types/Assessment';

import { PCRequestAssessmentPreviousAnswererSelect } from '../../admin/ui/PCRequestAssessmentPreviousAnswererSelect';
import { PCRequestAssessmentPreviousAnswerContent } from '../../admin/ui/PCRequestAssessmentPreviousAnswerContent';
import { getDirection } from '../../../utilities/useLanguage';

type AssessmentCompareAnswerProps = {
  selectKey: string;
  answer?: AssessmentAnswer;
  answerer: AssessmentType;
  answerers: AssessmentType[];
  changeAnswerer: (value: AssessmentType) => void;
};

const useStyle = makeStyles<Theme, { hasAttachments: boolean }>((theme) => ({
  main: {
    flip: false,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '8px 72px 40px 60px',
    flex: '1',
  },

  answerer: {
    marginTop: theme.spacing(4),
  },

  answer: {
    marginTop: theme.spacing(3),
  },


  uploadedFiles: {
    marginTop: theme.spacing(2),
  },

  uploadedFilesWrapper: {
    marginTop: ({ hasAttachments }) => (hasAttachments ? `-${theme.spacing(3)}px` : ''),
  },
}));

export const AssessmentCompareAnswer: React.FC<AssessmentCompareAnswerProps> = ({
  selectKey,
  answer,
  answerer,
  answerers,
  changeAnswerer,
}) => {
  const hasAttachments = Boolean(answer?.attachments && answer.attachments.length > 0);

  const classes = useStyle({ hasAttachments });
  const direction = getDirection(i18n.language);

  return (
    <div dir={direction} className={classes.main}>
      <PCRequestAssessmentPreviousAnswererSelect
        answerers={answerers}
        selectedAnswerer={answerer}
        selectKey={selectKey}
        changeAnswerer={changeAnswerer}
      />
      <PCRequestAssessmentPreviousAnswerContent answer={answer} answerer={answerer} />
    </div>
  );
};
