import { Container, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Routes } from 'infrastructure/routes';
import { LoadingWrapper } from 'ui/LoadingWrapper';
import { ProtectedLayout } from 'ui/ProtectedLayout';
import { useNavigate } from 'utilities/navigate';

import { useGetEstablishmentQuery } from './api/companyRepresentative.api';
import { CompanyRepresentativeCompanyHeader } from './CompanyRepresentativeCompanyHeader';
import { CompanyRepresentativePerformanceCards } from './company-representative-performance-cards/CompanyRepresentativePerformanceCards';
import { TypeOfPlatform } from '../../infrastructure/services/auth.type';

type CompanyRepresentativeProps = {
  platform: TypeOfPlatform.OSH;
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(10),
  },
}));

export const CompanyRepresentative: React.FC<CompanyRepresentativeProps> = ({ platform }) => {
  const { establishmentId } = useParams<{ establishmentId: string }>();

  const { data: establishment, isLoading } = useGetEstablishmentQuery(+establishmentId);
  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    if (establishment && !establishment.isUpdated) {
      navigate(`${Routes.CR_MISSING_INFO}/${establishment.extId}`);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [establishment]);

  const pcRequestIdPlatform = () => {
    if (platform === TypeOfPlatform.OSH) {
      return establishment?.pcRequestIdOSH;
    } else {
      return establishment?.pcRequestIdCA;
    }
  }

  return (
    <ProtectedLayout maxWidth="lg">
      <Container maxWidth="lg" className={classes.container}>
        <LoadingWrapper isLoading={isLoading}>
          {establishment && <CompanyRepresentativeCompanyHeader establishment={establishment} platform={platform} />}
          {establishment && pcRequestIdPlatform() && (
            <CompanyRepresentativePerformanceCards
              establishment={establishment}
              platform={platform}
            />
          )}
        </LoadingWrapper>
      </Container>
    </ProtectedLayout>
  );
};
