import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import i18n from 'i18next';

import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { AssessmentTemplate } from 'domain/admin/types/AssessmentTemplate';
import { ResultMarker } from 'ui/ResultMarker';

import { getResultMessageFn } from '../utils/getResultMessageFn';
import { CompanyRepresentativeSAResultSection } from './CompanyRepresentativeSAResultSection';
import { Assessment } from '../../../types/Assessment';
import { AssessmentSection } from '../../../types/AssessmentSection';
import { getDirection, getReverseDirection } from '../../../utilities/useLanguage';

type SAResultProps = {
  assessment: Assessment;
  questionnaire: AssessmentTemplate | undefined;
  sections: AssessmentSection[];
};

const useStyles = makeStyles((theme) => ({
  scorePaper: {
    flip: false,
    marginTop: theme.spacing(7),
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(4),
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  scoreText: {
    flip: false,
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },

  scoreTextDescription: {
    marginTop: theme.spacing(1),
  },
}));

export const CompanyRepresentativeSAResult: React.FC<SAResultProps> = ({ assessment, questionnaire, sections }) => {
  const classes = useStyles();

  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const getMessage = getResultMessageFn(translate);
  const direction = getDirection(i18n.language);
  const reverseDirection = getReverseDirection(i18n.language);

  return (
    <>
      <Paper dir={reverseDirection} className={classes.scorePaper}>
        <div dir={direction} className={classes.scoreText}>
          <Typography variant="h5">
            {translate(assessment?.isPassed ? 'passedMessage' : 'failedMessage', {
              score: `${assessment?.score || 0}`,
            })}
          </Typography>
          <Typography variant="body2" className={classes.scoreTextDescription}>
            {getMessage(assessment.passingStatus, questionnaire?.minSuccessfulScore)}
          </Typography>
        </div>
        <ResultMarker isPassed={assessment.isPassed} wrapperHeight={48} markHeight={32} />
      </Paper>
      {sections.map((section, index) => (
        <CompanyRepresentativeSAResultSection
          key={`result-section-${section.id}`}
          assessmentId={assessment?.id}
          section={section}
          sectionNumber={index + 1}
          sectionsCount={sections.length}
        />
      ))}
    </>
  );
};
