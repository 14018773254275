import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

type LoadingWrapperProps = { isLoading: boolean };

const useStyles = makeStyles((theme) => ({
  loaderWrapper: {
    position: 'absolute',
    margin: 'auto',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
}));

export const LoadingWrapper: React.FunctionComponent<LoadingWrapperProps> = ({ children, isLoading }) => {
  const classes = useStyles();

  return isLoading ? <CircularProgress className={classes.loaderWrapper} color="primary" /> : <div>{children}</div>;
};
