import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import clsx from 'clsx';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';

const tableCellStyle = (width: number) => ({
  minWidth: `${width}px`,
  maxWidth: `${width}px`,
});

const useStyles = makeStyles((theme) => ({
  actions: { ...tableCellStyle(98), textAlign: 'center' },
  status: { ...tableCellStyle(250), textAlign: 'center' },
  assignedTo: { ...tableCellStyle(120), textAlign: 'center' },
  issuedOn: { ...tableCellStyle(120), textAlign: 'center' },
  createdOn: { ...tableCellStyle(120), textAlign: 'center' },
  requestsNumber: { ...tableCellStyle(10), textAlign: 'center' },
  establishmentName: { ...tableCellStyle(162), textAlign: 'center' },
  pcRequestId: { ...tableCellStyle(120), textAlign: 'center' },
  cellPadding: {
    padding: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px ${theme.spacing(20)}px ${theme.spacing(40)}px`,
  },
}));

export const PCRequestTableHead: React.FC = () => {
  const classes = useStyles();
  const translateCommon = useCreateTranslate(TranslationNamespace.common);
  const translate = useCreateTranslate(TranslationNamespace.admin);

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.actions}>{translateCommon('actions')}</TableCell>
        <TableCell className={clsx(classes.status, classes.cellPadding)}>{translate('status')}</TableCell>
        <TableCell className={clsx(classes.assignedTo, classes.cellPadding)}>{translate('assignedTo')}</TableCell>
        <TableCell className={clsx(classes.issuedOn, classes.cellPadding)}>{translate('issuedOn')}</TableCell>
        <TableCell className={clsx(classes.createdOn, classes.cellPadding)}>{translate('createdOn')}</TableCell>
        <TableCell className={clsx(classes.requestsNumber, classes.cellPadding)}>
          {translate('requestsNumber')}
        </TableCell>
        <TableCell className={clsx(classes.establishmentName, classes.cellPadding)}>
          {translate('establishmentName')}
        </TableCell>
        <TableCell className={clsx(classes.pcRequestId, classes.cellPadding)}>{translate('pcRequestId')}</TableCell>
      </TableRow>
    </TableHead>
  );
};
