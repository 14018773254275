import React, { forwardRef } from 'react';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { Theme } from '@material-ui/core';

import { AlertIcon, CrossCloseIcon, CheckGreenOutlineIcon } from 'ui/icons/icons';

import { NotificationsSnackbarProps } from './types';
import { NotificationType } from './NotificationType.enum';

const useStyles = makeStyles<Theme, { status: NotificationType }>((theme) => ({
  notificationContainer: {
    width: 'auto',
    height: theme.spacing(6),
    backgroundColor: ({ status }) => {
      switch (status) {
        case NotificationType.Success:
          return theme.palette.success.main;
        case NotificationType.Error:
          return theme.palette.error.main;
        default:
          return theme.palette.error.main;
      }
    },
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.spacing(1),
  },
  notificationMessage: {
    color: ({ status }) => {
      switch (status) {
        case NotificationType.Success:
          return theme.palette.success.contrastText;
        case NotificationType.Error:
          return theme.palette.error.contrastText;
        default:
          return theme.palette.error.contrastText;
      }
    },
  },
  crossIcon: {
    marginTop: '-4px',
  },
  notificationIcon: {
    flip: false,
    marginRight: theme.spacing(1),
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

const getNotificationIcon = (status: NotificationType) => {
  switch (status) {
    case NotificationType.Success:
      return CheckGreenOutlineIcon;
    default:
      return AlertIcon;
  }
};

export const NotificationsSnackbar = forwardRef<HTMLDivElement, NotificationsSnackbarProps>(
  ({ id, notificationParams }, ref) => {
    const { closeSnackbar } = useSnackbar();
    const { message, status } = notificationParams;
    const classes = useStyles({ status });

    return (
      <div ref={ref} dir="ltr" className={classes.notificationContainer} data-testid="notification-snackbar">
        <SvgIcon component={getNotificationIcon(status)} className={classes.notificationIcon} />
        <Typography variant="body2" className={classes.notificationMessage}>
          {message}
        </Typography>
        <IconButton
          onClick={() => {
            closeSnackbar(id);
          }}
          edge="start"
          color="inherit"
          className={classes.crossIcon}
        >
          <SvgIcon component={CrossCloseIcon} />
        </IconButton>
      </div>
    );
  }
);
