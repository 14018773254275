import React from 'react';
import { makeStyles } from '@material-ui/core';

import { Dialog } from 'ui/Dialog';

type UsersDialogProps = {
  isFormOpen: boolean;
  handleClose: () => void;
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '600px',
    padding: '32px 24px 24px 24px',
  },
}));

export const UsersDialog: React.FC<UsersDialogProps> = ({ isFormOpen, handleClose, children }) => {
  const classes = useStyles();

  return (
    <Dialog className={classes.dialog} open={isFormOpen} handleClose={handleClose}>
      {children}
    </Dialog>
  );
};
