import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';

import { shdShadow } from 'ui/styles';

import { QuestionnaireReviewTopicEditable } from './QuestionnaireReviewTopicEditable';

type QuestionnaireReviewContainerProps = {
  topicLabelName: string;
  stepIndex: number;
  setStepIndex: React.Dispatch<React.SetStateAction<number>>;
  isEditable: boolean;
};

const useStyles = makeStyles((theme) => ({
  main: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  stepWrapper: {
    boxShadow: shdShadow,
    marginTop: theme.spacing(5),
    padding: `${theme.spacing(4)}px`,
  },
}));

export const QuestionnaireReviewContainer: React.FC<QuestionnaireReviewContainerProps> = ({
  children,
  topicLabelName,
  stepIndex,
  setStepIndex,
  isEditable,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.stepWrapper}>
      <div className={classes.main}>
        <QuestionnaireReviewTopicEditable
          topicLabelName={topicLabelName}
          setStepIndex={setStepIndex}
          stepIndex={stepIndex}
          isEditable={isEditable}
        />
        <div>{children}</div>
      </div>
    </Paper>
  );
};
