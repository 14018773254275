export enum AssessmentTemplateStatus {
  Draft = 1,
  Published,
  Archived,
}

enum AssessmentTemplateStatusDisplay {
  Draft = 'draft',
  Published = 'published',
  Archived = 'archived',
}

export const toStatusDisplay = (status: AssessmentTemplateStatus): AssessmentTemplateStatusDisplay => {
  switch (status) {
    case AssessmentTemplateStatus.Draft:
      return AssessmentTemplateStatusDisplay.Draft;
    case AssessmentTemplateStatus.Published:
      return AssessmentTemplateStatusDisplay.Published;
    case AssessmentTemplateStatus.Archived:
      return AssessmentTemplateStatusDisplay.Archived;
    default:
      throw Error('Unhandled SelfAssessmentTemplateStatus');
  }
};
