import { EstablishmentDto, Establishment } from './Establishment';

export type EstablishmentDetailsDto = Pick<
  EstablishmentDto,
  | 'id'
  | 'economic_activity_name_ar'
  | 'name_ar'
  | 'self_assessment_current_obj_score'
  | 'self_assessment_current_obj_status_osh'
  | 'self_assessment_current_obj_status_ca'
>;

export type EstablishmentDetails = Pick<
  Establishment,
  'id' | 'assessmentCurrentObjStatusOSH' | 'assessmentCurrentObjStatusCA' | 'assessmentCurrentObjScore' | 'economicActivityNameAr' | 'nameAr'
>;

export const toEstablishmentDetails = ({
  id,
  economic_activity_name_ar,
  self_assessment_current_obj_score,
  self_assessment_current_obj_status_osh,
  self_assessment_current_obj_status_ca,
  name_ar,
}: EstablishmentDetailsDto): EstablishmentDetails => ({
  id,
  assessmentCurrentObjScore: self_assessment_current_obj_score,
  assessmentCurrentObjStatusOSH: self_assessment_current_obj_status_osh,
  assessmentCurrentObjStatusCA: self_assessment_current_obj_status_ca,
  economicActivityNameAr: economic_activity_name_ar,
  nameAr: name_ar,
});
