import React from 'react';
import { useKeycloak } from '@react-keycloak/web';

import { Layout } from 'ui/Layout';
import { CompanyRepresentativeLogin } from 'domain/company-representative/company-representative-login/CompanyRepresentativeLogin';

export const CompanyLogin = () => {
  const { keycloak } = useKeycloak();
  const data = {
    token: keycloak.token as string,
    refresh: keycloak.refreshToken as string,
  };

  return (
    <Layout>
      <CompanyRepresentativeLogin {...data} />
    </Layout>
  );
};
