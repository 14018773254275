import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';

import { shdShadow } from 'ui/styles';

import { CompanyRepresentativeSASectionHeader } from './CompanyRepresentativeSASectionHeader';
import { CompanyRepresentativeSAQuestions } from './CompanyRepresentativeSAQuestions';
import { AssessmentSectionPropTypes } from '../../../types/AssessmentSectionPropTypes';

const useStyles = makeStyles((theme) => ({
  main: {
    width: theme.breakpoints.values.lg,
    marginTop: theme.spacing(4),
    boxShadow: shdShadow,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
}));

export const CompanyRepresentativeSASection: React.FC<AssessmentSectionPropTypes> = ({
  sectionsCount,
  sectionNumber,
  section,
  assessmentId,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.main}>
      <CompanyRepresentativeSASectionHeader
        sectionsCount={sectionsCount}
        sectionNumber={sectionNumber}
        section={section}
      />
      <CompanyRepresentativeSAQuestions sectionId={section.id} assessmentId={assessmentId} />
    </Paper>
  );
};
