import React from 'react';
/* eslint-disable import/no-duplicates */

import { AuditorLayout } from 'domain/auditor/ui/AuditorLayout';

import { PCRequestDetails as CommonPCRequestDetails } from '../../../common/pcrequests/pcrequests-details/PCRequestDetails';
import { getAuditorPath } from '../../AuditorRoutes';

export const PCRequestDetails = () => {
  return (
    <AuditorLayout>
        <CommonPCRequestDetails getPath={getAuditorPath} />
    </AuditorLayout>
  );
};
