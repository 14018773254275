import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosRequestConfig, AxiosError } from 'axios';

import { REACT_APP_API_URL } from 'infrastructure/core/variables';
import { RootState } from 'infrastructure/store/rootStore.types';

import { createHeaderWithAuthorization } from './axiosInstance';
import { reAuthAndResendRequest } from './axiosInstanceWithInterceptor';
import { AxiosErrorResponseData } from './types';

type ErrorData = {
  detail: string[];
  errorCode: string;
};

export type BaseQueryError = {
  status?: number | undefined;
  data: ErrorData;
};

export const DEFAULT_ERROR_CODE = '0000';

const toErrorData = (
  { file, detail = [], error_code = [] }: AxiosErrorResponseData = { detail: [], error_code: [] }
): ErrorData => ({
  detail: file || detail,
  errorCode: (Array.isArray(error_code) ? error_code[0] : error_code) || DEFAULT_ERROR_CODE,
});


// Added validation to check if the authentication token is valid before making multiple requests
export const baseQueryWithReAuth = 
  (): BaseQueryFn<AxiosRequestConfig, unknown, BaseQueryError> => async (args, api) => {
    const headersWithAuth = createHeaderWithAuthorization(api.getState() as RootState);
    const authorizationHeader = headersWithAuth.authorization;

    if (!authorizationHeader || authorizationHeader === 'Bearer undefined') {
      return {
        error: { status: 401, data: { detail: ['No valid authentication token found'], errorCode: DEFAULT_ERROR_CODE } },
      };
    }

    try {
      const headers = {
        ...args.headers,
        ...headersWithAuth,
      };

      const result = await axios({ ...args, url: `${REACT_APP_API_URL}/api${args.url || ''}`, headers });
      return { data: result.data };  
    } catch (axiosError) {
      const err = axiosError as AxiosError<AxiosErrorResponseData>;

      if (!err.response) {
        return Promise.reject(err);
      }

      if (err.response.status === 401) {
        return reAuthAndResendRequest(err, api.dispatch, api.getState as () => RootState)
          .then(({ data }) => ({ data }))
          .catch((secondError: AxiosError<AxiosErrorResponseData>) => ({
            error: {
              status: secondError.response?.status,
              data: toErrorData(secondError.response?.data),
            },
          }));
      }

      return {
        error: { status: err.response.status, data: toErrorData(err.response.data) },
      };
    }
  };