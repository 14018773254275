export type InfoDto = {
  self_assessment: number;
  establishment: number;
};

export type Info = {
  assessment: number;
  establishment: number;
};

export const toInfo = (info: InfoDto) =>
  ({ assessment: info.self_assessment, establishment: info.establishment } as Info);

export type TicketDto = {
  formData: FormData;
};

export type ResponseTicket = {
  issueKey: string;
};
