import React from 'react';

import { PCRequestList as CommonPCRequestList } from '../../common/pcrequests/PCRequestList';
import { getAuditorPath } from '../AuditorRoutes';
import { AuditorLayout } from '../ui/AuditorLayout';

export const AuditorDashboard: React.FunctionComponent = () => {
  return (
    <AuditorLayout>
      <CommonPCRequestList getPath={getAuditorPath} />
    </AuditorLayout>
  );
};
