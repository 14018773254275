import React from 'react';
/* eslint-disable import/no-duplicates */

import { AdminLayout } from 'domain/admin/ui/AdminLayout';
import { getAdminPath } from 'domain/admin/AdminRoutes';

import { PCRequestDetails as CommonPCRequestDetails } from '../../../common/pcrequests/pcrequests-details/PCRequestDetails';

export const PCRequestDetails = () => {
  return (
    <AdminLayout>
        <CommonPCRequestDetails getPath={getAdminPath} />
    </AdminLayout>
  );
};
