import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Button from '@material-ui/core/Button';
import i18n from 'i18next';

import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { PlusCircleOutlineIcon } from 'ui/icons/icons';
import { useAppSelector } from 'infrastructure/store/rootStore.hooks';

import { selectQuestionnaireId } from '../AdminQuestionnaire.slice';
import { useGetQuestionsBySectionIdQuery } from '../../api/admin.api';
import { AdminQuestionnaireQuestionDialog } from './AdminQuestionnaireQuestionDialog';
import { AdminQuestionnaireQuestion } from './AdminQuestionnaireQuestion';
import { getDirection } from '../../../../utilities/useLanguage';

type AdminQuestionnaireQuestionsProps = {
  sectionId: number;
  isEditable: boolean;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  addQuestionButtonSpace: {
    padding: '32px 100px 8px 100px',
  },

  addQuestionButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px dashed ${theme.palette.secondary.main}`,
    boxSizing: 'border-box',
    borderRadius: theme.spacing(1),
    height: '120px',
  },

  addQuestionButton: {
    fontWeight: 700,
    flip: false,
    display: 'flex',
    padding: 0,
  },

  addQuestionIcon: {
    fill: theme.palette.secondary.main,
    flip: false,
    width: '40px',
    height: '40px',
    marginRight: 4,
    marginLeft: 4,
  },
}));

export const AdminQuestionnaireQuestions: React.FC<AdminQuestionnaireQuestionsProps> = ({ sectionId, isEditable }) => {
  const classes = useStyles();
  const translateQuestionnaire = useCreateTranslate(TranslationNamespace.admin);
  const [open, setOpen] = React.useState(false);
  const { data: questions } = useGetQuestionsBySectionIdQuery({ sectionId });
  const questionnaireId = useAppSelector(selectQuestionnaireId);
  const direction = getDirection(i18n.language);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {questionnaireId && (
        <div className={classes.wrapper}>
          {questions &&
            questions.map((question, index) => (
              <AdminQuestionnaireQuestion
                key={`questionnaire_section_question_${question.id}`}
                question={question}
                index={index}
                questionnaireId={questionnaireId}
                sectionId={sectionId}
                isEditable={isEditable}
              />
            ))}
          {isEditable && (
            <div dir={direction} className={classes.addQuestionButtonSpace}>
              <div className={classes.addQuestionButtonWrapper}>
                <Button
                  className={classes.addQuestionButton}
                  color="secondary"
                  size="large"
                  variant="text"
                  onClick={handleClickOpen}
                >
                  <SvgIcon className={classes.addQuestionIcon} component={PlusCircleOutlineIcon} viewBox="0 0 40 40" />
                  {translateQuestionnaire('addQuestion')}
                </Button>
              </div>
            </div>
          )}
          <AdminQuestionnaireQuestionDialog
            open={open}
            handleClose={handleClose}
            sectionId={sectionId}
            questionnaireId={questionnaireId}
          />
        </div>
      )}
    </>
  );
};
