import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {
  useGetAnswersByAssessmentIdAndSectionIdQuery,
  useGetQuestionsByAssessmentIdAndSectionIdQuery,
} from '../api/companyRepresentative.api';
import { CompanyRepresentativeSAQuestion } from './CompanyRepresentativeSAQuestion';
import { BottomBorderWrapper } from '../../../ui/BottomBorderWrapper';

type CompanyRepresentativeSAQuestionsProps = {
  assessmentId: number;
  sectionId: number;
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
}));

export const CompanyRepresentativeSAQuestions: React.FC<CompanyRepresentativeSAQuestionsProps> = ({
  assessmentId,
  sectionId,
}) => {
  const classes = useStyles();
  const { data: { results: questions } = { count: 0, results: [] }, isLoading: isLoadingQuestions } =
    useGetQuestionsByAssessmentIdAndSectionIdQuery({
      assessmentId,
      sectionId,
    });
  const { data: { results: answers } = { count: 0, results: [] }, isLoading: isLoadingAnswers } =
    useGetAnswersByAssessmentIdAndSectionIdQuery({
      assessmentId,
      assessmentSectionId: sectionId,
    });

  const findAnswerForQuestion = (currentQuestionId: number) =>
    answers.find(({ questionId }) => questionId === currentQuestionId);

  const isLoaded = !(isLoadingAnswers || isLoadingQuestions);

  return (
    <div className={classes.main}>
      {isLoaded &&
        questions.map((question, index) => {
          const questionKey = `sa-question-${sectionId}-${question.id}`;

          return index < questions.length - 1 ? (
            <BottomBorderWrapper key={questionKey}>
              <CompanyRepresentativeSAQuestion
                assessmentId={assessmentId}
                question={question}
                questionNo={index + 1}
                answer={findAnswerForQuestion(question.id)}
              />
            </BottomBorderWrapper>
          ) : (
            <CompanyRepresentativeSAQuestion
              assessmentId={assessmentId}
              key={questionKey}
              question={question}
              questionNo={index + 1}
              answer={findAnswerForQuestion(question.id)}
            />
          );
        })}
    </div>
  );
};
