import React from 'react';
import { ClickAwayListener, Grow, makeStyles, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';
import { isToday } from 'date-fns';
import i18n from 'i18next';

import { TranslationNamespace } from 'i18n/config';
import { FieldData } from 'types/FieldData';
import { PCRequest, PCRequestStatus } from 'types/PCRequest';
import { PaperCard } from 'ui/PaperCard';
import { toLocaleDate } from 'utilities/localDate';
import { useCreateTranslate } from 'utilities/translate.hook';
import { Button } from 'ui/Button';
import { useCacheableDownload } from 'utilities/useCachableDownload';
import { Hint } from 'ui/Hint';
import { LoadingSpinner } from 'ui/LoadingSpinner';

import { getZoneName } from '../utils/getZoneName';
import { getDirection } from '../../../utilities/useLanguage';

const DROPDOWN_WIDTH = 165;
const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
  button: {
    height: 50,
  },
  dropdownButton: {
    width: DROPDOWN_WIDTH,
  },
  menuItem: {
    width: DROPDOWN_WIDTH,
    '&:hover': {
      background: 'linear-gradient(0deg, rgba(20, 130, 135, 0.08), rgba(20, 130, 135, 0.08)), #FFFFFF',
      color: theme.palette.secondary.main,
    },
  },
  menuList: {
    marginTop: theme.spacing(0.5),
  },
}));

type CompanyRepresentativePcCardProps = {
  pcRequest: PCRequest;
  isDownloadDropdown?: boolean;
};

const CompanyRepresentativePcCard = ({ pcRequest, isDownloadDropdown }: CompanyRepresentativePcCardProps) => {
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const classes = useStyles();
  const direction = getDirection(i18n.language);

  const [open, setOpen] = React.useState(false);

  const [handlePcDownload, isPcCardDownloading] = useCacheableDownload({
    apiUrl: `performance_card_request/${pcRequest.id}/download_performance_card/`,
    fileName: 'PcCard.pdf',
  });
  const [handleReportDownload, isReportDownloading] = useCacheableDownload({
    apiUrl: `performance_card_request/${pcRequest.id}/download_report/`,
    fileName: 'Report.pdf',
  });

  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const isPcIssued = pcRequest.status === PCRequestStatus.PCIssued;

  const paperCardData: FieldData[] = [
    {
      name: translate('pcRequestId'),
      value: isPcIssued ? null : pcRequest.id,
    },
    {
      name: translate('status'),
      value: isPcIssued ? translate('pcIssued') : translate('underAudition'),
    },
    {
      name: translate('issuedOn'),
      value: pcRequest.issuingDate && isPcIssued ? toLocaleDate(pcRequest.issuingDate) : null,
    },
    {
      name: translate('expirationDate'),
      value: pcRequest.performanceCardExpiresAt && isPcIssued ? toLocaleDate(pcRequest.performanceCardExpiresAt) : null,
    },

    {
      name: translate('createdOn'),
      value: pcRequest.createdAt && !isPcIssued ? toLocaleDate(pcRequest.createdAt) : null,
    },
    {
      name: translate('zone'),
      value:
        pcRequest.performanceCardPassingStatus && isPcIssued
          ? getZoneName(pcRequest.performanceCardPassingStatus, translate)
          : null,
    },
    {
      name: '',
      value:
        isPcIssued && pcRequest.nextAttemptPossibleAt && !isToday(new Date(pcRequest.nextAttemptPossibleAt)) ? (
          <Hint
            text={translate('retryDateInformation', {
              retryDate: toLocaleDate(new Date(pcRequest.nextAttemptPossibleAt)),
            })}
          />
        ) : null,
    },
  ];

  if (pcRequest.status !== PCRequestStatus.PCIssued) {
    return <PaperCard title={translate('ongoingPCRequest')} data={paperCardData} />;
  }

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handlePcCardDownload = () => {
    handlePcDownload();
  };

  const handlePCReportDownload = () => {
    handleReportDownload();
  };

  const issuedPcActionButtons = isDownloadDropdown ? (
    <div dir={direction}>
      <Button
        className={clsx(classes.dropdownButton, classes.button)}
        onClick={handleToggle}
        ref={anchorRef}
        variant="outlined"
        size="large"
        color="secondary"
        disabled={isPcCardDownloading || isReportDownloading}
      >
        {translate('download')}
        <KeyboardArrowDownIcon className={classes.icon} />
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} transition disablePortal role={undefined}>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" className={classes.menuList}>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={(e) => {
                      handlePcCardDownload();
                      handleClose(e);
                    }}
                  >
                    {translate('performanceCard')}
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={(e) => {
                      handleReportDownload();
                      handleClose(e);
                    }}
                  >
                    {translate('report')}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  ) : (
    <>
      <Button
        className={classes.button}
        disabled={isPcCardDownloading}
        onClick={handlePcCardDownload}
        variant="outlined"
        size="large"
        color="secondary"
      >
        {isPcCardDownloading ? <LoadingSpinner /> : translate('downloadPcCard')}
      </Button>

      <Button
        className={classes.button}
        disabled={isReportDownloading}
        onClick={handlePCReportDownload}
        variant="outlined"
        size="large"
        color="secondary"
      >
        {isReportDownloading ? <LoadingSpinner /> : translate('downloadReport')}
      </Button>
    </>
  );

  return (
    <PaperCard
      title={translate('performanceCard')}
      data={paperCardData}
      reversedColors
      headerNode={issuedPcActionButtons}
    />
  );
};

export default CompanyRepresentativePcCard;
