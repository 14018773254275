import React from 'react';
import { SnackbarProvider, SnackbarKey } from 'notistack';

import { NotificationsSnackbar } from './NotificationsSnackbar';
import { NotificationsProps } from './types';

export const NotificationsProvider: React.FunctionComponent = ({ children }) => (
  <SnackbarProvider
    preventDuplicate={true}
    maxSnack={1}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    content={(key: SnackbarKey, notificationParams: NotificationsProps) => (
      <NotificationsSnackbar id={key} notificationParams={notificationParams} />
    )}
  >
    {children}
  </SnackbarProvider>
);
