import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import i18n from 'i18next';

import { InfoIcon } from 'ui/icons/icons';
import { MissingIcon } from 'ui/icons/icons';

import { getReverseDirection as getReverseDirectionForHint } from '../utilities/useLanguage';

type HintProps = { text: string; type?: 'error' | 'info' };

const useStyles = makeStyles((theme) => ({
  hintWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },

  hint: {
    marginTop: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2196F3',
    padding: '10px 16px',
    borderRadius: '4px',
    flexGrow: 0,
    width: 'auto',
  },

  errorStyle: {
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #f44336',
  },

  icon: {
    color: theme.palette.info.main,
  },

  text: {
    color: 'rgba(0, 0, 0, 0.6)',
    marginRight: '10px',
    marginLeft: '10px',
  },
}));

export const Hint: React.FC<HintProps> = ({ text, type = 'info' }) => {
  const classes = useStyles();
  const reverseDirection = getReverseDirectionForHint(i18n.language);


  const IconComponent = type === 'error' ? MissingIcon : InfoIcon;

  return (
    <div className={classes.hintWrapper}>
      <div className={`${classes.hint} ${type === 'error' ? classes.errorStyle : ''}`} dir={reverseDirection}>
        <Typography className={classes.text} variant="body2">
          {text}
        </Typography>
        {type === 'error' ? (
             <SvgIcon component={MissingIcon} viewBox="0 0 48 48" data-testid="MissingIcon" />
        ) : (
          <SvgIcon className={classes.icon} component={InfoIcon} />
        )}

      </div>
    </div>
  );
};
