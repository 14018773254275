import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { AxiosRequestConfig } from 'axios';

import { BaseQueryError } from 'infrastructure/persistence/baseQueryWithReAuth';
import { Page, Search } from 'infrastructure/persistence/types';
import { ListResult, Results } from 'interfaces/Results';
import { PCRequest, PCRequestDto, toPCRequest } from 'types/PCRequest';

import { AdminApiTags } from '../../../admin/api/admin.tags.api';
import { PcRequestApiTagTypes } from './pcrequest.tags.api';

type GetPCRequestsQuery = Page;

export const pcRequestApiEndpoints = (
  build: EndpointBuilder<BaseQueryFn<AxiosRequestConfig, unknown, BaseQueryError, {}, {}>, AdminApiTags, 'adminApi'>
) => ({
  getPCRequests: build.query<ListResult<PCRequest>, { param: Search; parameters : GetPCRequestsQuery}>({
    query: ({ parameters: { pageSize = 100, page = 1 } , param: { searchInfo } }) => ({
      url: `/performance_card_request/?page_size=${pageSize}&page=${page}&param=${searchInfo}`,
      method: 'GET',
    }),
    providesTags: (responseData) =>
      responseData
        ? [...responseData.results.map(({ id }) => ({ type: PcRequestApiTagTypes.PCRequest as const, id }))]
        : [PcRequestApiTagTypes.PCRequest],
    transformResponse: (response: Results<PCRequestDto>) => ({
      count: response.count,
      results: response.results.map(toPCRequest),
    }),
  }),
  getPCRequest: build.query<PCRequest, number>({
    query: (id) => ({
      method: 'GET',
      url: `/performance_card_request/${id}/`,
    }),
    transformResponse: (responseData: PCRequestDto) => toPCRequest(responseData),
    providesTags: (responseData) =>
      responseData ? [{ type: PcRequestApiTagTypes.PCRequest, id: responseData.id }] : [],
  }),
  startPcRequestAssessment: build.mutation<void, number>({
    query: (pcRequestId) => ({
      url: '/self_assessment/',
      method: 'POST',
      data: {
        performance_card_request_id: pcRequestId,
      },
    }),
    invalidatesTags: (results, error, pcRequestId) => [{ type: PcRequestApiTagTypes.PCRequest, id: pcRequestId }],
  }),
  assignSafetySpecialist: build.mutation<void, { pcRequestId: number; userId: number }>({
    query: ({ pcRequestId, userId }) => ({
      method: 'POST',
      url: `/performance_card_request/${pcRequestId}/assign/`,
      data: { user_id: userId },
    }),
    invalidatesTags: (results, error, { pcRequestId }) => [{ type: PcRequestApiTagTypes.PCRequest, id: pcRequestId }],
  }),
  approvedSafetySpecialist: build.mutation<void, { pcRequestId: string; userId: number }>({
    query: ({ pcRequestId, userId }) => ({
      method: 'POST',
      url: `/performance_card_request/${pcRequestId}/approved/`,
      data: { user_id: userId },
    }),
    invalidatesTags: (results, error, { pcRequestId }) => [{ type: PcRequestApiTagTypes.PCRequest, id: pcRequestId }],
  }),
  rejectedSafetySpecialist: build.mutation<void, { pcRequestId: string; userId: number }>({
    query: ({ pcRequestId, userId }) => ({
      method: 'POST',
      url: `/performance_card_request/${pcRequestId}/rejected/`,
      data: { user_id: userId },
    }),
    invalidatesTags: (results, error, { pcRequestId }) => [{ type: PcRequestApiTagTypes.PCRequest, id: pcRequestId }],
  }),
  issuePc: build.mutation<void, number>({
    query: (pcRequestId) => ({
      url: `/performance_card_request/${pcRequestId}/issue_performance_card/`,
      method: 'POST',
    }),
    invalidatesTags: (results, error, pcRequestId) => [{ type: PcRequestApiTagTypes.PCRequest, id: pcRequestId }],
  }),
});