import { SubmitHandler } from 'react-hook-form';

import {
  Container,
  makeStyles,
  TextField,
  Typography,
  useForm,
  React,
  Redirect,
  TranslationNamespace,
  useCreateTranslate,
  Button,
  shdShadow,
  useAppDispatch,
  useAppSelector,
  selectIsLoginInProgress,
  selectLoginError,
  selectUserTowFactor,
  PublicLayout,
  Routes,
  ListItemText,
  authenticateAuditor,
  authenticateAdmin,
  //selectAuth,
  authenticateInspector,
} from '../utils/importLib';
import { AdminAuthenticateCredentials } from '../../../infrastructure/services/auth.type';

type AuthenticateProps = {
  isUser: boolean;
  getPath: (value: Routes) => string;
  login: string;
  googleAuthenticatorDescription: string;
};

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    width: theme.breakpoints.values.md,
    maxWidth: theme.breakpoints.values.md,
    padding: '40px 120px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: shdShadow,
    marginTop: theme.spacing(6),
  },

  title: {
    marginBottom: theme.spacing(1),
    textAlign: 'center',
  },

  description: {
    marginBottom: theme.spacing(5),
    textAlign: 'center',
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
  },

  details: {
    textAlign: 'left',
  },

  input: {
    marginBottom: '32px',
  },

  invalidCredentials: {
    height: '40px',
    marginTop: '10px',
  },

  continueButton: {
    height: '50px',
  },
}));

export const Authenticate: React.FC<AuthenticateProps> = ({
  isUser,
  getPath,
  login,
  googleAuthenticatorDescription,
}) => {
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const translateCommon = useCreateTranslate(TranslationNamespace.common);

  const dispatch = useAppDispatch();
  const isLoginInProgress = useAppSelector(selectIsLoginInProgress);
 // const auth = useAppSelector(selectAuth);
  const loginError = useAppSelector(selectLoginError);
  const userTowFactor = useAppSelector(selectUserTowFactor);

  const classes = useStyles();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<AdminAuthenticateCredentials>();

  const onSubmit: SubmitHandler<AdminAuthenticateCredentials> = async (data) => {
    let newData = { ...data, ...userTowFactor } as AdminAuthenticateCredentials;
    switch (login) {
      case 'admin':
        return dispatch(authenticateAdmin(newData));
      case 'auditor':
        return dispatch(authenticateAuditor(newData));
      case 'inspector':
        return dispatch(authenticateInspector(newData));
    }
  };
  if (isUser) {
    return <Redirect to={getPath(Routes.dashboard)} />;
  }
  // if (isUser && !auth?.showPlatform) {
  //   return <Redirect to={getPath(Routes.dashboard)} />;
  // }
  // if (isUser && auth?.showPlatform) {
  //   return <Redirect to={getPath(Routes.selectPlatform)} />;
  // }
  if (!userTowFactor) {
    return <Redirect to={getPath(Routes.login)} />;
  }
  return (
    <PublicLayout>
      <Container className={classes.formWrapper}>
        <Typography variant="h4" className={classes.title}>
          {translate('PinCode')}
        </Typography>
        <Typography variant="body1" className={classes.description}>
          {translate(googleAuthenticatorDescription)}
        </Typography>
        {userTowFactor.first_login ? (
          <Typography variant="body1" className={classes.description}>
            <ListItemText className={classes.details} primary={translate('download')} />
            <ListItemText className={classes.details} primary={translate('application')} />
            <ListItemText className={classes.details} primary={translate('scanQR')} />
            <img alt="osh" src={userTowFactor.google_authenticator_qr_image} />
            <ListItemText className={classes.details} primary={translate('enterPinCode')} />
          </Typography>
        ) : null}

        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <TextField
            {...register('password', { required: true })}
            type="password"
            variant="outlined"
            error={errors.password?.type === 'required'}
            label={translate('enterYourPinCode')}
          />
          <div className={classes.invalidCredentials}>
            {loginError && (
              <Typography variant="caption" color="error">
                {translate('invalidCode')}
              </Typography>
            )}
          </div>

          <Button
            className={classes.continueButton}
            type="submit"
            disabled={!errors || isLoginInProgress}
            variant="contained"
            color="secondary"
          >
            {translateCommon('continue')}
          </Button>
        </form>
      </Container>
    </PublicLayout>
  );
};
