import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import clsx from 'clsx';
import { Theme, Chip } from '@material-ui/core';

import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { PCRequestStatus } from 'types/PCRequest';

import { useGetMeQuery } from '../../../infrastructure/services/user.api';
import { getPcRequestStatus, AuditorList, InspectorList, AuditorAssignList, InspectorAssignList } from '../utils/getPcRequestStatus';

type StatusProps = { status: PCRequestStatus };
type CellProps = TableCellProps & StatusProps;

const useStatusIconStyles = makeStyles<Theme, StatusProps>(({ palette }) => ({
  root: {
    fontWeight: 700,
    padding: '6px 9px',
    borderRadius: 60,
    fontSize: 10,

    color: (props) => {
      if (props.status === PCRequestStatus.AuditorStarted) {
        return palette.success.dark;
      } else {
        return palette.text.primary;
      }
    },
  },
}));

export const PCRequestTableStatusTableCell = (props: CellProps) => {
  const { status, className, ...other } = props;
  const classes = useStatusIconStyles({ status });
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const { data: currentUser } = useGetMeQuery();

  const getPCRequestTableStatus = () => {
    switch (status) {
      case PCRequestStatus.AuditorStarted:
      case PCRequestStatus.InspectorStarted:
        return (
          <Chip
            label={translate(getPcRequestStatus(status))}
            style={{ backgroundColor: '#c2b0e2', color: '#673ab7', width: '75%' }}
          />
        );

      case PCRequestStatus.auditorAssigned:
        return (
          <>
            {currentUser &&
              AuditorAssignList(currentUser.currentplatform).map((role) => (
                <Chip key={role.nameAuditorAssignList}
                      label= {translate(role.nameAuditorAssignList)}
                      style={{ backgroundColor: '#e3f2fd', color: '#2196F3', width: '75%' }}
                />
             ))}
           </>
        );
      case PCRequestStatus.InspectorAssigned:
        return (
          <>
            {currentUser &&
              InspectorAssignList(currentUser.currentplatform).map((role) => (
                <Chip key={role.nameInspectorAssignList}
                      label= {translate(role.nameInspectorAssignList)}
                      style={{ backgroundColor: '#e3f2fd', color: '#2196F3', width: '75%' }}
                />
             ))}
           </>
        );

      case PCRequestStatus.InspectorFinished:
      case PCRequestStatus.InspectorApproved:
      case PCRequestStatus.AuditorFinished:
        return (
          <Chip
            label={translate(getPcRequestStatus(status))}
            style={{ backgroundColor: '#fcedc1', color: '#856620', width: '75%' }}
          />
        );
      case PCRequestStatus.AuditorRejected:
      case PCRequestStatus.InspectorRejected:
        return (
          <Chip
            label={translate(getPcRequestStatus(status))}
            style={{ backgroundColor: '#FFE7E3', color: '#D76152', width: '75%' }}
          />
        );
      case PCRequestStatus.AuditorApproved:
        return (
          <>
            {currentUser &&
              InspectorList(currentUser.currentplatform).map((role) => (
                <Chip key={role.nameInspector}
                      label= {translate(role.nameInspector)}
                      style={{ backgroundColor: '#fcedc1', color: '#856620', width: '75%' }}
                />
             ))}
           </>
        );
      case PCRequestStatus.Unassigned:
        return (
          <>
          {currentUser &&
            AuditorList(currentUser.currentplatform).map((role) => (
              <Chip key={role.nameAuditor}
                    label= {translate(role.nameAuditor)}
                    style={{ backgroundColor: '#fcedc1', color: '#856620', width: '75%' }}
              />
          ))}
         </>
        );

      case PCRequestStatus.PCIssued:
        return (
          <Chip
            label={translate(getPcRequestStatus(status))}
            style={{ backgroundColor: '#e8f5e9', color: '#3B873E', width: '75%' }}
          />
        );
      default:
        return (
          <Chip
            label={translate(getPcRequestStatus(status))}
            style={{ backgroundColor: '#fcedc2', color: '#856620', width: '75%' }}
          />
        );
    }
  };

  return (
    <TableCell className={clsx(className)} {...other}>
      <span className={classes.root}>{getPCRequestTableStatus()}</span>
    </TableCell>
  );
};