import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { MouseEventHandler } from 'react';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import NavigatePreviousIcon from '@material-ui/icons/NavigateBefore';
import SvgIcon from '@material-ui/core/SvgIcon';
import Paper from '@material-ui/core/Paper';
import i18n from 'i18next';

import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { useNavigate } from 'utilities/navigate';
import { Button } from 'ui/Button';
import { ReactComponent as ArrowForwardIcon } from 'ui/icons/arrow_forward.svg';
import { ReactComponent as ArrowBackIcon } from 'ui/icons/arrow-back.svg';
import { ReactComponent as SaveIcon } from 'ui/icons/save.svg';
import { backButtonBackgroundColor, shdShadow } from 'ui/styles';
import { useAppDispatch, useAppSelector } from 'infrastructure/store/rootStore.hooks';
import { isQuestionnaireDraftOrMissing } from 'domain/admin/utils/QuestionnaireUtils';

import { AdminLayout } from '../ui/AdminLayout';
import { getAdminPath } from '../AdminRoutes';
import { AdminQuestionnaireStepOne } from './AdminQuestionnaireStepOne';
import { AdminQuestionnaireSections } from './admin-questionnaire-section/AdminQuestionnaireSections';
import { AdminQuestionnaireStepThree } from './AdminQuestionnaireStepThree';
import { AdminQuestionnaireReview } from './AdminQuestionnaireReview';
import { AdminQuestionnaireStepper } from './AdminQuestionnaireStepper';
import { AdminQuestionnairePublishDialog } from './AdminQuestionnairePublishDialog';
import { reset as resetAdminQuestionnaireSlice, selectQuestionnaireId } from './AdminQuestionnaire.slice';
import { JUMP_TO_STEP, NEXT_STEP } from './formDataAction';
import { useGetQuestionnaireByIdQuery } from '../api/admin.api';
import { Routes } from '../../common/Routes';
import { getDirection, getReverseDirection } from '../../../utilities/useLanguage';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: theme.spacing(10),
  },
  topic: {
    marginTop: theme.spacing(4),
    display: 'flex',
    alignItems: 'flex-start',
  },

  nextButton: {
    flip: false,
    height: '50px',
    width: '187px',
  },

  nextButtonDown: {
    marginTop: theme.spacing(1),
    direction: 'rtl',
  },

  backButtonBase: {
    flip: false,
    height: '50px',
    width: '80px',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },

  backButton: {
    backgroundColor: backButtonBackgroundColor,
    color: theme.palette.secondary.main,
  },

  saveDraft: {
    marginTop: '12px',
    flip: true,
    flexGrow: 1,
  },

  saveDraftButton: {
    flip: false,
    display: 'inline-block',
    position: 'relative',
    padding: 0,
  },

  saveDraftIcon: {
    flip: false,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    verticalAlign: 'bottom',
  },

  topicTypography: {
    marginTop: theme.spacing(1),
    flip: false,
    display: 'flex',
    flexDirection: 'column',
  },
  stepWrapper: {
    boxShadow: shdShadow,
    marginTop: theme.spacing(5),
    padding: `${theme.spacing(4)}px`,
  },
  backToListButton: {
    width: '165px',
  },
}));

export const AdminQuestionnaire: React.FC = () => {
  const translate = useCreateTranslate(TranslationNamespace.admin);

  const classes = useStyles();
  const navigate = useNavigate();
  const [stepIndex, setStepIndex] = React.useState(0);
  const dispatch = useAppDispatch();
  const questionnaireId = useAppSelector(selectQuestionnaireId);
  const { data: questionnaire } = useGetQuestionnaireByIdQuery(questionnaireId, {
    skip: !questionnaireId,
  });
  const [openPublishDialog, setOpenPublishDialog] = React.useState(false);

  const goNext = (event: React.BaseSyntheticEvent | undefined) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { action, next } = (event?.nativeEvent as any).submitter.dataset;

    switch (action) {
      case NEXT_STEP:
        setStepIndex(stepIndex + 1);
        break;
      case JUMP_TO_STEP:
        setStepIndex(+next);
        break;
      default:
        navigateToDashboard();
    }
  };

  const getStepComponent = () => {
    switch (stepIndex) {
      case 1:
        return questionnaire && <AdminQuestionnaireSections goNextStep={goNext} questionnaire={questionnaire} />;
      case 2:
        return (
          <Paper className={classes.stepWrapper}>
            {questionnaireId && <AdminQuestionnaireStepThree questionnaireId={questionnaireId} goNextStep={goNext} />}
          </Paper>
        );
      case 3:
        return (
          questionnaire && (
            <AdminQuestionnaireReview setStepIndex={setStepIndex} questionnaire={questionnaire} goNextStep={goNext} />
          )
        );
      default:
        return (
          <Paper className={classes.stepWrapper}>
            <AdminQuestionnaireStepOne goNextStep={goNext} />
          </Paper>
        );
    }
  };

  const goPrevStep = () => {
    stepIndex > 0 && setStepIndex(stepIndex - 1);
  };

  const navigateToDashboardHandler: MouseEventHandler = (event) => {
    event.preventDefault();

    navigateToDashboard();
  };

  const navigateToDashboard = () => {
    dispatch(resetAdminQuestionnaireSlice());

    navigate(getAdminPath(Routes.dashboard));
  };
  const direction = getDirection(i18n.language);
  const reverseDirection = getReverseDirection(i18n.language);

  return (
    <AdminLayout>
      <Container maxWidth="lg" className={classes.wrapper}>
        <Breadcrumbs separator={<NavigatePreviousIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="textSecondary" href="#" onClick={navigateToDashboardHandler}>
            {translate('selfAssessmentTemplates')}
          </Link>
          <Typography color="textPrimary">{translate('newSelfAssessmentTemplate')}</Typography>
        </Breadcrumbs>
        <div dir={reverseDirection} className={classes.topic}>
          {stepIndex === 3 && isQuestionnaireDraftOrMissing(questionnaire) && (
            <Button
              variant="contained"
              size="large"
              className={classes.nextButton}
              color="secondary"
              onClick={() => {
                setOpenPublishDialog(true);
              }}
            >
              {translate('publish')}
            </Button>
          )}

          {stepIndex === 0 ? (
            <Button
              size="large"
              color="secondary"
              variant="outlined"
              className={` ${classes.nextButton} ${classes.backToListButton}`}
              onClick={navigateToDashboard}
            >
              {translate('backToTemplates')}
            </Button>
          ) : (
            <Button
              onClick={goPrevStep}
              variant="outlined"
              size="large"
              className={`${classes.backButtonBase} ${classes.backButton}`}
              color="secondary"
            >
              {i18n.language === 'ar' ? (
                <SvgIcon component={ArrowForwardIcon} />
              ) : (
                <SvgIcon component={ArrowBackIcon} />
              )}
            </Button>
          )}
          <div className={classes.saveDraft}>
            <Button
              className={classes.saveDraftButton}
              hidden={stepIndex === 0}
              color="secondary"
              size="large"
              type="submit"
              form="stepForm"
              value="Submit"
            >
              <>
                <SvgIcon className={classes.saveDraftIcon} component={SaveIcon} />
                {translate('saveAndExit')}
              </>
            </Button>
          </div>
          <div dir={direction} className={classes.topicTypography}>
            <Typography variant="h4">{translate('createNewSelfAssessmentTemplate')}</Typography>
          </div>
        </div>

        <AdminQuestionnaireStepper
          setStepIndex={setStepIndex}
          activeStepIndex={stepIndex}
          steps={[
            translate('generalInfo'),
            translate('sectionAndQuestions'),
            translate('scoring'),
            translate('reviewAndPublish'),
          ]}
        />

        {getStepComponent()}
        <div className={classes.nextButtonDown}>
          {stepIndex !== 3 && (
            <Button
              variant="contained"
              size="large"
              className={classes.nextButton}
              color="secondary"
              type="submit"
              form="stepForm"
              value="Submit"
              data-action={NEXT_STEP}
            >
              {translate('nextStep')}
            </Button>
          )}
        </div>
      </Container>

      {questionnaire?.id && (
        <AdminQuestionnairePublishDialog
          handleClose={() => {
            setOpenPublishDialog(false);
          }}
          navigateToDashboard={navigateToDashboard}
          open={openPublishDialog}
          questionnaireId={questionnaire.id}
        />
      )}
    </AdminLayout>
  );
};
