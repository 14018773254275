import {
  AssessmentApiTagsType,
  assessmentApiTagsTypes,
} from 'infrastructure/services/api/assessment.endpoints.tags.api';
import {
  EstablishmentApiTagType,
  establishmentTagTypes,
} from 'infrastructure/services/api/establishment.endpoints.tags';

import { pcRequestApiTagTypes, PcRequestApiTagTypes } from '../../common/pcrequests/api/pcrequest.tags.api';

export enum AdminApiTagType {
  Questionnaire = 'Questionnaire',
  QSection = 'QuestionnaireSection',
  QSections = 'QuestionnaireSections',
  QSectionQuestion = 'QuestionnaireSectionQuestion',
  QSectionQuestions = 'QuestionnaireSectionQuestions',
  Users = 'Users',
}

export type AdminApiTags = AdminApiTagType | PcRequestApiTagTypes | AssessmentApiTagsType | EstablishmentApiTagType;

export const adminApiTagTypes: AdminApiTags[] = [
  AdminApiTagType.Questionnaire,
  AdminApiTagType.QSection,
  AdminApiTagType.QSections,
  AdminApiTagType.QSectionQuestion,
  AdminApiTagType.QSectionQuestions,
  AdminApiTagType.Users,
  ...pcRequestApiTagTypes,
  ...assessmentApiTagsTypes,
  ...establishmentTagTypes,
];
