import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';

import { Login } from '../../common/login/Login';
import { getAuditorPath } from '../AuditorRoutes';

export const AuditorLogin: React.FunctionComponent = () => {
  const translateCommon = useCreateTranslate(TranslationNamespace.common);
     return <Login getPath={getAuditorPath} login={'auditor'} continueMessage={translateCommon('continue')} />;
};
