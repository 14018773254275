import {  makeStyles, ListItemText, List } from '@material-ui/core';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';



type UsersCreateDialogProps = {
  upperCaseFlag: string;
  lowerCaseFlag:string;
  characterLengthFlag:string;
  symbolFlag:string;
  oneNumberFlag:string;

};

const useStyles = makeStyles((theme) => ({

  passwordTerms: {
    textAlign: 'justify',
  },

}));

export const ValidationPassword: React.FC<UsersCreateDialogProps>  = ({
  upperCaseFlag,
  lowerCaseFlag,
  characterLengthFlag,
  symbolFlag,
  oneNumberFlag
 }) => {
  const translate = useCreateTranslate(TranslationNamespace.confirmation);
  const classes = useStyles();


    return (
      <>
        <List className={classes.passwordTerms}>
          <ListItemText className={characterLengthFlag} primary={translate('passwordValidation1')} />
          <ListItemText className={symbolFlag} primary={translate('passwordValidation2')} />
          <ListItemText className={upperCaseFlag} primary={translate('passwordValidation3')} />
          <ListItemText className={lowerCaseFlag} primary={translate('passwordValidation4')} />
          <ListItemText className={oneNumberFlag} primary={translate('passwordValidation5')} />
        </List>
      </>
    )
}