/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';

import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { AssessmentType } from 'types/Assessment';

import { PCRequestAssessmentCommentHeader } from '../../admin/ui/PCRequestAssessmentCommentHeader';
import { PCRequestAssessmentPreviousAnswererSelect } from '../../admin/ui/PCRequestAssessmentPreviousAnswererSelect';
import { PCRequestAssessmentPreviousAnswerValueTitle } from '../../admin/ui/PCRequestAssessmentPreviousAnswerValueTitle';

type PCRequestAssessmentCompareCommentProps = {
  comment?: string;
  answerers: AssessmentType[];
  answerer: AssessmentType;
  changeAnswerer: (value: AssessmentType) => void;
};

const useStyle = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  commentWrapper: {
    flip: false,
    padding: '8px 104px 32px 71px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  commentTopic: {
    marginTop: theme.spacing(3),
  },

  comment: {
    marginTop: theme.spacing(1),
  },
}));

export const PCRequestAssessmentCompareComment: React.FC<PCRequestAssessmentCompareCommentProps> = ({
  comment,
  answerers,
  answerer,
  changeAnswerer,
}) => {
  const classes = useStyle();
  const translate = useCreateTranslate(TranslationNamespace.admin);

  return (
    <Paper className={classes.main}>
      <PCRequestAssessmentCommentHeader
        topicTitle={translate('finalCommentTitle')}
        topicDescription={translate('finalAssessmentComment')}
      />
      <div className={classes.commentWrapper}>
        <PCRequestAssessmentPreviousAnswererSelect
          answerers={answerers}
          selectedAnswerer={answerer}
          selectKey="assessment-compare-final-comment-select"
          changeAnswerer={changeAnswerer}
        />
        <PCRequestAssessmentPreviousAnswerValueTitle text={translate('comment')} className={classes.commentTopic} />
        <Typography variant="body2" className={classes.comment}>
          {comment && comment.length > 0 ? comment : '-'}
        </Typography>
      </div>
    </Paper>
  );
};
