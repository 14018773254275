import { Container, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { shdShadow } from 'ui/styles';

import { PublicLayout } from '../ui/PublicLayout';

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    width: theme.breakpoints.values.md,
    maxWidth: theme.breakpoints.values.md,
    padding: '40px 120px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: shdShadow,
    marginTop: theme.spacing(6),
    textAlign: 'center',
  },
  title: { marginBottom: theme.spacing(1) },
  description: {
    marginBottom: theme.spacing(5),
  },
  passwordTerms: {
    textAlign: 'justify',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
  },
  input: {
    marginBottom: '32px',
  },

  invalidCredentials: {
    height: '40px',
  },

  continueButton: {
    height: '50px',
  },
  field: {
    marginTop: theme.spacing(4),
  },
}));

export const NotFoundPage = () => {
  const translateCommon = useCreateTranslate(TranslationNamespace.common);
  const classes = useStyles();

  return (
    <PublicLayout>
      <Container className={classes.formWrapper}>
        <Typography variant="h4" className={classes.title}>
          {translateCommon('four_o_four')}
        </Typography>
        <Typography variant="body1" className={classes.description}>
          {translateCommon('not_found_page')}
        </Typography>
      </Container>
    </PublicLayout>
  );
};
