import { makeStyles } from '@material-ui/core';
import React from 'react';
import Paper from '@material-ui/core/Paper';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';

import { Button } from 'ui/Button';
import { ReactComponent as NoQuestionnairesIcon } from 'ui/icons/no_questionnaires2.svg';
import { shdShadow } from 'ui/styles';
import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';

import { getAdminPath } from '../AdminRoutes';
import { Routes } from '../../common/Routes';

const useStyles = makeStyles((theme) => ({
  noQuestionnaires: {
    padding: '95px 0',
    boxShadow: shdShadow,
    textAlign: 'center',
  },

  noQuestionnairesIcon: {
    height: '216px',
    width: 'auto',
    fill: 'none',
  },
  createButton: {
    height: '50px',
    width: '200px',
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      width: '300px',
    },
  },
  title: {
    marginTop: '34px',
    fontWeight: 'bold',
    fontSize: '30px',
    lineHeight: '20px',
    letterSpacing: '0.14px',
  },

  titleCaption: {
    marginTop: theme.spacing(5),
  },
}));

export const TemplateTableNoData = () => {
  const history = useHistory();
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const classes = useStyles();
  const goToCreateQuestionnaire = () => {
    history.push(getAdminPath(Routes.questionnaire));
  };
  return (
    <Paper data-testid="template-table-no-data" className={classes.noQuestionnaires}>
      <SvgIcon className={classes.noQuestionnairesIcon} component={NoQuestionnairesIcon} viewBox="0 0 375 216" />
      <Typography  className={classes.title}>{translate('noSelfAssessmentTemplates')}</Typography>
      <Typography className={classes.titleCaption} variant="body2">
        {translate('createTemplateFirst')}
      </Typography>
      <Button
         onClick={goToCreateQuestionnaire}
         variant="contained"
         color="secondary"
         className={classes.createButton}
         size="large"
        >
         {translate('createNewTemplate')}
      </Button>
    </Paper>
  );
};
