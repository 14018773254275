import { Container, makeStyles } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';

import { LoadingWrapper } from 'ui/LoadingWrapper';
import { Layout } from 'ui/Layout';

import { useGetEstablishmentQuery } from '../api/companyRepresentative.api';
import { CREstablishmentDetailsHeader } from './CREstablishmentDetailsHeader';
import { CREstablishmentDetailsBody } from './CREstablishmentDetailsBody';
import { toFormValues } from '../types/MissingInfo';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginTop: theme.spacing(10.5),
  },
}));

export const CREstablishmentDetails = () => {
  const { establishmentId } = useParams<{ establishmentId: string }>();
  const { data: establishment, isLoading } = useGetEstablishmentQuery(+establishmentId);

  const classes = useStyles();

  return (
    <Layout maxWidth="lg">
      <Container maxWidth="lg" className={classes.container}>
        <LoadingWrapper isLoading={isLoading}>
          {establishment && <CREstablishmentDetailsHeader establishment={establishment} />}
          {establishment && (
            <CREstablishmentDetailsBody establishment={establishment} formValues={toFormValues(establishment || {})} />
          )}
        </LoadingWrapper>
      </Container>
    </Layout>
  );
};
