import { Page } from 'infrastructure/persistence/types';

type PatchOrderDto = {
  id: number;
  new_order: number;
};

export type PatchQuestionDto = PatchOrderDto;
export type PatchSectionDto = PatchOrderDto;

export type QuestionnaireQuestionDto = {
  id: number;
  textAR: string;
  textEN: string;
  descriptionAR: string;
  descriptionEN: string;
  weight: string;
  order: number;
  created_at: string;
  section_id: number;
  type: QuestionType;
};

export type QuestionnaireQuestion = {
  id: number;
  textAR: string;
  textEN: string;
  descriptionAR: string;
  descriptionEN: string;
  weight: number;
  order: number;
  createdAt: string;
  sectionId: number;
  type: QuestionType;
};

export type GetQuestionQuery = Page & {
  sectionId: number;
};

export type CreateQuestionDto = Pick<
  QuestionnaireQuestionDto,
  'section_id' | 'textAR' | 'textEN' | 'descriptionAR' | 'descriptionEN' | 'weight' | 'type'
>;

export type CreateQuestionInput = {
  questionnaireId: number;
  data: CreateQuestionDto;
};

export type UpdateQuestionDto = {
  id: number;
  questionnaireId: number;
  data: CreateQuestionDto;
};

export const toQuestionnaireQuestion = (dto: QuestionnaireQuestionDto): QuestionnaireQuestion => ({
  id: dto.id,
  textAR: dto.textAR,
  textEN: dto.textEN,
  descriptionAR: dto.descriptionAR,
  descriptionEN: dto.descriptionEN,
  weight: Number(dto.weight),
  order: dto.order,
  createdAt: dto.created_at,
  sectionId: dto.section_id,
  type: dto.type,
});

export enum QuestionType {
  YES_NO_NOT_APPLICABLE = 1,
  YES_NO = 2,
}

export const QuestionTypes = [
  { value: QuestionType.YES_NO_NOT_APPLICABLE, name: 'yes_no_not_applicable' },
  { value: QuestionType.YES_NO, name: 'yes_no' },
];
