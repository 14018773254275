import { SelfAssessmentPassingStatus } from 'types/Assessment';
import { TranslateFn } from 'utilities/translate.hook';

export const getZoneName = (status: SelfAssessmentPassingStatus, translate: TranslateFn) => {
  switch (status) {
    case SelfAssessmentPassingStatus.ABOVE_HIGH_SCORE_VALUE:
      return translate('green');
    case SelfAssessmentPassingStatus.BELOW_HIGH_SCORE_VALUE:
      return translate('orange');
    case SelfAssessmentPassingStatus.BELOW_PASSING_SCORE:
      return translate('red');
  }
};
