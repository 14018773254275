import { PCRequestStatus } from 'types/PCRequest';

export const getPcRequestStatus = (status: PCRequestStatus) => {
  switch (status) {
    case PCRequestStatus.Unassigned:
      return 'unassigned';
    case PCRequestStatus.auditorAssigned:
      return 'auditorAssigned';
    case PCRequestStatus.AuditorStarted:
      return 'auditorStarted';
    case PCRequestStatus.AuditorFinished:
      return 'auditorFinished';
    case PCRequestStatus.AuditorApproved:
      return 'auditorFinished';
    case PCRequestStatus.InspectorAssigned:
      return 'inspectorAssigned';
    case PCRequestStatus.InspectorStarted:
      return 'inspectorStarted';
    case PCRequestStatus.InspectorFinished:
      return 'waitForInspectorApproved';
    case PCRequestStatus.AuditorRejected:
        return 'auditorRejectedPC';
    case PCRequestStatus.InspectorRejected:
        return 'auditorRejectedPC';
    case PCRequestStatus.InspectorApproved:
      return 'inspectorFinished';
    case PCRequestStatus.PCIssued:
      return 'pcIssued';
    default:
      throw Error('Unhandled status display key');
  }
};