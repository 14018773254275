import React from 'react';


import { Establishments } from '../../common/establishments/Establishments';
import { getAdminPath } from '../AdminRoutes';
import { AdminLayout } from '../ui/AdminLayout';

export const AdminEstablishments: React.FC = () => {
  return (
    <AdminLayout>
      <Establishments getPath={getAdminPath} />
    </AdminLayout>
  );
};
