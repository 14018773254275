import axios, { AxiosRequestConfig } from 'axios';

import { REACT_APP_API_URL } from 'infrastructure/core/variables';
import { selectToken } from 'infrastructure/services/authSlice.selector';
import { RootState } from 'infrastructure/store/rootStore.types';

export const createAxiosInstance = (config?: Partial<AxiosRequestConfig>) =>
  axios.create({ baseURL: `${REACT_APP_API_URL}/api`, ...config });

export const axiosInstance = createAxiosInstance();

export const defaultAxiosInstance = () => axios.create();

export const createHeaderWithAuthorization = (rootState: RootState) => ({
  authorization: `Bearer ${selectToken(rootState)}`,
});
