import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import i18n from 'i18next';

import { Button, CustomButtonProps } from '../Button';
import { backButtonBackgroundColor } from '../styles';
import { ArrowForwardIcon } from '../icons/icons';
import { getDirection } from '../../utilities/useLanguage';

const useStyles = makeStyles((theme) => ({
  backButton: {
    height: '50px',
    width: '80px',
    backgroundColor: backButtonBackgroundColor,
    color: theme.palette.secondary.main,
  },
}));
const direction = getDirection(i18n.language);

export const BackButton: React.FC<CustomButtonProps> = (props) => {
  const classes = useStyles();

  return (
    <Button
      dir={direction}
      variant="outlined"
      size="large"
      className={`${classes.backButton} ${props.className || ''}`}
      color="secondary"
      onClick={props.onClick}
      data-testid="back-button"
    >
      <SvgIcon component={ArrowForwardIcon} />
    </Button>
  );
};
