import { makeStyles, Container, Stepper, Step, StepLabel } from '@material-ui/core';
import React from 'react';
import StepButton from '@material-ui/core/StepButton';

import { shdShadow } from 'ui/styles';
import { ProgressBarProps } from 'ui/types/ProgressBarProps.type';

import { JUMP_TO_STEP } from './formDataAction';

type AdminQuestionnaireStepperProps = {
  setStepIndex: React.Dispatch<React.SetStateAction<number>>;
} & ProgressBarProps;

const useStyles = makeStyles((theme) => ({
  main: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '29px 0',
    background: theme.palette.background.paper,
    marginTop: theme.spacing(6),
    borderRadius: '8px',
    boxShadow: shdShadow,
  },

  stepper: {
    width: '100%',
    padding: 0,
  },
}));

export const AdminQuestionnaireStepper: React.FC<AdminQuestionnaireStepperProps> = ({
  activeStepIndex,
  steps,
  setStepIndex,
}) => {
  const classes = useStyles();

  const handleStep = (index: number) => () => {
    setStepIndex(index);
  };

  return (
    <Container className={classes.main}>
      <Stepper className={classes.stepper} alternativeLabel nonLinear activeStep={activeStepIndex}>
        {steps.length === activeStepIndex + 1
          ? steps.map((label, index) => (
              <Step key={`${label}-questionnaire`}>
                <StepButton
                  onClick={handleStep(index)}
                  completed={activeStepIndex !== index && activeStepIndex > index}
                >
                  <StepLabel>{label}</StepLabel>
                </StepButton>
              </Step>
            ))
          : steps.map((label, index) => (
              <Step key={`${label}-questionnaire`}>
                <StepButton
                  data-action={JUMP_TO_STEP}
                  data-next={index}
                  type="submit"
                  form="stepForm"
                  value="Submit"
                  completed={activeStepIndex !== index && activeStepIndex > index}
                >
                  <StepLabel>{label}</StepLabel>
                </StepButton>
              </Step>
            ))}
      </Stepper>
    </Container>
  );
};
