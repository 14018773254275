import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { REACT_APP_API_URL } from 'infrastructure/core/variables';

import { Info, InfoDto, toInfo } from '../types/Info';

export const landingPageApi = createApi({
  reducerPath: 'landingPageApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${REACT_APP_API_URL}/api` }),
  endpoints: (build) => ({
    getInfo: build.query<Info, void>({
      query: () => ({
        url: `/info/`,
      }),
      transformResponse: (res: InfoDto) => toInfo(res),
    }),
  }),
});

export const { useGetInfoQuery } = landingPageApi;
