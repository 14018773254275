import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import i18n from 'i18next';

import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { CheckGreenIcon, MissingIcon } from 'ui/icons/icons';

import { getDirection, getReverseDirection } from '../../../utilities/useLanguage';
const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '32px 56px',
  },

  svgIcon: {
    flip: false,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
}));

export const CompanyRepresentativeSASummarySection: React.FC<{
  sectionNumber: number;
  isCompleted: boolean;
  nameAR: string;
  nameEN: string;
}> = ({ isCompleted, nameAR, nameEN, sectionNumber }) => {
  const classes = useStyles();
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const direction = getDirection(i18n.language);
  const reverseDirection = getReverseDirection(i18n.language);

  return (
    <div dir={reverseDirection} className={classes.section}>
      <Typography dir={direction} variant="h6">
        {translate('section')}
        {`${sectionNumber}: `}
        {i18n.language === 'en' ? nameEN : nameAR}
      </Typography>
      {isCompleted ? (
        <SvgIcon className={classes.svgIcon} component={CheckGreenIcon} />
      ) : (
        <SvgIcon className={classes.svgIcon} component={MissingIcon} viewBox="0 0 48 48" />
      )}
    </div>
  );
};
