export enum AssessmentApiTagsType {
  AssessmentSection = 'AssessmentSection',
  AssessmentSections = 'AssessmentSections',
  AssessmentQuestion = 'AssessmentQuestion',
  AssessmentQuestions = 'AssessmentQuestions',
  AssessmentAnswer = 'AssessmentAnswer',
  AssessmentAnswers = 'AssessmentAnswers',
  AssessmentAttachment = 'AssessmentAttachment',
  Assessment = 'Assessment',
}

export const assessmentApiTagsTypes: AssessmentApiTagsType[] = [
  AssessmentApiTagsType.AssessmentAnswer,
  AssessmentApiTagsType.AssessmentAnswers,
  AssessmentApiTagsType.AssessmentQuestion,
  AssessmentApiTagsType.AssessmentQuestions,
  AssessmentApiTagsType.AssessmentSection,
  AssessmentApiTagsType.AssessmentSections,
  AssessmentApiTagsType.AssessmentAttachment,
  AssessmentApiTagsType.Assessment,
];
