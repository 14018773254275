import { makeStyles } from '@material-ui/core';
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import React from 'react';

import { useGetCitiesListQuery } from '../api/companyRepresentative.api';
import { City } from '../types/Localization';

export interface FillMissingInfoCityProps {
  regionId: number;
  renderInput: (params: AutocompleteRenderInputParams) => React.ReactNode;
  onChange: ((event: React.ChangeEvent<{}>, value: City | null) => void) | undefined;
}

const useStyles = makeStyles((theme) => ({
  inputField: {
    marginBlock: theme.spacing(1.5),
    [theme.breakpoints.up('sm')]: {
      marginBlock: theme.spacing(2),
    },
  },
}));

export const CrFillMissingInfoCity: React.FC<FillMissingInfoCityProps> = ({ regionId, renderInput, onChange }) => {
  const classes = useStyles();
  const { data: cityOptions } = useGetCitiesListQuery(regionId);

  return (
    <Autocomplete
      selectOnFocus
      clearOnBlur
      blurOnSelect
      className={classes.inputField}
      options={cityOptions || []}
      getOptionLabel={(city) => city.nameAr}
      renderInput={renderInput}
      onChange={onChange}
      getOptionSelected={(option, v) => option.id === v.id}
    />
  );
};
