import { PCRequestStatus } from 'types/PCRequest';
import { User } from 'types/User';

import { Role } from '../../../infrastructure/services/auth.type';

type AvailablePcRequestActions<T> = {
  isAbleToStartAudit: T;
  isAbleToContinueAudit: T;
  isAbleToIssuePc: T;
  isAbleToAuditorApproved: T;
  isAbleToAuditorRejected: T;
  isAbleToInspectorApproved: T;
  isAbleToInspectorRejected: T;
  isAbleToAssignSpecialist: T;
  isAbleToStartInspection: T;
  isAbleToContinueInspection: T;
};

export const availablePcRequestActions = (
  pcRequestStatus: PCRequestStatus,
  auditor?: User | null,
  inspector?: User | null,
  currentUser?: User | null
): AvailablePcRequestActions<boolean> => ({
  isAbleToStartAudit:
    pcRequestStatus === PCRequestStatus.auditorAssigned && Boolean(auditor?.id) && auditor?.id === currentUser?.id,
  isAbleToContinueAudit:
    pcRequestStatus === PCRequestStatus.AuditorStarted && Boolean(auditor?.id) && auditor?.id === currentUser?.id,
  isAbleToIssuePc:
    pcRequestStatus === PCRequestStatus.AuditorApproved || pcRequestStatus === PCRequestStatus.InspectorApproved,
  isAbleToAuditorApproved:
    pcRequestStatus === PCRequestStatus.AuditorFinished && Boolean(auditor?.id) && auditor?.id === currentUser?.id,
  isAbleToAuditorRejected:
    pcRequestStatus === PCRequestStatus.AuditorFinished && Boolean(auditor?.id) && auditor?.id === currentUser?.id,
  isAbleToInspectorApproved:
    pcRequestStatus === PCRequestStatus.InspectorFinished && Boolean(inspector?.id) &&
    inspector?.id === currentUser?.id,
  isAbleToInspectorRejected:
    pcRequestStatus === PCRequestStatus.InspectorFinished && Boolean(inspector?.id) &&
    inspector?.id === currentUser?.id,
  isAbleToAssignSpecialist:
    (pcRequestStatus === PCRequestStatus.Unassigned || pcRequestStatus === PCRequestStatus.AuditorApproved) &&
    currentUser?.accountType === Role.Admin,
  isAbleToStartInspection:
    pcRequestStatus === PCRequestStatus.InspectorAssigned &&
    Boolean(inspector?.id) &&
    inspector?.id === currentUser?.id,
  isAbleToContinueInspection:
    pcRequestStatus === PCRequestStatus.InspectorStarted && Boolean(inspector?.id) && inspector?.id === currentUser?.id,
});
