import React from 'react';

import { InspectorLayout } from '../ui/InspectorLayout';
import { PCRequestAssessment as CommonPCRequestAssessment } from '../../common/pcrequest-assessment/PCRequestAssessment';
import { getInspectorPath } from '../InspectorRoutes';

export const PCRequestAssessment: React.FC = () => {
  return (
    <InspectorLayout>
      <CommonPCRequestAssessment getPath={getInspectorPath} />
    </InspectorLayout>
  );
};
