import OshPattern from 'images/osh-pattern2.svg';

export const shdShadow = '0px 16px 40px rgba(0, 0, 0, 0.04)';
export const backButtonBackgroundColor = '#ECF5F5';
export const mainBackground = {
  backgroundImage: `url(${OshPattern})`,
  backgroundRepeat: 'repeat',
};

// text
export const labelCardColor = 'rgba(0, 0, 0, 0.6)';

// modals
export const backdropBackgroundColor = 'rgba(20, 65, 90, 0.6)';

// collapseButtonSpace
export const collapseButtonSpaceWidth = '172px';

export const popperItemHoverBackground =
  'linear-gradient(0deg, rgba(20, 130, 135, 0.08), rgba(20, 130, 135, 0.08)), #FFFFFF';
