import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { useAssessmentAnswerTypeText } from 'utilities/useAssessmentAnswerTypeText.hook';

import { AssessmentAnswer } from '../../../types/AssessmentAnswer';

type CompanyRepresentativeSAResultQuestionAnswerProps = {
  answer: AssessmentAnswer;
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
  },

  disabled: {
    color: `${theme.palette.text.primary}!important`,
  },

  answer: {
    flip: true,
    color: `${theme.palette.secondary.main}!important`,
  },
}));

export const CompanyRepresentativeSAResultQuestionAnswer: React.FC<CompanyRepresentativeSAResultQuestionAnswerProps> =
  ({ answer }) => {
    const classes = useStyles();
    const getAnswerTypeText = useAssessmentAnswerTypeText();

    return (
      <FormControlLabel
        disabled
        classes={{
          root: classes.root,
          disabled: classes.disabled,
        }}
        control={<Radio className={classes.answer} checked disableRipple />}
        label={getAnswerTypeText(answer.type)}
      />
    );
  };
