import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { BottomBorderWrapper } from 'ui/BottomBorderWrapper';
import { AssessmentAnswer } from 'types/AssessmentAnswer';
import { LoadingSpinner } from 'ui/LoadingSpinner';
import { AssessmentQuestion } from 'types/AssessmentQuestion';
import { AssessmentType } from 'types/Assessment';

import {
  useGetAnswersByAssessmentIdAndSectionIdQuery,
  useGetQuestionsByAssessmentIdAndSectionIdQuery,
} from '../../admin/api/admin.api';
import { PCRequestAssessmentQuestion } from '././PCRequestAssessmentQuestion';
import { PcRequestAssessmentInfo } from '././types/PcRequestAssessmentInfo';
import { QuestionAndAnswers } from './types/QuestionAndAnswers';

type PCRequestAssessmentQuestionsProps = {
  pcAssessmentInfo: PcRequestAssessmentInfo;
  sectionId: number;
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
}));

const findAnswersForQuestion = (
  currentAssessmentType: AssessmentType,
  questions: AssessmentQuestion[],
  currentAnswers: AssessmentAnswer[],
  crAnswers: AssessmentAnswer[],
  auditorAnswers: AssessmentAnswer[]
): QuestionAndAnswers[] =>
  questions.map((question) => ({
    currentAssessmentType,
    question,
    currentAnswer: currentAnswers.find(({ questionId }) => questionId === question.id),
    crAnswer: crAnswers.find(({ questionId }) => questionId === question.id),
    auditorAnswer: auditorAnswers.find(({ questionId }) => questionId === question.id),
  }));

export const PCRequestAssessmentQuestions: React.FC<PCRequestAssessmentQuestionsProps> = ({
  pcAssessmentInfo: { crAssessmentId, currentAssessmentId, currentAssessmentType, auditorAssessmentId = 0 },
  sectionId,
}) => {
  const classes = useStyles();
  const { data: { results: questions } = { count: 0, results: [] }, isLoading: isLoadingQuestions } =
    useGetQuestionsByAssessmentIdAndSectionIdQuery({
      assessmentId: currentAssessmentId,
      sectionId,
    });
  const { data: { results: crAnswers } = { count: 0, results: [] }, isLoading: isLoadingPrevAnswers } =
    useGetAnswersByAssessmentIdAndSectionIdQuery({
      assessmentId: crAssessmentId,
      assessmentSectionId: sectionId,
    });
  const { data: { results: auditorAnswers } = { count: 0, results: [] }, isLoading: isLoadingAuditorAnswers } =
    useGetAnswersByAssessmentIdAndSectionIdQuery(
      {
        assessmentId: auditorAssessmentId,
        assessmentSectionId: sectionId,
      },
      {
        skip: auditorAssessmentId < 1,
      }
    );

  const { data: { results: currentAnswers } = { count: 0, results: [] }, isLoading: isLoadingCurrentAnswers } =
    useGetAnswersByAssessmentIdAndSectionIdQuery({
      assessmentId: currentAssessmentId,
      assessmentSectionId: sectionId,
    });
  const isLoading = isLoadingCurrentAnswers || isLoadingPrevAnswers || isLoadingQuestions || isLoadingAuditorAnswers;

  return (
    <div dir="ltr" className={classes.main}>
      {isLoading ? (
        <BottomBorderWrapper>
          <LoadingSpinner></LoadingSpinner>
        </BottomBorderWrapper>
      ) : (
        findAnswersForQuestion(currentAssessmentType, questions, currentAnswers, crAnswers, auditorAnswers).map(
          (questionAndAnswer, index) => {
            const questionKey = `sa-question-${sectionId}-${questionAndAnswer.question.id}`;

            return index < questions.length - 1 ? (
              <BottomBorderWrapper key={questionKey}>
                <PCRequestAssessmentQuestion
                  assessmentId={currentAssessmentId}
                  questionNo={index + 1}
                  questionAndAnswers={questionAndAnswer}
                />
              </BottomBorderWrapper>
            ) : (
              <PCRequestAssessmentQuestion
                assessmentId={currentAssessmentId}
                key={questionKey}
                questionNo={index + 1}
                questionAndAnswers={questionAndAnswer}
              />
            );
          }
        )
      )}
    </div>
  );
};
