import React from 'react';

import { useAppSelector } from 'infrastructure/store/rootStore.hooks';
import { selectRole } from 'infrastructure/services/authSlice.selector';
import { isInspector as isInspectorCheck } from 'infrastructure/services/auth.utils';

import { getInspectorPath } from '../InspectorRoutes';
import { Authenticate } from '../../common/authenticate/Authenticate';

export const InspectorAuthenticate: React.FunctionComponent = () => {
  const isInspector = isInspectorCheck(useAppSelector(selectRole));
  return (
    <Authenticate
      isUser={isInspector}
      getPath={getInspectorPath}
      login={'inspector'}
      googleAuthenticatorDescription={'googleAuthenticatorDescription'}
    />
  );
};
