import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import { Theme } from '@material-ui/core';
import { debounce } from 'lodash-es';

import { shdShadow } from 'ui/styles';
import { isInViewport } from 'utilities/isInViewport';
import { PCRequest } from 'types/PCRequest';
import { AssessmentType } from 'types/Assessment';

import { PCRequestAssessmentBar } from './PCRequestAssessmentBar';
import { PCRequestAssessmentSections } from './PCRequestAssessmentSections';
import { getCurrentAssessment } from './utils/pcRequestAssessment.utils';
import { getAssessmentSectionId } from './utils/getAssessmentSectionId';
import { PCRequestAssessmentComment } from './PCRequestAssessmentComment';
import { useGetAssessmentByIdQuery, useGetSectionsByAssessmentIdQuery } from '../../admin/api/admin.api';
import { getAssessmentByAssessmentType } from '../utils/getAssessmentByAssessmentType';
import { Routes } from '../Routes';

type PCRequestAssessmentBodyProps = {
  pcRequest: PCRequest;
  getPath: (value: Routes) => string;
};

const TOP_BAR_ID = 'topBarId';

const useStyles = makeStyles<Theme, { isTopBarInView: boolean }>((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: ({ isTopBarInView }) => (isTopBarInView ? 0 : '120px'),
  },

  bottomBarWrapper: {
    top: 'auto',
    bottom: 0,
  },

  bottomBar: {
    flip: false,
    justifyItems: '',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'stretch',
    padding: '30px 0',
  },

  bar: {
    boxShadow: shdShadow,
    marginTop: theme.spacing(6),
  },
}));

export const PCRequestAssessmentBody: React.FC<PCRequestAssessmentBodyProps> = ({ pcRequest, getPath }) => {
  const topBarRef = React.useRef(null);
  const [isTopBarInView, setIsTopBarInView] = React.useState(true);
  const classes = useStyles({ isTopBarInView });
  const [currentSectionId, setCurrentSectionId] = React.useState(0);
  const [shouldScroll, setShouldScroll] = React.useState(false);

  const crAssessment = getAssessmentByAssessmentType(pcRequest.assessments, AssessmentType.COMPANY_REPRESENTATIVE);
  const auditorAssessment = getAssessmentByAssessmentType(pcRequest.assessments, AssessmentType.AUDITOR);
  const currentAssessmentId = getCurrentAssessment(pcRequest)?.id;

  const { data: currentAssessment } = useGetAssessmentByIdQuery(currentAssessmentId as number, {
    skip: !currentAssessmentId,
  });

  const setCurrentSectionIdAndScroll = (value: number) => {
    setCurrentSectionId(value);
    setShouldScroll(true);
  };

  const {
    data: { results: sections } = {
      count: 0,
      results: [],
    },
  } = useGetSectionsByAssessmentIdQuery(
    {
      assessmentId: currentAssessment?.id,
    },
    {
      skip: !currentAssessment?.id,
    }
  );

  const handleScrollRef = React.useRef(
    debounce(() => {
      const element = topBarRef.current;

      if (element) {
        setIsTopBarInView(isInViewport(element as HTMLElement));
      }
    }, 30)
  );

  React.useEffect(() => {
    if (sections?.length) {
      setCurrentSectionId(sections[0].id);
    }
  }, [sections]);

  React.useEffect(() => {
    if (currentSectionId > 0 && shouldScroll) {
      document.getElementById(getAssessmentSectionId(currentSectionId))?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentSectionId, shouldScroll]);

  React.useEffect(() => {
    const handleScroll = () => handleScrollRef.current();
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
      // eslint-disable-next-line
      handleScrollRef.current.cancel();
    };
  }, []);

  return (
    <div className={classes.main}>
      {currentAssessment && (
        <PCRequestAssessmentBar
          pcRequestId={pcRequest.id}
          currentAssessmentId={currentAssessment.id}
          barId={TOP_BAR_ID}
          setCurrentSectionIdAndScroll={setCurrentSectionIdAndScroll}
          currentSectionId={currentSectionId}
          specialistComment={currentAssessment.specialistComment}
          sections={sections}
          className={classes.bar}
          ref={topBarRef}
          getPath={getPath}
        />
      )}
      {currentAssessment && crAssessment && (
        <PCRequestAssessmentSections
          setCurrentSectionId={setCurrentSectionId}
          pcAssessmentInfo={{
            crAssessmentId: crAssessment.id,
            currentAssessmentId: currentAssessment.id,
            currentAssessmentType: currentAssessment.type,
            auditorAssessmentId: auditorAssessment?.id,
          }}
          sections={sections}
        />
      )}
      {currentAssessment && (
        <PCRequestAssessmentComment
          assessmentId={currentAssessment.id}
          specialistComment={currentAssessment.specialistComment}
        />
      )}
      {!isTopBarInView && currentAssessment && (
        <AppBar position="fixed" color="inherit" className={classes.bottomBarWrapper}>
          <Container maxWidth="lg" className={classes.bottomBar}>
            <PCRequestAssessmentBar
              pcRequestId={pcRequest.id}
              currentAssessmentId={currentAssessment.id}
              setCurrentSectionIdAndScroll={setCurrentSectionIdAndScroll}
              currentSectionId={currentSectionId}
              specialistComment={currentAssessment.specialistComment}
              sections={sections}
              getPath={getPath}
            />
          </Container>
        </AppBar>
      )}
    </div>
  );
};