import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Redirect, useParams } from 'react-router-dom';
import i18n from 'i18next';

import rootStore from 'infrastructure/store/rootStore';
import { useNavigate } from 'utilities/navigate';
import { ProtectedLayout } from 'ui/ProtectedLayout';

import { CompanyRepresentativeRoutes, getCompanyRepresentativePath } from '../CompanyRepresentativeRoutes';
import { CompanyRepresentativeSAStepper } from './CompanyRepresentativeSAStepper';
import { CompanyRepresentativeSASection } from './CompanyRepresentativeSASection';
import { useGetSectionsByAssessmentIdQuery } from '../api/companyRepresentative.api';
import { CompanyRepresentativeSASummary } from './CompanyRepresentativeSASummary';
import { CompanyRepresentativeSAHeader } from './CompanyRepresentativeSAHeader';
import { AssessmentSection } from '../../../types/AssessmentSection';
import { getDirection } from '../../../utilities/useLanguage';
import { selectEstablishmentInfo } from '../company-representative-login/selectors/establishmentInfo.selector';

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const getValidSectionsCount = (sections: AssessmentSection[]) =>
  sections.reduce((prev, { questionsNo, validAnswersNo }) => (questionsNo === validAnswersNo ? prev + 1 : prev), 0);

export const CompanyRepresentativeSA: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const assessmentId = +id;
  const classes = useStyles();
  const navigate = useNavigate();
  const [redirectToEstablishmentList] = React.useState(false);
  const [stepIndex, setStepIndex] = React.useState(0);
  const direction = getDirection(i18n.language);
  const establishmentId = selectEstablishmentInfo(rootStore.getState())?.establishmentId || '';

  const {
    data: { results: sections } = {
      count: 0,
      results: [],
    },
  } = useGetSectionsByAssessmentIdQuery({
    assessmentId,
  });
  const validSectionsCount = getValidSectionsCount(sections);

  const handleGoToEstablishmentList = () => {
    navigate(`${getCompanyRepresentativePath(CompanyRepresentativeRoutes.osh)}/${establishmentId}`);
  };

  if (redirectToEstablishmentList) {
    return <Redirect to={getCompanyRepresentativePath(CompanyRepresentativeRoutes.companySelect)} />;
  }

  return (
    <ProtectedLayout maxWidth="lg">
      <div dir={direction} className={classes.main}>
        <CompanyRepresentativeSAHeader />
        <CompanyRepresentativeSAStepper
          handleGoToEstablishmentList={handleGoToEstablishmentList}
          stepIndex={stepIndex}
          setStepIndex={setStepIndex}
          isSummary={sections.length > 0 && stepIndex === sections.length}
          sectionNames={sections.map(({ nameAR }) => nameAR)}
          assessmentId={assessmentId}
          isSubmitDisabled={validSectionsCount < sections.length}
        />
        {sections.length <= stepIndex ? (
          <CompanyRepresentativeSASummary assessmentId={assessmentId} validSectionsCount={validSectionsCount} />
        ) : (
          <CompanyRepresentativeSASection
            sectionsCount={sections.length}
            sectionNumber={stepIndex + 1}
            assessmentId={assessmentId}
            section={sections[stepIndex]}
          />
        )}
      </div>
    </ProtectedLayout>
  );
};
