import { useMemo } from 'react';

import rootStore from 'infrastructure/store/rootStore';

import { Role } from './auth.type';
import { selectRole } from './authSlice.selector';

export const useAuth = (restrictedRole: Role) => {
  const role = selectRole(rootStore.getState());

  return {
    isAllowed: useMemo(() => role === restrictedRole, [role, restrictedRole]),
    isLoggedIn: useMemo(() => !!role, [role]),
  };
};
