import { TranslationNamespace } from 'i18n/config';
import { AssessmentAnswerType } from 'types/AssessmentAnswer';

import { useCreateTranslate } from './translate.hook';

export const useAssessmentAnswerTypeText = () => {
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);

  return (answerType: AssessmentAnswerType) => {
    switch (answerType) {
      case AssessmentAnswerType.Yes:
        return translate('yes');
      case AssessmentAnswerType.No:
        return translate('no');
      default:
        return translate('notApplicable');
    }
  };
};
