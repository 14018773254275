import { RootState } from 'infrastructure/store/rootStore.types';

export const selectAuth = (state: RootState) => state.auth;

export const selectToken = (state: RootState) => state.auth.tokenData?.token;

export const selectExpiry = (state: RootState) => state.auth.tokenData?.expiry;

export const selectRole = (state: RootState) => state.auth.role;

export const selectIsLoginInProgress = (state: RootState) => state.auth.isLoginInProgress;

export const selectLoginError = (state: RootState) => state.auth.loginError;

export const selectIsRefreshingToken = (state: RootState) => state.auth.isRefreshingToken;

export const selectUserTowFactor = (state: RootState) => state.auth.userTowFactorResponse;

export const selectUserConfirmation = (state: RootState) => state.auth.invitationConfirmationResponse;
export const selectIsInvitationConfirmationProgress = (state: RootState) => state.auth.isInvitationConfirmationProgress;
export const selectInvitationConfirmationError = (state: RootState) => state.auth.invitationConfirmationError;

export const selectCheckInvitation = (state: RootState) => state.auth.checkInvitationResponse;
export const selectCheckInvitationError = (state: RootState) => state.auth.checkInvitationError;
export const selectIsCheckInvitationProgress = (state: RootState) => state.auth.isCheckInvitationProgress;
