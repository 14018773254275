export const getDirection = (language: string) => {
  return language === 'ar' ? 'rtl' : 'ltr';
};
export const getReverseDirection = (language: string) => {
  return language === 'ar' ? 'ltr' : 'rtl';
};

export const isArabicLang = (language: string) => {
  return language === 'ar';
};
export const isEngLang = (language: string) => {
  return language === 'en';
};

export const isRefresh = () => {
  return window.location.reload();
};
