import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';

import { CheckGreenIcon, MissingIcon } from 'ui/icons/icons';

type AssessmentAnswerMarkProps = {
  isVisible?: boolean;
  isValid?: boolean | null;
};

const useStyles = makeStyles((theme) => ({
  statusSpace: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    flexShrink: 0,
    width: '48px',
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}));

const Mark: React.FC<Pick<AssessmentAnswerMarkProps, 'isValid'>> = ({ isValid }) =>
  isValid != null ? (
    isValid ? (
      <SvgIcon component={CheckGreenIcon} data-testid="CheckGreenIcon" />
    ) : (
      <SvgIcon component={MissingIcon} viewBox="0 0 48 48" data-testid="MissingIcon" />
    )
  ) : null;

export const AssessmentAnswerMark: React.FC<AssessmentAnswerMarkProps> = ({ isValid, isVisible }) => {
  const classes = useStyles();

  return (
    <div className={classes.statusSpace}>
      {isVisible !== undefined ? isVisible && <Mark isValid={isValid} /> : <Mark isValid={isValid} />}
    </div>
  );
};
