import { Role, TypeOfPlatform } from 'infrastructure/services/auth.type';

export enum UserAccountStatus {
  Created = 1,
  Invited,
  Activated,
  Deactivated,
}

export type UserDto = {
  id: number;
  account_type: Role;
  first_name: string;
  last_name: string;
  status: UserAccountStatus;
  email: string;
  phone_number: string;
  lang: string;
  current_platform: TypeOfPlatform.OSH;
};

export type TwoFactorAuthentication = {
  user_id: number;
};

export type UserChangePassword = {
  old_password: string;
  new_password: string;
  re_new_password: string;
};

export type CreateUserDto = Pick<UserDto, 'first_name' | 'last_name' | 'email' | 'phone_number' | 'account_type'>;
export type FactorAuthenticationDto = Pick<TwoFactorAuthentication, 'user_id'>;
export type ChangeUserPasswordDto = Pick<UserChangePassword, 'old_password' | 'new_password' | 're_new_password'>;

export type User = {
  id: number;
  accountType: Role;
  firstName: string;
  lastName: string;
  status: UserAccountStatus;
  email: string;
  phoneNumber: string;
  lang: string;
  currentplatform: TypeOfPlatform.OSH;
};
export type UserTwoFactorAuthentication = {
  user_id: number;
};
export type UserPassword = {
  old_password: string;
  new_password: string;
  re_new_password: string;
};

export const toUser = ({
  id,
  account_type,
  first_name,
  last_name,
  status,
  email,
  phone_number,
  lang,
  current_platform,
}: UserDto): User => ({
  id,
  accountType: account_type,
  firstName: first_name,
  lastName: last_name,
  phoneNumber: phone_number,
  status,
  email,
  lang,
  currentplatform: TypeOfPlatform.OSH,
});
export const toUserTwoFactorAuthentication = ({ user_id }: TwoFactorAuthentication): UserTwoFactorAuthentication => ({
  user_id,
});
export const toUserPass = ({ old_password, new_password, re_new_password }: UserChangePassword): UserPassword => ({
  old_password,
  new_password,
  re_new_password,
});
export const toUserPassDto = ({ old_password, new_password, re_new_password }: UserPassword): UserChangePassword => ({
  old_password,
  new_password,
  re_new_password,
});

export const toUserDto = ({
  id,
  accountType,
  firstName,
  lastName,
  phoneNumber,
  status,
  email,
  lang,
}: User): UserDto => ({
  id,
  account_type: accountType,
  first_name: firstName,
  last_name: lastName,
  status,
  email,
  phone_number: phoneNumber,
  lang,
  current_platform: TypeOfPlatform.OSH,
});
export const toTwoFactorAuthenticationDto = ({ user_id }: UserTwoFactorAuthentication): TwoFactorAuthentication => ({
  user_id,
});

export type ChangeLangInput = {
  lang: string;
};

export type ChangePlatformInput = {
  platform: number;
};
