import React from 'react';
import { Switch, useRouteMatch, Redirect } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { AuditorRoute, RedirectToAllowedRoute } from 'ui/PrivateRoute';
import { Role } from 'infrastructure/services/auth.type';
import { SAResultContainer } from 'ui/SAResultContainer';

import { SetPassword } from '../common/set-password/SetPassword';
import { AuditorLogin } from './auditor-login/AuditorLogin';
import { AuditorAuthenticate } from './auditor-authenticate/AuditorAuthenticate';
import { REACT_APP_GOOGLE_RECAPCHA_KEY } from '../../infrastructure/core/variables';
import { AuditorDashboard } from './auditor-dashboard/AuditorDashboard';
import { Routes } from '../common/Routes';
import { AuditorEstablishments } from './auditor-establishmens/AuditorEstablishments';
import { AuditorEstablishmentDetails } from './auditor-establishmens/auditor-establishments-details/AuditorEstablishmentDetails';
import { PCRequestList } from './auditor-pcrequests/PCRequestList';
import { PCRequestDetails } from './auditor-pcrequests/auditor-pcrequests-details/PCRequestDetails';
import { PCRequestAssessmentFinished } from '../common/pcrequest-assessment/PCRequestAssessmentFinished';
import { PCRequestAssessment } from './auditor-pcrequest-assessment/PCRequestAssessment';
import { AssessmentCompare } from './auditor-assessment-compare/AuditorAssessmentCompare';
import { getAuditorPath } from './AuditorRoutes';
//import { SelectPlatformButtons } from '../common/select-platform/SelectPlatformButtons';
//import { CAPcReviewAnswer } from '../common/pcrequest-assessment/CAPcReviewAnswer';
import { TypeOfPlatform } from '../../infrastructure/services/auth.type';

export const AuditorRouter: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <AuditorRoute exact path={path}>
        <Redirect to={`${path}${Routes.dashboard}`} />;
      </AuditorRoute>

      <AuditorRoute path={`${path}${Routes.dashboard}`}>
        <AuditorDashboard />
      </AuditorRoute>

      {/* <AuditorRoute exact path={path}>
        <Redirect to={`${path}${Routes.selectPlatform}`} />;
      </AuditorRoute>
      <AuditorRoute path={`${path}${Routes.selectPlatform}`}>
        <SelectPlatformButtons getPath={getAuditorPath} />
      </AuditorRoute> */}

      <AuditorRoute exact path={`${path}${Routes.establishments}`}>
        <AuditorEstablishments />
      </AuditorRoute>

      <AuditorRoute exact path={`${path}${Routes.establishment}/:id`}>
        <AuditorEstablishmentDetails />
      </AuditorRoute>

      <AuditorRoute path={`${path}${Routes.establishment}/:establishmentId${Routes.saResults}/:id${Routes.answers}`}>
        <SAResultContainer />
      </AuditorRoute>

      <AuditorRoute exact path={`${path}${Routes.setPassword}`}>
        <SetPassword />
      </AuditorRoute>

      <AuditorRoute exact path={`${path}${Routes.pcRequests}`}>
        <PCRequestList />
      </AuditorRoute>

      <AuditorRoute exact path={`${path}${Routes.pcRequests}/:pcRequestId`}>
        <PCRequestDetails />
      </AuditorRoute>

      <RedirectToAllowedRoute
        role={Role.Auditor}
        path={`${path}${Routes.login}`}
        redirectPath={`${path}${Routes.authenticate}`}
      >
        <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_GOOGLE_RECAPCHA_KEY || ''}>
          <AuditorLogin />
        </GoogleReCaptchaProvider>
      </RedirectToAllowedRoute>

      <RedirectToAllowedRoute
        role={Role.Auditor}
        path={`${path}${Routes.authenticate}`}
        redirectPath={`${path}${Routes.dashboard}`}
      >
        <AuditorAuthenticate />
      </RedirectToAllowedRoute>

      {/* <AuditorRoute
        exact
        path={`${path}${Routes.pcRequests}/:pcRequestId${Routes.assessment}/:assessmentId${Routes.reviewAnswer}`}
      >
        <CAPcReviewAnswer getPath={getAuditorPath } platform={TypeOfPlatform.CA} />
      </AuditorRoute> */}

      <AuditorRoute
        exact
        path={`${path}${Routes.pcRequests}/:pcRequestId${Routes.assessment}/:assessmentId${Routes.finished}`}
      >
        <PCRequestAssessmentFinished getPath={getAuditorPath}  platform={TypeOfPlatform.OSH} />
      </AuditorRoute>

      <AuditorRoute exact path={`${path}${Routes.pcRequests}/:pcRequestId${Routes.assessment}`}>
        <PCRequestAssessment />
      </AuditorRoute>

      <AuditorRoute exact path={`${path}${Routes.pcRequests}/:pcRequestId${Routes.compare}`}>
        <AssessmentCompare />
      </AuditorRoute>
    </Switch>
  );
};