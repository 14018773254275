import React from 'react';

import { AuditorLayout } from '../ui/AuditorLayout';
import { PCRequestAssessment as CommonPCRequestAssessment } from '../../common/pcrequest-assessment/PCRequestAssessment';
import { getAuditorPath } from '../AuditorRoutes';

export const PCRequestAssessment: React.FC = () => {
  return (
    <AuditorLayout>
      <CommonPCRequestAssessment getPath={getAuditorPath} />
    </AuditorLayout>
  );
};
