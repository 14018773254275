import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Box } from '@material-ui/core';
import { useKeycloak } from '@react-keycloak/web';

import { useNavigate } from 'utilities/navigate';
import { useResetUserData } from 'infrastructure/store/resetUserData.hooks';
import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { useAppDispatch } from 'infrastructure/store/rootStore.hooks';
import rootStore from 'infrastructure/store/rootStore';
import { Dialog } from 'ui/Dialog';
import { blacklistRefreshToken } from 'infrastructure/store/resetUserData';

import { Button, CustomButtonProps } from '../Button';

type LogoutButtonProps = {
  redirectPath: string;
  withIcon?: boolean;
} & CustomButtonProps;

const useStyles = makeStyles((theme) => ({
  logout: {
    flip: false,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  logoutTypography: {
    flip: false,
    color: theme.palette.common.white,
    cursor: 'pointer',
  },

  logoutContent: {
    flip: false,
    marginLeft: '11px',
    marginRight: '11px',
    verticalAlign: 'sub',
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  buttonsContainer: {
    display: 'flex',
    flip: false,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    flexWrap: 'wrap',
  },
  button: {
    flex: 1,
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));

export const LogoutButton: React.FC<LogoutButtonProps> = ({ redirectPath, withIcon = true, ...props }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const resetUserData = useResetUserData();
  const translate = useCreateTranslate(TranslationNamespace.common);
  const dispatch = useAppDispatch();
  const { keycloak } = useKeycloak();

  const [isDialogOpen, setDialogOpen] = React.useState(false);

  const logout = async () => {
    await blacklistRefreshToken(dispatch, rootStore.getState).finally(() => {
      if (keycloak.authenticated) {
        keycloak.logout();
      }
      resetUserData();
      navigate(redirectPath);
    });
  };

  return (
    <>
      <Button
        onClick={() => {
          setDialogOpen(true);
        }}
        {...props}
      >
        <Typography variant="subtitle2" className={classes.logoutTypography} noWrap>
          {translate('logout')}
          {withIcon && <ExitToAppIcon fontSize="small" className={classes.logoutContent} />}
        </Typography>
      </Button>

      <Dialog open={isDialogOpen} handleClose={() => setDialogOpen(false)}>
        <Box>
          <Typography variant="h6" className={classes.title}>
            {translate('logoutDialogTitle')}
          </Typography>

          <Box className={classes.buttonsContainer}>
            <Button
              className={classes.button}
              onClick={() => {
                setDialogOpen(false);
              }}
              variant="outlined"
              color="secondary"
              size="large"
            >
              {translate('no')}
            </Button>
            <Button className={classes.button} onClick={logout} color="secondary" variant="contained" size="large">
              {translate('yes')}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
