import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import i18n from 'i18next';

import { labelCardColor } from 'ui/styles';

import { getDirection } from '../../utilities/useLanguage';

export type SectionTopicProps = {
  topicHeader: string;
  topic: string;
  topicDescription: string;
};

const useStyles = makeStyles((theme) => ({
  topic: {
    flip: true,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },

  topicHeader: {
    fontWeight: 'bold',
    color: labelCardColor,
  },

  typography: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },

  description: {
    color: labelCardColor,
  },
}));

export const SectionTopic: React.FC<SectionTopicProps> = ({ topicHeader, topic, topicDescription }) => {
  const classes = useStyles();
  const direction = getDirection(i18n.language);
  return (
    <div dir={direction} className={classes.topic}>
      <Typography className={classes.topicHeader} variant="body2">
        {topicHeader}
      </Typography>
      <Typography className={classes.typography} variant="h5">
        {topic}
      </Typography>
      <Typography className={`${classes.typography} ${classes.description}`} variant="body1">
        {topicDescription}
      </Typography>
    </div>
  );
};
