import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom'; // Use 'react-dom' for React 17
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { REACT_APP_API_URL } from 'infrastructure/core/variables';

import keycloak from './keycloak';
import './index.css';
import App from './application/App';
import reportWebVitals from './reportWebVitals';

const apiURL = REACT_APP_API_URL || '';

Sentry.init({
  dsn: 'https://5ea739ca57962ca2b155d918c3cdf832@debug.tamkeen.cloud/98',
  integrations: [
    new Sentry.BrowserTracing({
      // tracePropagationTargets: ['localhost', new RegExp(`^${apiURL.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')}`)],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <React.StrictMode>
    <ReactKeycloakProvider authClient={keycloak}>
      <App />
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
