import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiDialog from '@material-ui/core/Dialog';
import clsx from 'clsx';
import i18n from 'i18next';

import { backdropBackgroundColor, shdShadow } from 'ui/styles';
import { Transition } from 'ui/Transition';

import { getDirection } from '../utilities/useLanguage';

type DialogProps = {
  open: boolean;
  withPadding?: boolean;
  className?: string;
  handleClose: () => void;
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: '#FAFAFA',
    width: '601px',
    maxWidth: '601px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    boxShadow: shdShadow,
  },
  dialogPadding: {
    padding: '32px 24px 24px 24px',
  },
}));

export const Dialog: React.FC<DialogProps> = ({
  open,
  handleClose,
  children,
  withPadding = true,
  className,
  ...props
}) => {
  const classes = useStyles();
  const direction = getDirection(i18n.language);

  return (
    <MuiDialog
      dir={direction}
      PaperProps={{ className: clsx(classes.dialog, withPadding && classes.dialogPadding, className) }}
      BackdropProps={{ style: { backgroundColor: backdropBackgroundColor } }}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      closeAfterTransition
      {...props}
    >
      {children}
    </MuiDialog>
  );
};
