import {
  ClickAwayListener,
  Divider,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  Link,
} from '@material-ui/core';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import clsx from 'clsx';

import { TranslationNamespace } from 'i18n/config';
import { LogoutButton } from 'ui/buttons/LogoutButton';
import { HamburgerMenuIcon } from 'ui/icons/icons';
import { useCreateTranslate } from 'utilities/translate.hook';
import { popperItemHoverBackground } from 'ui/styles';
import { useNavigate } from 'utilities/navigate';

import { getInspectorPath } from '../InspectorRoutes';
import { Routes } from '../../common/Routes';

const useStyles = makeStyles((theme) => ({
  activeMenuItem: {
    background: popperItemHoverBackground,
    color: theme.palette.secondary.main,

    '&:hover': {
      cursor: 'default',
    },
  },
  active: {
    opacity: '1 !important',
  },
  logoutButton: {
    flip: false,
    marginRight: theme.spacing(4),
    [theme.breakpoints.down(1180)]: {
      display: 'none',
    },
  },
  hamburgerWrapper: {
    display: 'none',
    flex: 1,
    justifyContent: 'flex-start',
    [theme.breakpoints.down(1180)]: {
      display: 'block',
    },
  },
  hamburger: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.spacing(2),
    color: theme.palette.background.paper,
    fontWeight: 700,
    border: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  hamburgerIcon: {
    flip: false,
    marginLeft: theme.spacing(1),
  },
  menuList: {
    color: theme.palette.text.primary,
    flip: false,
    textAlign: 'right',
  },
  menuItem: {
    justifyContent: 'flex-end',
    '&:hover': {
      background: popperItemHoverBackground,
      color: theme.palette.secondary.main,
    },
  },
  menuLogout: {
    padding: `6px ${theme.spacing(2)}px`,
    borderRadius: 0,
    width: '100%',
    justifyContent: 'flex-end',
    '& h6': {
      color: 'inherit',
      fontWeight: '400',
      fontSize: '1rem',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      background: popperItemHoverBackground,
      color: theme.palette.secondary.main,
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
    '& .MuiTouchRipple-root': {
      opacity: 0,
    },
  },
  popper: {
    margin: `${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
    zIndex: 10,
  },
  navBar: {
    flex: '1',
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.down(1180)]: {
      display: 'none',
    },
  },
  navElement: {
    flip: false,
    color: theme.palette.common.white,
    paddingRight: '80px',
    opacity: '0.6',
  },
}));

export const InspectorHeaderMenu = () => {
  const classes = useStyles();
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const [open, setOpen] = React.useState(false);
  const { path } = useRouteMatch();
  const navigate = useNavigate();

  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const routesData = [
    {
      label: translate('establishments'),
      handler: () => navigate(getInspectorPath(Routes.establishments)),
      route: Routes.establishments,
    },
    {
      label: translate('pcRequests'),
      handler: () => navigate(getInspectorPath(Routes.pcRequests)),
      route: Routes.pcRequests,
    },
  ];

  const handleHamburgerOpen = () => setOpen(true);

  const handleHamburgerClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <div className={classes.hamburgerWrapper} dir="ltr">
        <button ref={anchorRef} onClick={handleHamburgerOpen} className={classes.hamburger}>
          {translate('menu')} <HamburgerMenuIcon className={classes.hamburgerIcon} />
        </button>

        <Popper
          className={classes.popper}
          open={open}
          anchorEl={anchorRef.current}
          transition
          disablePortal
          role={undefined}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper elevation={8}>
                <ClickAwayListener onClickAway={handleHamburgerClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" className={classes.menuList}>
                    {routesData.map(({ label, handler, route }) => (
                      <MenuItem
                        key={label}
                        onClick={handler}
                        className={clsx(classes.menuItem, getInspectorPath(route) === path && classes.activeMenuItem)}
                      >
                        {label}
                      </MenuItem>
                    ))}

                    <Divider />

                    <LogoutButton
                      withIcon={false}
                      className={classes.menuLogout}
                      redirectPath={getInspectorPath(Routes.login)}
                    />
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>

      <div className={classes.navBar}>
        {routesData.map(({ label, handler, route }) => (
          <Typography key={label} variant="body1">
            <Link
              className={clsx(classes.navElement, getInspectorPath(route) === path && classes.active)}
              underline="none"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handler();
              }}
            >
              {label}
            </Link>
          </Typography>
        ))}
      </div>
    </>
  );
};
