import { FieldError } from 'react-hook-form';

import { Role } from '../infrastructure/services/auth.type';
import { Page, Search } from '../infrastructure/persistence/types';
import { UserAccountStatus } from '../types/User';
import { TranslateFn } from './translate.hook';

export const getUrl = (accountTypes: Role[], parameters: Page, param: Search, status?: UserAccountStatus[]) => {
  const firstPart = accountTypes.length > 0 && `?account_type=${accountTypes.join()}`;
  const secondPart = status && status?.length > 0 ? `&status=${status.join()} &param=${param.searchInfo} ` : '';
  return `/user/${firstPart}&page_size=${parameters.pageSize}&page=${parameters.page}${secondPart}`;
};

export const getHelperText = (translateCommon: TranslateFn, error?: FieldError) => {
  if (!error) {
    return null;
  }
  if (error.type === 'required') {
    return translateCommon('fieldRequired');
  } else {
    return error.message;
  }
};
