import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReAuth } from 'infrastructure/persistence/baseQueryWithReAuth';
import { Page, Search } from 'infrastructure/persistence/types';
import { ListResult, Results } from 'interfaces/Results';
import {
  toUser,
  User,
  UserDto,
  CreateUserDto,
  UserAccountStatus,
  ChangeUserPasswordDto,
  UserPassword,
  toUserPass,
  UserChangePassword,
  TwoFactorAuthentication,
  // FactorAuthenticationDto,
  UserTwoFactorAuthentication,
  //toUserTwoFactorAuthentication,
} from 'types/User';
import { Role } from 'infrastructure/services/auth.type';
import { createAssessmentEndpoints } from 'infrastructure/services/api/assessment.endpoints.api';
import { createEstablishmentEndpoints } from 'infrastructure/services/api/establishment.endpoints';
import { EconomicActivity, EconomicActivityDto, toEconomicActivity } from 'types/EconomicActivity';

import {
  AssessmentTemplate,
  AssessmentTemplateCreateDto,
  AssessmentTemplateDto,
  AssessmentTemplateScoringPatchDto,
  toAssessmentTemplate,
  toAssessmentTemplateUpdateDto,
  AssessmentTemplateQuestionnairePatchDto,
} from '../types/AssessmentTemplate';
import { AssessmentTemplateDetails, toAssessmentTemplateDetails } from '../types/AssessmentTemplateDetails';
import {
  EstablishmentDetailsDto,
  EstablishmentDetails,
  toEstablishmentDetails,
} from '../../../types/EstablishmentDetails';
import { AdminApiTagType, adminApiTagTypes } from './admin.tags.api';
import { adminApiReducerPath } from './admin.config.api';
import { questionSectionApiEndpoints } from '../admin-questionnaire/questionnaireSection.api';
import { pcRequestApiEndpoints } from '../../common/pcrequests/api/pcrequest.api';
import { getUrl } from '../../../utilities/useHelper';

export const adminApi = createApi({
  reducerPath: adminApiReducerPath,
  baseQuery: baseQueryWithReAuth(),
  tagTypes: adminApiTagTypes,
  endpoints: (build) => ({
    getTemplateList: build.query<ListResult<AssessmentTemplateDetails>, { param: Search; parameters: Page }>({
      query: ({ parameters: { page = 1, pageSize = 5 }, param: { searchInfo } }) => ({
        url: `/questionnaire/?page=${page}&page_size=${pageSize} &param=${searchInfo}`,
      }),
      providesTags: (responseData) =>
        responseData
          ? [
              ...responseData.results.map(({ id }) => ({ type: AdminApiTagType.Questionnaire, id })),
              AdminApiTagType.Questionnaire,
            ]
          : [AdminApiTagType.Questionnaire],
      transformResponse: (response: Results<AssessmentTemplateDto>) => ({
        count: response.count,
        results: response.results.map(toAssessmentTemplateDetails),
      }),
    }),
    getEstablishmentsList: build.query<ListResult<EstablishmentDetails>, { param: Search; parameters : Page }>({
      query: ({ parameters: { page = 1, pageSize = 5 } , param: { searchInfo } }) => ({
        url: `/establishment/?page=${page}&page_size=${pageSize} &param=${searchInfo}`,
      }),
      transformResponse: (response: Results<EstablishmentDetailsDto>) => ({
        count: response.count,
        results: response.results.map(toEstablishmentDetails),
      }),
    }),
    getEconomicActivityList: build.query<EconomicActivity[], void>({
      query: () => ({
        url: `/territory/economic_activities/`,
      }),
      transformResponse: (response: EconomicActivityDto[]) => response.map(toEconomicActivity),
    }),
    getUsers: build.query<
      ListResult<User>,
      {
        accountTypes: Role[];
        parameters: Page;
        status?: UserAccountStatus[];
        param: Search;
      }
    >({
      query: ({ accountTypes, parameters: { page = 1, pageSize = 10 }, status, param: { searchInfo } }) => ({
        url: getUrl(accountTypes, { page, pageSize }, { searchInfo }, status),
      }),
      providesTags: (responseData) =>
        responseData
          ? [...responseData.results.map(({ id }) => ({ type: AdminApiTagType.Users, id }))]
          : [AdminApiTagType.Users],
      transformResponse: (response: Results<UserDto>) => ({
        count: response.count,
        results: response.results.map(toUser),
      }),
    }),
    getUserById: build.query<User, number>({
      query: (id) => ({
        url: `/user/${id}`,
        method: 'GET',
      }),
      providesTags: (user) => (!!user ? [{ type: AdminApiTagType.Users, id: user.id }] : []),
      transformResponse: (response: UserDto) => toUser(response),
    }),
    createUser: build.mutation<User, CreateUserDto>({
      query: (data) => ({
        url: '/user/',
        method: 'POST',
        data,
      }),
      transformResponse: (response: UserDto) => toUser(response),
      invalidatesTags: [AdminApiTagType.Users],
    }),
    updateUser: build.mutation<User, UserDto>({
      query: (data) => ({
        url: `/user/${data.id}`,
        method: 'PUT',
        data,
      }),
      transformResponse: (response: UserDto) => toUser(response),
      invalidatesTags: (result, error, { id }) => [{ type: AdminApiTagType.Users, id }, AdminApiTagType.Users],
    }),
    changeUserStatus: build.mutation<void, Pick<User, 'status' | 'id'>>({
      query: ({ id, status }) => ({
        method: 'PATCH',
        url: `/user/${id}/change_status/`,
        data: {
          status,
        },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: AdminApiTagType.Users, id }],
    }),
    twoFactorAuthentication: build.mutation<UserTwoFactorAuthentication, TwoFactorAuthentication>({
      query: ({ user_id }) => ({
        method: 'POST',
        url: `/user/reset/2FA/`,
        data: {
          user_id,
        },
      }),
      invalidatesTags: (result, error, { user_id }) => [{ type: AdminApiTagType.Users, user_id }],
    }),
    changeUserPassword: build.mutation<UserPassword, ChangeUserPasswordDto>({
      query: (data) => ({
        url: '/user/token/change_password/',
        method: 'POST',
        data,
      }),
      transformResponse: (response: UserChangePassword) => toUserPass(response),
      invalidatesTags: [AdminApiTagType.Users],
    }),
    createQuestionnaire: build.mutation<AssessmentTemplate, AssessmentTemplateCreateDto>({
      query: (createQuestionnaireDto) => ({
        url: '/questionnaire/',
        method: 'POST',
        data: createQuestionnaireDto,
      }),
      transformResponse: (response: AssessmentTemplateDto) => toAssessmentTemplate(response),
      invalidatesTags: [AdminApiTagType.Questionnaire],
    }),
    duplicateQuestionnaire: build.mutation<AssessmentTemplate, number>({
      query: (questionnaireId) => ({
        url: `/questionnaire/${questionnaireId}/duplicate/`,
        method: 'POST',
      }),
      transformResponse: (response: AssessmentTemplateDto) => toAssessmentTemplate(response),
      invalidatesTags: [AdminApiTagType.Questionnaire],
    }),
    deleteQuestionnaire: build.mutation<AssessmentTemplate, number>({
      query: (questionnaireId) => ({
        url: `/questionnaire/${questionnaireId}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: [AdminApiTagType.Questionnaire],
    }),
    patchScoring: build.mutation<AssessmentTemplate, AssessmentTemplateScoringPatchDto>({
      query: (patchDto) => ({
        url: `/questionnaire/${patchDto.id}/`,
        method: 'PATCH',
        data: patchDto,
      }),
      transformResponse: (response: AssessmentTemplateDto) => toAssessmentTemplate(response),
      invalidatesTags: (result, error, { id }) => [{ type: AdminApiTagType.Questionnaire, id }],
    }),
    getQuestionnaireById: build.query<AssessmentTemplate, number | undefined | null>({
      query: (id) => ({
        url: `/questionnaire/${id}/`,
        method: 'GET',
      }),
      transformResponse: (response: AssessmentTemplateDto) => toAssessmentTemplate(response),
      providesTags: (responseData) => [{ type: AdminApiTagType.Questionnaire, id: responseData?.id }],
    }),
    publishQuestionnaire: build.mutation<() => void, AssessmentTemplateQuestionnairePatchDto>({
      query: (patchDto) => ({
        url: `/questionnaire/${patchDto.id}/publish/`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, arg) => [{ type: AdminApiTagType.Questionnaire, id: arg.id }],
    }),
    updateQuestionnaire: build.mutation<AssessmentTemplate, AssessmentTemplate>({
      query: (patchDto) => ({
        url: `/questionnaire/${patchDto.id}/`,
        method: 'PATCH',
        data: toAssessmentTemplateUpdateDto(patchDto),
      }),
      invalidatesTags: (result, error, arg) => [{ type: AdminApiTagType.Questionnaire, id: arg.id }],
    }),
  }),
});

const enhancedApi = adminApi
  .injectEndpoints({
    endpoints: questionSectionApiEndpoints,
  })
  .injectEndpoints({
    endpoints: pcRequestApiEndpoints,
  })
  .injectEndpoints({
    endpoints: createAssessmentEndpoints(adminApiReducerPath),
  })
  .injectEndpoints({
    endpoints: createEstablishmentEndpoints(adminApiReducerPath),
  });

export const {
  useGetTemplateListQuery,
  useGetEconomicActivityListQuery,
  useCreateQuestionnaireMutation,
  useDuplicateQuestionnaireMutation,
  useDeleteQuestionnaireMutation,
  useGetQuestionnaireByIdQuery,
  usePatchScoringMutation,
  usePublishQuestionnaireMutation,
  useGetEstablishmentsListQuery,
  useUpdateQuestionnaireMutation,
  useUpdateSectionMutation,

  //user
  useGetUsersQuery,
  useGetUserByIdQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useChangeUserStatusMutation,
  useTwoFactorAuthenticationMutation,
  useChangeUserPasswordMutation,

  // establishmentApiEndpoints.ap
  useGetEstablishmentQuery,

  // questionnaireSection.api
  useGetSectionsByQuestionnaireIdQuery,
  useCreateSectionMutation,
  useDeleteSectionMutation,
  usePatchQuestionOrderMutation,
  useGetQuestionsBySectionIdQuery,
  useCreateQuestionMutation,
  useDeleteQuestionMutation,
  useGetQuestionQuery,
  useUpdateQuestionMutation,
  usePatchSectionOrderMutation,

  // pcRequestApiEndpoints.api
  useGetPCRequestsQuery,
  useGetPCRequestQuery,
  useStartPcRequestAssessmentMutation,
  useAssignSafetySpecialistMutation,
  useApprovedSafetySpecialistMutation,
  useRejectedSafetySpecialistMutation,
  useIssuePcMutation,

  // assessmentEndpoints.api
  useGetSectionsByAssessmentIdQuery,
  useGetQuestionsByAssessmentIdAndSectionIdQuery,
  useGetAnswersByAssessmentIdAndSectionIdQuery,
  useCreateSAAnswerMutation,
  useUpdateSAAnswerMutation,
  useUploadSelfAssessmentAttachmentMutation,
  useDeleteSelfAssessmentAttachmentMutation,
  useSubmitAssessmentMutation,
  useUpdateAssessmentMutation,
  useGetAssessmentByIdQuery,
} = enhancedApi;