/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { SectionTopic } from 'ui/assessment/SectionTopic';
import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { CircleWrapper } from 'ui/CircleWrapper';
import { CommentIcon } from 'ui/icons/icons';
import { BottomBorderWrapper } from 'ui/BottomBorderWrapper';

type PCRequestAssessmentCommentHeaderProps = {
  topicTitle: string;
  topicDescription: string;
};

const useStyle = makeStyles((theme) => ({
  header: {
    padding: '40px 32px',
    flip: false,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
}));

export const PCRequestAssessmentCommentHeader: React.FC<PCRequestAssessmentCommentHeaderProps> = ({
  topicTitle,
  topicDescription,
}) => {
  const classes = useStyle();
  const translate = useCreateTranslate(TranslationNamespace.admin);

  return (
    <BottomBorderWrapper>
      <div className={classes.header}>
        <SectionTopic topicHeader={translate('comment')} topic={topicTitle} topicDescription={topicDescription} />
        <CircleWrapper>
          <CommentIcon />
        </CircleWrapper>
      </div>
    </BottomBorderWrapper>
  );
};
