import React from 'react';

import { AssessmentSectionHeaderContent } from 'ui/assessment/AssessmentSectionHeaderContent';

import { BottomBorderWrapper } from '../../../ui/BottomBorderWrapper';
import { AssessmentSectionPropTypes } from '../../../types/AssessmentSectionPropTypes';

type CompanyRepresentativeSASectionHeaderProps = Pick<
  AssessmentSectionPropTypes,
  'sectionsCount' | 'sectionNumber' | 'section'
>;

export const CompanyRepresentativeSASectionHeader: React.FC<CompanyRepresentativeSASectionHeaderProps> = (props) => (
  <BottomBorderWrapper>
    <AssessmentSectionHeaderContent {...props} />
  </BottomBorderWrapper>
);
