import React, { useState, useEffect } from 'react';
import { styled } from '@material-ui/core/styles';
import { makeStyles, Typography, CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { MutationActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { BaseQueryFn, MutationDefinition } from '@reduxjs/toolkit/dist/query';
import { AxiosRequestConfig } from 'axios';
import i18n from 'i18next';
import { InfoIcon } from 'ui/icons/icons';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { labelCardColor } from 'ui/styles';
import { BaseQueryError } from 'infrastructure/persistence/baseQueryWithReAuth';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { CheckGreyIcon, CrossCloseGreyIcon } from 'ui/icons/icons';
import { AnswerAttachment, AssessmentAnswer, DeleteAttachment, UploadAttachment } from 'types/AssessmentAnswer';
import { Hint } from 'ui/Hint';
import SvgIcon from '@material-ui/core/SvgIcon';

import { getDirection, getReverseDirection } from '../../utilities/useLanguage';

const Input = styled('input')({
  display: 'none',
});

type AssessmentAnswerAttachmentsProps = {
  answer: AssessmentAnswer | undefined;
  assessmentSectionId: number;
  uploadSelfAssessmentAttachment: (arg: UploadAttachment) => MutationActionCreatorResult<
    MutationDefinition<
      UploadAttachment,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      BaseQueryFn<AxiosRequestConfig<any>, unknown, BaseQueryError, {}, {}>,
      string,
      AnswerAttachment,
      string
    >
  >;
  delateSelfAssessmentAttachment: (arg: DeleteAttachment) => MutationActionCreatorResult<
    MutationDefinition<
      DeleteAttachment,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      BaseQueryFn<AxiosRequestConfig<any>, unknown, BaseQueryError, {}, {}>,
      string,
      AnswerAttachment,
      string
    >
  >;
  isUploading: boolean;
  uploadTitle?: string;
  isOptional?: boolean;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: '40px',
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  uploadButtonSpace: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  uploadButton: {
    flip: false,
    marginLeft: theme.spacing(0),
    fontSize: '16px',
    height: 40,
    [theme.breakpoints.up('md')]: {
      width: 120,
    },
  },

  icon: {
    flip: false,
    color: theme.palette.info.main,
  },
  uploadButtonDesc: {
    width: '340px',
    color: labelCardColor,
    marginLeft: theme.spacing(4),
    paddingRight: theme.spacing(12),
    textAlign: 'left',
  },
  uploadedContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexWrap: 'wrap',
  },
  attachment: {
    flip: false,
    backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  progress: {
    flip: false,
    marginRight: theme.spacing(6),
    color: theme.palette.primary.main,
  },
  buttonProgress: {
    flip: false,
    marginLeft: theme.spacing(12),
    color: theme.palette.primary.main,
  },
}));

export const AssessmentAnswerAttachments: React.FC<AssessmentAnswerAttachmentsProps> = ({
  answer,
  assessmentSectionId,
  delateSelfAssessmentAttachment,
  uploadSelfAssessmentAttachment,
  isUploading,
  uploadTitle,
  isOptional,
}) => {
  const classes = useStyles();
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const showNotification = useNotifications();
  const direction = getDirection(i18n.language);
  const [hasFiles, setHasFiles] = useState(!!answer?.attachments.length);
  const [showErrorHint, setShowErrorHint] = useState(false);
  const removeAttachment = (id: number) => {
    if (answer) {
      const data = {
        id,
        assessmentData: {
          answer_id: answer.id,
          question_id: answer.questionId,
          self_assessment_id: answer.assessmentId,
          assessmentSectionId,
        },
      };
      delateSelfAssessmentAttachment(data)
        .unwrap()
        .catch((error: BaseQueryError) => {
          showNotification(NotificationType.Error, { errorId: error.data.errorCode });
        });
    }
  };

  const uploadAttachment = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files && answer) {
      const formData = new FormData();
      formData.append('file', e.currentTarget.files[0]);
      formData.append('answer_id', answer.id.toString());
      const data = {
        uploadedFile: formData,
        assessmentData: {
          answer_id: answer.id,
          question_id: answer.questionId,
          self_assessment_id: answer.assessmentId,
          assessmentSectionId,
        },
      };

      uploadSelfAssessmentAttachment(data)
        .unwrap()
        .catch((error: BaseQueryError) => {
          showNotification(NotificationType.Error, { errorId: error.data.errorCode });
        });
    }
  };
  const reverseDirection = getReverseDirection(i18n.language);
  useEffect(() => {
    setHasFiles(!!answer?.attachments.length);
  }, [answer?.attachments.length]);


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      uploadAttachment(event);
      setHasFiles(!!files.length);
    }
  };

  useEffect(() => {
    if (hasFiles) {
      setShowErrorHint(false);
    }
  }, [hasFiles]);
  return (
    <div dir={direction} className={classes.wrapper}>
      <Typography variant="h6">{uploadTitle || translate('uploadDocuments')}</Typography>
      <div dir={reverseDirection} className={classes.uploadButtonSpace}>
        <Typography dir="rtl" variant="body2" className={classes.uploadButtonDesc}>
          {translate('maximum5Files')} {translate('filesTypes')}
        </Typography>
        {answer ? (
          <label htmlFor={`button-file-${answer.id}`}>
            <Input
              id={`button-file-${answer.id}`}
              type="file"
              onChange={uploadAttachment}
              accept="image/*,.pdf,.heic,.hevc,.heif"
            />
            <Button size="large" variant="outlined" component="span" color="secondary" className={classes.uploadButton}>
              {translate('upload')}
            </Button>
          </label>
        ) : (
          <CircularProgress size={28} className={classes.buttonProgress} />
        )}
      </div>
      <div dir={direction} className={classes.uploadedContainer}>
        {answer?.attachments.map((attachment: AnswerAttachment) => (
          <Button
            key={attachment.id}
            className={classes.attachment}
            variant="outlined"
            startIcon={<CheckGreyIcon fill="#148287" />}
            endIcon={<CrossCloseGreyIcon fill="#9E9E9E" onClick={() => removeAttachment(attachment.id)} />}
          >
            {attachment.name}
          </Button>
        ))}
        {isUploading && <CircularProgress size={28} className={classes.progress} />}
      </div>
       {!isOptional && (
        <Hint
          text={translate('atLeastOneFile')}
          type={!hasFiles ? 'error' : undefined}
        />
      )}
    </div>
  );
};
