import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';

import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { isQuestionnaireDraftOrMissing } from 'domain/admin/utils/QuestionnaireUtils';

import { useGetQuestionnaireByIdQuery, usePatchScoringMutation } from '../api/admin.api';
import { AdminQuestionnaireStepTopic } from './AdminQuestionnaireStepTopic';
import { AdminQuestionnaireStepProps } from './AdminQuestionnaireStepOne';
import { getCommonValidationError } from '../utils/getCommonValidationError';

type Props = AdminQuestionnaireStepProps & {
  questionnaireId: number;
};

type FormValues = {
  minSuccessfulScore: number;
};

const useStyles = makeStyles((theme) => ({
  main: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  description: {
    flip: false,
    marginRight: '67px',
    marginLeft: '67px',
  },

  formWrapper: {
    marginTop: theme.spacing(3),
    width: '100%',
  },

  field: {},
}));

const preventNonNumericalInput: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
  const isAllowed = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'Backspace'].includes(e.key);

  !isAllowed && e.preventDefault();
  return isAllowed;
};

export const AdminQuestionnaireStepThree: React.FC<Props> = ({ goNextStep, questionnaireId }) => {
  const classes = useStyles();
  const translate = useCreateTranslate(TranslationNamespace.admin);

  const { data: questionnaire } = useGetQuestionnaireByIdQuery(questionnaireId);
  const showNotification = useNotifications();
  const [patchScoring, { isLoading }] = usePatchScoringMutation();

  const { handleSubmit, control } = useForm<FormValues>({
    defaultValues: {
      minSuccessfulScore: questionnaire?.minSuccessfulScore || 60,
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async ({ minSuccessfulScore }, event) => {
    if (minSuccessfulScore < 60) {
      showNotification(NotificationType.Error, { message: translate('minScore') });
      return;
    }
    if (minSuccessfulScore > 80) {
      showNotification(NotificationType.Error, { message: translate('minScore') });
      return;
    }
    try {
      if (!isLoading && questionnaire && questionnaire.minSuccessfulScore !== minSuccessfulScore) {
        await patchScoring({
          id: questionnaire.id,
          min_successful_score: minSuccessfulScore,
        }).unwrap();
      }

      goNextStep(event);
    } catch (err) {
      showNotification(NotificationType.Error);
    }
  };

  return (
    <div className={classes.main}>
      <AdminQuestionnaireStepTopic topicLabelName="scoring" />
      <Typography className={classes.description} variant="body2">
        {translate('setMinimumScore')}
      </Typography>
      <form id="stepForm" onSubmit={handleSubmit(onSubmit)}>
        <FormControl className={classes.formWrapper}>
          <Controller
            control={control}
            name="minSuccessfulScore"
            rules={{
              required: true,
              minLength: 1,
              maxLength: 100,
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                type="text"
                variant="outlined"
                className={classes.field}
                error={Boolean(error)}
                InputProps={{
                  startAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                disabled={!isQuestionnaireDraftOrMissing(questionnaire)}
                label={translate('minSuccessScore')}
                helperText={getCommonValidationError(error, { maxLength: 100 })}
                onKeyPress={preventNonNumericalInput}
              />
            )}
          />
        </FormControl>
      </form>
    </div>
  );
};
