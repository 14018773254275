import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { LoadingWrapper } from 'ui/LoadingWrapper';

import { useGetPCRequestQuery, useGetSectionsByAssessmentIdQuery } from '../../admin/api/admin.api';
import { AuditorLayout } from '../ui/AuditorLayout';
import { AssessmentCompareHeader as CommonAssessmentCompareHeader } from '../../common/assessment-compare/AssessmentCompareHeader';
import { CompareSectionsBody as CommonCompareSectionsBody } from '../../common/assessment-compare/CompareSectionsBody';
import { Scoring } from '../../admin/ui/Scoring';
import { getAuditorPath } from '../AuditorRoutes';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginTop: theme.spacing(10.5),
  },
}));

export const AssessmentCompare = () => {
  const { pcRequestId } = useParams<{ pcRequestId: string }>();
  const { data: pcRequest, isLoading, isFetching } = useGetPCRequestQuery(+pcRequestId);
  const classes = useStyles();

  const {
    data: { results: sections } = {
      count: 0,
      results: [],
    },
  } = useGetSectionsByAssessmentIdQuery(
    {
      // Sections are the same for each assessment in pc request.
      assessmentId: pcRequest?.assessments[0].id,
    },
    {
      skip: !pcRequest?.assessments[0],
    }
  );

  return (
    <AuditorLayout>
      <Container className={classes.container} maxWidth="lg">
        <LoadingWrapper isLoading={isLoading || isFetching}>
          {pcRequest && (
            <>
              <CommonAssessmentCompareHeader getPath={getAuditorPath} pcRequestId={pcRequest.id} />
              <Scoring pcRequest={pcRequest} />
            </>
          )}

          {pcRequest && sections && <CommonCompareSectionsBody getPath={getAuditorPath}
          pcRequest={pcRequest} sections={sections} />}        </LoadingWrapper>
      </Container>
    </AuditorLayout>
  );
};
