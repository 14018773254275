import { makeStyles } from '@material-ui/core';

export const useFormStyles = makeStyles((theme) => ({
  numberInput: {
    flip: false,
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
}));
