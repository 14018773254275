import React from 'react';
import { FormControl, makeStyles, TextField, Typography } from '@material-ui/core';
import { Controller, FieldError, SubmitHandler, useForm } from 'react-hook-form';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { Button } from 'ui/Button';
import { emailRegex, saudiPhoneNumberRegex } from 'infrastructure/constants/regex.constants';
import { useFormStyles } from 'styles/form';
import { preventDefaultIfNotNumberAndPlusValues } from 'utilities/preventDefaultIfNotNumberAndPlusValues';
import { BaseQueryError } from 'infrastructure/persistence/baseQueryWithReAuth';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { toUserDto } from 'types/User';

import { useGetUserByIdQuery, useUpdateUserMutation } from '../api/admin.api';
import { UsersDialog } from './UsersDialog';

type UsersEditDialogProps = {
  id: number;
  isFormOpen: boolean;
  handleClose: () => void;
};

type UserEditInfo = {
  email: string;
  phoneNumber: string;
};

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  description: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(6),
  },

  field: {
    marginTop: theme.spacing(4),
  },

  buttons: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  submitButton: {
    flex: 1,
  },

  cancelButton: {
    flex: 1,
    flip: false,
    marginLeft: theme.spacing(2),
  },
}));

export const UsersEditDialog: React.FC<UsersEditDialogProps> = ({ id, isFormOpen, handleClose }) => {
  const classes = useStyles();
  const formClasses = useFormStyles();

  const translate = useCreateTranslate(TranslationNamespace.admin);
  const translateCommon = useCreateTranslate(TranslationNamespace.common);

  const { data: user, isLoading } = useGetUserByIdQuery(id);
  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();
  const showNotification = useNotifications();

  const {
    handleSubmit,
    control,
    reset: resetForm,
  } = useForm<UserEditInfo>({
    mode: 'onTouched',
    defaultValues: {
      email: user?.email || '',
      phoneNumber: user?.phoneNumber || '',
    },
  });

  const handleFormClose = () => {
    resetForm();
    handleClose();
  };

  React.useEffect(() => {
    if (!isLoading && user) {
      resetForm({
        email: user.email,
        phoneNumber: user.phoneNumber,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const onUserEdit: SubmitHandler<UserEditInfo> = async ({ email, phoneNumber }) => {
    if (!isUpdating && user) {
      await updateUser({
        ...toUserDto(user),
        email,
        phone_number: phoneNumber,
      })
        .unwrap()
        .then(() => {
          handleFormClose();
        })
        .catch((error: BaseQueryError) => {
          showNotification(NotificationType.Error, { errorId: error.data.errorCode });
        });
    }
  };

  const getHelperText = (error?: FieldError) => {
    if (!error) return ' ';

    if (error.type === 'required') return translateCommon('fieldRequired');

    return error.message;
  };

  return (
    <UsersDialog isFormOpen={isFormOpen} handleClose={handleFormClose}>
      <form onSubmit={handleSubmit(onUserEdit)}>
        <Typography variant="h6">{translate('editAccount')}</Typography>
        <Typography variant="body2" className={classes.description}>
          {translate('editAccountDescription')}
        </Typography>
        <FormControl className={classes.formWrapper}>
          <Controller
            control={control}
            name="email"
            rules={{
              required: true,
              pattern: {
                value: emailRegex,
                message: translate('invalidEmailAddressFormat'),
              },
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                type="text"
                variant="outlined"
                className={classes.field}
                error={invalid}
                helperText={getHelperText(error)}
                label={translate('emailAddress')}
              />
            )}
          />
          <Controller
            control={control}
            name="phoneNumber"
            rules={{
              required: true,
              pattern: {
                value: saudiPhoneNumberRegex,
                message: translate('invalidPhoneFormat'),
              },
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                InputProps={{
                  classes: {
                    input: formClasses.numberInput,
                  },
                }}
                type="text"
                variant="outlined"
                className={classes.field}
                error={invalid}
                helperText={getHelperText(error)}
                label={translateCommon('phoneNumber')}
                placeholder={translate('formatPhoneField')}
                inputMode="numeric"
                onKeyPress={preventDefaultIfNotNumberAndPlusValues}
              />
            )}
          />
        </FormControl>
        <div dir="ltr" className={classes.buttons}>
          <Button
            variant="contained"
            size="medium"
            disabled={isUpdating}
            color="secondary"
            className={classes.submitButton}
            type="submit"
          >
            {translate('saveChanges')}
          </Button>
          <Button
            variant="outlined"
            size="medium"
            disabled={isUpdating}
            color="secondary"
            className={classes.cancelButton}
            onClick={handleFormClose}
          >
            {translateCommon('cancel')}
          </Button>
        </div>
      </form>
    </UsersDialog>
  );
};
