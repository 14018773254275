import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import i18n from 'i18next';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

import { getDirection } from '../../utilities/useLanguage';

const useStyles = makeStyles((theme) => ({
  search: {
    width: '35%',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#fafafa',
    marginBottom: theme.spacing(),
    marginTop: theme.spacing(),
  },
  searchIcon: {
    color: '#9e9e9e',
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputInput: {
    padding: theme.spacing(1.8, 1, 1.8, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

export type TemplateSearchProps = {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  name: string;
};

export const Search: React.FC<TemplateSearchProps> = ({ onChange, name }) => {
  const classes = useStyles();
  const direction = getDirection(i18n.language);

  return (
    <div dir={direction} className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder={name}
        classes={{
          input: classes.inputInput,
        }}
        onChange={onChange}
      />
    </div>
  );
};
