import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';

import { AssessmentTemplate } from '../../types/AssessmentTemplate';
import { QuestionnaireReviewContainer } from './QuestionnaireReviewContainer';

type QuestionnaireReviewScoringProps = {
  setStepIndex: React.Dispatch<React.SetStateAction<number>>;
  questionnaire: AssessmentTemplate;
  isEditable: boolean;
};

const useStyles = makeStyles((theme) => ({
  body2: {
    opacity: '0.6',
  },
  infoWrapper: {
    marginTop: theme.spacing(8),
    marginLeft: theme.spacing(1),
  },
  itemWrapper: {
    marginTop: theme.spacing(2),
  },
}));

export const QuestionnaireReviewScoring: React.FC<QuestionnaireReviewScoringProps> = ({
  setStepIndex,
  questionnaire,
  isEditable,
}) => {
  const stepIndex = 2;
  const classes = useStyles();
  const translateQuestionnaire = useCreateTranslate(TranslationNamespace.admin);

  return (
    <QuestionnaireReviewContainer
      topicLabelName="scoring"
      setStepIndex={setStepIndex}
      stepIndex={stepIndex}
      isEditable={isEditable}
    >
      <div className={classes.infoWrapper}>
        <div className={classes.itemWrapper}>
          <Typography variant="body2" className={classes.body2}>
            {translateQuestionnaire('minSuccessScore')}
          </Typography>
          <Typography variant="h6">{questionnaire.minSuccessfulScore}%</Typography>
        </div>
      </div>
    </QuestionnaireReviewContainer>
  );
};
