import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import i18n from 'i18next';

import { TranslationNamespace } from 'i18n/config';
import { Routes } from 'infrastructure/routes';
import { ProtectedLayout } from 'ui/ProtectedLayout';
import { shdShadow } from 'ui/styles';
import { useCreateTranslate } from 'utilities/translate.hook';
import { useAppDispatch } from 'infrastructure/store/rootStore.hooks';
import { Button } from 'ui/Button';
import { CustomTablePagination } from 'ui/CustomTablePagination';
import { useNavigate } from 'utilities/navigate';
import { AssessmentStatus } from 'types/AssessmentStatus';
import { LoadingSpinner } from 'ui/LoadingSpinner';

import { useGetCompanyListQuery } from '../api/companyRepresentative.api';
import { setEstablishmentExtId } from './CompanyRepresentativeLogin.slice';
import { CompanyRepresentativeRoutes, getCompanyRepresentativePath } from '../CompanyRepresentativeRoutes';
import { getDirection, getReverseDirection } from '../../../utilities/useLanguage';
import { Search } from '../../common/Search';

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },

  selectBox: {
    flexDirection: 'column',
    flexGrow: 1,

    background: theme.palette.background.paper,
    boxShadow: shdShadow,
    borderRadius: '8px',
    marginTop: '34px',
    padding: theme.spacing(5),
  },

  // Desktop Typography
  h4: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },

  body1: {
    marginBottom: '44px',
  },

  establishmentList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    alignSelf: 'stretch',

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      width: 'fit-content',
      alignSelf: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      alignItems: 'center',
      display: 'grid',
      overflowX: 'auto',
    },
  },

  establishment: {
    padding: '20px 24px',
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: `1px solid #E3E4E9`,
    borderRadius: theme.spacing(1),
  },

  button: {
    flexShrink: 0,
    height: '36px',
    width: '112px',
    fontWeight: 'bold',
  },

  establishmentDetails: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },

  establishmentStatusDescription: {
    marginTop: '4px',
  },
  yellowStatus: {
    backgroundColor: '#fcedc1',
    color: '#856620',
    width: 'fit-content',
    padding: `${theme.spacing(0.5)}px 12px ${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
    borderRadius: '40px',
  },
  underAuditionColor: {
    backgroundColor: '#fcedc1',
    color: '#856620',
    width: 'fit-content',
    padding: `${theme.spacing(0.5)}px 12px ${theme.spacing(0.5)}px ${theme.spacing(1.5)}px `,
    borderRadius: '40px',
  },
  greenStatus: {
    backgroundColor: '#e3f2fd',
    color: '#2196f3',
    width: 'fit-content',
    padding: `${theme.spacing(0.5)}px 12px ${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
    borderRadius: '40px',
  },
  pcGreen: {
    backgroundColor: '#e8f5e9',
    color: '#3B873E',
    width: 'fit-content',
    padding: `${theme.spacing(0.5)}px 12px ${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
    borderRadius: '40px',
  },
  redColor: {
    backgroundColor: '#ffcdd2',
    color: '#c62828',
    width: 'fit-content',
    padding: `${theme.spacing(0.5)}px 12px ${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
    borderRadius: '40px',
  },
  pagination: {
    alignSelf: 'flex-end',
  },

  underAudition: {
    '& circle': {
      fill: theme.palette.secondary.light,
    },
  },
}));

export const CompanyRepresentativeEstablishmentSelection: React.FC = () => {
  const dispatch = useAppDispatch();
  const translateLogin = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const navigate = useNavigate();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(5);
  const [query, setQuery] = useState('');
  const { data: establishments, isLoading } = useGetCompanyListQuery({
    parameters: { page: page + 1, pageSize },
    search: { searchInfo: [query] },
  });
  const direction = getDirection(i18n.language);
  const reverseDirection = getReverseDirection(i18n.language);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const getStatusData = (
    status: AssessmentStatus | null
  ): { statusDescription: string; statusIcon?: React.FC; className?: string; viewBox?: string } => {
    switch (status) {
      case AssessmentStatus.Draft:
        return { statusDescription: translateLogin('saInProgress'), className: classes.yellowStatus };
      case AssessmentStatus.Passed:
        return { statusDescription: translateLogin('saPassed'), className: classes.greenStatus };
      case AssessmentStatus.Failed:
        return {
          statusDescription: translateLogin('saFailed'),
          className: classes.redColor,
          viewBox: '0 0 28 28',
        };

      case AssessmentStatus.AuditorStarted:
        return {
          statusDescription: translateLogin('underAudition'),
          className: classes.underAuditionColor,
        };

      case AssessmentStatus.PcIssued:
        return {
          statusDescription: translateLogin('pcIssued'),
          className: classes.pcGreen,
        };
      default:
        return {
          statusDescription: translateLogin('saNotStarted'),
          className: classes.yellowStatus,
        };
    }
  };

  const handleSelectClick = (extId: string, establishmentId: number | null) => {
    dispatch(setEstablishmentExtId(extId));

    if (!establishmentId) {
      navigate(`${Routes.CR_MISSING_INFO}/${extId}`);
      return;
    }

    navigate(`${getCompanyRepresentativePath(CompanyRepresentativeRoutes.establishment)}/OSH/${establishmentId}`);
  };
  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;
    setQuery(value);
  };

  return (
    <ProtectedLayout>
      <div className={classes.main}>
        <Container dir={direction} className={classes.selectBox}>
          <Typography variant="h4" className={classes.h4}>
            {translateLogin('selectYourCompany')}
          </Typography>
          <Typography className={classes.body1} variant="body1">
            {translateLogin('findCompany')}
          </Typography>

          <Search name={translateLogin('searchEstablishment')} onChange={(e) => handleSearch(e)} />
          <div dir={reverseDirection} className={classes.establishmentList}>
            {isLoading && !establishments && <LoadingSpinner type="admin" />}
            {establishments &&
              establishments.results.map((establishment) => {
                const statusConfig = getStatusData(establishment.assessmentCurrentObjStatusOSH);
                return (
                  <div
                    key={`${establishment.extId}-${establishment.nameAr}-${establishment.extId}`}
                    className={classes.establishment}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      color="secondary"
                      className={classes.button}
                      onClick={() => handleSelectClick(establishment.extId, establishment.establishmentId)}
                    >
                      {translateLogin('select')}
                    </Button>

                    <div dir={direction} className={classes.establishmentDetails}>
                      <Typography variant="h6">{establishment.nameAr}</Typography>
                      <Typography
                        variant="body2"
                        className={`${classes.establishmentStatusDescription} ${statusConfig.className}`}
                      >
                        {statusConfig.statusDescription}
                      </Typography>
                    </div>
                  </div>
                );
              })}
          </div>
          {establishments && (
            <CustomTablePagination
              className={classes.pagination}
              component="div"
              dir={direction}
              rowsPerPageOptions={[5, 10, 25]}
              count={establishments.count}
              rowsPerPage={pageSize}
              page={page}
              SelectProps={{
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Container>
      </div>
    </ProtectedLayout>
  );
};
