import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import i18n from 'i18next';

import { ReactComponent as ActionIcon } from 'ui/icons/actions.svg';
import { CustomTablePagination } from 'ui/CustomTablePagination';
import { backButtonBackgroundColor } from 'ui/styles';
import { CircleButton } from 'ui/buttons/CircleButton';
import { useNavigate } from 'utilities/navigate';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { BaseQueryError } from 'infrastructure/persistence/baseQueryWithReAuth';
import { PCRequest } from 'types/PCRequest';
import { LoadingSpinner } from 'ui/LoadingSpinner';

import { PcRequestNoData } from './PcRequestNoData';
import { PCRequestTableMenuDialog } from './PCRequestTableMenuDialog';
import { useGetPCRequestsQuery, useStartPcRequestAssessmentMutation } from '../../admin/api/admin.api';
import { PCRequestTableHead } from './PCRequestTableHead';
import { PCRequestTableStatusTableCell } from './PCRequestTableStatusTableCell';
import { PCRequestSpecialistDialog } from './PCRequestSpecialistDialog';
import { Routes } from '../../common/Routes';
import { getDirection, getReverseDirection } from '../../../utilities/useLanguage';

type PCRequestsTableProps = {
  getPath: (value: Routes) => string;
  searchPCRequest: string;
  getDate: (pcRequest?: Date) => void;
  getAssignedTo: (pcRequest: PCRequest) => React.ReactNode;
};

const useStyle = makeStyles((theme) => ({
  table: {
    minWidth: theme.breakpoints.values.lg,
    marginTop: theme.spacing(4),
  },

  actionCell: {
    padding: '6px',
  },

  menuButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },

  menuButton: {
    padding: 0,
    margin: 0,
  },

  svgIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    width: '40px',
    backgroundColor: backButtonBackgroundColor,
  },
  cell: {
    padding: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  cellNo: {
    paddingRight: '90px',
    paddingLeft: '125px',
  },

  left: {
    textAlign: 'left',
  },
  right: {
    textAlign: 'right',
  },
  center: {
    textAlign: 'center',
  },
}));

export const PCRequestTable: React.FC<PCRequestsTableProps> = ({
  getPath,
  searchPCRequest,
  getDate,
  getAssignedTo,
}) => {
  const classes = useStyle();
  const navigate = useNavigate();
  const showNotification = useNotifications();
  const direction = getDirection(i18n.language);
  const [pageInfo, setPageInfo] = React.useState({
    page: 0,
    pageSize: 5,
  });
  const [isAssignSpecialistOpen, setIsAssignSpecialistOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement | undefined>(null);
  const [selectedPcRequest, setSelectedPcRequest] = React.useState<undefined | PCRequest>();
  const { data: { count, results } = { count: 0, results: [] }, isLoading } = useGetPCRequestsQuery({
    parameters: { page: pageInfo.page + 1, pageSize: pageInfo.pageSize },
    param: { searchInfo: [searchPCRequest] },
  });

  const [startAssessment] = useStartPcRequestAssessmentMutation();

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const goToAssessment = () => {
    selectedPcRequest && navigate(`${getPath(Routes.pcRequests)}/${selectedPcRequest.id}${Routes.assessment}`);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageInfo({
      ...pageInfo,
      page: newPage,
    });
  };

  const handleViewDetails = () => {
    if (!selectedPcRequest) return;
    navigate(`${getPath(Routes.pcRequests)}/${selectedPcRequest.id}`);
    handleCloseMenu();
  };

  const handleOpenMenuFn =
    (pcRequest: PCRequest, requestId: number): React.MouseEventHandler<HTMLButtonElement> =>
    (event) => {
      setAnchorEl(event.currentTarget?.parentElement?.parentElement);
      setSelectedPcRequest(pcRequest);
    };

  const handleAssignSpecialist = () => {
    setIsAssignSpecialistOpen(true);
    handleCloseMenu();
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageInfo({
      page: 0,
      pageSize: parseInt(event.target.value, 10),
    });
  };

  const handleStartAssessment = async () => {
    if (!selectedPcRequest) return;
    try {
      await startAssessment(selectedPcRequest.id).unwrap().then(goToAssessment);
    } catch (err) {
      const error = err as BaseQueryError;

      if (!error.data) {
        showNotification(NotificationType.Error);
      }
      showNotification(NotificationType.Error, { errorId: error.data.errorCode });
    }
  };

  const reverseDirection = getReverseDirection(i18n.language);

  return results.length > 0 ? (
    <div dir={reverseDirection}>
      <TableContainer className={classes.table} component={Paper}>
        <Table size="medium">
          <PCRequestTableHead />
          <TableBody>
            {results.map((pcRequest) => (
              <TableRow tabIndex={-1} key={`pcRequest-${pcRequest.id}`}>
                <TableCell className={classes.actionCell}>
                  <div className={classes.menuButtonWrapper}>
                    <CircleButton
                      size={20}
                      icon={ActionIcon}
                      className={classes.svgIcon}
                      viewBoxWidth={22}
                      viewBoxHeight={16}
                      onClick={handleOpenMenuFn(pcRequest, pcRequest.id)}
                    />
                  </div>
                </TableCell>
                <PCRequestTableStatusTableCell status={pcRequest.status} />
                <TableCell className={classes.cell}>{getAssignedTo(pcRequest)}</TableCell>
                <TableCell className={classes.cell}> {getDate(pcRequest.issuingDate)} </TableCell>
                <TableCell className={classes.cell}> {getDate(pcRequest.createdAt)} </TableCell>
                <TableCell className={classes.cellNo}>{pcRequest.numberOfRequests}</TableCell>
                <TableCell className={classes.cell}>{pcRequest.establishment.nameAr}</TableCell>
                <TableCell className={classes.center}>{pcRequest.id}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <CustomTablePagination
                dir={direction}
                count={count}
                rowsPerPage={pageInfo.pageSize}
                page={pageInfo.page}
                SelectProps={{
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {anchorEl && selectedPcRequest && (
        <PCRequestTableMenuDialog
          anchorEl={anchorEl}
          selectedPcRequest={selectedPcRequest}
          handleCloseMenu={handleCloseMenu}
          handleViewDetails={handleViewDetails}
          handleStartAssessment={handleStartAssessment}
          handleContinueAssessment={goToAssessment}
          handleAssignSpecialist={handleAssignSpecialist}
          pcRequestId={selectedPcRequest.id}
        />
      )}
      {isAssignSpecialistOpen && selectedPcRequest && (
        <PCRequestSpecialistDialog
          auditorId={selectedPcRequest.auditor ? selectedPcRequest.auditor.id : null}
          open={isAssignSpecialistOpen}
          handleClose={() => setIsAssignSpecialistOpen(false)}
          pcRequestId={selectedPcRequest.id}
          pcStatus={selectedPcRequest.status}
        />
      )}
    </div>
  ) : isLoading ? (
    <LoadingSpinner type="admin" />
  ) : (
    <PcRequestNoData />
  );
};
