import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';

import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { ReactComponent as EditCircleIcon } from 'ui/icons/edit_circle.svg';

import { AdminQuestionnaireStepTopic } from '../AdminQuestionnaireStepTopic';

type TopicProps = {
  topicLabelName: string;
  questionsNoSummary: number | undefined;
  totalWeightSummary: string | undefined;
  stepIndex: number;
  setStepIndex: React.Dispatch<React.SetStateAction<number>>;
  isEditable: boolean;
};

const useStyles = makeStyles((theme) => ({
  topicEditable: {
    flip: false,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  topicIcon: {
    flip: false,
    marginLeft: theme.spacing(2),
    width: '48px',
    height: '48px',
    fill: 'none',
    cursor: 'pointer',
  },
  body1: {
    opacity: '0.6',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  statisticsContainer: {
    display: 'flex',
  },
  statisticsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(4),
  },
}));

export const QuestionnaireReviewTopicEditableWithStatistics: React.FC<TopicProps> = ({
  topicLabelName,
  questionsNoSummary,
  totalWeightSummary,
  stepIndex,
  setStepIndex,
  isEditable,
}) => {
  const classes = useStyles();
  const translateQuestionnaire = useCreateTranslate(TranslationNamespace.admin);

  return (
    <div className={classes.topicEditable}>
      <AdminQuestionnaireStepTopic topicLabelName={topicLabelName} />
      <div className={classes.statisticsContainer}>
        <div className={classes.statisticsWrapper}>
          <Typography variant="body1" className={classes.body1}>
            {translateQuestionnaire('overallWeight')}
          </Typography>
          <Typography variant="h6">{Number(totalWeightSummary).toFixed(2)}</Typography>
        </div>
        <div className={classes.statisticsWrapper}>
          <Typography variant="body1" className={classes.body1}>
            {translateQuestionnaire('overallQuestions')}
          </Typography>
          <Typography variant="h6">{questionsNoSummary}</Typography>
        </div>
        {isEditable && (
          <SvgIcon
            onClick={() => setStepIndex(stepIndex)}
            className={classes.topicIcon}
            component={EditCircleIcon}
            viewBox="0 0 48 48"
          />
        )}
      </div>
    </div>
  );
};
