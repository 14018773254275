import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import i18n from 'i18next';

import { AssessmentType } from 'types/Assessment';
import { AssessmentAnswer } from 'types/AssessmentAnswer';
import { PCRequestStatus } from 'types/PCRequest';

import { AssessmentCompareAnswer } from './AssessmentCompareAnswer';
import { QuestionAndAnswerCompare } from '../assessment-compare/types/QuestionAndAnswersCompare';
import { isPCRequestBeforeInspection } from '../utils/isPCRequestBeforeInspection';
import { getDirection } from '../../../utilities/useLanguage';

type AssessmentCompareAnswersProps = {
  questionAndAnswer: QuestionAndAnswerCompare;
};

const useStyle = makeStyles((theme) => ({
  main: {
    flip: false,
    display: 'flex',
    flexDirection: 'row',

    '&>:nth-child(1)': {
      flip: false,
      borderRight: `1px solid #EEEEEE`,
    },
  },
}));

const getAnswerByAssessmentType = (
  answerer: AssessmentType,
  { auditorAnswer, crAnswer, inspectorAnswer }: QuestionAndAnswerCompare
) => {
  switch (answerer) {
    case AssessmentType.AUDITOR:
      return auditorAnswer;
    case AssessmentType.INSPECTOR:
      return inspectorAnswer;
    default:
      return crAnswer;
  }
};

const initialAnswerers = [AssessmentType.COMPANY_REPRESENTATIVE, AssessmentType.AUDITOR];

const useAnswererState = (
  initialAnswerer: AssessmentType,
  questionAndAnswer: QuestionAndAnswerCompare
): [
  {
    answer: AssessmentAnswer | undefined;
    answerer: AssessmentType;
  },
  (value: AssessmentType) => void
] => {
  const [answerer, setAnswerer] = useState(initialAnswerer);
  const [answer, setAnswer] = useState<AssessmentAnswer | undefined>(
    getAnswerByAssessmentType(initialAnswerer, questionAndAnswer)
  );

  const changeAnswerer = (value1: AssessmentType) => {
    if (value1 !== answerer) {
      setAnswerer(value1);
      setAnswer(getAnswerByAssessmentType(value1, questionAndAnswer));
    }
  };

  return [{ answer, answerer }, changeAnswerer];
};

export const AssessmentCompareAnswers: React.FC<AssessmentCompareAnswersProps> = ({ questionAndAnswer }) => {
  const isBeforeInspection = isPCRequestBeforeInspection(questionAndAnswer.pcRequestStatus);

  const [answerLeft, changeLeftAnswerer] = useAnswererState(
    isBeforeInspection ? AssessmentType.COMPANY_REPRESENTATIVE : AssessmentType.AUDITOR,
    questionAndAnswer
  );
  const [answerRight, changeRightAnswerer] = useAnswererState(
    isBeforeInspection ? AssessmentType.AUDITOR : AssessmentType.INSPECTOR,
    questionAndAnswer
  );
  const classes = useStyle();
  const { pcRequestStatus, question } = questionAndAnswer;

  const answerers = [PCRequestStatus.AuditorFinished, PCRequestStatus.InspectorStarted].includes(pcRequestStatus)
    ? initialAnswerers
    : initialAnswerers.concat(AssessmentType.INSPECTOR);
  const direction = getDirection(i18n.language);

  return (
    <div dir={direction} className={classes.main}>
      <AssessmentCompareAnswer
        selectKey={`question-answerer-select-${question.id}-left`}
        {...answerLeft}
        answerers={answerers}
        changeAnswerer={changeLeftAnswerer}
      />
      <AssessmentCompareAnswer
        selectKey={`question-answerer-select-${question.id}-right`}
        {...answerRight}
        answerers={answerers}
        changeAnswerer={changeRightAnswerer}
      />
    </div>
  );
};
