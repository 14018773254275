import React, { useCallback } from 'react';
import { Autocomplete, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { makeStyles, InputBase, IconButton, CircularProgress } from '@material-ui/core';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import SearchIcon from '@material-ui/icons/Search';

import { Coords } from 'domain/company-representative/types/Localization';
import { REACT_APP_GOOGLE_MAPS_API_KEY } from 'infrastructure/core/variables';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';

const containerStyle = {
  width: '100%',
  height: '300px',
};

// Riyadh center
const DEFAULT_MAP_CENTER = {
  lat: 24.774265,
  lng: 46.738586,
};

const useStyles = makeStyles((theme) => ({
  // Styles for google button
  panButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    border: 0,
    borderRadius: 2,
    boxShadow: '0 1px 4px -1px rgba(0, 0, 0, 0.3)',
    margin: 10,
    padding: '0 0.5em',
    font: '400 16px Roboto, Arial, sans-serif',
    overflow: 'hidden',
    height: 40,
    width: 40,
    cursor: 'pointer',
    '&:hover': {
      background: '#ebebeb',
    },
  },
  icon: {
    color: '#666666',
  },
  input: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 1px 4px -1px rgba(0, 0, 0, 0.3)',
    border: 0,
    borderRadius: 2,
    margin: '10px 80px 10px',
    height: 40,
    padding: '0 0.5em',
  },
}));

type Libraries = Parameters<typeof useJsApiLoader>[0]['libraries'];

const libraries: Libraries = ['places'];
interface MapProps {
  setValue?: ({ lat, lng }: Coords) => void;
  markerPosition?: Coords;
  disabled?: boolean;
}

export const Map: React.FC<MapProps> = ({ setValue, markerPosition, disabled }) => {
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const showNotification = useNotifications();
  const classes = useStyles();

  const [marker, setMarker] = React.useState<Coords | undefined>();
  const [mapCenter, setMapCenter] = React.useState<Coords | undefined>(markerPosition);
  const [autocomplete, setAutocomplete] = React.useState<google.maps.places.Autocomplete | undefined>();
  const [isNavigatorLoading, setNavigatorLoading] = React.useState(false);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY || '',
    libraries,
    language: 'ar',
    region: 'ar',
  });

  const changeMarkerPosition = (position: google.maps.MapMouseEvent) => {
    if (!disabled && position && position.latLng) {
      const coords = {
        lat: position.latLng.lat(),
        lng: position.latLng.lng(),
      };

      setMarker(coords);

      if (setValue) {
        setValue(coords);
      }
    }
  };

  React.useEffect(() => {
    if (markerPosition) {
      setMarker({
        lat: markerPosition.lat,
        lng: markerPosition.lng,
      });
      setMapCenter({
        lat: markerPosition.lat,
        lng: markerPosition.lng,
      });
    }
  }, [markerPosition, disabled]);

  const panToCurrentLocation = useCallback(() => {
    if (navigator.geolocation) {
      // Prevent user from spamming button
      setNavigatorLoading(true);
      navigator.geolocation.getCurrentPosition(
        (positionObj: GeolocationPosition) => {
          setMapCenter({
            lat: positionObj.coords.latitude,
            lng: positionObj.coords.longitude,
          });
          setNavigatorLoading(false);
        },
        () => {
          setNavigatorLoading(false);
          showNotification(NotificationType.Error, { message: translate('notAllowedLocationUsage') });
        }
      );
    } else {
      showNotification(NotificationType.Error, { message: translate('geoLocationNotSupported') });
    }
  }, [showNotification, translate]);

  const onPlaceChanged = React.useCallback(() => {
    if (!autocomplete) return;

    const place = autocomplete.getPlace();

    if (!place.geometry || !place.geometry.location) return;

    const coords = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };

    setMapCenter(coords);
  }, [autocomplete]);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={9}
      onClick={changeMarkerPosition}
      options={{ streetViewControl: false }}
      center={mapCenter || DEFAULT_MAP_CENTER}
    >
      {marker && <Marker position={marker} />}
      {!disabled && (
        <Autocomplete
          onPlaceChanged={onPlaceChanged}
          restrictions={{ country: 'SA' }}
          onLoad={(autocompleteInstance) => {
            setAutocomplete(autocompleteInstance);
          }}
        >
          <InputBase
            endAdornment={<SearchIcon className={classes.icon} />}
            className={classes.input}
            id="google-input"
          />
        </Autocomplete>
      )}

      {!disabled && (
        <IconButton disabled={isNavigatorLoading} onClick={panToCurrentLocation} className={classes.panButton}>
          <MyLocationIcon className={classes.icon} />
        </IconButton>
      )}
    </GoogleMap>
  ) : (
    <CircularProgress />
  );
};
