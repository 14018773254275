import { Page } from 'infrastructure/persistence/types';

export enum AssessmentAnswerType {
  Yes = 1,
  No = 2,
  NotApplicable = 3,
}

export type AssessmentAnswerDto = {
  id: number;
  question_id: number;
  self_assessment_id: number;
  type: number;
  text: string;
  attachments: AnswerAttachmentDto[] | null;
  created_at: string;
  modified_at: string;
  specialist_comment: string;
};

export type AnswerAttachmentDto = {
  id: number;
  file: string;
  name: string;
  answer_id: number;
};

export type AssessmentAnswer = {
  id: number;
  questionId: number;
  assessmentId: number;
  type: AssessmentAnswerType;
  text: string;
  attachments: AnswerAttachment[];
  createdAt: string;
  modifiedAt: string;
  specialistComment: string;
};

export type AnswerAttachment = {
  id: number;
  file: string;
  name: string;
  answerId: number;
};

export type GetAssessmentAnswerQuery = Page & {
  assessmentId: number;
  assessmentSectionId: number;
};

export type CreateAnswerDto = Pick<AssessmentAnswerDto, 'question_id' | 'self_assessment_id' | 'type'> & {
  text?: string;
  specialist_comment?: string;
};

export type CreateAnswerInput = {
  assessmentSectionId: number;
  data: CreateAnswerDto;
};

export type UpdateAnswerDto = Partial<AssessmentAnswerDto> &
  Pick<AssessmentAnswerDto, 'id' | 'question_id' | 'self_assessment_id'>;

export type UpdateAnswerInput = {
  assessmentSectionId: number;
  data: UpdateAnswerDto;
};
export type UploadAttachment = {
  uploadedFile: FormData;
  assessmentData: {
    answer_id: number;
    question_id: number;
    self_assessment_id: number;
    assessmentSectionId: number;
  };
};

export type DeleteAttachment = {
  id: number;
  assessmentData: {
    answer_id: number;
    question_id: number;
    self_assessment_id: number;
    assessmentSectionId: number;
  };
};

export const toAnswerAttachment = (dto: AnswerAttachmentDto): AnswerAttachment => ({
  answerId: dto.answer_id,
  file: dto.file,
  id: dto.id,
  name: dto.name,
});

export const toSelfAssessmentAnswer = (dto: AssessmentAnswerDto): AssessmentAnswer => ({
  id: dto.id,
  attachments: dto.attachments?.map(toAnswerAttachment) || [],
  questionId: dto.question_id,
  assessmentId: dto.self_assessment_id,
  text: dto.text,
  type: dto.type,
  createdAt: dto.created_at,
  modifiedAt: dto.modified_at,
  specialistComment: dto.specialist_comment,
});
