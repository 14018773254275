export enum Routes {
  CR_LOGIN = '/auth/sso',
  CR_MISSING_INFO = '/auth/sso/fill-info',
  CR_START_SA = '/auth/sso/start-sa',
  CA_START_SA = '/auth/ca/start-sa',
  ADMIN_LOGIN = '/admin/login',
  TERMS_AND_CONDITIONS = '/terms',
  PRIVACY_POLICY = '/privacy',
  CONFIRMATION_INVITATION = '/confirmation/form',
  NOT_FOUND = '/notfound',
  HOME = '/',
}
