import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { Popper, MenuList, MenuItem, Paper, Divider, ClickAwayListener } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { UserAvatarIcon } from 'ui/icons/icons';
import { LogoutButton } from 'ui/buttons/LogoutButton';
import { popperItemHoverBackground } from 'ui/styles';
import { useNavigate } from 'utilities/navigate';
import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { CustomButtonProps, Button } from 'ui/Button';
import { useChangeLangMutation } from 'infrastructure/services/user.api';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';

import { Routes } from '../../common/Routes';
import { ChangeLangInput } from '../../../types/User';
import { BaseQueryError } from '../../../infrastructure/persistence/baseQueryWithReAuth';
import { useNotifications } from '../../../infrastructure/notifications/NotificationsHandler';
import { getReverseDirection as getReverseDirectionForProfileMenu } from '../../../utilities/useLanguage';

type ProButtonProps = {
  redirectPath: string;
  withText?: boolean;
  withIcon?: boolean;
  getPath: (value: Routes) => string;
} & CustomButtonProps;

const useStyles = makeStyles((theme) => ({
  menuList: {
    color: theme.palette.text.primary,
    flip: false,
    textAlign: 'right',
  },
  menuItem: {
    justifyContent: 'flex-end',
    '&:hover': {
      background: popperItemHoverBackground,
      color: theme.palette.secondary.main,
    },
  },
  menuLogout: {
    padding: `6px ${theme.spacing(2)}px`,
    borderRadius: 0,
    width: '100%',
    justifyContent: 'flex-end',
    '& h6': {
      color: 'inherit',
      fontWeight: '400',
      fontSize: '1rem',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      background: popperItemHoverBackground,
      color: theme.palette.secondary.main,
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
    '& .MuiTouchRipple-root': {
      opacity: 0,
    },
  },
  hamburgerWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down(1180)]: {
      display: 'block',
    },
  },
  popper: {
    margin: `${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
    zIndex: 10,
  },
  userAvatarIcon: {
    flip: false,
    marginLeft: theme.spacing(1),
    width: 60,
  },
  activeMenuItem: {
    background: popperItemHoverBackground,
    color: theme.palette.secondary.main,

    '&:hover': {
      cursor: 'default',
    },
  },
  headerMenu: {
    marginLeft: theme.spacing(8),
  },
  headerMenuEn: {
    marginLeft: theme.spacing(0),
  },
  userProfile: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.spacing(2),
    color: theme.palette.background.paper,
    fontWeight: 700,
    border: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  language: {
    padding: `6px ${theme.spacing(2)}px`,
    borderRadius: 0,
    width: '100%',
    justifyContent: 'flex-end',
    '& h6': {
      color: 'inherit',
      fontWeight: '400',
      fontSize: '1rem',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      background: popperItemHoverBackground,
      color: theme.palette.secondary.main,
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
    '& .MuiTouchRipple-root': {
      opacity: 0,
    },
  },
}));

export const ProfileMenu: React.FC<ProButtonProps> = ({
  getPath,
  redirectPath,
  withText,
  withIcon = true,
  ...props
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const { path } = useRouteMatch();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const { i18n } = useTranslation();
  const [changeLang] = useChangeLangMutation();
  const showNotification = useNotifications();

  const routesData = [
    {
      label: translate('changePassword'),
      handler: () => navigate(getPath(Routes.setPassword)),
      route: Routes.setPassword,
    },
  ];
  const handleHamburgerOpen = () => setOpen(true);

  const handleHamburgerClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const HandleOnclick = async () => {
    const newLang = i18n.language === 'ar' ? 'en' : 'ar';
    await changeLang({ lang: newLang } as ChangeLangInput)
      .unwrap()
      .catch((error: BaseQueryError) => {
        showNotification(NotificationType.Error, { errorId: error.data.errorCode });
      });
    i18n.changeLanguage(newLang);
    setOpen(false);
  };
  const reverseDirection = getReverseDirectionForProfileMenu(i18n.language);

  return (
    <>
      <div
        className={`${classes.hamburgerWrapper} ${classes.headerMenu} ${
          i18n.language === 'ar' ? '' : classes.headerMenuEn
        }`}
        dir={reverseDirection}
      >
        <button ref={anchorRef} onClick={handleHamburgerOpen} className={classes.userProfile}>
          <UserAvatarIcon className={classes.userAvatarIcon} />
        </button>

        <Popper
          className={classes.popper}
          open={open}
          anchorEl={anchorRef.current}
          transition
          disablePortal
          role={undefined}
        >
          <Paper elevation={8}>
            <ClickAwayListener onClickAway={handleHamburgerClose}>
              <MenuList autoFocusItem={open} id="menu-list-grow" className={classes.menuList}>
                <Button className={classes.language} onClick={HandleOnclick}>
                  {i18n.language === 'ar' ? translate('english') : translate('arabic')}
                </Button>
                {routesData.map(({ label, handler, route }) => (
                  <MenuItem
                    key={label}
                    onClick={handler}
                    className={clsx(classes.menuItem, getPath(route) === path && classes.activeMenuItem)}
                  >
                    {label}
                  </MenuItem>
                ))}
                <Divider />
                <LogoutButton withIcon={false} className={classes.menuLogout} redirectPath={getPath(Routes.login)} />
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popper>
      </div>
    </>
  );
};
