import { createTheme } from '@material-ui/core';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import createTypography from '@material-ui/core/styles/createTypography';
import createPalette from '@material-ui/core/styles/createPalette';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { effraRegular, effraBold, EFFRA_FAMILY_NAME } from 'fonts/fonts';
import { backButtonBackgroundColor, mainBackground, shdShadow } from 'ui/styles';

const breakpointsValues = {
  xs: 0,
  sm: 320,
  md: 808,
  lg: 1224,
  xl: 1920,
};

const breakpoints = createBreakpoints({ values: breakpointsValues });
const palette = createPalette({
  primary: {
    main: '#14415a',
    light: '#456C87',
    dark: '#001B30',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#148287',
    light: '#54B2B7',
    dark: '#00555A',
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#2196F3',
    dark: '#0B79D0',
    light: '#64B6F7',
    contrastText: '#FFFFFF',
  },
  success: {
    main: '#4CAF50',
    dark: '#3B873E',
    light: '#7BC67E',
    contrastText: '#FFFFFF',
  },
  error: {
    main: '#F44336',
    dark: '#E31B0C',
    light: '#F88078',
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#FF9800',
    dark: '#C77700',
    light: '#FFB547',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  background: {
    paper: '#FFFFFF',
    default: '#e3e3e3',
  },
});

// eslint-disable-next-line @typescript-eslint/no-shadow
const typography = createTypography(palette, (palette) => ({
  fontFamily: [
    EFFRA_FAMILY_NAME,
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
  ].join(','),
  subtitle2: {
    fontWeight: 700,
  },
  h1: {
    fontWeight: 400,
  },
  h2: {
    fontWeight: 400,
  },
  h4: {
    fontWeight: 700,
  },
  h5: {
    fontWeight: 700,
  },
  h6: {
    fontWeight: 700,
  },
  button: {
    textTransform: 'none',
  },
}));

export const theme = createTheme({
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: '8px',
      },

      elevation1: {
        boxShadow: shdShadow,
        borderRadius: '8px',
      },
    },
    MuiButton: {
      root: {
        fontWeight: 'bold',
      },
      outlinedPrimary: {
        borderColor: palette.secondary.main,
      },
      outlinedSecondary: {
        backgroundColor: backButtonBackgroundColor,
        color: palette.secondary.main,
      },

      containedSecondary: {
        boxShadow: 'none!important',
      },
    },
    MuiTableCell: {
      root: {
        ...typography.body2,
      },
      head: {
        fontWeight: 700,
      },
    },
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: palette.secondary.main,
        },
      },
      completed: {
        color: palette.secondary.main,
      },
    },
    MuiStepLabel: {
      label: {
        ...typography.body2,

        '&$active': {
          ...typography.subtitle2,
        },

        '&$completed': {
          ...typography.subtitle2,
        },
      },
    },

    MuiCssBaseline: {
      '@global': {
        '@font-face': [effraRegular, effraRegular, effraBold] as unknown as CSSProperties,
        body: {
          backgroundColor: '#FAFAFA',
          [breakpoints.up('md')]: mainBackground,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '10px',
        color: palette.primary.contrastText,
        backgroundColor: palette.primary.dark,
        padding: '10px',
        maxWidth: '240px',
      },
    },
  },
  breakpoints: {
    values: breakpointsValues,
  },
  palette,
  typography,
  shape: {
    borderRadius: 8,
  },
});
