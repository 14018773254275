import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import Menu from '@material-ui/core/Menu';
import ListItemText from '@material-ui/core/ListItemText';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { AddSpecialistIcon, ArrowBackIcon, StarIcon, ViewDetailsIcon } from 'ui/icons/icons';
import { CustomMenuItem } from 'ui/CustomMenuItem';
import { useGetMeQuery } from 'infrastructure/services/user.api';
import { PCRequest, PCRequestStatus } from 'types/PCRequest';

import { AssignAuditorList, AssignInspectorList } from '../utils/getPcRequestStatus';
import { availablePcRequestActions } from '../utils/availablePcRequestActions';

type PropsTypes = {
  anchorEl: HTMLElement;
  selectedPcRequest: PCRequest;
  handleCloseMenu: () => void;
  handleViewDetails: () => void;
  handleAssignSpecialist: () => void;
  handleStartAssessment: () => void;
  handleContinueAssessment: () => void;
  pcRequestId: number;
};

const useStyle = makeStyles((theme) => ({
  list: {
    marginLeft: 6,
  },
}));

export const PCRequestTableMenuDialog: React.FC<PropsTypes> = ({
  anchorEl,
  selectedPcRequest,
  handleCloseMenu,
  handleViewDetails,
  handleAssignSpecialist,
  handleStartAssessment,
  handleContinueAssessment,
  pcRequestId,
}) => {
  const translateCommon = useCreateTranslate(TranslationNamespace.common);
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const { data: currentUser } = useGetMeQuery();
  const {
    isAbleToStartAudit,
    isAbleToContinueAudit,
    isAbleToStartInspection,
    isAbleToContinueInspection,
    isAbleToAssignSpecialist,
  } = availablePcRequestActions(
    selectedPcRequest.status,
    selectedPcRequest.auditor,
    selectedPcRequest.inspector,
    currentUser
  );
  const classes = useStyle();

  return (
    <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
      <CustomMenuItem onClick={handleViewDetails}>
        <SvgIcon component={ViewDetailsIcon} />
        <ListItemText className={classes.list}>{translateCommon('viewDetails')}</ListItemText>
      </CustomMenuItem>

      {isAbleToAssignSpecialist && (
        <CustomMenuItem onClick={handleAssignSpecialist}>
          <SvgIcon component={AddSpecialistIcon} />
           {selectedPcRequest.status === PCRequestStatus.AuditorApproved
           ?
            <ListItemText  className={classes.list}>              
                {currentUser &&
                 AssignInspectorList(currentUser.currentplatform).map((role) => (
                    translate(role.name)
                 ))
                 }
            </ListItemText>
           :
             <ListItemText className={classes.list}>
                {currentUser &&
                 AssignAuditorList(currentUser.currentplatform).map((role) => (
                    translate(role.name)
                 ))
                 }
             </ListItemText>
           }
        </CustomMenuItem>
      )}

      {(isAbleToStartAudit || isAbleToStartInspection) && (
        <CustomMenuItem onClick={handleStartAssessment}>
          <SvgIcon component={StarIcon} />
          <ListItemText className={classes.list}>{isAbleToStartAudit ? translate('startAuditing') : translate('startInspection')}</ListItemText>
        </CustomMenuItem>
      )}

      {(isAbleToContinueAudit || isAbleToContinueInspection) && (
        <CustomMenuItem onClick={handleContinueAssessment}>
          <SvgIcon component={ArrowBackIcon} />
          <ListItemText className={classes.list}>
            {isAbleToContinueAudit ? translate('continueAuditing') : translate('continueInspection')}
          </ListItemText>
        </CustomMenuItem>
      )}
    </Menu>
  );
};