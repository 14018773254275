import { Page } from 'infrastructure/persistence/types';
import { QuestionnaireQuestion, QuestionnaireQuestionDto, toQuestionnaireQuestion } from 'types/QuestionnaireQuestion';

export type AssessmentQuestionDto = QuestionnaireQuestionDto & {
  is_valid_answer: boolean | null;
};

export type AssessmentQuestion = QuestionnaireQuestion & {
  isValidAnswer: boolean | null;
};

export type GetAssessmentQuestionQuery = Page & {
  assessmentId: number;
  sectionId: number;
};

export const toAssessmentQuestion = (dto: AssessmentQuestionDto): AssessmentQuestion => ({
  ...toQuestionnaireQuestion(dto),
  isValidAnswer: dto.is_valid_answer,
});
