import React from 'react';

import { AdminLayout } from '../ui/AdminLayout';
import { PCRequestAssessment as CommonPCRequestAssessment } from '../../common/pcrequest-assessment/PCRequestAssessment';
import { getAdminPath } from '../AdminRoutes';

export const PCRequestAssessment: React.FC = () => {
  return (
    <AdminLayout>
      <CommonPCRequestAssessment getPath={getAdminPath} />
    </AdminLayout>
  );
};
