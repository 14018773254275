import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import i18n from 'i18next';

import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { GeneralInfoDocumentIcon } from 'ui/icons/icons';

import { getReverseDirection as getReverseDirectionAdminQuestionnaireStepTopic } from '../../../utilities/useLanguage';

type AdminQuestionnaireStepTopicProps = {
  topicLabelName: string;
};

const useStyles = makeStyles((theme) => ({
  topic: {
    flip: false,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  topicIcon: {
    flip: false,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(2),
    width: '48px',
    height: '48px',
    fill: 'none',
  },
}));

export const AdminQuestionnaireStepTopic: React.FC<AdminQuestionnaireStepTopicProps> = ({ topicLabelName }) => {
  const classes = useStyles();
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const reverseDirection = getReverseDirectionAdminQuestionnaireStepTopic(i18n.language);

  return (
    <div dir={reverseDirection} className={classes.topic}>
      <Typography variant="h5">{translate(topicLabelName)}</Typography>
      <SvgIcon className={classes.topicIcon} component={GeneralInfoDocumentIcon} viewBox="0 0 48 48" />
    </div>
  );
};
