import React from 'react';
import { Switch, useRouteMatch, Redirect } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { InspectorRoute, RedirectToAllowedRoute } from 'ui/PrivateRoute';
import { Role, TypeOfPlatform } from 'infrastructure/services/auth.type';
import { SAResultContainer } from 'ui/SAResultContainer';

import { SetPassword } from '../common/set-password/SetPassword';
import { InspectorLogin } from './inspector-login/InspectorLogin';
import { InspectorAuthenticate } from './inspector-authenticate/InspectorAuthenticate';
import { REACT_APP_GOOGLE_RECAPCHA_KEY } from '../../infrastructure/core/variables';
import { InspectorDashboard } from './inspector-dashboard/InspectorDashboard';
import { Routes } from '../common/Routes';
import { InspectorEstablishments } from './inspector-establishmens/InspectorEstablishments';
import { InspectorEstablishmentDetails } from './inspector-establishmens/inspector-establishments-details/InspectorEstablishmentDetails';
import { PCRequestList } from './inspector-pcrequests/PCRequestList';
import { PCRequestDetails } from './inspector-pcrequests/inspector-pcrequests-details/PCRequestDetails';
import { PCRequestAssessmentFinished } from '../common/pcrequest-assessment/PCRequestAssessmentFinished';
import { PCRequestAssessment } from './inspector-pcrequest-assessment/PCRequestAssessment';
import { AssessmentCompare } from './inspector-assessment-compare/InspectorAssessmentCompare';
import { getInspectorPath } from './InspectorRoutes';
//import { SelectPlatformButtons } from '../common/select-platform/SelectPlatformButtons';
import { CAPcReviewAnswer } from '../common/pcrequest-assessment/CAPcReviewAnswer';

export const InspectorRouter: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <InspectorRoute exact path={path}>
        <Redirect to={`${path}${Routes.dashboard}`} />;
      </InspectorRoute>

      <InspectorRoute path={`${path}${Routes.dashboard}`}>
        <InspectorDashboard />
      </InspectorRoute>

      {/* <InspectorRoute exact path={path}>
        <Redirect to={`${path}${Routes.selectPlatform}`} />;
      </InspectorRoute>
      <InspectorRoute path={`${path}${Routes.selectPlatform}`}>
        <SelectPlatformButtons getPath={getInspectorPath} />
      </InspectorRoute> */}

      <RedirectToAllowedRoute
        role={Role.Inspector}
        path={`${path}${Routes.login}`}
        redirectPath={`${path}${Routes.authenticate}`}
      >
        <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_GOOGLE_RECAPCHA_KEY || ''}>
          <InspectorLogin />
        </GoogleReCaptchaProvider>
      </RedirectToAllowedRoute>

      <RedirectToAllowedRoute
        role={Role.Inspector}
        path={`${path}${Routes.authenticate}`}
        redirectPath={`${path}${Routes.dashboard}`}
      >
        <InspectorAuthenticate />
      </RedirectToAllowedRoute>

      <InspectorRoute exact path={`${path}${Routes.establishments}`}>
        <InspectorEstablishments />
      </InspectorRoute>

      <InspectorRoute exact path={`${path}${Routes.establishment}/:id`}>
        <InspectorEstablishmentDetails />
      </InspectorRoute>

      <InspectorRoute path={`${path}${Routes.establishment}/:establishmentId${Routes.saResults}/:id${Routes.answers}`}>
        <SAResultContainer />
      </InspectorRoute>

      <InspectorRoute exact path={`${path}${Routes.pcRequests}`}>
        <PCRequestList />
      </InspectorRoute>

      <InspectorRoute exact path={`${path}${Routes.pcRequests}/:pcRequestId`}>
        <PCRequestDetails />
      </InspectorRoute>

      <InspectorRoute exact path={`${path}${Routes.setPassword}`}>
        <SetPassword />
      </InspectorRoute>

      <InspectorRoute
        exact
        path={`${path}${Routes.pcRequests}/:pcRequestId${Routes.assessment}/:assessmentId${Routes.reviewAnswer}`}
      >
        <CAPcReviewAnswer getPath={getInspectorPath} platform={TypeOfPlatform.CA} />
      </InspectorRoute>

      <InspectorRoute
        exact
        path={`${path}${Routes.pcRequests}/:pcRequestId${Routes.assessment}/:assessmentId${Routes.finished}`}
      >
        <PCRequestAssessmentFinished getPath={getInspectorPath} platform={TypeOfPlatform.OSH}/>
      </InspectorRoute>

      <InspectorRoute exact path={`${path}${Routes.pcRequests}/:pcRequestId${Routes.assessment}`}>
        <PCRequestAssessment />
      </InspectorRoute>

      <InspectorRoute exact path={`${path}${Routes.pcRequests}/:pcRequestId${Routes.compare}`}>
        <AssessmentCompare />
      </InspectorRoute>
    </Switch>
  );
};