import { makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
//import SvgIcon from '@material-ui/core/SvgIcon';
//import { useHistory } from 'react-router-dom';

//import { Button } from 'ui/Button';
import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
//import { WinnersIcon, BuildingSafetyIcon } from 'ui/icons/icons';
import FirstSectionImage from 'images/landing-item.svg';

import { CommonLandingProps } from './types/CommonLandingProps';

const useStyles = makeStyles<Theme>((theme) => ({
  firstSection: {
    height: 656,
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    background: theme.palette.common.white,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingInline: 108,
      paddingBlock: 132,
    },
  },
  firstSectionText: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  sectionTopic: {
    marginBottom: theme.spacing(3),
    lineHeight: 1.4,
    fontWeight: 300,
  },
  firstSectionDesc: {
    color: theme.palette.text.secondary,
    lineHeight: 1.4,
    fontWeight: 300,
    marginBottom: 32,
  },
  dFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },

  text: {
    fontSize: '20px',
    color: '#00435F',
    margin: 20,
    marginLeft: '30px',
    width: '100%',
  },
  buttonColor: {
    fontWeight: 700,
    height: '40px',
    width: '195px',
    color: 'white',
    marginLeft: '26px',
    marginBottom: '10px',
  },

  buttonColorHover: {
    height: '40px',
    width: '195px',
    color: '#148287',
    fontWeight: 700,
    border: `1px solid #148287`,
    backgroundColor: 'transparent',
    marginLeft: '26px',
    marginBottom: '10px',
  },
  mainDiv: {
    display: 'flex',
    width: '40%',
    backgroundColor: '#E2F1F1',
    height: '275px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },

  superDiv: {
    display: 'flex',
    gap: '40px',
    marginTop: '50px',
    justifyContent: 'center',
  },
  svgIcon1: {
    display: 'flex',
    height: '200px',
    width: '200px',
    marginLeft: 'auto',
    marginRight: '22px',
    marginBottom: '-37px',
    marginTop: 'auto',
  },
  title: {
    color: 'black',
    fontWeight: 100,
    fontSize: '36px',
    textAlign: 'center',
    marginTop: '65px',
  },

  dec: {
    marginLeft: '28px',
    marginTop: '-32px',
    color: '#00435F',
    fontWeight: 100,
    fontSize: '18px',
    fontFamily: 'sans-serif',
  },
}));

type FirstSectionProps = Omit<CommonLandingProps, 'onClick' | 'onClickBelow'>;


export const LandingFirstSection = React.forwardRef(
  ({ isUpMd }: FirstSectionProps, nextSectionRef: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    const translate = useCreateTranslate(TranslationNamespace.landingPage);

    return (
      <div ref={nextSectionRef} className={classes.firstSection}>
        <div className={classes.firstSectionText}>
          <Typography className={classes.sectionTopic} variant={!isUpMd ? 'h5' : 'h3'}>
            {translate('firstSectionTopic')}
          </Typography>
          <Typography className={classes.firstSectionDesc} variant={!isUpMd ? 'body1' : 'h5'}>
            {translate('firstSectionDesc')}
          </Typography>
        </div>
        <img src={FirstSectionImage} alt="first-section" className={classes.firstSectionImage} />
      </div>
    );
  }
);
// export const LandingFirstSection = React.forwardRef(
//   ({ isUpMd }: FirstSectionProps, nextSectionRef: React.Ref<HTMLDivElement>) => {
//     const classes = useStyles();
//     const translate = useCreateTranslate(TranslationNamespace.landingPage);
//     const [openWinners, setOpenWinners] = useState(false);
//     const [openBuilding, setOpenBuilding] = useState(false);
//     const history = useHistory();
//     const handleOnclick = async (platformId: number) => {
//       history.push('/admin/login');
//     };
//     return (
//       <div style={{ marginBottom: 66 }}>
//         <h3 className={classes.title}>{translate('topic')}</h3>

//         <div className={classes.superDiv}>
//           <div
//             className={classes.mainDiv}
//             onMouseOver={() => setOpenBuilding(true)}
//             onMouseOut={() => setOpenBuilding(false)}
//           >
//             <div className={classes.dFlex}>
//               <h3 className={classes.text}> {translate('firstSectionTopic')} </h3>
//               <p className={classes.dec}>{translate('firstSectionDesc')} </p>
//               <Button
//                 onClick={() => handleOnclick(1)}
//                 variant="contained"
//                 color="secondary"
//                 size="large"
//                 className={openBuilding ? classes.buttonColor : classes.buttonColorHover}
//               >
//                 {translate('startNow')}
//               </Button>
//             </div>
//             <SvgIcon className={`${classes.svgIcon1}`} component={BuildingSafetyIcon} viewBox="0 0 245 260" />
//           </div>

//           <div
//             className={classes.mainDiv}
//             onMouseOver={() => setOpenWinners(true)}
//             onMouseOut={() => setOpenWinners(false)}
//           >
//             <div className={classes.dFlex}>
//               <h3 className={classes.text}> {translate('caSectionTopic')} </h3>
//               <p className={classes.dec}>{translate('caSectionDesc')} </p>

//               <Button
//                 onClick={() => handleOnclick(2)}
//                 variant="contained"
//                 color="secondary"
//                 size="large"
//                 className={openWinners ? classes.buttonColor : classes.buttonColorHover}
//               >
//                 {translate('startNow')}
//               </Button>
//             </div>

//             <SvgIcon className={`${classes.svgIcon1}`} component={WinnersIcon} viewBox="0 0 245 260" />
//           </div>
//         </div>
//       </div>
//     );
//   }
// );
