import React from 'react';

import { useAppSelector } from 'infrastructure/store/rootStore.hooks';
import { selectRole } from 'infrastructure/services/authSlice.selector';
import { isAuditor as isAuditorCheck } from 'infrastructure/services/auth.utils';

import { getAuditorPath } from '../AuditorRoutes';
import { Authenticate } from '../../common/authenticate/Authenticate';

export const AuditorAuthenticate: React.FunctionComponent = () => {
  const isAuditor = isAuditorCheck(useAppSelector(selectRole));
  return (
    <Authenticate
      isUser={isAuditor}
      getPath={getAuditorPath}
      login={'auditor'}
      googleAuthenticatorDescription={'googleAuthenticatorDescription'}
    />
  );
};
