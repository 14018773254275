import { makeStyles } from '@material-ui/core';
import React from 'react';
import Paper from '@material-ui/core/Paper';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';

import { NoUsersIcon } from 'ui/icons/icons';
import { shdShadow } from 'ui/styles';
import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';


const useStyles = makeStyles((theme) => ({
  noUsers: {
    padding: '95px 0',
    boxShadow: shdShadow,
    textAlign: 'center',
    marginTop: '50px',
  },

  noUsersIcon: {
    height: '216px',
    width: 'auto',
    fill: 'none',
  },

  title: {
    marginTop: '34px',
    fontWeight: 'bold',
    fontSize: '30px',
    lineHeight: '20px',
    letterSpacing: '0.14px',
  },

  titleCaption: {
    marginTop: theme.spacing(5),
  },
}));

type UsersSearchProps = {
  userSearchNotFound: string;
};

export const UsersTableNoData: React.FC<UsersSearchProps> = ({ userSearchNotFound }) => {
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const classes = useStyles();

  return (
    <Paper data-testid="template-table-no-data" className={classes.noUsers}>
      <SvgIcon className={classes.noUsersIcon} component={NoUsersIcon} viewBox="0 0 375 216" />
      <Typography  className={classes.title}>{translate('noUsersTitle')}</Typography>
      <Typography  className={classes.title}>{translate('notFoundUser', { userSearchNotFound: `${userSearchNotFound}` })}</Typography>
      <Typography className={classes.titleCaption} variant="body2">
        {translate('noUsersDes') }
      </Typography>

    </Paper>
  );
};
