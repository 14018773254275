import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { REACT_APP_JIRA_API_URL, REACT_APP_JIRA_TOKEN } from 'infrastructure/core/variables';

import { TicketDto, ResponseTicket } from '../types/Info';

export const jiraApi = createApi({
  reducerPath: 'jiraApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${REACT_APP_JIRA_API_URL}/api/ticket/support/osh/create`,
    prepareHeaders: (headers) => {
      headers.set('authorization', `Bearer ${REACT_APP_JIRA_TOKEN}`);
      return headers;
    },
  }),
  endpoints: (build) => ({
    createTicket: build.mutation<ResponseTicket, TicketDto>({
      query: ({ formData }) => ({
        url: ``,
        method: 'POST',
        body: formData,
      }),
      transformResponse: (response: ResponseTicket) => response,
    }),
  }),
});

export const { useCreateTicketMutation } = jiraApi;
