import FrutigerLight from './FrutigerLTArabic-45Light.woff';
import FrutigerRegular from './FrutigerLTArabic-55Roman.woff';
import FrutigerBold from './FrutigerLTArabic-65Bold.woff';
import EffraRegular from './Effra_Std_Rg.woff2';
import EffraBold from './Effra_Std_Bd.woff2';

export const FRUTIGER_FAMILY_NAME = 'Frutiger';
export const EFFRA_FAMILY_NAME = 'Effra';

export const frutigerLight = {
  fontFamily: FRUTIGER_FAMILY_NAME,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `url(${FrutigerLight}) format('woff')`,
};

export const frutigerRegular = {
  fontFamily: FRUTIGER_FAMILY_NAME,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${FrutigerRegular}) format('woff')`,
};

export const frutigerBold = {
  fontFamily: FRUTIGER_FAMILY_NAME,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `url(${FrutigerBold}) format('woff')`,
};

export const effraRegular = {
  fontFamily: FRUTIGER_FAMILY_NAME,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${EffraRegular}) format('woff2')`,
};

export const effraBold = {
  fontFamily: FRUTIGER_FAMILY_NAME,
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `url(${EffraBold}) format('woff2')`,
};
