import React from 'react';

import { AuditorLayout } from '../ui/AuditorLayout';
import { PCRequestList as CommonPCRequestList } from '../../common/pcrequests/PCRequestList';
import { getAuditorPath } from '../AuditorRoutes';

export const PCRequestList: React.FC = () => {
  return (
    <AuditorLayout>
      <CommonPCRequestList getPath={getAuditorPath} />
    </AuditorLayout>
  );
};
