import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';

import { AssessmentSection } from 'types/AssessmentSection';

import { PCRequestAssessmentSection } from '././PCRequestAssessmentSection';
import { PcRequestAssessmentInfo } from './types/PcRequestAssessmentInfo';

type PCRequestAssessmentSectionsProps = {
  setCurrentSectionId: React.Dispatch<React.SetStateAction<number>>;
  sections: AssessmentSection[];
  pcAssessmentInfo: PcRequestAssessmentInfo;
};

const useStyle = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
}));

export const PCRequestAssessmentSections: React.FC<PCRequestAssessmentSectionsProps> = ({
  setCurrentSectionId,
  sections,
  pcAssessmentInfo,
}) => {
  const classes = useStyle();

  const onSectionFocusFn = (id: number) => () => {
    setCurrentSectionId(id);
  };

  return (
    <Paper className={classes.main}>
      {sections.map((section, index) => (
        <PCRequestAssessmentSection
          onFocus={onSectionFocusFn(section.id)}
          key={`pc-request-assessment-section-${section.id}`}
          sectionNumber={index + 1}
          sectionsCount={sections.length}
          section={section}
          pcAssessmentInfo={pcAssessmentInfo}
        />
      ))}
    </Paper>
  );
};
