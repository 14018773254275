import React from 'react';

import { InspectorLayout } from '../ui/InspectorLayout';
import { PCRequestList as CommonPCRequestList } from '../../common/pcrequests/PCRequestList';
import { getInspectorPath } from '../InspectorRoutes';

export const PCRequestList: React.FC = () => {
  return (
    <InspectorLayout>
      <CommonPCRequestList getPath={getInspectorPath} />
    </InspectorLayout>
  );
};
