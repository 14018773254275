import React, { useEffect } from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';

import { BriefcaseIcon } from 'ui/icons/icons';
import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import ThirdSectionImage from 'images/SA.svg';

import { TileData } from './types/Tile';
import { useGetInfoQuery } from './api/landingPage.api';
import { Tiles } from './Tiles';
import { CommonLandingProps } from './types/CommonLandingProps';
import { LandingPageButtons } from './LandingPageButtons';

const useStyles = makeStyles<Theme>((theme) => ({
  sectionTopic: {
    marginBottom: theme.spacing(3),
    lineHeight: 1.4,
    fontWeight: 300,
  },
  sectionDesc: {
    lineHeight: 1.4,
    fontWeight: 300,
  },
  sectionText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 32,
    [theme.breakpoints.up('md')]: {
      maxWidth: 600,
      marginBottom: 64,
    },
  },
  button: {
    color: theme.palette.common.white,
    width: '100%',
    height: 50,
    [theme.breakpoints.up('md')]: {
      width: 185,
    },
  },
  thirdSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingInline: theme.spacing(2),
    paddingBlock: theme.spacing(5),
    background: theme.palette.common.white,
    [theme.breakpoints.up('md')]: {
      paddingBlock: 132,
      paddingInline: 108,
      backgroundImage: `url(${ThirdSectionImage})`,
      backgroundPosition: 'center',
      backgroundSize: 'calc(100% - 216px)',
      backgroundRepeat: 'no-repeat',
    },
  },
  thirdSectionText: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  thirdSectionTopic: {
    marginBottom: theme.spacing(6),
    lineHeight: 1.4,
    fontWeight: 300,
    [theme.breakpoints.up('md')]: {
      fontSize: 40,
    },
  },
  thirdSectionNumber: {
    fontWeight: 700,
  },
  thirdSectionNumberDesc: {
    marginTop: theme.spacing(1),
    marginInline: theme.spacing(2),
    fontSize: 14,
    fontWeight: 300,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      width: 184,
      top: 80,
      fontSize: 18,
    },
  },
  thirdSectionSteps: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: -theme.spacing(2),
    marginInline: -theme.spacing(2),
    marginBottom: 24,
    backgroundImage: `url(${ThirdSectionImage})`,
    backgroundPosition: 'center',
    backgroundSize: 686,
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('md')]: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 0,
      marginInline: 0,
      marginBottom: 104,
      paddingInline: 64,
      backgroundImage: 'none',
    },
  },
  thirdSectionStep: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBlock: theme.spacing(2),
    position: 'relative',
  },
  tileWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'center',
      marginBottom: 132,
    },
  },
  line: {
    width: '100%',
    height: 1,
    borderTop: `2px dashed ${theme.palette.primary.main}`,
    margin: 0,
    marginInline: theme.spacing(2),
  },
}));

type ThirdSectionProps = Omit<CommonLandingProps, 'onClickBelow'>;

const stepKeys = ['firstStep', 'secondStep', 'thirdStep', 'fourthStep', 'fifthStep'];

export const LandingThirdSection = React.forwardRef(
  ({ isUpMd, onClick }: ThirdSectionProps, ref: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    const translate = useCreateTranslate(TranslationNamespace.landingPage);
    const [tileData, setTileData] = React.useState<TileData[] | null>(null);
    const { data } = useGetInfoQuery();

    useEffect(() => {
      if (data) {
        setTileData([
          {
            num: data.assessment,
            text: translate('sentTemplate'),
            icon: DescriptionIcon,
          },
          {
            num: data.establishment,
            text1: translate('registeredEstablishment'),
            icon: BriefcaseIcon,
            viewbox: '0 0 40 40',
          },
        ]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, setTileData]);

    return (
      <div ref={ref} className={classes.thirdSection}>
        <div className={classes.thirdSectionText}>
          <Typography className={classes.thirdSectionTopic} variant={!isUpMd ? 'h5' : 'h3'}>
            {translate('thirdSectionTopic')}
          </Typography>

          {tileData && (
            <div className={classes.tileWrapper}>
              <Tiles tileData={tileData} />
            </div>
          )}
        </div>

        <div className={classes.sectionText}>
          <Typography className={classes.sectionTopic} variant={!isUpMd ? 'h5' : 'h3'}>
            {translate('thirdSectionQuestion')}
          </Typography>
          <Typography className={classes.sectionDesc} variant={!isUpMd ? 'body1' : 'h5'}>
            {translate('thirdSectionAnswer')}
          </Typography>
        </div>

        <div className={classes.thirdSectionSteps}>
          {stepKeys.map((key, index) => (
            <React.Fragment key={key}>
              <div className={classes.thirdSectionStep}>
                <Typography className={classes.thirdSectionNumber} variant="h2">
                  0{index + 1}
                </Typography>
                <Typography className={classes.thirdSectionNumberDesc} variant="subtitle1">
                  {translate(key)}
                </Typography>
              </div>
              {isUpMd && index !== stepKeys.length - 1 && <hr className={classes.line} />}
            </React.Fragment>
          ))}
        </div>
        <LandingPageButtons onClick={onClick} />
      </div>
    );
  }
);
