import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import i18n from 'i18next';

import { SectionHeaderTopic, SectionHeaderTopicProps } from '../../../ui/assessment/SectionHeaderTopic';
import { AssessmentSection } from '../../../types/AssessmentSection';
import { CompanyRepresentativeSAResultQuestion } from './CompanyRepresentativeSAResultQuestion';
import {
  useGetAnswersByAssessmentIdAndSectionIdQuery,
  useGetQuestionsByAssessmentIdAndSectionIdQuery,
} from '../api/companyRepresentative.api';
import { getReverseDirection as getReverseDirectionForCompanyRepresentativeSAResultSection } from '../../../utilities/useLanguage';

type ResultSectionProps = {
  assessmentId: number;
  section: AssessmentSection;
} & Pick<SectionHeaderTopicProps, 'sectionNumber' | 'sectionsCount'>;

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(4),
    padding: '40px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  header: {
    padding: '0 32px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  headerTopicWrapper: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(3),
    alignSelf: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  questions: {
    marginTop: theme.spacing(5),
    alignSelf: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
}));

export const CompanyRepresentativeSAResultSection: React.FC<ResultSectionProps> = ({
  assessmentId,
  section,
  sectionNumber,
  sectionsCount,
}) => {
  const classes = useStyles();
  const { data: { results: questions } = { count: 0, results: [] }, isLoading: isLoadingQuestions } =
    useGetQuestionsByAssessmentIdAndSectionIdQuery({
      assessmentId,
      sectionId: section.id,
    });
  const { data: { results: answers } = { count: 0, results: [] }, isLoading: isLoadingAnswers } =
    useGetAnswersByAssessmentIdAndSectionIdQuery({
      assessmentId,
      assessmentSectionId: section.id,
    });

  const findAnswerByQuestionId = (currentQuestionId: number) => {
    const answer = answers.find(({ questionId }) => questionId === currentQuestionId);

    if (!answer) {
      throw Error(`Answer for question[${currentQuestionId}] is missing`);
    }

    return answer;
  };
  const reverseDirection = getReverseDirectionForCompanyRepresentativeSAResultSection(i18n.language);

  return (
    <Paper dir={reverseDirection} className={classes.main}>
      <div>
        <div className={classes.headerTopicWrapper}>
          <SectionHeaderTopic
            sectionNumber={sectionNumber}
            sectionsCount={sectionsCount}
            nameAR={section.nameAR}
            nameEN={section.nameEN}
            descriptionAR={section.descriptionAR}
            descriptionEN={section.descriptionEN}
          />
        </div>
      </div>
      <div className={classes.questions}>
        {!isLoadingAnswers &&
          !isLoadingQuestions &&
          questions.map((question, index) => (
            <CompanyRepresentativeSAResultQuestion
              key={`result-question-${question.id}`}
              questionNo={index + 1}
              question={question}
              answer={findAnswerByQuestionId(question.id)}
            />
          ))}
      </div>
    </Paper>
  );
};
