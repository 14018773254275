import { useSnackbar } from 'notistack';

import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { DEFAULT_ERROR_CODE } from 'infrastructure/persistence/baseQueryWithReAuth';

import { NotificationType } from './NotificationType.enum';

type ErrorData = {
  errorCode: string;
};

type MessageData = {
  errorId?: string | ErrorData;
  message?: string;
};

export const useNotifications = () => {
  const { enqueueSnackbar } = useSnackbar();
  const translate = useCreateTranslate(TranslationNamespace.errorMessages);

  return (
    status: NotificationType,
    { errorId = DEFAULT_ERROR_CODE, message }: MessageData = { errorId: DEFAULT_ERROR_CODE }
  ) => {
    const errorCode = typeof errorId === 'string' ? errorId : errorId?.errorCode;
    const notificationMessage = message || translate(errorCode);

    enqueueSnackbar(
      {
        status,
        message: notificationMessage,
      },
      {
        persist: false,
      }
    );
  };
};
