import jwt_decode, { JwtPayload } from 'jwt-decode';

import {
  TokenResponse,
  Role,
  RefreshTokenResponse,
  UserData,
  UserTowFactorResponse,
  UserTowFactorInfo,
  InvitationConfirmationResponse,
  CheckInvitationResponse,
} from './auth.type';
import { session, SessionStorageKey } from './storage';

export const processTokenResponse = ({ access, refresh, account_type, show_platform }: TokenResponse): UserData => {
  const { exp } = jwt_decode<JwtPayload>(access);
  const { exp: refreshExp } = jwt_decode<JwtPayload>(refresh);

  session.setItem(SessionStorageKey.refreshKey, refresh);
  session.setItem(SessionStorageKey.expiryKey, `${(refreshExp || 0) * 1000}`);
  localStorage.setItem('accessToken', access);

  return {
    tokenData: { token: access, expiry: (exp || 0) * 1000 },
    role: account_type,
    showPlatform: show_platform,
  };
};

export const processAuthenticateResponse = ({
  email,
  first_login,
  google_authenticator_token,
  google_authenticator_qr_image,
}: UserTowFactorResponse): UserTowFactorInfo => {
  return {
    email: email,
    firstLogin: first_login,
    googleAuthenticatorToken: google_authenticator_token,
    googleAuthenticatorQRImage: google_authenticator_qr_image,
  };
};

export const processConfirmationResponse = ({
  link,
  confirmed,
}: InvitationConfirmationResponse): InvitationConfirmationResponse => {
  return {
    link: link,
    confirmed: confirmed,
  };
};

export const processCheckInvitationResponse = ({
  validate_success,
  confirmed,
}: CheckInvitationResponse): CheckInvitationResponse => {
  return {
    validate_success: validate_success,
    confirmed: confirmed,
  };
};

export const processRefreshTokenResponse = ({ access, account_type }: RefreshTokenResponse): UserData => {
  const { exp } = jwt_decode<JwtPayload>(access);

  return {
    tokenData: { token: access, expiry: (exp || 0) * 1000 },
    role: account_type,
    showPlatform: false,
  };
};

export const isAdmin = (role: Role | null) => role === Role.Admin;
export const isInspector = (role: Role | null) => role === Role.Inspector;
export const isAuditor = (role: Role | null) => role === Role.Auditor;

export const isCompanyRepresentative = (role: Role | null) => role === Role.CompanyRepresentative;
