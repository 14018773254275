import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import i18n from 'i18next';

import { CollapseButton } from 'ui/buttons/CollapseButton';
import {
  AssessmentSectionHeaderContent,
  AssessmentSectionHeaderContentProps,
} from 'ui/assessment/AssessmentSectionHeaderContent';
import { useOnScreen } from 'utilities/useOnScreen.hook';
import { collapseButtonSpaceWidth } from 'ui/styles';

import { getReverseDirection as getReverseDirectionForPCRequestAssessmentSectionHeader } from '../../../utilities/useLanguage';

type PCRequestAssessmentSectionHeaderProps = AssessmentSectionHeaderContentProps & {
  isOpen: boolean;
  handleCollapse: () => void;
  onFocus: () => void;
};

const useStyle = makeStyles<Theme, Pick<PCRequestAssessmentSectionHeaderProps, 'isOpen'>>((theme) => ({
  wrapperBase: {
    flip: false,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '32px',
  },

  wrapper: {
    borderBottom: ({ isOpen }) => (isOpen ? `1px solid ${theme.palette.grey[200]}` : 'none'),
  },

  collapseButtonSpace: {
    flip: false,
    flexShrink: 0,
    width: collapseButtonSpaceWidth,
  },
}));

export const PCRequestAssessmentSectionHeader: React.FC<PCRequestAssessmentSectionHeaderProps> = ({
  isOpen,
  handleCollapse,
  onFocus,
  ...contentProps
}) => {
  const classes = useStyle({ isOpen });

  const ref = React.useRef(null);
  const isVisible = useOnScreen(ref);

  React.useEffect(() => {
    if (isVisible) {
      onFocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);
  const reverseDirection = getReverseDirectionForPCRequestAssessmentSectionHeader(i18n.language);

  return (
    <div dir={reverseDirection} ref={ref} className={clsx(classes.wrapperBase, classes.wrapper)}>
      <div className={classes.collapseButtonSpace}>
        <CollapseButton onClick={handleCollapse} />
      </div>
      <AssessmentSectionHeaderContent {...contentProps} />
    </div>
  );
};
