import React from 'react';

import { EstablishmentDetails as CommonEstablishmentDetails } from '../../../common/establishments/establishments-details/EstablishmentDetails';
import { AuditorLayout } from '../../ui/AuditorLayout';
import { getAuditorPath } from '../../AuditorRoutes';

export const AuditorEstablishmentDetails: React.FC = () => {
  return (
    <AuditorLayout>
      <CommonEstablishmentDetails getPath={getAuditorPath} />
    </AuditorLayout>
  );
};
