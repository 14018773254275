import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import i18n from 'i18next';

import { AssessmentAnswer } from 'types/AssessmentAnswer';
import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { AssessmentType } from 'types/Assessment';

import { PCRequestAssessmentPreviousAnswererSelect } from '../../admin/ui/PCRequestAssessmentPreviousAnswererSelect';
import { PCRequestAssessmentPreviousAnswerContent } from '../../admin/ui/PCRequestAssessmentPreviousAnswerContent';
import { getDirection } from '../../../utilities/useLanguage';

type PCRequestAssessmentPreviousAnswerProps = {
  crAnswer: AssessmentAnswer;
  auditorAnswer?: AssessmentAnswer;
  currentAssessmentType: AssessmentType;
};

const useStyle = makeStyles((theme) => ({
  main: {
    flip: false,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '32px 72px 40px 60px',
    backgroundColor: theme.palette.grey[200],
    flex: '1',
  },

  answerer: {
    marginTop: theme.spacing(4),
  },

  answer: {
    marginTop: theme.spacing(3),
  },
}));

export const PCRequestAssessmentPreviousAnswer: React.FC<PCRequestAssessmentPreviousAnswerProps> = ({
  crAnswer,
  auditorAnswer,
  currentAssessmentType,
}) => {
  const [answerer, setAnswerer] = useState(AssessmentType.COMPANY_REPRESENTATIVE);
  const [answer, setAnswer] = useState<AssessmentAnswer | undefined>(crAnswer);

  const classes = useStyle();
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const direction = getDirection(i18n.language);

  const changeAnswerer = (value5: AssessmentType) => {
    if (value5 !== answerer) {
      setAnswerer(value5);
      setAnswer(value5 === AssessmentType.COMPANY_REPRESENTATIVE ? crAnswer : auditorAnswer);
    }
  };

  const answerers =
    currentAssessmentType === AssessmentType.INSPECTOR
      ? [AssessmentType.COMPANY_REPRESENTATIVE, AssessmentType.AUDITOR]
      : [AssessmentType.COMPANY_REPRESENTATIVE];

  return (
    <div dir={direction} className={classes.main}>
      <Typography variant="h6">{translate('seePreviousAnswers')}</Typography>
      <PCRequestAssessmentPreviousAnswererSelect
        answerers={answerers}
        selectedAnswerer={answerer}
        selectKey={`selectAnswerer-${crAnswer.id}`}
        changeAnswerer={changeAnswerer}
      />
      <PCRequestAssessmentPreviousAnswerContent answer={answer} answerer={answerer} />
    </div>
  );
};
