import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import i18n from 'i18next';

import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';

import { AssessmentTemplate } from '../../types/AssessmentTemplate';
import { QuestionnaireReviewContainer } from './QuestionnaireReviewContainer';

type QuestionnaireReviewGeneralInfoProps = {
  setStepIndex: React.Dispatch<React.SetStateAction<number>>;
  questionnaire: AssessmentTemplate;
  isEditable: boolean;
};

const useStyles = makeStyles((theme) => ({
  body2: {
    opacity: '0.6',
  },
  infoWrapper: {
    marginTop: theme.spacing(8),
    marginLeft: theme.spacing(1),
  },
  itemWrapper: {
    marginTop: theme.spacing(2),
  },
}));

export const QuestionnaireReviewGeneralInfo: React.FC<QuestionnaireReviewGeneralInfoProps> = ({
  setStepIndex,
  questionnaire,
  isEditable,
}) => {
  const stepIndex = 0;
  const classes = useStyles();
  const translateQuestionnaire = useCreateTranslate(TranslationNamespace.admin);

  return (
    <QuestionnaireReviewContainer
      topicLabelName="generalInfo"
      setStepIndex={setStepIndex}
      stepIndex={stepIndex}
      isEditable={isEditable}
    >
      <div className={classes.infoWrapper}>
        <div className={classes.itemWrapper}>
          <Typography variant="body2" className={classes.body2}>
            {translateQuestionnaire('versionName')}
          </Typography>
          <Typography variant="h6">
            {i18n.language === 'en' ? questionnaire?.nameEN : questionnaire?.nameAR}
          </Typography>

        </div>
        <div className={classes.itemWrapper}>
          <Typography variant="body2" className={classes.body2}>
            {translateQuestionnaire('economicActivity')}
          </Typography>
          <Typography variant="h6">{questionnaire.economicActivity.nameAr}</Typography>
        </div>
      </div>
    </QuestionnaireReviewContainer>
  );
};
