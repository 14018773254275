import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Collapse from '@material-ui/core/Collapse';

import { AssessmentSection } from 'types/AssessmentSection';

import { PCRequestAssessmentSectionHeader } from './PCRequestAssessmentSectionHeader';
import { PCRequestAssessmentQuestions } from './PCRequestAssessmentQuestions';
import { getAssessmentSectionId } from './utils/getAssessmentSectionId';
import { PcRequestAssessmentInfo } from '././types/PcRequestAssessmentInfo';

type PCRequestAssessmentSectionProps = {
  onFocus: () => void;
  section: AssessmentSection;
  sectionsCount: number;
  sectionNumber: number;
  pcAssessmentInfo: PcRequestAssessmentInfo;
};

const useStyle = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const PCRequestAssessmentSection: React.FC<PCRequestAssessmentSectionProps> = ({
  onFocus,
  section,
  sectionsCount,
  sectionNumber,
  pcAssessmentInfo,
}) => {
  const classes = useStyle();
  const [isOpen, setIsOpen] = React.useState(false);

  const handleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div id={getAssessmentSectionId(section.id)} className={classes.main}>
      <PCRequestAssessmentSectionHeader
        isOpen={isOpen}
        handleCollapse={handleCollapse}
        section={section}
        sectionNumber={sectionNumber}
        sectionsCount={sectionsCount}
        onFocus={onFocus}
      />
      <Collapse in={isOpen} mountOnEnter unmountOnExit>
        <PCRequestAssessmentQuestions pcAssessmentInfo={pcAssessmentInfo} sectionId={section.id} />
      </Collapse>
    </div>
  );
};
