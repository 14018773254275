import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import { Theme } from '@material-ui/core';
import clsx from 'clsx';

import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { CheckGreenIcon } from 'ui/icons/icons';
import { labelCardColor } from 'ui/styles';
import { AssessmentSectionPropTypes } from 'types/AssessmentSectionPropTypes';

import { SectionHeaderTopic } from './SectionHeaderTopic';

export type AssessmentSectionHeaderContentProps = Pick<
  AssessmentSectionPropTypes,
  'sectionsCount' | 'sectionNumber' | 'section'
>;

const useStyles = makeStyles<Theme, { isVisible: boolean }>((theme) => ({
  header: {
    flip: false,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px 32px',
    flex: 1,
  },

  completedSection: {
    flip: true,
    flexShrink: 0,
    padding: 0,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },

  checkIcon: {
    marginRight: '12px',
    marginLeft: '12px',
    flip: false,
  },

  visibleIcon: {
    visibility: ({ isVisible }) => (isVisible ? 'visible' : 'hidden'),
  },

  values: {
    flip: true,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },

  valuesText: {
    flexShrink: 0,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },

  topic: {
    flip: true,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
    marginRight: theme.spacing(3),
  },

  typography: {
    marginTop: theme.spacing(1),
  },

  description: {
    color: labelCardColor,
  },
}));

export const AssessmentSectionHeaderContent: React.FC<AssessmentSectionHeaderContentProps> = ({
  sectionsCount,
  sectionNumber,
  section: { questionsNo, validAnswersNo, nameAR, nameEN, descriptionAR, descriptionEN },
}) => {
  const classes = useStyles({ isVisible: questionsNo === validAnswersNo });
  const translateSA = useCreateTranslate(TranslationNamespace.companyRepresentative);

  return (
    <div className={classes.header}>
      <div className={`${classes.header} ${classes.completedSection}`}>
        <SvgIcon className={clsx(classes.checkIcon, classes.visibleIcon)} component={CheckGreenIcon} />
        <Typography className={classes.values} variant="h6">{`${validAnswersNo}/${questionsNo}`}</Typography>
        <Typography className={classes.valuesText} variant="body1">
          {translateSA('completedQuestions')}
        </Typography>
      </div>
      <SectionHeaderTopic
        sectionNumber={sectionNumber}
        sectionsCount={sectionsCount}
        nameAR={nameAR}
        nameEN={nameEN}
        descriptionAR={descriptionAR}
        descriptionEN={descriptionEN}
      />
    </div>
  );
};
