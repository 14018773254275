export enum Routes {
  login = '/login',
  authenticate = '/authenticate',
  dashboard = '/dashboard',
  questionnaire = '/assessment',
  establishments = '/establishments',
  establishment = '/establishment',
  answers = '/answers',
  saResults = '/sa-results',
  pcRequests = '/pc-requests',
  assessment = '/assessment',
  finished = '/finished',
  users = '/users',
  setPassword = '/set-password',
  compare = '/compare',
  selectPlatform = '/selectPlatform',
  reviewAnswer = '/review-answer',
}