import { Container, makeStyles, TextField, Typography } from '@material-ui/core';
import { useForm, SubmitHandler, Controller, FieldError } from 'react-hook-form';
import React, { useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { Button } from 'ui/Button';
import { shdShadow } from 'ui/styles';
import { ConfirmationInvitationCredentials } from 'infrastructure/services/auth.type';
import { useAppDispatch, useAppSelector } from 'infrastructure/store/rootStore.hooks';
import {
  selectUserConfirmation,
  selectIsInvitationConfirmationProgress,
  selectInvitationConfirmationError,
  selectCheckInvitation,
  selectCheckInvitationError,
  selectIsCheckInvitationProgress,
} from 'infrastructure/services/authSlice.selector';
import { checkInvitation, confirmationInvitation } from 'infrastructure/services/auth.api';

import { PublicLayout } from '../common/ui/PublicLayout';
import {
  passwordRegex,
  upperCaseRegex,
  lowerCaseRegex,
  symbolCaseRegex,
  oneNumberRegex,
} from '../../infrastructure/constants/regex.constants';
import { Routes } from '../../infrastructure/routes';
import { ValidationPassword } from './ValidationPassword';

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    width: theme.breakpoints.values.md,
    maxWidth: theme.breakpoints.values.md,
    padding: '40px 120px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: shdShadow,
    marginTop: theme.spacing(6),
  },

  title: {
    marginBottom: theme.spacing(1),
    textAlign: 'center',
  },

  description: {
    marginBottom: theme.spacing(5),
    textAlign: 'center',
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
  },
  input: {
    marginBottom: '32px',
  },

  invalidCredentials: {
    textAlign: 'justify',
  },

  continueButton: {
    height: '50px',
    marginTop: theme.spacing(4),
  },
  field: {
    marginTop: theme.spacing(4),
  },
  valid: {
    color: 'green',
  },
  invalid: {
    color: 'grey',
  },
}));

export const ConfirmationPage = () => {
  const dispatch = useAppDispatch();
  const { formId } = useParams<{ formId: string }>();

  const translate = useCreateTranslate(TranslationNamespace.confirmation);
  const translateCommon = useCreateTranslate(TranslationNamespace.common);
  const classes = useStyles();

  const isCheckInvitationProgress = useAppSelector(selectIsCheckInvitationProgress);
  const checkInvitationError = useAppSelector(selectCheckInvitationError);
  const checkInvitationResponse = useAppSelector(selectCheckInvitation);

  const isInvitationConfirmationProgress = useAppSelector(selectIsInvitationConfirmationProgress);
  const invitationConfirmationError = useAppSelector(selectInvitationConfirmationError);
  const userConfirmation = useAppSelector(selectUserConfirmation);

  const [checks, setChecks] = useState({
    upperCase: false,
    lowerCase: false,
    oneNumber: false,
    characterLength: false,
    symbol: false,
  });
  const [password, setPassword] = useState(false);

  if (!isCheckInvitationProgress && !checkInvitationResponse && !checkInvitationError) {
    dispatch(checkInvitation(formId));
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ConfirmationInvitationCredentials>({
    mode: 'onTouched',
  });

  const getHelperText = (error?: FieldError) => {
    if (error) {
      if (error.type === 'required') {
        return translateCommon('fieldRequired');
      }
      return error.message;
    }
    return ' ';
  };

  const onSubmit: SubmitHandler<ConfirmationInvitationCredentials> = async (data) => {
    let newData = { ...data };
    newData.form_id = formId;
    dispatch(confirmationInvitation(newData));
  };

  if (!checkInvitationResponse && checkInvitationError && !isCheckInvitationProgress) {
    return <Redirect to={Routes.NOT_FOUND} />;
  }

  if (userConfirmation) {
    return <Redirect to={userConfirmation.link} />;
  }

  const handelOnFocus = () => {
    setPassword(true);
  };

  const handleOnKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { value } = e.target as HTMLInputElement;
    const oneNumber = oneNumberRegex.test(value);
    const symbol = symbolCaseRegex.test(value);
    const characterLength = value.length >= 8;
    const lowerCase = lowerCaseRegex.test(value);
    const upperCase = upperCaseRegex.test(value);

    setChecks({
      oneNumber,
      symbol,
      characterLength,
      upperCase,
      lowerCase,
    });
  };

  return (
    <PublicLayout>
      {checkInvitationResponse && (
        <Container className={classes.formWrapper}>
          <Typography variant="h4" className={classes.title}>
            {translate('confirmation')}
          </Typography>
          <Typography variant="body1" className={classes.description}>
            {translate('description')}
          </Typography>

          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              name="password"
              rules={{
                required: true,
                pattern: {
                  value: passwordRegex,
                  message: translate('enterYourPassword'),
                },
              }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  type="password"
                  variant="outlined"
                  className={classes.field}
                  error={invalid}
                  helperText={getHelperText(error)}
                  label={translate('password')}
                  onFocus={handelOnFocus}
                  onKeyUp={handleOnKeyUp}
                />
              )}
            />
            <Controller
              control={control}
              name="repassword"
              rules={{
                required: true,
              }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  type="password"
                  variant="outlined"
                  className={classes.field}
                  error={invalid}
                  helperText={getHelperText(error)}
                  label={translate('confirmationPassword')}
                />
              )}
            />

            <div className={classes.invalidCredentials}>
              {invitationConfirmationError && (
                <Typography variant="caption" color="error">
                  {translate('invalidCredentials')}
                </Typography>
              )}
            </div>

            {password ? (
              <ValidationPassword
                upperCaseFlag={checks.upperCase ? classes.valid : classes.invalid}
                lowerCaseFlag={checks.lowerCase ? classes.valid : classes.invalid}
                characterLengthFlag={checks.characterLength ? classes.valid : classes.invalid}
                symbolFlag={checks.symbol ? classes.valid : classes.invalid}
                oneNumberFlag={checks.oneNumber ? classes.valid : classes.invalid}
              />
            ) : null}

            <Button
              className={classes.continueButton}
              type="submit"
              disabled={!errors || isInvitationConfirmationProgress}
              variant="contained"
              color="secondary"
            >
              {translateCommon('continue')}
            </Button>
          </form>
        </Container>
      )}
    </PublicLayout>
  );
};
