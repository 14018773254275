import React from 'react';

import { AdminLayout } from '../ui/AdminLayout';
import { PCRequestList as CommonPCRequestList } from '../../common/pcrequests/PCRequestList';
import { getAdminPath } from '../AdminRoutes';

export const PCRequestList: React.FC = () => {
  return (
    <AdminLayout>
      <CommonPCRequestList getPath={getAdminPath} />
    </AdminLayout>
  );
};
