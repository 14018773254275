import makeStyles from '@material-ui/core/styles/makeStyles';
import { ListItemText, Menu, SvgIcon } from '@material-ui/core';
import React from 'react';

import { TranslationNamespace } from 'i18n/config';
import { CustomMenuItem } from 'ui/CustomMenuItem';
import { useCreateTranslate } from 'utilities/translate.hook';
import { CrossCloseGreyIcon, EditIcon, inviteUserIcon, TowfactorIcon } from 'ui/icons/icons';

import { User, UserAccountStatus } from '../../../types/User';
import { Role } from '../../../infrastructure/services/auth.type';

type UsersTableMenuDialogProps = {
  anchorEl: HTMLElement;
  handleCloseMenu: () => void;
  handleDeactivate: () => void;
  handleEditUser: () => void;
  handleInviteUser: () => void;
  handleTwoFactorAuthentication: () => void;
  user: null | User;
};

const useStyle = makeStyles((theme) => ({
  list: {
    marginLeft: 6,
  },
}));

export const UsersTableMenuDialog: React.FC<UsersTableMenuDialogProps> = ({
  anchorEl,
  handleCloseMenu,
  handleDeactivate,
  handleEditUser,
  handleInviteUser,
  handleTwoFactorAuthentication,
  user,
}) => {
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const isInvited = (status: UserAccountStatus) =>
    status === UserAccountStatus.Created || status === UserAccountStatus.Invited;
  const classes = useStyle();

  return (
    <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleCloseMenu}>
      {user && user.accountType !== Role.Admin && (
        <CustomMenuItem onClick={handleDeactivate}>
          <SvgIcon component={CrossCloseGreyIcon} />
          <ListItemText className={classes.list}>{translate('deactivateAccount')}</ListItemText>
        </CustomMenuItem>
      )}
      {user && user.accountType !== Role.Admin && (
        <CustomMenuItem onClick={handleEditUser}>
          <SvgIcon component={EditIcon} />
          <ListItemText className={classes.list}>{translate('editAccount')}</ListItemText>
        </CustomMenuItem>
      )}
      {user && user.accountType !== Role.Admin && isInvited(user.status) && (
        <CustomMenuItem onClick={handleInviteUser}>
          <SvgIcon component={inviteUserIcon} />
          <ListItemText className={classes.list}>{translate('inviteAccount')}</ListItemText>
        </CustomMenuItem>
      )}
      <CustomMenuItem onClick={handleTwoFactorAuthentication}>
        <SvgIcon component={TowfactorIcon} />
        <ListItemText className={classes.list}>{translate('factorAuthentication')}</ListItemText>
      </CustomMenuItem>
    </Menu>
  );
};
