import { FieldError } from 'react-hook-form';

import i18n from 'i18n/config';

const MIN_LENGTH = 1;
const MAX_LENGTH = 300;

export const getCommonValidationError = (
  error?: FieldError,
  settings?: {
    maxLength?: number;
    minLength?: number;
  }
) => {
  switch (error?.type) {
    case 'required': {
      return i18n.t('common:fieldRequired');
    }

    case 'maxLength': {
      return i18n.t('common:maxLength', { value: `${settings?.maxLength ? settings.maxLength : MAX_LENGTH}` });
    }

    case 'minLength': {
      return i18n.t('common:minLength', { value: `${settings?.minLength ? settings.minLength : MIN_LENGTH}` });
    }

    default:
      return ' ';
  }
};
