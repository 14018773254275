import { makeStyles, Paper } from '@material-ui/core';
import React, { useState } from 'react';

import { AssessmentType } from 'types/Assessment';
import { AssessmentSection } from 'types/AssessmentSection';
import { PCRequest } from 'types/PCRequest';

import { getAssessmentByAssessmentType } from '../utils/getAssessmentByAssessmentType';
import { isPCRequestBeforeInspection } from '../utils/isPCRequestBeforeInspection';
import { AdminAssessmentCompareSection } from './AdminAssessmentCompareSection';
import { PCRequestAssessmentCompareComment } from './PCRequestAssessmentCompareComment';
import { CommentsCompare } from './types/CommentsCompare';

type AdminCompareSectionsBodyProps = {
  pcRequest: PCRequest;
  sections: AssessmentSection[];
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  body: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
}));

const getCommentByAssessmentType = (
  answerer: AssessmentType,
  auditorComment: string | undefined,
  inspectorComment: string | undefined
) => {
  switch (answerer) {
    case AssessmentType.AUDITOR:
      return auditorComment;
    case AssessmentType.INSPECTOR:
      return inspectorComment;
    default:
      return '-';
  }
};

const useAnswererState = (
  initialAnswerer: AssessmentType,
  { auditorComment, inspectorComment }: CommentsCompare
): [
  {
    comment: string | undefined;
    answerer: AssessmentType;
  },
  (value: AssessmentType) => void
] => {
  const [answerer, setAnswerer] = useState(initialAnswerer);
  const [comment, setComment] = useState(getCommentByAssessmentType(initialAnswerer, auditorComment, inspectorComment));

  const changeAnswerer = (value3: AssessmentType) => {
    if (value3 !== answerer) {
      setAnswerer(value3);
      setComment(getCommentByAssessmentType(value3, auditorComment, inspectorComment));
    }
  };
  return [{ comment, answerer }, changeAnswerer];
};

export const AdminCompareSectionsBody = ({ pcRequest, sections }: AdminCompareSectionsBodyProps) => {
  const classes = useStyles();
  const isBeforeInspection = isPCRequestBeforeInspection(pcRequest.status);
  const answerers = isBeforeInspection ? [AssessmentType.AUDITOR] : [AssessmentType.AUDITOR, AssessmentType.INSPECTOR];

  const crAssessmentId = getAssessmentByAssessmentType(
    pcRequest.assessments,
    AssessmentType.COMPANY_REPRESENTATIVE
  )?.id;
  const auditorAssessment = getAssessmentByAssessmentType(pcRequest.assessments, AssessmentType.AUDITOR);
  const inspectorAssessment = getAssessmentByAssessmentType(pcRequest.assessments, AssessmentType.INSPECTOR);
  const [commentCompare, changeAnswerer] = useAnswererState(
    isBeforeInspection ? AssessmentType.AUDITOR : AssessmentType.INSPECTOR,
    {
      auditorComment: auditorAssessment?.specialistComment,
      inspectorComment: inspectorAssessment?.specialistComment,
    }
  );

  return (
    <div className={classes.main}>
      <Paper className={classes.body}>
        {crAssessmentId &&
          sections.map((section, index) => (
            <AdminAssessmentCompareSection
              key={section.id}
              section={section}
              sectionNumber={index + 1}
              sectionsCount={sections.length}
              pcAssessmentInfo={{
                crAssessmentId: crAssessmentId,
                auditorAssessmentId: auditorAssessment?.id,
                inspectorAssessmentId: inspectorAssessment?.id,
                pcRequestStatus: pcRequest.status,
              }}
            />
          ))}
      </Paper>
      <PCRequestAssessmentCompareComment answerers={answerers} {...commentCompare} changeAnswerer={changeAnswerer} />
    </div>
  );
};
