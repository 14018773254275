import { AxiosRequestConfig } from 'axios';
import { useState, useMemo } from 'react';

import { REACT_APP_API_URL } from 'infrastructure/core/variables';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { createHeaderWithAuthorization } from 'infrastructure/persistence/axiosInstance';
import { createAxiosInstanceWithRefreshInterceptor } from 'infrastructure/persistence/axiosInstanceWithInterceptor';
import { BaseQueryError } from 'infrastructure/persistence/baseQueryWithReAuth';
import rootStore from 'infrastructure/store/rootStore';
import { useAppDispatch } from 'infrastructure/store/rootStore.hooks';
import { isRefresh } from 'utilities/useLanguage';

type UseCacheableDownloadProps = {
  responseType?: AxiosRequestConfig['responseType'];
  customHeaders?: AxiosRequestConfig['headers'];
  fileName: `${string}.pdf`; // in the future extend file extension
  apiUrl: string;
};

export const useCacheableDownload = ({
  responseType = 'blob',
  customHeaders,
  fileName,
  apiUrl,
}: UseCacheableDownloadProps): [() => void, boolean] => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [cachedObjectURL, setCachedObjectURL] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const showNotification = useNotifications();

  const axiosInstance = useMemo(
    () =>
      createAxiosInstanceWithRefreshInterceptor(dispatch, rootStore.getState, {
        headers: { ...createHeaderWithAuthorization(rootStore.getState()), ...customHeaders },
        responseType,
      }),
    [dispatch, responseType, customHeaders]
  );

  const clientDownload = (url: string) => {
    isRefresh();
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);

    document.body.appendChild(link);

    link.click();

    link.parentNode?.removeChild(link);
  };

  const handleDownload = () => {
    if (cachedObjectURL !== null) {
      clientDownload(cachedObjectURL);
      return;
    }

    setIsDownloading(true);
    axiosInstance
      .get(`${REACT_APP_API_URL}/api/${apiUrl}`)
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        setCachedObjectURL(url);
        clientDownload(url);
        setIsDownloading(false);
      })
      .catch((err) => {
        setIsDownloading(false);

        const error = err as BaseQueryError;

        if (!error.data) {
          showNotification(NotificationType.Error);
          return;
        }
        showNotification(NotificationType.Error, { errorId: error.data.errorCode });
      });
  };

  return [handleDownload, isDownloading];
};
