import { AxiosError } from 'axios';
import React from 'react';
import { useQuery } from 'react-query';
import { Redirect } from 'react-router-dom';

import { TranslationNamespace } from 'i18n/config';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { createToken } from 'infrastructure/services/auth';
import { useAppDispatch } from 'infrastructure/store/rootStore.hooks';
import { LoadingWrapper } from 'ui/LoadingWrapper';
import { useCreateTranslate } from 'utilities/translate.hook';

import { CompanyRepresentativeRoutes, getCompanyRepresentativePath } from '../CompanyRepresentativeRoutes';

type CompanyRepresentativeLoginFormProps = { token: string; refresh: string };

export const CompanyRepresentativeLogin: React.FunctionComponent<CompanyRepresentativeLoginFormProps> = ({
  token,
  refresh,
}) => {
  const dispatch = useAppDispatch();
  const translate = useCreateTranslate(TranslationNamespace.errorMessages);
  const notification = useNotifications();
  const { isLoading, isError } = useQuery(
    'tokenData',
    () =>
      createToken(
        {
          token,
          refresh,
        },
        dispatch
      ),
    {
      onError: (error: AxiosError) => {
        notification(NotificationType.Error, { message: translate('unableToLogin') });

        if (error.response?.status === 401) {
          window.location.href = '/';
        }
      },
      retry: false,
    }
  );
  return isError ? null : (
    <LoadingWrapper isLoading={isLoading}>
      <Redirect to={getCompanyRepresentativePath(CompanyRepresentativeRoutes.companySelect)} />
    </LoadingWrapper>
  );
};
