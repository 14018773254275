import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';

import { Login } from '../../common/login/Login';
import { getInspectorPath } from '../InspectorRoutes';

export const InspectorLogin: React.FunctionComponent = () => {
  const translateCommon = useCreateTranslate(TranslationNamespace.common);
     return <Login getPath={getInspectorPath} login={'inspector'} continueMessage={translateCommon('continue')} />;
};
