// storage.js
import { storageFactory } from 'storage-factory';

export enum SessionStorageKey {
  refreshKey = 'OshRefreshToken',
  expiryKey = 'OshRefreshExpiry',
}

export const local = storageFactory(() => localStorage);
export const session = storageFactory(() => sessionStorage);

export const getSessionValueByKey = (key: SessionStorageKey) => session.getItem(key);
