import React from 'react';

import { Establishments } from '../../common/establishments/Establishments';
import { getAuditorPath } from '../AuditorRoutes';
import { AuditorLayout } from '../ui/AuditorLayout';

export const AuditorEstablishments: React.FC = () => {
  return (
    <AuditorLayout>
      <Establishments getPath={getAuditorPath} />
    </AuditorLayout>
  );
};
