import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

type PCRequestAssessmentPreviousAnswerValueTitleProps = {
  text: string;
  className?: string;
};

const useStyle = makeStyles((theme) => ({
  valueTitle: {
    fontWeight: 'bold',
  },
}));

export const PCRequestAssessmentPreviousAnswerValueTitle: React.FC<PCRequestAssessmentPreviousAnswerValueTitleProps> =
  ({ text, className }) => {
    const classes = useStyle();

    return (
      <Typography variant="body1" className={clsx(className, classes.valueTitle)}>
        {text}
      </Typography>
    );
  };
