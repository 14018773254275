import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
import i18n from 'i18next';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { Button } from 'ui/Button';
import { AssessmentSection } from 'types/AssessmentSection';
import { getValidSectionsCount } from 'utilities/getValidSectionsCount';
import { useNavigate } from 'utilities/navigate';
import { SaveIcon } from 'ui/icons/icons';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { BaseQueryError } from 'infrastructure/persistence/baseQueryWithReAuth';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
//import { TypeOfPlatform } from 'infrastructure/services/auth.type';

//import { useGetMeQuery } from '../../../infrastructure/services/user.api';
import { useSubmitAssessmentMutation } from '../../admin/api/admin.api';
import { isValidAssessmentComment } from './utils/isValidAssessmentComment';
import { isAssessmentCommentMarkVisible } from './utils/isAssessmentCommentMarkVisible';
import { Routes } from '../../common/Routes';
import { getReverseDirection as getReverseDirectionForPCRequestAssessmentBar } from '../../../utilities/useLanguage';

type PCRequestAssessmentBarProps = {
  barId?: string;
  sections: AssessmentSection[];
  currentSectionId: number;
  setCurrentSectionIdAndScroll: (value: number) => void;
  className?: string;
  currentAssessmentId: number;
  specialistComment: string;
  pcRequestId: number;
  getPath: (value: Routes) => string;
};

const useStyles = makeStyles((theme) => ({
  stepper: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
  },

  leftButton: {
    flip: false,
    height: '50px',
    width: '187px',
    marginRight: theme.spacing(2),
  },

  saveDraft: {
    flip: false,
    flexShrink: 0,
    textAlign: 'left',
    marginRight: theme.spacing(5),
    marginLeft: theme.spacing(3),
  },

  saveDraftButton: {
    flip: false,
    alignItems: 'center',
    height: '50px',
    width: '167px',
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: '8px',
  },

  saveDraftIcon: {
    flip: false,
    marginRight: theme.spacing(1),
    verticalAlign: 'bottom',
  },

  select: {
    flexGrow: 1,
    direction: 'ltr',
  },
}));

export const PCRequestAssessmentBar = React.forwardRef<unknown, PCRequestAssessmentBarProps>(
  (
    {
      currentSectionId,
      setCurrentSectionIdAndScroll,
      sections,
      className,
      barId,
      currentAssessmentId,
      specialistComment,
      pcRequestId,
      getPath,
    },
    ref
  ) => {
    const classes = useStyles();
    const validSectionsCount = getValidSectionsCount(sections);
    const navigate = useNavigate();
    const showNotification = useNotifications();
   // const { data: currentUser } = useGetMeQuery();

    const translateSA = useCreateTranslate(TranslationNamespace.companyRepresentative);
    const translateCommon = useCreateTranslate(TranslationNamespace.common);

    const isSubmitDisabled =
      validSectionsCount !== sections.length ||
      (isAssessmentCommentMarkVisible(specialistComment) && !isValidAssessmentComment(specialistComment));
    const [submitAssessment, { isLoading }] = useSubmitAssessmentMutation();

    const changeSection = (event: React.ChangeEvent<{ value: unknown }>) => {
      setCurrentSectionIdAndScroll(event.target.value as number);
    };

    const handleGoBackToRequestsList = () => {
      navigate(getPath(Routes.pcRequests))
    };

    const handleAssessmentSubmit = async () => {
      if (isSubmitDisabled || isLoading) return;
      try {
        await submitAssessment({ assessmentId: currentAssessmentId, pcRequestId }).unwrap();
      } catch (err) {
        const error = err as BaseQueryError;

        showNotification(NotificationType.Error, { errorId: error.data.errorCode });
      }

      // currentUser?.currentPlatform === TypeOfPlatform.OSH ?
        navigate(`${getPath(Routes.pcRequests)}/${pcRequestId}${Routes.assessment}/${currentAssessmentId}${Routes.finished}`)
        // :navigate(`${getPath(Routes.pcRequests)}/${pcRequestId}${Routes.assessment}/${currentAssessmentId}${Routes.reviewAnswer}`)
    };
    const reverseDirection = getReverseDirectionForPCRequestAssessmentBar(i18n.language);

    return (
      <Paper ref={ref} id={barId} dir={reverseDirection} className={clsx(classes.stepper, className)}>
        <Button
          disabled={isSubmitDisabled}
          variant="contained"
          size="large"
          className={classes.leftButton}
          color="secondary"
          onClick={handleAssessmentSubmit}
          data-testid="pc-request-assessment-bar-submit"
        >
          {translateSA('submit')}
        </Button>
        <div className={classes.saveDraft}>
          <Button
            data-testid="pc-request-assessment-bar-save"
            className={classes.saveDraftButton}
            color="secondary"
            size="large"
            dir={reverseDirection}
            onClick={handleGoBackToRequestsList}
          >
            <SvgIcon className={classes.saveDraftIcon} component={SaveIcon} />
            {translateCommon('saveAndExit')}
          </Button>
        </div>
        {sections.length > 0 && (
          <Select
            className={classes.select}
            variant="outlined"
            dir="rtl"
            value={currentSectionId === 0 ? '' : currentSectionId}
            onChange={changeSection}
            data-testid="pc-request-assessment-bar-select"
          >
            {sections.map((section, index) => (
              <MenuItem key={`pcrequest-assessment-sectionSelect-${section.id}`} value={section.id}>
               {translateSA('section')} {index + 1}: {section.nameAR.length > 50 ? `${section.nameAR.substring(0, 50)}...` : section.nameAR}
              </MenuItem>
            ))}
          </Select>
        )}
      </Paper>
    );
  }
);