import axios, { AxiosResponse } from 'axios';

import { REACT_APP_API_URL } from 'infrastructure/core/variables';
import { AppDispatch } from 'infrastructure/store/rootStore.types';

import { TokenResponse } from './auth.type';
import { processTokenResponse } from './auth.utils';
import { setUserCredentials } from './authSlice';

export type CreateTokenDto = { token: string; refresh: string };

export const createToken = async (data: CreateTokenDto, dispatch: AppDispatch): Promise<void> => {
  const { data: response } = await axios.post<CreateTokenDto, AxiosResponse<TokenResponse>>(
    `${REACT_APP_API_URL}/api/user/token/establishment/create/`,
    data
  );

  const tokenData = processTokenResponse(response);
  dispatch(setUserCredentials(tokenData));
};
