import { PCRequestStatus } from 'types/PCRequest';
import { TypeOfPlatform } from 'infrastructure/services/auth.type';

export const AuditorList = (platform: TypeOfPlatform) => [
  { nameAuditor: platform === TypeOfPlatform.OSH ? 'unassigned' : 'unassignedCA' },
];

export const InspectorList = (platform: TypeOfPlatform) => [
  { nameInspector: platform === TypeOfPlatform.CA ? 'auditorFinishedCA' : 'auditorFinished' },
];

export const AuditorAssignList = (platform: TypeOfPlatform) => [
  { nameAuditorAssignList: platform === TypeOfPlatform.OSH ? 'auditorAssigned' : 'auditorAssignedCA' },
];

export const InspectorAssignList = (platform: TypeOfPlatform) => [
  { nameInspectorAssignList: platform === TypeOfPlatform.OSH ? 'inspectorAssigned' : 'inspectorAssignedCA' },
];

export const AssignAuditorList = (platform: TypeOfPlatform) => [
  { name: platform === TypeOfPlatform.OSH ? 'assignAuditorOSH' : 'assignAuditorCA' },
];

export const AssignInspectorList = (platform: TypeOfPlatform) => [
  { name: platform === TypeOfPlatform.OSH ? 'assignInspector' : 'assignInspectorCA' },
];
export const getPcRequestStatusPlatform = (platform: TypeOfPlatform, status: PCRequestStatus) => {
  switch (status) {
    case PCRequestStatus.Unassigned:
      return AuditorList;
    case PCRequestStatus.AuditorApproved:
      return InspectorList;
    case PCRequestStatus.auditorAssigned:
      return AuditorAssignList;
    case PCRequestStatus.InspectorAssigned:
      return InspectorAssignList;

    default:
      throw Error('Unhandled status display key');
  }
};
export const getPcRequestStatus = (status: PCRequestStatus) => {
  switch (status) {
    case PCRequestStatus.AuditorStarted:
      return 'auditorStarted';
    case PCRequestStatus.InspectorStarted:
      return 'inspectorStarted';
    case PCRequestStatus.AuditorFinished:
        return 'auditorWaitingApproved';
    case PCRequestStatus.AuditorRejected:
        return 'auditorRejectedPC';
    case PCRequestStatus.InspectorFinished:
      return 'waitForInspectorApproved';
    case PCRequestStatus.InspectorRejected:
        return 'auditorRejectedPC';
    case PCRequestStatus.InspectorApproved:
      return 'inspectorFinished';
    case PCRequestStatus.PCIssued:
      return 'pcIssued';
    default:
      throw Error('Unhandled status display key');
  }
};