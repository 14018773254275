import React from 'react';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme, Chip } from '@material-ui/core';
import clsx from 'clsx';

import { AssessmentStatus } from 'types/AssessmentStatus';
import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';

type CellCustomProps = { status: AssessmentStatus | null };
type CellProps = TableCellProps & CellCustomProps;

enum EstablishmentStatusDisplay {
  Registered = 'registered',
  Started = 'startedSA',
  Submitted = 'submittedSA',
}

const toEstablishmentStatusDisplay = (status: AssessmentStatus | null): EstablishmentStatusDisplay => {
  switch (status) {
    case AssessmentStatus.Draft:
      return EstablishmentStatusDisplay.Started;
    case AssessmentStatus.Passed:
    case AssessmentStatus.Failed:
      return EstablishmentStatusDisplay.Submitted;
    case AssessmentStatus.NotStarted:
    default:
      return EstablishmentStatusDisplay.Registered;
  }
};

const useStatusIconStyles = makeStyles<Theme, CellCustomProps>(({ palette }) => ({
  root: {
    fontWeight: 700,
    color: (props) => {
      switch (props.status) {
        case AssessmentStatus.Draft:
          return palette.success.dark;
        case AssessmentStatus.Passed:
          return '#757575';
        default:
          return palette.text.primary;
      }
    },
  },
}));

export const EstablishmentStatusTableCell = (props: CellProps) => {
  const { status, className, ...other } = props;
  const classes = useStatusIconStyles({ status });
  const translate = useCreateTranslate(TranslationNamespace.admin);

  const getEstablishmentStatus = () => {
    switch (status) {
      case AssessmentStatus.Passed:
        return (
          <Chip
            label={translate(toEstablishmentStatusDisplay(status))}
            style={{ backgroundColor: '#e3f2fd', color: '#2196F3', width: '75%' }}
          />
        );

      case AssessmentStatus.Draft:
      case AssessmentStatus.Failed:
      case AssessmentStatus.NotStarted:
        return (
          <Chip
            label={translate(toEstablishmentStatusDisplay(status))}
            style={{ backgroundColor: '#fcedc1', color: '#856620', width: '75%' }}
          />
        );
      default:
        return (
          <Chip
            label={translate(toEstablishmentStatusDisplay(status))}
            style={{ backgroundColor: '#eaeaea', color: '#696969', width: '75%' }}
          />
        );
    }
  };
  return (
    <TableCell className={clsx(className)} {...other}>
      <span className={classes.root}>{getEstablishmentStatus()}</span>
    </TableCell>
  );
};
