import { TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';

const useStyles = makeStyles((theme) => ({
  actions: {
    width: 94,
    textAlign: 'center',
  },
  baseColumnWidth: {
    width: 226,
  },
  cellPadding: {
    padding: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  statusCenter: {
    textAlign: 'center',
  },
}));

export const UsersTableHeader = () => {
  const classes = useStyles();

  const translateCommon = useCreateTranslate(TranslationNamespace.common);
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.actions}>{translateCommon('actions')}</TableCell>
        <TableCell className={clsx(classes.baseColumnWidth, classes.statusCenter)}>{translate('status')}</TableCell>
        <TableCell className={clsx(classes.baseColumnWidth, classes.cellPadding)}>{translate('userType')}</TableCell>
        <TableCell className={clsx(classes.baseColumnWidth, classes.cellPadding)}>{translate('phoneNumber')}</TableCell>
        <TableCell className={clsx(classes.baseColumnWidth, classes.cellPadding)}>{translate('email')}</TableCell>
        <TableCell className={clsx(classes.baseColumnWidth, classes.cellPadding)}>
          {translateCommon('lastName')}
        </TableCell>
        <TableCell className={clsx(classes.baseColumnWidth, classes.cellPadding)}>
          {translateCommon('firstName')}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
