import { makeStyles, PropTypes, Theme } from '@material-ui/core';
import React from 'react';
import MuiButton, { ButtonProps, ButtonTypeMap } from '@material-ui/core/Button';
import { PaletteColor } from '@material-ui/core/styles/createPalette';

import { backButtonBackgroundColor } from './styles';

export type CustomButtonProps = ButtonProps<ButtonTypeMap['defaultComponent']>;

type VariantType = 'text' | 'outlined' | 'contained' | undefined;

const getPalette = (theme: Theme, color: PropTypes.Color | undefined, variant: VariantType) => {
  if (!variant || variant === 'text') {
    return undefined;
  }

  switch (color) {
    case 'primary':
    case 'secondary':
      return theme.palette[color];
    case 'default':
    case 'inherit':
    default:
      return undefined;
  }
};

const getStyleVariant = (palette: PaletteColor, variant: VariantType) => {
  switch (variant) {
    case 'contained':
      return {
        color: `${palette.contrastText}!important`,
        backgroundColor: `#89C0C3 !important`,
      };
    case 'outlined':
      return {
        color: `${palette.main}!important`,
        backgroundColor: `${backButtonBackgroundColor}!important`,
      };
    default:
      return {};
  }
};

const useStyles = makeStyles<Theme, ButtonProps>((theme) => ({
  customButton: ({ variant, color, disabled }) => {
    if (disabled) {
      const palette = getPalette(theme, color, variant);
      const styles = palette ? getStyleVariant(palette, variant) : {};

      return { ...styles };
    }

    if (variant === 'outlined') {
      return {
        '&:hover': {
          backgroundColor: theme.palette.common.white,
        },
      };
    }

    return {};
  },
}));

export const Button = React.forwardRef(
  ({ children, className, ...props }: CustomButtonProps, ref: React.Ref<HTMLButtonElement>) => {
    const classes = useStyles({ ...props });
    return (
      <MuiButton {...props} ref={ref} className={`${className} ${classes.customButton}`}>
        {children}
      </MuiButton>
    );
  }
);
