import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import i18n from 'i18next';

import { AssessmentQuestionText } from 'ui/assessment/AssessmentQuestionText';
import { collapseButtonSpaceWidth, labelCardColor } from 'ui/styles';

import { PCRequestAssessmentAnswer } from '././PCRequestAssessmentAnswer';
import { QuestionAndAnswers } from './types/QuestionAndAnswers';
import { getDirection, getReverseDirection } from '../../../utilities/useLanguage';

type PCRequestAssessmentQuestionProps = {
  assessmentId: number;
  questionNo: number;
  questionAndAnswers: QuestionAndAnswers;
};

const useStyle = makeStyles<Theme, { isOpen: boolean }>((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
  },

  questionSpace: {
    padding: '18px 32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    borderBottom: ({ isOpen }) => `${isOpen ? '1px' : '0px'} solid ${theme.palette.grey[200]}`,
  },

  questionSpaceText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  collapseButtonSpace: {
    flip: false,
    flexShrink: 0,
    width: collapseButtonSpaceWidth,
    alignSelf: 'baseline',
  },

  questionText: {
    flexGrow: 1,
  },

  questionDescription: {
    flip: false,
    color: labelCardColor,
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(6),
  },
}));

export const PCRequestAssessmentQuestion: React.FC<PCRequestAssessmentQuestionProps> = ({
  assessmentId,
  questionNo,
  questionAndAnswers,
}) => {
  const [isOpen] = React.useState(false);
  const classes = useStyle({ isOpen });
  const direction = getDirection(i18n.language);
  const reverseDirection = getReverseDirection(i18n.language);

  return (
    <div dir={reverseDirection} className={classes.main}>
      <div className={classes.questionSpace}>
        <div className={classes.questionSpaceText}>
          <div className={classes.questionText}>
            <AssessmentQuestionText question={questionAndAnswers.question} questionNo={questionNo} />
          </div>
        </div>
        <div>
          <Typography dir={direction} variant="body2" className={classes.questionDescription}>
            {questionAndAnswers.question.descriptionAR}
          </Typography>
        </div>
        <div>
          <Typography dir={direction} variant="body2" className={classes.questionDescription}>
            {questionAndAnswers.question.descriptionEN}
          </Typography>
        </div>
      </div>
      <div>
        <PCRequestAssessmentAnswer questionAndAnswer={questionAndAnswers} assessmentId={assessmentId} />
      </div>
    </div>
  );
};
