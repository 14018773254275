import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder, FullTagDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { AxiosRequestConfig } from 'axios';

import { PcRequestApiTagTypes } from 'domain/common/pcrequests/api/pcrequest.tags.api';
import { CompanyRepresentativeApiTagType } from 'domain/company-representative/api/companyRepresentative.tags.api';
import { BaseQueryError } from 'infrastructure/persistence/baseQueryWithReAuth';
import { ListResult, Results } from 'interfaces/Results';
import { Assessment, AssessmentUpdateInput, toAssessment } from 'types/Assessment';
import {
  CreateAnswerInput,
  GetAssessmentAnswerQuery,
  AssessmentAnswer,
  AssessmentAnswerDto,
  toSelfAssessmentAnswer,
  UpdateAnswerInput,
  AnswerAttachment,
  AnswerAttachmentDto,
  toAnswerAttachment,
  UploadAttachment,
  DeleteAttachment,
} from 'types/AssessmentAnswer';
import {
  GetAssessmentQuestionQuery,
  AssessmentQuestion,
  AssessmentQuestionDto,
  toAssessmentQuestion,
} from 'types/AssessmentQuestion';
import {
  GetAssessmentSectionQuery,
  AssessmentSection,
  AssessmentSectionDto,
  toAssessmentSection,
} from 'types/AssessmentSection';

import { AssessmentApiTagsType } from './assessment.endpoints.tags.api';

export const createAssessmentEndpoints =
  (
    reducerPath: string // reducerPath has to be const if it is not const then type will be not string from reducerPath just "string"
  ) =>
  (
    build: EndpointBuilder<BaseQueryFn<AxiosRequestConfig, unknown, BaseQueryError, {}, {}>, string, typeof reducerPath>
  ) => ({
    // sections endpoints
    getSectionsByAssessmentId: build.query<ListResult<AssessmentSection>, GetAssessmentSectionQuery>({
      query: ({ page = 1, pageSize = 100, assessmentId = 0 }) => ({
        url: `/self_assessment/${assessmentId}/sections/?page=${page}&page_size=${pageSize}`,
      }),
      providesTags: (result, error, args) => [
        {
          type: AssessmentApiTagsType.AssessmentSections,
          id: args.assessmentId,
        },
      ],
      transformResponse: (response: Results<AssessmentSectionDto>) => ({
        count: response.count,
        results: response.results.map(toAssessmentSection),
      }),
    }),
    // questions endpoints
    getQuestionsByAssessmentIdAndSectionId: build.query<ListResult<AssessmentQuestion>, GetAssessmentQuestionQuery>({
      query: ({ page = 1, pageSize = 100, assessmentId, sectionId }) => ({
        url: `/self_assessment/${assessmentId}/questions/?section_id=${sectionId}&page=${page}&page_size=${pageSize}`,
      }),
      providesTags: (data, error, args) => [
        ...(data?.results.map(({ id }) => ({
          type: AssessmentApiTagsType.AssessmentQuestion,
          id,
        })) || []),
        {
          type: AssessmentApiTagsType.AssessmentQuestions,
          id: `section-${args.sectionId}`,
        },
      ],
      transformResponse: (response: Results<AssessmentQuestionDto>) => ({
        count: response.count,
        results: response.results.map(toAssessmentQuestion),
      }),
    }),
    // answers endpoints
    getAnswersByAssessmentIdAndSectionId: build.query<ListResult<AssessmentAnswer>, GetAssessmentAnswerQuery>({
      query: ({ page = 1, pageSize = 100, assessmentId, assessmentSectionId }) => ({
        url: `/answer/?page=${page}&page_size=${pageSize}&question__section__id=${assessmentSectionId}&self_assessment__id=${assessmentId}`,
      }),
      providesTags: (data, error, args) => [
        ...(data?.results.reduce(
          (tags, { questionId, id }) => [
            ...tags,
            {
              type: AssessmentApiTagsType.AssessmentAnswer,
              id: `question-${questionId}`,
            },
            {
              type: AssessmentApiTagsType.AssessmentAnswer,
              id: `answer-${id}`,
            },
          ],
          new Array<FullTagDescription<string>>()
        ) || []),
        {
          type: AssessmentApiTagsType.AssessmentAnswer,
          id: `${args.assessmentId}-section-${args.assessmentSectionId}`,
        },
      ],
      transformResponse: (response: Results<AssessmentAnswerDto>) => ({
        count: response.count,
        results: response.results.map(toSelfAssessmentAnswer),
      }),
    }),
    createSAAnswer: build.mutation<AssessmentAnswer, CreateAnswerInput>({
      query: ({ data }) => ({
        url: '/answer/',
        method: 'POST',
        data,
      }),
      transformResponse: toSelfAssessmentAnswer,
      invalidatesTags: (result, error, { assessmentSectionId, data: { question_id, self_assessment_id } }) => [
        {
          type: AssessmentApiTagsType.AssessmentQuestion,
          id: question_id,
        },
        {
          type: AssessmentApiTagsType.AssessmentSections,
          id: self_assessment_id,
        },
        {
          type: AssessmentApiTagsType.AssessmentAnswer,
          id: `${self_assessment_id}-section-${assessmentSectionId}`,
        },
        {
          type: AssessmentApiTagsType.Assessment,
          id: `${self_assessment_id}`,
        },
      ],
    }),
    updateSAAnswer: build.mutation<AssessmentAnswer, UpdateAnswerInput>({
      query: ({ data }) => ({
        url: `/answer/${data.id}/`,
        method: 'PUT',
        data,
      }),
      transformResponse: toSelfAssessmentAnswer,
      invalidatesTags: (result, error, { assessmentSectionId, data: { question_id, self_assessment_id } }) => [
        {
          type: AssessmentApiTagsType.AssessmentQuestion,
          id: question_id,
        },
        {
          type: AssessmentApiTagsType.AssessmentSections,
          id: self_assessment_id,
        },
        {
          type: AssessmentApiTagsType.AssessmentAnswer,
          id: `${self_assessment_id}-section-${assessmentSectionId}`,
        },
      ],
    }),
    uploadSelfAssessmentAttachment: build.mutation<AnswerAttachment, UploadAttachment>({
      query: ({ uploadedFile }) => ({
        url: '/attachment/',
        method: 'POST',
        headers: { 'content-type': 'multipart/form-data' },
        data: uploadedFile,
      }),
      transformResponse: (response: AnswerAttachmentDto) => toAnswerAttachment(response),
      invalidatesTags: (
        result,
        error,
        { assessmentData: { answer_id, question_id, self_assessment_id, assessmentSectionId } }
      ) => [
        {
          type: AssessmentApiTagsType.AssessmentQuestion,
          id: question_id,
        },
        {
          type: AssessmentApiTagsType.AssessmentSections,
          id: self_assessment_id,
        },
        {
          type: AssessmentApiTagsType.AssessmentAnswer,
          id: `${self_assessment_id}-section-${assessmentSectionId}`,
        },
      ],
    }),
    deleteSelfAssessmentAttachment: build.mutation<AnswerAttachment, DeleteAttachment>({
      query: ({ id }) => ({
        url: `/attachment/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: (
        result,
        error,
        { assessmentData: { answer_id, question_id, self_assessment_id, assessmentSectionId } }
      ) => [
        {
          type: AssessmentApiTagsType.AssessmentQuestion,
          id: question_id,
        },
        {
          type: AssessmentApiTagsType.AssessmentSections,
          id: self_assessment_id,
        },
        {
          type: AssessmentApiTagsType.AssessmentAnswer,
          id: `${self_assessment_id}-section-${assessmentSectionId}`,
        },
      ],
    }),

    submitAssessment: build.mutation<void, { assessmentId: number; pcRequestId?: number }>({
      query: ({ assessmentId }) => ({
        url: `/self_assessment/${assessmentId}/submit/`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, { assessmentId, pcRequestId }) =>
        pcRequestId
          ? [
              { type: AssessmentApiTagsType.Assessment, id: assessmentId },
              { type: PcRequestApiTagTypes.PCRequest, id: pcRequestId },
            ]
          : [
              { type: AssessmentApiTagsType.Assessment, id: assessmentId },
              CompanyRepresentativeApiTagType.EstablishmentInfo,
            ],
    }),
    updateAssessment: build.mutation<void, AssessmentUpdateInput>({
      query: ({ assessmentId, data }) => ({
        url: `/self_assessment/${assessmentId}/`,
        method: 'PATCH',
        data,
      }),
      invalidatesTags: (result, error, { assessmentId }) => [
        {
          type: AssessmentApiTagsType.Assessment,
          id: assessmentId,
        },
      ],
    }),
    getAssessmentById: build.query<Assessment, number>({
      query: (id) => ({
        url: `/self_assessment/${id}/`,
      }),
      providesTags: (result, error, id) => [
        {
          type: AssessmentApiTagsType.Assessment,
          id,
        },
      ],
      transformResponse: toAssessment,
    }),
  });
