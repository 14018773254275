import { makeStyles, Theme, Chip } from '@material-ui/core';
import React from 'react';

import { TranslationNamespace } from 'i18n/config';
import { UserAccountStatus } from 'types/User';
import { useCreateTranslate } from 'utilities/translate.hook';

type UserStatusProps = {
  status: UserAccountStatus;
};

const useUserStatusStyle = makeStyles<Theme, UserStatusProps>(({ palette }) => ({
  root: {
    fontWeight: 700,
    color: (props) => {
      switch (props.status) {
        case UserAccountStatus.Activated:
          return palette.success.dark;
        case UserAccountStatus.Deactivated:
          return '#757575';
        default:
          return palette.text.primary;
      }
    },
  },
}));

export const UserStatus = ({ status }: UserStatusProps) => {
  const classes = useUserStatusStyle({ status });
  const translate = useCreateTranslate(TranslationNamespace.admin);

  const getUserStatus = () => {
    switch (status) {
      case UserAccountStatus.Invited:
        return <Chip
                    label={translate('invited')}
                    style={{ backgroundColor: '#e3f2fd', color: '#2196F3' ,width: '80%' }}
                    />

      case UserAccountStatus.Deactivated:
        return <Chip
                    label={translate('deactivated')}
                    style={{ backgroundColor: '#eaeaea', color: '#696969', width: '80%' }}
                    />

      case UserAccountStatus.Activated:
        return <Chip
                    label={translate('active')}
                    style={{ backgroundColor: '#e8f5e9', color: '#3B873E' ,width: '80%' }}
                    />

      case UserAccountStatus.Created:
        return <Chip
                    label={translate('created')}
                    style={{ backgroundColor: '#fcedc1', color: '#856620' ,width: '80%' }}
                    />
    }
  };

  return <span className={classes.root}>{getUserStatus()}</span>;
};
