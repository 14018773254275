import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { makeStyles, Typography, TextField, Divider } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import i18n from 'i18next';
import MenuItem from '@material-ui/core/MenuItem';

import { BaseQueryError } from 'infrastructure/persistence/baseQueryWithReAuth';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { TypeOfRequest, TypeOfRequestList } from 'infrastructure/services/auth.type';
import { preventDefaultIfNotNumberAndPlusValues } from 'utilities/preventDefaultIfNotNumberAndPlusValues';
import { TranslationNamespace } from 'i18n/config';
import { Hint } from 'ui/Hint';
import { CheckGreyIcon, CrossCloseGreyIcon } from 'ui/icons/icons';
import { useCreateTranslate } from 'utilities/translate.hook';
import { emailRegex, saudiPhoneNumberRegex } from 'infrastructure/constants/regex.constants';

import { useCreateTicketMutation } from './api/jira.api';
import { ContactUsDialog } from './ContactUsDialog';
import { ResponseTicket } from './types/Info';
import { getDirection, getReverseDirection } from '../../utilities/useLanguage';
import { getHelperText } from '../../utilities/useHelper';

type CreateTicket = {
  type: TypeOfRequest;
  name: string;
  phoneNumber: string;
  email: string;
  description: string;
};
const Input = styled('input')({
  display: 'none',
});
type ContactUsDialogProps = {
  isFormOpen: boolean;
  handleClose: () => void;
};

const useStyles = makeStyles((theme) => ({
  text: {
    marginBottom: theme.spacing(4),
  },
  uploadButtonSpace: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  uploadButtonDesc: {
    width: '340px',
    marginLeft: theme.spacing(4),
    paddingRight: theme.spacing(12),
    textAlign: 'left',
  },
  uploadedContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexWrap: 'wrap',
  },
  attachment: {
    flip: false,
    backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  error: {
    color: theme.palette.error.main,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
  },
  field: {
    marginTop: theme.spacing(4),
  },
  uploadButton: {
    flip: false,
    marginLeft: theme.spacing(0),
    fontSize: '18px',
    height: 45,
    [theme.breakpoints.up('md')]: {
      width: 150,
    },
  },
  button: {
    color: theme.palette.common.white,
    width: '100%',
    height: 50,
    [theme.breakpoints.up('md')]: {
      width: 184,
    },
  },
  submitButton: {
    flex: 1,
    width: '100%',
    marginTop: theme.spacing(4),
    fontSize: '18px',
    height: 50,
  },
}));

export const ContactUs: React.FC<ContactUsDialogProps> = ({ isFormOpen, handleClose }) => {
  const classes = useStyles();
  const translate = useCreateTranslate(TranslationNamespace.landingPage);
  const translateCommon = useCreateTranslate(TranslationNamespace.common);
  const translateAdmin = useCreateTranslate(TranslationNamespace.admin);
  const [createTicket, { isLoading }] = useCreateTicketMutation();
  const showNotification = useNotifications();
  const [attachments, setAttachment] = useState<File[]>([]);
  const direction = getDirection(i18n.language);
  const [sendFailed, setSendFailed] = useState(false);

  const handleFormClose = () => {
    resetForm();
    setSendFailed(false);
    handleClose();
  };

  const {
    handleSubmit,
    control,
    reset: resetForm,
  } = useForm<CreateTicket>({
    mode: 'onTouched',
  });

  const onTicketSubmit: SubmitHandler<CreateTicket> = async ({ type, name, phoneNumber, email, description }) => {
    if (attachments.length > 5) {
      return showNotification(NotificationType.Error, { errorId: '3002' });
    }

    const formData = new FormData();
    attachments.forEach((attachment, index) => {
      formData.append(`attachments[${index}]`, attachment, attachment.name);
    });
    formData.append('name', name);
    formData.append('email', email);
    formData.append('type', type.toString());
    formData.append('description', description);
    formData.append('phone_number', phoneNumber);

    if (!isLoading) {
      await createTicket({
        formData,
      })
        .unwrap()
        .then((response: ResponseTicket) => {
          handleFormClose();
          showNotification(NotificationType.Success, {
            message: translate('sendSuccess', { issueKey: `${response.issueKey}` }),
          });
          setAttachment([]);
        })
        .catch((error: BaseQueryError) => {
          setSendFailed(true);
          if (!error.data) {
            showNotification(NotificationType.Error);
          }
        });
    }
  };

  const handleFileChange = ({ currentTarget: { files } }: React.ChangeEvent<HTMLInputElement>) => {
    if (files && files.length) {
      setAttachment((existing) => existing.concat(Array.from(files)));
    }
  };

  const removeAttachment = (id: number) => {
    return setAttachment(
      attachments.filter((item, i) => {
        return i !== id;
      })
    );
  };
  const reverseDirection = getReverseDirection(i18n.language);

  return (
    <ContactUsDialog isFormOpen={isFormOpen} handleClose={handleFormClose}>
      <Typography component="pre" variant="h6" className={classes.text}>
        {translate('leaveMessage')}
      </Typography>
      <Divider />
      <form className={classes.form} onSubmit={handleSubmit(onTicketSubmit)}>
        <Controller
          name="type"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              select
              SelectProps={{ dir: 'rtl' }}
              variant="outlined"
              className={classes.field}
              error={invalid}
              helperText={getHelperText(translateCommon, error)}
              label={translate('requestType')}
            >
              {TypeOfRequestList.map((typeRequest) => (
                <MenuItem key={typeRequest.value} value={typeRequest.value}>
                  {translate(typeRequest.name)}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              variant="outlined"
              className={classes.field}
              error={invalid}
              helperText={getHelperText(translateCommon, error)}
              label={translate('name')}
            />
          )}
        />
        <Controller
          name="phoneNumber"
          control={control}
          rules={{
            required: true,
            pattern: {
              value: saudiPhoneNumberRegex,
              message: translate('invalidPhoneFormat'),
            },
          }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              type="text"
              variant="outlined"
              className={classes.field}
              error={invalid}
              helperText={getHelperText(translateCommon, error)}
              label={translate('contactNumber')}
              inputMode="numeric"
              onKeyPress={preventDefaultIfNotNumberAndPlusValues}
            />
          )}
        />
        <Controller
          control={control}
          name="email"
          rules={{
            required: true,
            pattern: {
              value: emailRegex,
              message: translateAdmin('invalidEmailAddressFormat'),
            },
          }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              type="text"
              variant="outlined"
              className={classes.field}
              error={invalid}
              helperText={getHelperText(translateCommon, error)}
              label={translate('email')}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              variant="outlined"
              multiline
              rows={3}
              rowsMax={3}
              className={classes.field}
              error={invalid}
              helperText={getHelperText(translateCommon, error)}
              label={translate('description')}
            />
          )}
        />
        <Typography variant="h6" className={classes.field}>
          {translate('attachment')}
        </Typography>
        <div dir={reverseDirection} className={classes.uploadButtonSpace}>
          <Typography dir="rtl" variant="body2" className={classes.uploadButtonDesc}>
            {translate('maximum5Files')} {translate('filesTypes')}
          </Typography>
          <label>
            <Input type="file" onChange={handleFileChange} accept=".png,.jpg,.jpeg,.pdf,.heic,.hevc,.heif" multiple />
            <Button size="large" variant="outlined" component="span" color="secondary" className={classes.uploadButton}>
              {translate('upload')}
            </Button>
          </label>
        </div>
        <div dir={direction} className={classes.uploadedContainer}>
          {attachments.map((file, i) => (
            <Button
              key={i}
              variant="outlined"
              size="medium"
              color="secondary"
              className={classes.attachment}
              startIcon={<CheckGreyIcon fill="#148287" />}
              endIcon={<CrossCloseGreyIcon fill="#9E9E9E" onClick={() => removeAttachment(i)} />}
            >
              {file.name}
            </Button>
          ))}
        </div>
        {attachments.length < 1 ? <Hint text={translate('atLeastOneFile')} /> : null}
        {sendFailed && <Hint text={translate('sendError')} type={'error'} />}
        <Button variant="outlined" size="medium" color="secondary" className={classes.submitButton} type="submit">
          {translate('submit')}
        </Button>
      </form>
    </ContactUsDialog>
  );
};
