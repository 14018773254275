import React from 'react';
import TableCell from '@material-ui/core/TableCell';

type SelectTableCellProps = {
  className: string;
  isEngLang: boolean;
  nameEN: string;
  nameAR: string;
};
export const SelectTableCell = ({ className, isEngLang, nameEN, nameAR }: SelectTableCellProps) => {
  return isEngLang ? (
    <TableCell className={className}>{nameEN}</TableCell>
  ) : (
    <TableCell className={className}>{nameAR}</TableCell>
  );
};
