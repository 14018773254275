import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enCommon from './en/common.json';
import arCommon from './ar/common.json';
import enErrorMessages from './en/error-messages.json';
import arErrorMessages from './ar/error-messages.json';
import arLandingPage from './ar/landing-page.json';
import enLandingPage from './en/landing-page.json';
import enAdmin from './en/admin.json';
import arAdmin from './ar/admin.json';
import enConfirmation from './en/confirmation.json';
import arConfirmation from './ar/confirmation.json';
import enCompanyRepresentative from './en/company-representative.json';
import arCompanyRepresentative from './ar/company-representative.json';

export enum TranslationNamespace {
  common = 'common',
  errorMessages = 'errorMessages',
  landingPage = 'landingPage',
  admin = 'admin',
  confirmation = 'confirmation',
  companyRepresentative = 'companyRepresentative',
}

export const resources = {
  en: {
    [TranslationNamespace.common]: enCommon,
    [TranslationNamespace.errorMessages]: enErrorMessages,
    [TranslationNamespace.landingPage]: enLandingPage,
    [TranslationNamespace.admin]: enAdmin,
    [TranslationNamespace.confirmation]: enConfirmation,
    [TranslationNamespace.companyRepresentative]: enCompanyRepresentative,
  },
  ar: {
    [TranslationNamespace.common]: arCommon,
    [TranslationNamespace.errorMessages]: arErrorMessages,
    [TranslationNamespace.landingPage]: arLandingPage,
    [TranslationNamespace.admin]: arAdmin,
    [TranslationNamespace.confirmation]: arConfirmation,
    [TranslationNamespace.companyRepresentative]: arCompanyRepresentative,
  },
};

i18n.use(initReactI18next).init({
  lng: 'ar',
  ns: Object.keys(resources.ar),
  resources,
});

export default i18n;
