import { TranslateFn } from 'utilities/translate.hook';
import { SelfAssessmentPassingStatus } from '../../../types/Assessment';

export const getAttemptsMessageFn =
  (translate: TranslateFn) =>
  (passingStatus: SelfAssessmentPassingStatus | null, attemptsNumber: number = 10) => {
    switch (passingStatus) {
      case SelfAssessmentPassingStatus.BELOW_PASSING_SCORE:
        if (attemptsNumber > 1) {
          return translate('attemptsFailInfo', {
            attemptsInfo: `${attemptsNumber}`,
          });
        }
        if (attemptsNumber === 1) {
          return translate('attemptsFailInfoSingular');
        }
        return translate('retryBlocked', {
          attemptsInfo: `${attemptsNumber}`,
        });

      case SelfAssessmentPassingStatus.ABOVE_HIGH_SCORE_VALUE:
      case SelfAssessmentPassingStatus.BELOW_HIGH_SCORE_VALUE:
        return (
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            <li>لقد اجتزت الخطوة الأولى بنجاح،</li>
            <li>للإستمرار إلى مرحلة التدقيق المكتبي والزيارة الميدانية</li>
            <li>المرجو تحويل مبلغ الرسوم 5,750 ريال سعودي إلى الحساب التالي:</li>
            <li>الاسم: شركة تكامل لخدمات الأعمال</li>
            <li>رقم الايبان: SA9245000000003450400919 البنك: البنك الأول SAB</li>
            <li>
              وإرسال إيصال التحويل إلى&nbsp;
              <a href="mailto:OSH@takamol.sa">OSH@takamol.sa</a>
           </li>
          </ul>
        );

      default:
        throw new Error('Unsupported passing SA status');
    }
  };
