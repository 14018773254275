import { Typography, Box, makeStyles } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

import { TranslationNamespace } from 'i18n/config';
import { Button } from 'ui/Button';
import { Dialog } from 'ui/Dialog';
import { useCreateTranslate } from 'utilities/translate.hook';
import { BaseQueryError } from 'infrastructure/persistence/baseQueryWithReAuth';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';

import { useIssuePcMutation } from '../../admin/api/admin.api';

const useStyles = makeStyles((theme) => ({
  typography: {
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  buttonsContainer: {
    display: 'flex',
    flip: false,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    flexWrap: 'wrap',
  },
  button: {
    flex: 1,
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));

type PCRequestIssueDialogProps = {
  open: boolean;
  handleClose: () => void;
  pcRequestId: number;
};

export const PCRequestIssueDialog = ({ open, handleClose, pcRequestId }: PCRequestIssueDialogProps) => {
  const classes = useStyles();
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const showNotification = useNotifications();
  const [issuePc] = useIssuePcMutation();

  const handleIssuePc = async () => {
    try {
      await issuePc(pcRequestId)
        .unwrap()
        .then(() => {
          showNotification(NotificationType.Success, { message: translate('issuePcSuccess') });
          handleClose();
        });
    } catch (err) {
      const error = err as BaseQueryError;
      showNotification(NotificationType.Error, { errorId: error.data.errorCode });
      handleClose();
    }
  };

  return (
    <Dialog open={open} handleClose={handleClose}>
      <Box>
        <Typography
          data-testid="pc-request-issue-dialog-title"
          variant="h6"
          className={clsx(classes.typography, classes.title)}
        >
          {translate('issuePcDialogTitle')}
        </Typography>
        <Typography variant="body2" className={classes.typography}>
          {translate('undoneAction')}
        </Typography>
        <Box className={classes.buttonsContainer}>
          <Button className={classes.button} onClick={handleClose} variant="outlined" color="secondary" size="large">
            {translate('cancel')}
          </Button>
          <Button className={classes.button} onClick={handleIssuePc} color="secondary" variant="contained" size="large">
            {translate('issuePc')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
