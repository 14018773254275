import { Page } from 'infrastructure/persistence/types';

export type QuestionnaireSectionDto = {
  id: number;
  nameAR: string;
  nameEN: string;
  descriptionAR: string;
  descriptionEN: string;
  order: number;
  created_at: string;
  questionnaire_id: number;
  total_weight: string;
  questions_no: number;
};

export type QuestionnaireSection = {
  id: number;
  nameAR: string;
  nameEN: string;
  descriptionAR: string;
  descriptionEN: string;
  order: number;
  createdAt: string;
  questionnaireId: number;
  totalWeight: string;
  questionsNo: number;
};

export type GetSectionsQuery = Page & {
  questionnaireId: number;
};

export type CreateQuestionnaireSectionDto = Pick<QuestionnaireSectionDto, 'questionnaire_id' | 'nameAR' | 'nameEN'
| 'descriptionAR' | 'descriptionEN'>;

const toTotalWeight = (totalWeight: string | null) => {
  if (totalWeight) {
    return totalWeight === 'None' ? '0' : Number(totalWeight).toString();
  }

  return '0';
};

export type UpdateSectionDto = {
  id: number;
  data: CreateQuestionnaireSectionDto;
};

export const toQuestionnaireSection = (dto: QuestionnaireSectionDto): QuestionnaireSection => ({
  id: dto.id,
  nameAR: dto.nameAR,
  nameEN: dto.nameEN,
  descriptionAR: dto.descriptionAR,
  descriptionEN: dto.descriptionEN,
  order: dto.order,
  createdAt: dto.created_at,
  questionnaireId: dto.questionnaire_id,
  totalWeight: toTotalWeight(dto.total_weight),
  questionsNo: dto.questions_no,
});
