import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import i18n from 'i18next';

import { labelCardColor } from 'ui/styles';
import { AssessmentResultQuestionAttachments } from 'ui/assessment/AssessmentResultQuestionAttachments';

import { AssessmentQuestion } from '../../../types/AssessmentQuestion';
import { AssessmentAnswer } from '../../../types/AssessmentAnswer';
import { CompanyRepresentativeSAResultQuestionAnswer } from './CompanyRepresentativeSAResultQuestionAnswer';
import { getDirection } from '../../../utilities/useLanguage';

type ResultQuestionProps = {
  questionNo: number;
  question: AssessmentQuestion;
  answer: AssessmentAnswer;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    padding: '32px 102px',
    display: 'flex',
    flexDirection: 'row',
  },

  main: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  content: {
    alignItems: 'stretch',
    minWidth: '912px',
    maxWidth: '912px',
  },

  questionDescription: {
    marginTop: theme.spacing(2),
    color: labelCardColor,
  },

  answerWrapper: {
    marginTop: theme.spacing(5),
  },
}));

export const CompanyRepresentativeSAResultQuestion: React.FC<ResultQuestionProps> = ({
  questionNo,
  question,
  answer,
}) => {
  const classes = useStyles();
  const direction = getDirection(i18n.language);

  return (
    <div dir={direction} className={classes.wrapper}>
      <div className={classes.main}>
        <div className={classes.content}>
          <Typography variant="h6">
            {questionNo}. {i18n.language === 'en' ? question.textEN : question.textAR}
          </Typography>
          <Typography dir={direction} className={classes.questionDescription} variant="body2">
            {i18n.language === 'en' ? question.descriptionEN : question.descriptionAR}
          </Typography>
        </div>
        <div className={classes.answerWrapper}>
          <CompanyRepresentativeSAResultQuestionAnswer answer={answer} />
        </div>
        <AssessmentResultQuestionAttachments attachments={answer.attachments} />
      </div>
    </div>
  );
};
