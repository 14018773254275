import React from 'react';

import { Establishment } from 'types/Establishment';
import { DetailsHeader } from 'ui/DetailsHeader';


type CREstablishmentDetailsHeaderProps = {
  establishment: Establishment;
};
export const CREstablishmentDetailsHeader = ({ establishment }: CREstablishmentDetailsHeaderProps) => {

  return (
      <DetailsHeader  title={establishment.nameAr}/>
  );
};
