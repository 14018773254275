import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootStoreActionType } from 'infrastructure/store/rootStore.actions';
import { establishmentToEstablishmentInfo, EstablishmentInfo } from 'types/Establishment';

import { companyRepresentativeEnhancedApi } from '../api/companyRepresentative.api';

type SliceState = {
  establishmentExtId: string;
  establishmentInfo?: EstablishmentInfo;
};

const initialState: SliceState = {
  establishmentExtId: '',
};

export const companyRepresentativeLoginSlice = createSlice({
  name: 'companyRepresentativeLogin',
  initialState,
  reducers: {
    setEstablishmentExtId: (state, action: PayloadAction<string>) => {
      state.establishmentExtId = action.payload;
    },
    setEstablishmentInfo: (state, action: PayloadAction<EstablishmentInfo>) => {
      state.establishmentInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RootStoreActionType.resetState, () => initialState);
    builder.addMatcher(
      companyRepresentativeEnhancedApi.endpoints.getEstablishment.matchFulfilled,
      (state, { payload }) => {
        state.establishmentInfo = establishmentToEstablishmentInfo(payload);
      }
    );
  },
});

export const { setEstablishmentExtId, setEstablishmentInfo } = companyRepresentativeLoginSlice.actions;
