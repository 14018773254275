import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useState } from 'react';

import { toLocaleDate } from 'utilities/localDate';
import { TranslationNamespace } from 'i18n/config';
import { PCRequest, PCRequestStatus } from 'types/PCRequest';
import { useCreateTranslate } from 'utilities/translate.hook';

//import { AuditorList } from '../utils/getPcRequestStatus';
//import { useGetMeQuery } from '../../../infrastructure/services/user.api';
import { PCRequestTable } from './PCRequestTable';
import { Routes } from '../Routes';
import { Search } from '../Search'

type PCRequestsListProps = {
  getPath: (value: Routes) => string;
};

const useStyles = makeStyles((theme) => ({
  main: {
    padding: 0,
    marginTop: theme.spacing(15),
  },
  pageTitle: {
    marginBottom: theme.spacing(1.5),
  },

  header: {
    [theme.breakpoints.down('md')]: {
      padding: `0 ${theme.spacing(6)}px`,
    },
  },
}));

export const PCRequestList: React.FC<PCRequestsListProps> = ({ getPath }) => {
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const classes = useStyles();
  const [query, setQuery] = useState('');
  //const { data: currentUser } = useGetMeQuery();

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;
    setQuery(value);
  };

  const getDate = (pcRequest?: Date) => {
    return pcRequest? toLocaleDate(pcRequest): '-';
  };

  const getAssignedTo = React.useCallback((pcRequest: PCRequest) => {
    switch (pcRequest.status) {
      case PCRequestStatus.auditorAssigned:
      case PCRequestStatus.AuditorStarted:
      case PCRequestStatus.AuditorFinished:
      case PCRequestStatus.AuditorApproved:
        return `${pcRequest.auditor?.firstName} ${pcRequest.auditor?.lastName}`;
      case PCRequestStatus.InspectorApproved:
      case PCRequestStatus.PCIssued:
      case PCRequestStatus.InspectorRejected:
      case PCRequestStatus.AuditorRejected:
        return;
      case PCRequestStatus.InspectorAssigned:
      case PCRequestStatus.InspectorStarted:
      case PCRequestStatus.InspectorFinished:
        return `${pcRequest.inspector?.firstName} ${pcRequest.inspector?.lastName}`;
      default: return translate('unassigned');
      // return (
      //   <div>
      //     'unassigned'
      //     {/* {currentUser &&
      //       AuditorList(currentUser.currentPlatform).map((role) => (
      //         <Typography key={role.nameAuditor} variant="body1">
      //           {translate(role.nameAuditor)}
      //          </Typography>
      //     ))} */}
      //    </div>

      //   );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Container className={classes.main} maxWidth="lg">
      <div className={classes.header}>
        <Typography variant="h4" className={classes.pageTitle}>
          {translate('pcRequests')}
        </Typography>
      </div>
      <Search name={translate('searchPCRequest')} onChange={(e) => handleSearch(e)}/>
      <PCRequestTable getPath={getPath} searchPCRequest={query} getDate={getDate} getAssignedTo={getAssignedTo}/>
    </Container>
  );
};
