import { makeStyles } from '@material-ui/core';
import React from 'react';
import Paper from '@material-ui/core/Paper';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as NoPcRequestIcon } from 'ui/icons/no-establishmentdata.svg';
import { shdShadow } from 'ui/styles';
import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';



const useStyles = makeStyles((theme) => ({
  noQuestionnaires: {
    padding: '95px 0',
    boxShadow: shdShadow,
    textAlign: 'center',
  },

  noQuestionnairesIcon: {
    height: '225.621px',
    width: 'auto',
    fill: 'none',
  },

  title: {
    marginTop: '34px',
    fontWeight: 'bold',
    fontSize: '30px',
    lineHeight: '20px',
    letterSpacing: '0.14px',
  },

  titleCaption: {
    marginTop: theme.spacing(5),
  },
}));

export const EstablishmentNoData = () => {

  const translate = useCreateTranslate(TranslationNamespace.admin);
  const classes = useStyles();

  return (
    <Paper data-testid="establishment-table-no-data" className={classes.noQuestionnaires}>
      <SvgIcon className={classes.noQuestionnairesIcon} component={NoPcRequestIcon} viewBox="0 0 338.289 225.621" />
      <Typography  className={classes.title}>{translate('establishmentNoData')}</Typography>
      <Typography className={classes.titleCaption} variant="body2">
        {translate('descriptionEstablishmentNoData')}
      </Typography>
    </Paper>
  );
};
