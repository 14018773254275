import { Container, makeStyles } from '@material-ui/core';
import React from 'react';

export type AppContentProps = {
  maxWidth?: false | 'md' | 'xs' | 'sm' | 'lg' | 'xl' | undefined;
};

const useStyles = makeStyles((theme) => ({
  main: {
    padding: 0,
    marginBottom: '40px',
  },
}));

export const AppContent: React.FC<AppContentProps> = ({ maxWidth = 'md', children }) => {
  const classes = useStyles();

  return (
    <Container maxWidth={maxWidth} className={classes.main}>
      <div>{children}</div>
    </Container>
  );
};
