import Keycloak from 'keycloak-js';

import { REACT_APP_SSO_URL, REACT_APP_SSO_CLIENT_ID, REACT_APP_SSO_REALM } from './infrastructure/core/variables';

const keycloak = new Keycloak({
  url: REACT_APP_SSO_URL || '',
  realm: REACT_APP_SSO_REALM || '',
  clientId: REACT_APP_SSO_CLIENT_ID || '',
});

export default keycloak;
