import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { PCRequestAssessmentCurrentAnswer } from '././PCRequestAssessmentCurrentAnswer';
import { PCRequestAssessmentPreviousAnswer } from '././PCRequestAssessmentPreviousAnswer';
import { QuestionAndAnswers } from '././types/QuestionAndAnswers';

type PCRequestAssessmentAnswerProps = {
  assessmentId: number;
  questionAndAnswer: QuestionAndAnswers;
};

const useStyle = makeStyles((theme) => ({
  main: {
    flip: false,
    display: 'flex',
    flexDirection: 'row',
  },
}));

export const PCRequestAssessmentAnswer: React.FC<PCRequestAssessmentAnswerProps> = ({
  assessmentId,
  questionAndAnswer: { question, auditorAnswer, crAnswer, currentAnswer, currentAssessmentType },
}) => {
  const classes = useStyle();

  return (
    <div className={classes.main}>
      <PCRequestAssessmentCurrentAnswer
        assessmentId={assessmentId}
        question={question}
        answer={currentAnswer}
        currentAssessmentType={currentAssessmentType}
      />
      {crAnswer && (
        <PCRequestAssessmentPreviousAnswer
          crAnswer={crAnswer}
          auditorAnswer={auditorAnswer}
          currentAssessmentType={currentAssessmentType}
        />
      )}
    </div>
  );
};
