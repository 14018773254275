import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import i18n from 'i18next';

import { BottomBorderWrapper } from 'ui/BottomBorderWrapper';
import { AssessmentAnswer } from 'types/AssessmentAnswer';
import { LoadingSpinner } from 'ui/LoadingSpinner';
import { AssessmentQuestion } from 'types/AssessmentQuestion';
import { PCRequestStatus } from 'types/PCRequest';

import {
  useGetAnswersByAssessmentIdAndSectionIdQuery,
  useGetQuestionsByAssessmentIdAndSectionIdQuery,
} from '../../admin/api/admin.api';
import { AssessmentCompareQuestion } from './AssessmentCompareQuestion';
import { PcRequestAssessmentCompareInfo } from '../assessment-compare/types/PcRequestAssessmentCompareInfo';
import { QuestionAndAnswerCompare } from '../assessment-compare/types/QuestionAndAnswersCompare';
import { getReverseDirection as getReverseDirectionfindAnswersForQuestion } from '../../../utilities/useLanguage';

type AssessmentCompareQuestionsProps = {
  pcAssessmentInfo: PcRequestAssessmentCompareInfo;
  sectionId: number;
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
}));

const findAnswersForQuestion = (
  pcRequestStatus: PCRequestStatus,
  questions: AssessmentQuestion[],
  inspectorAnswers: AssessmentAnswer[],
  crAnswers: AssessmentAnswer[],
  auditorAnswers: AssessmentAnswer[]
): QuestionAndAnswerCompare[] =>
  questions.map((question) => ({
    pcRequestStatus,
    question,
    inspectorAnswer: inspectorAnswers.find(({ questionId }) => questionId === question.id),
    crAnswer: crAnswers.find(({ questionId }) => questionId === question.id),
    auditorAnswer: auditorAnswers.find(({ questionId }) => questionId === question.id),
  }));

export const AssessmentCompareQuestions: React.FC<AssessmentCompareQuestionsProps> = ({
  pcAssessmentInfo: { crAssessmentId, inspectorAssessmentId = 0, pcRequestStatus, auditorAssessmentId = 0 },
  sectionId,
}) => {
  const classes = useStyles();
  const { data: { results: questions } = { count: 0, results: [] }, isLoading: isLoadingQuestions } =
    useGetQuestionsByAssessmentIdAndSectionIdQuery({
      assessmentId: crAssessmentId,
      sectionId,
    });
  const { data: { results: crAnswers } = { count: 0, results: [] }, isLoading: isLoadingPrevAnswers } =
    useGetAnswersByAssessmentIdAndSectionIdQuery({
      assessmentId: crAssessmentId,
      assessmentSectionId: sectionId,
    });
  const { data: { results: auditorAnswers } = { count: 0, results: [] }, isLoading: isLoadingAuditorAnswers } =
    useGetAnswersByAssessmentIdAndSectionIdQuery(
      {
        assessmentId: auditorAssessmentId,
        assessmentSectionId: sectionId,
      },
      {
        skip: auditorAssessmentId < 1,
      }
    );

  const { data: { results: inspectorAnswers } = { count: 0, results: [] }, isLoading: isLoadingCurrentAnswers } =
    useGetAnswersByAssessmentIdAndSectionIdQuery(
      {
        assessmentId: inspectorAssessmentId,
        assessmentSectionId: sectionId,
      },
      {
        skip: inspectorAssessmentId < 1,
      }
    );
  const isLoading = isLoadingCurrentAnswers || isLoadingPrevAnswers || isLoadingQuestions || isLoadingAuditorAnswers;
  const reverseDirection = getReverseDirectionfindAnswersForQuestion(i18n.language);

  return (
    <div dir={reverseDirection} className={classes.main}>
      {isLoading ? (
        <BottomBorderWrapper>
          <LoadingSpinner></LoadingSpinner>
        </BottomBorderWrapper>
      ) : (
        findAnswersForQuestion(pcRequestStatus, questions, inspectorAnswers, crAnswers, auditorAnswers).map(
          (questionAndAnswer, index) => {
            const questionKey = `sa-question-${sectionId}-${questionAndAnswer.question.id}`;

            return index < questions.length - 1 ? (
              <BottomBorderWrapper key={questionKey}>
                <AssessmentCompareQuestion questionNo={index + 1} questionAndAnswers={questionAndAnswer} />
              </BottomBorderWrapper>
            ) : (
              <AssessmentCompareQuestion
                key={questionKey}
                questionNo={index + 1}
                questionAndAnswers={questionAndAnswer}
              />
            );
          }
        )
      )}
    </div>
  );
};
