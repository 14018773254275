import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { baseQueryWithReAuth } from 'infrastructure/persistence/baseQueryWithReAuth';
import { ChangeLangInput, ChangePlatformInput, toUser, User, UserDto } from 'types/User';

import { UserApiTagType, userApiTagTypes } from './user.tags.api';

const UNUSED_DATA_TIME = 60 * 60 * 2; // 2 hours

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: baseQueryWithReAuth(),
  tagTypes: userApiTagTypes,
  endpoints: (build) => ({
    getMe: build.query<User, void>({
      query: () => ({
        url: '/user/me/',
      }),
      providesTags: (responseData) =>
        responseData ? [{ type: UserApiTagType.UserData, id: responseData.id }] : [UserApiTagType.UserData],
      transformResponse: (response: UserDto) => toUser(response),
      // How much time RTK Query should keep the response from this endpoint cached
      keepUnusedDataFor: UNUSED_DATA_TIME,
    }),
    changeLang: build.mutation<User, ChangeLangInput>({
      query: (data) => ({
        url: '/user/change_lang/',
        method: 'put',
        data,
      }),
      transformResponse: (response: UserDto) => toUser(response),
    }),
    changePlatform: build.mutation<User, ChangePlatformInput>({
      query: (data) => ({
        url: '/user/change_platform/',
        method: 'put',
        data,
      }),
      transformResponse: (response: UserDto) => toUser(response),
    }),
  }),
});

export const { useGetMeQuery, useChangeLangMutation, useChangePlatformMutation } = userApi;
