import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';

const useStyle = makeStyles((theme) => ({
  main: {
    display: 'flex',
    alignItems: 'center',
    padding: '24px',
    justifyContent: 'center',
  },
  admin: {
    height: '50vh',
  },
}));

interface LoadingSpinnerProps {
  type?: string;
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ type = '' }) => {
  const classes = useStyle();
  return (
    <div className={type === 'admin' ? `${classes.main} ${classes.admin}` : classes.main}>
      <CircularProgress color="primary" />
    </div>
  );
};
