import { toEconomicActivity } from '../../../types/EconomicActivity';
import { AssessmentTemplate, AssessmentTemplateDto } from './AssessmentTemplate';

export type AssessmentTemplateDetails = Pick<
  AssessmentTemplate,
  'id' | 'status' | 'createdAt' | 'version' | 'economicActivity' | 'nameAR' |  'nameEN' | 'questionsNoSummary' | 'publishDate'
>;

export const toAssessmentTemplateDetails = ({
  id,
  status,
  created_at,
  version,
  economic_activity,
  nameAR,
  nameEN,
  questions_no,
  publish_date,
}: AssessmentTemplateDto): AssessmentTemplateDetails => ({
  id,
  nameAR,
  nameEN,
  version,
  status,
  createdAt: new Date(created_at),
  questionsNoSummary: questions_no,
  economicActivity: toEconomicActivity(economic_activity),
  publishDate: publish_date ? new Date(publish_date) : undefined,
});
