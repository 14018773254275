import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';

import { useCreateTranslate } from 'utilities/translate.hook';
import i18n, { TranslationNamespace } from 'i18n/config';
import { Routes } from 'infrastructure/routes';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalStyle: {
    backgroundColor: '#ffffff',
    display: 'flex',
    width: '680px',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '8px',
    borderRadius: '15px',
    background: '#FFF',
    boxShadow: '0px 0px 13px 0px rgba(0, 0, 0, 0.10)',
  },
  headerStyle: {
    display: 'flex',
    paddingTop: '16px',
    paddingRight: '32px',
    paddingLeft: '32px',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    borderBottom: '1px solid #E2E2EA',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '8px',
  },
  titleStyle: {
    color: '#14415A',
    textAlign: 'left',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '28px' /* 140% */,
  },
  frameWrapper: {
    display: 'flex',
    padding: '16px 32px 32px 32px',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '20px',
    alignSelf: 'stretch',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '8px',
    alignSelf: 'stretch',
  },
  contentStyle: {
    color: 'transparent',
    flex: 1,
    fontSize: '22px',
    fontWeight: 400,
    letterSpacing: '-0.44px',
    lineHeight: '40px',
    marginTop: '-1.00px',
    position: 'relative',
    textAlign: 'justify',
  },
  textStyle: {
    color: '#000',
    textAlign: 'justify',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '40px',
    letterSpacing: '-0.44px',
  },
  textRouteStyle: {
    color: '#148287',
    textAlign: 'justify',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '40px',
    letterSpacing: '-0.44px',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '16px',
    alignSelf: 'stretch',
  },
  cancelButton: {
    display: 'flex',
    width: '120px',
    height: '40px',
    padding: '0px 43px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    borderRadius: '5px',
    border: '1px solid var(--new-color, #148287)',
    background: '#FFF',
  },
  cancelButtonText: {
    color: '#148287',
    textAlign: 'center',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: '0.114px',
  },
  agreeButton: {
    display: 'flex',
    width: '120px',
    height: '40px',
    padding: '0px 43px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '5px',
    background: 'var(--new-color, #148287)',
    border: '1px solid var(--new-color, #148287)',
    '&:hover': {
      background: '#2A9D8F',
      color: 'red',
    },
  },
  agreeButtonText: {
    color: '#FFF',
    textAlign: 'center',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '30px',
    letterSpacing: '0.114px',
  },
}));

interface PrivacyPolicyModalProps {
  open: boolean;
  handleClose: () => void;
  handleAgree: () => void;
}

const PrivacyPolicyModal: React.FC<PrivacyPolicyModalProps> = ({ open, handleClose, handleAgree }) => {
  const classes = useStyles();
  const translate = useCreateTranslate(TranslationNamespace.landingPage);
  const translateCommon = useCreateTranslate(TranslationNamespace.common);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.modalStyle}>
          <div className={classes.headerStyle}>
            <div className={classes.titleWrapper}>
              <p className={classes.titleStyle}>{translate('privacyPolicy')}</p>
            </div>
          </div>

          <div className={classes.frameWrapper}>
            <div className={classes.contentWrapper}>
              <p className={classes.contentStyle}>
                <span className={classes.textStyle}>{translate('privacyPolicyPopup')}</span>
                <a href={Routes.TERMS_AND_CONDITIONS} className={classes.textRouteStyle} target="_blank" rel="noopener noreferrer">
                  {translate('termsAndConditions')}
                </a>
                <span className={classes.textStyle}>{i18n.language === 'en' ? ', ' : '، '}</span>
                <a href={Routes.PRIVACY_POLICY} className={classes.textRouteStyle} target="_blank" rel="noopener noreferrer">
                  {translateCommon('and')}{translate('privacyPolicy')}
                </a>
                <span className={classes.textStyle}>{translate('privacyPolicyPopup2')}</span>
              </p>
            </div>

            <div className={classes.buttonsWrapper}>
              <Button className={classes.agreeButton} onClick={handleAgree}>
                <div className={classes.agreeButtonText}>{translateCommon('agree')}</div>
              </Button>

              <Button className={classes.cancelButton} onClick={handleClose}>
                <div className={classes.cancelButtonText}>{translateCommon('cancel')}</div>
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default PrivacyPolicyModal;
