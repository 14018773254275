import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';

import { shdShadow } from 'ui/styles';

import { useGetSectionsByAssessmentIdQuery } from '../api/companyRepresentative.api';
import { CompanyRepresentativeSASummaryHeader } from './CompanyRepresentativeSASummaryHeader';
import { CompanyRepresentativeSASummarySection } from './CompanyRepresentativeSASummarySection';

type PropTypes = {
  assessmentId: number;
  validSectionsCount: number;
};

const useStyles = makeStyles((theme) => ({
  main: {
    width: theme.breakpoints.values.lg,
    marginTop: theme.spacing(4),
    boxShadow: shdShadow,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
}));

export const CompanyRepresentativeSASummary: React.FC<PropTypes> = ({ assessmentId, validSectionsCount }) => {
  const classes = useStyles();

  const {
    data: { results: sections } = {
      count: 0,
      results: [],
    },
  } = useGetSectionsByAssessmentIdQuery({
    assessmentId,
  });
  return (
    <Paper className={classes.main}>
      <CompanyRepresentativeSASummaryHeader sectionsCount={sections.length} validSectionsCount={validSectionsCount} />
      {sections.map((section, index) => (
        <CompanyRepresentativeSASummarySection
          key={`summary-section-${section.id}`}
          isCompleted={section.questionsNo === section.validAnswersNo}
          nameAR={section.nameAR}
          nameEN={section.nameEN}
          sectionNumber={index + 1}
        />
      ))}
    </Paper>
  );
};
