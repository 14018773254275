import React from 'react';
import { Box, Tooltip, makeStyles } from '@material-ui/core';
import i18n from 'i18next';
import SvgIcon from '@material-ui/core/SvgIcon';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { useNavigate } from 'utilities/navigate';
import { CustomBreadCrumbs } from 'ui/CustomBreadCrumbs';
import { ReactComponent as ArrowForwardIcon } from 'ui/icons/arrow_forward.svg';
import { ReactComponent as ArrowBackIcon } from 'ui/icons/arrow-back.svg';
import { backButtonBackgroundColor } from 'ui/styles';
import { Button } from 'ui/Button';
import { DetailsHeader } from 'ui/DetailsHeader';
import { useGetMeQuery } from 'infrastructure/services/user.api';
import { useStartPcRequestAssessmentMutation } from 'domain/admin/api/admin.api';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { BaseQueryError } from 'infrastructure/persistence/baseQueryWithReAuth';
import { PCRequest, PCRequestStatus } from 'types/PCRequest';
import { availablePcRequestActions } from 'domain/admin/utils/availablePcRequestActions';

import { PCRequestSpecialistDialog } from '../PCRequestSpecialistDialog';
import { PCRequestIssueDialog } from '../PCRequestIssueDialog';
import { Routes } from '../../../common/Routes';

const useStyles = makeStyles((theme) => ({
  disabledButton: {
    height: '100%',
  },
  backButtonBase: {
    flip: false,
    height: '50px',
    width: '80px',
  },

  backButton: {
    backgroundColor: backButtonBackgroundColor,
    color: theme.palette.secondary.main,
  },
}));

type PCRequestDetailsHeaderProps = {
  pcRequest: PCRequest;
  getPath: (value: Routes) => string;
};

export const PCRequestDetailsHeader = ({
  pcRequest: { id, status, auditor, establishment, inspector },
  getPath,
}: PCRequestDetailsHeaderProps) => {
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const navigate = useNavigate();
  const classes = useStyles();
  const { data: currentUser } = useGetMeQuery();
  const [startAssessment] = useStartPcRequestAssessmentMutation();
  const showNotification = useNotifications();
  const [isAssignSpecialistOpen, setIsAssignSpecialistOpen] = React.useState(false);
  const [isIssuePcOpen, setIsIssuePcOpen] = React.useState(false);

  const {
    isAbleToStartAudit,
    isAbleToContinueAudit,
    isAbleToIssuePc,
    isAbleToAssignSpecialist,
    isAbleToStartInspection,
    isAbleToContinueInspection,
  } = availablePcRequestActions(status, auditor, inspector, currentUser);

  const goBack = () => {
    navigate(getPath(Routes.pcRequests));
  };

  const goToEstablishmentDetails = () => {
    navigate(`${getPath(Routes.establishment)}/${establishment.id}`);
  };

  const onStartAssessment = async () => {
    try {
      await startAssessment(id)
        .unwrap()
        .then(() => {
          navigate(`${getPath(Routes.pcRequests)}/${id}${Routes.assessment}`);
        });
    } catch (err) {
      const error = err as BaseQueryError;
      if (!error.data) {
        showNotification(NotificationType.Error);
      }
      showNotification(NotificationType.Error, { errorId: error.data.errorCode });
    }
  };

  const onContinueAssessment = () => {
    navigate(`${getPath(Routes.pcRequests)}/${id}${Routes.assessment}`);
  };

  const startAssessmentButton =
    isAbleToStartAudit || isAbleToStartInspection ? (
      <Button
        data-testid="pc-request-start-assessment-button"
        onClick={onStartAssessment}
        variant="contained"
        color="secondary"
      >
        {isAbleToStartAudit ? translate('startAuditing') : translate('startInspection')}
      </Button>
    ) : (
      <Tooltip title={translate('alreadyAssigned')} arrow>
        <span>
          <Button
            data-testid="pc-request-disabled-auditing-btn"
            className={classes.disabledButton}
            disabled
            variant="contained"
            color="secondary"
          >
            {isAbleToStartAudit ? translate('startAuditing') : translate('startInspection')}
          </Button>
        </span>
      </Tooltip>
    );

  return (
    <div>
      <Box display="flex" alignItems="flex-end">
        <CustomBreadCrumbs
          currentLocationLabel={translate('pcDetailsCrumb')}
          parentCrumbs={[
            {
              label: translate('pcRequests'),
              path: getPath(Routes.pcRequests),
            },
          ]}
        />
      </Box>
      <DetailsHeader title={establishment.nameAr}>
        <>
          <Button
            onClick={goBack}
            variant="outlined"
            size="large"
            className={`${classes.backButtonBase} ${classes.backButton}`}
            color="secondary"
          >
            {i18n.language === 'en' ? <SvgIcon component={ArrowForwardIcon} /> : <SvgIcon component={ArrowBackIcon} />}
          </Button>

          {establishment.id ? (
            <Button onClick={goToEstablishmentDetails} variant="outlined" color="secondary">
              {translate('pcViewEstablishment')}
            </Button>
          ) : null}

          {(status === PCRequestStatus.auditorAssigned || status === PCRequestStatus.InspectorAssigned) &&
            startAssessmentButton}

          {(isAbleToContinueAudit || isAbleToContinueInspection) && (
            <Button onClick={onContinueAssessment} variant="contained" color="secondary">
              {isAbleToContinueAudit ? translate('continueAuditing') : translate('continueInspection')}
            </Button>
          )}

          {isAbleToAssignSpecialist && (
            <Button
              data-testid="pc-request-details-assign-specialist-btn"
              onClick={() => setIsAssignSpecialistOpen(true)}
              variant="contained"
              color="secondary"
            >
              {status === PCRequestStatus.AuditorApproved ? translate('assignInspector') : translate('assignAuditor')}
            </Button>
          )}

          {isAbleToIssuePc && (
            <Button
              data-testid="issue-pc-button"
              onClick={() => setIsIssuePcOpen(true)}
              variant="contained"
              color="secondary"
            >
              {translate('issuePc')}
            </Button>
          )}
        </>
      </DetailsHeader>
      {isAbleToAssignSpecialist && (
        <PCRequestSpecialistDialog
          auditorId={auditor ? auditor.id : null}
          open={isAssignSpecialistOpen}
          handleClose={() => setIsAssignSpecialistOpen(false)}
          pcRequestId={id}
          pcStatus={status}
        />
      )}

      {isAbleToIssuePc && (
        <PCRequestIssueDialog open={isIssuePcOpen} handleClose={() => setIsIssuePcOpen(false)} pcRequestId={id} />
      )}
    </div>
  );
};
