import { SubmitHandler } from 'react-hook-form';

import {
  Container,
  makeStyles,
  TextField,
  Typography,
  useForm,
  React,
  useCallback,
  Redirect,
  useGoogleReCaptcha,
  TranslationNamespace,
  useCreateTranslate,
  Button,
  shdShadow,
  useAppDispatch,
  useAppSelector,
  selectIsLoginInProgress,
  selectLoginError,
  selectUserTowFactor,
  emailRegex,
  PublicLayout,
  Routes,
  loginAdmin,
  loginAuditor,
  loginInspecor,
} from '../utils/importLib';
import { AdminLoginCredentials } from '../../../infrastructure/services/auth.type';

const useLoginStyles = makeStyles((theme) => ({
  formWrapper: {
    width: theme.breakpoints.values.md,
    maxWidth: theme.breakpoints.values.md,
    padding: '40px 120px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: shdShadow,
    marginTop: theme.spacing(6),
  },

  title: {
    marginBottom: theme.spacing(1),
    textAlign: 'center',
  },

  description: {
    marginBottom: theme.spacing(5),
    textAlign: 'center',
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
  },
  input: {
    marginBottom: '32px',
  },

  invalidCredentials: {
    height: '40px',
  },

  continueButton: {
    height: '50px',
  },
}));
type LoginProps = {
  getPath: (value: Routes) => string;
  login: string;
  continueMessage: string;
};

export const Login: React.FC<LoginProps> = ({ getPath, login, continueMessage }) => {
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const translateCommon = useCreateTranslate(TranslationNamespace.common);

  const dispatch = useAppDispatch();
  const isLoginInProgress = useAppSelector(selectIsLoginInProgress);
  const loginError = useAppSelector(selectLoginError);
  const userTowFactor = useAppSelector(selectUserTowFactor);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const classes = useLoginStyles();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<AdminLoginCredentials>();

  const onSubmit: SubmitHandler<AdminLoginCredentials> = useCallback(
    async (data) => {
      if (!executeRecaptcha) {
        return;
      }

      const token = await executeRecaptcha('login_page');
      let newData = { ...data } as AdminLoginCredentials;
      newData.token = token;
      switch (login) {
        case 'admin':
          return dispatch(loginAdmin(newData));
        case 'auditor':
          return dispatch(loginAuditor(newData));
        case 'inspector':
          return dispatch(loginInspecor(newData));
      }
    },
    // eslint-disable-next-line
    [executeRecaptcha]
  );

  if (userTowFactor) {
    return <Redirect to={getPath(Routes.authenticate)} />;
  }

  return (
    <PublicLayout>
      <Container className={classes.formWrapper}>
        <Typography variant="h4" className={classes.title}>
          {translate('signInAdmin')}
        </Typography>
        <Typography variant="body1" className={classes.description}>
          {translate('description')}
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <TextField
            {...register('email', {
              required: true,
              pattern: {
                value: emailRegex,
                message: translateCommon('invalidEmail'),
              },
            })}
            type="text"
            variant="outlined"
            className={classes.input}
            error={!!errors?.email}
            helperText={errors?.email ? errors.email.message : null}
            label={translate('enterYourUsername')}
          />
          <TextField
            {...register('password', { required: true })}
            type="password"
            variant="outlined"
            error={errors.password?.type === 'required'}
            label={translate('enterYourPassword')}
          />
          <div className={classes.invalidCredentials}>
            {loginError && (
              <Typography variant="caption" color="error">
                {translate('invalidCredentials')}
              </Typography>
            )}
          </div>

          <Button
            className={classes.continueButton}
            type="submit"
            disabled={!errors || isLoginInProgress}
            variant="contained"
            color="secondary"
          >
            {translate(continueMessage)}
          </Button>
        </form>
      </Container>
    </PublicLayout>
  );
};
