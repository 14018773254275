import React, { FormEventHandler } from 'react';

import { isQuestionnaireDraftOrMissing } from 'domain/admin/utils/QuestionnaireUtils';

import { AssessmentTemplate } from '../types/AssessmentTemplate';
import { QuestionnaireReviewGeneralInfo } from './questionnaire-review/QuestionnaireReviewGeneralInfo';
import { QuestionnaireReviewSections } from './questionnaire-review/QuestionnaireReviewSections';
import { QuestionnaireReviewScoring } from './questionnaire-review/QuestionnaireReviewScoring';
import { AdminQuestionnaireStepProps } from './AdminQuestionnaireStepOne';

type AdminQuestionnaireReviewProps = AdminQuestionnaireStepProps & {
  setStepIndex: React.Dispatch<React.SetStateAction<number>>;
  questionnaire: AssessmentTemplate;
};

export const AdminQuestionnaireReview: React.FC<AdminQuestionnaireReviewProps> = ({
  setStepIndex,
  questionnaire,
  goNextStep,
}) => {
  const isEditable = isQuestionnaireDraftOrMissing(questionnaire);
  const onSubmit: FormEventHandler = (event) => {
    event.preventDefault();

    goNextStep(event);
  };

  return (
    <>
      <form id="stepForm" onSubmit={onSubmit} />
      <QuestionnaireReviewGeneralInfo
        setStepIndex={setStepIndex}
        questionnaire={questionnaire}
        isEditable={isEditable}
      />
      <QuestionnaireReviewSections setStepIndex={setStepIndex} questionnaire={questionnaire} isEditable={isEditable} />
      <QuestionnaireReviewScoring setStepIndex={setStepIndex} questionnaire={questionnaire} isEditable={isEditable} />
    </>
  );
};
