import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { AxiosError, AxiosRequestConfig } from 'axios';

import { refreshToken } from 'infrastructure/services/auth.api';
import { RootState } from "infrastructure/store/rootStore.types"

import { createAxiosInstance, createHeaderWithAuthorization, defaultAxiosInstance } from './axiosInstance';
import { AxiosErrorResponseData } from './types';

export const createAxiosInstanceWithRefreshInterceptor = (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  getState: () => RootState,
  axiosConfig?: Partial<AxiosRequestConfig>
) => {
  const instance = createAxiosInstance(axiosConfig);

  instance.interceptors.response.use(
    (response) => response,
    async (error: AxiosError<AxiosErrorResponseData>) => {
      if (!error.response) {
        return Promise.reject(error);
      }

      if (error.response.status === 401) {
        return reAuthAndResendRequest(error, dispatch, getState);
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export const reAuthAndResendRequest = async (
   error: AxiosError<AxiosErrorResponseData>,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  getState: () => RootState // تحديث نوع getState
) => {
const request = error.config as AxiosRequestConfig;
  await dispatch(refreshToken());

  // إضافة التوكن الجديد إلى الطلب
  return defaultAxiosInstance().request({
    ...request,
headers: {
  ...(request.headers ?? {}), // تأكد من التعامل مع headers undefined بشكل صحيح
  ...createHeaderWithAuthorization(getState()),
},




  });
};
