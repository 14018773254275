import React from 'react';
import { useForm, Controller, FieldError, SubmitHandler } from 'react-hook-form';
import { makeStyles, Typography, TextField, FormControlLabel, Checkbox, FormControl } from '@material-ui/core';

import {
  numberPlusRegex,
  numberRegex,
  saudiPhoneNumberRegex,
  taxNumberRegex,
  enNameRegex,
} from 'infrastructure/constants/regex.constants';
import { Button } from 'ui/Button';
import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { useFormStyles } from 'styles/form';

import { EstablishmentDialog } from './EstablishmentDialog';
import { useUpdateEstablishmentInfoMutation } from '../api/companyRepresentative.api';
import { FormValues, toEstablishmentInfoDto } from '../types/MissingInfo';

type EstablishmentDialogProps = {
  isFormOpen: boolean;
  handleClose: () => void;
  formValues?: Partial<FormValues>;
};

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
  },
  dialog: {
    width: '600px',
    padding: '32px 24px 24px 24px',
  },

  description: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(6),
  },
  buttons: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputField: {
    marginBlock: theme.spacing(1.5),
    [theme.breakpoints.up('md')]: {
      marginBlock: theme.spacing(2),
    },
  },
  error: {
    color: theme.palette.error.main,
  },
  terms: {
    fontSize: 14,
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
    },
  },
  submitButton: {
    flex: 1,
  },
  field: {
    marginTop: theme.spacing(4),
  },
  cancelButton: {
    flex: 1,
    flip: false,
    marginLeft: theme.spacing(2),
  },
}));

export const EstablishmentEditDialog: React.FC<EstablishmentDialogProps> = ({
  isFormOpen,
  handleClose,
  formValues,
}) => {
  const [putEstablishmentInfo] = useUpdateEstablishmentInfoMutation();
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: formValues,
  });
  const preventDefaultIfEnterClicked = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  const preventDefaultIfNotNumberAndPlusValues = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!numberPlusRegex.test(e.key)) {
      e.preventDefault();
    }
  };

  const preventDefaultIfNotNumberValues = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!numberRegex.test(e.key)) {
      e.preventDefault();
    }
  };

  const onSubmit: SubmitHandler<FormValues> = (v) =>
    putEstablishmentInfo({ establishmentId: v.id, establishmentInfo: toEstablishmentInfoDto(v) })
      .unwrap()
      .then(() => handleFormClose())
      // eslint-disable-next-line no-console
      .catch((error) => console.error('Submit error: ', error));

  const getErrorText = (type: FieldError['type'] | undefined) => {
    switch (type) {
      case 'required':
        return translate('fieldRequired');
      case 'pattern':
        return translate('invalidFormat');
      default:
        return '';
    }
  };
  const classes = useStyles();
  const formClasses = useFormStyles();

  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const translateCommon = useCreateTranslate(TranslationNamespace.common);
  const translation = useCreateTranslate(TranslationNamespace.admin);

  const handleFormClose = () => {
    handleClose();
  };

  const getHelperText = (error?: FieldError) => {
    if (error) {
      if (error.type === 'required') {
        return translateCommon('fieldRequired');
      }

      return error.message;
    }

    return ' ';
  };

  return (
    <EstablishmentDialog isFormOpen={isFormOpen} handleClose={handleClose}>
      <Typography variant="h6">{translate('editCompany')}</Typography>
      <Typography variant="body2" className={classes.description}>
        {translate('editCompanyDescription')}
      </Typography>
      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e: React.KeyboardEvent<HTMLFormElement>) => preventDefaultIfEnterClicked(e)}
      >
        <Controller
          name="nameEn"
          control={control}
          rules={{ required: true, pattern: enNameRegex }}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              className={classes.inputField}
              error={!!errors.nameEn?.type}
              helperText={getErrorText(errors.nameEn?.type)}
              label={translate('companyNameEn')}
            />
          )}
        />
        <Controller
          name="taxNumber"
          control={control}
          rules={{ pattern: taxNumberRegex }}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              className={classes.inputField}
              error={!!errors.taxNumber?.type}
              helperText={getErrorText(errors.taxNumber?.type)}
              label={translate('taxNumber')}
              onKeyPress={preventDefaultIfNotNumberValues}
            />
          )}
        />

        <Controller
          name="phoneNumber"
          control={control}
          rules={{
            required: true,
            pattern: {
              value: saudiPhoneNumberRegex,
              message: translation('invalidPhoneFormat'),
            },
          }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              variant="outlined"
              type="text"
              inputMode="numeric"
              InputProps={{
                classes: {
                  input: formClasses.numberInput,
                },
              }}
              className={classes.field}
              error={invalid}
              helperText={getHelperText(error)}
              label={translateCommon('phoneNumber')}
              placeholder={translation('formatPhoneField')}
              onKeyPress={preventDefaultIfNotNumberAndPlusValues}
            />
          )}
        />

        <FormControl error={!!errors.areTermsAccepted?.type}>
          <FormControlLabel
            control={
              <Controller
                name="areTermsAccepted"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Checkbox
                    className={`${classes.terms} ${errors.areTermsAccepted?.type ? classes.error : ''}`}
                    checked={field.value}
                    {...field}
                  />
                )}
              />
            }
            label={
              <span className={`${classes.terms} ${errors.areTermsAccepted?.type ? classes.error : ''}`}>
                <span>{translate('termsAndConditionsPrefix')}</span>
                <a href="/terms">{translate('termsAndConditions')}</a>
                <span>{translate('termsAndConditionsPostfix')}</span>
              </span>
            }
            labelPlacement="end"
          />
        </FormControl>

        <div dir="ltr" className={classes.buttons}>
          <Button variant="contained" size="medium" color="secondary" className={classes.submitButton} type="submit">
            {translateCommon('saveChanges')}
          </Button>
          <Button
            variant="outlined"
            size="medium"
            color="secondary"
            className={classes.cancelButton}
            onClick={handleFormClose}
          >
            {translateCommon('cancel')}
          </Button>
        </div>
      </form>
    </EstablishmentDialog>
  );
};
