import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import i18n from 'i18next';
import axios from 'axios';

import { AnswerAttachment } from 'types/AssessmentAnswer';
import { DownloadIcon } from 'ui/icons/icons';

import { getReverseDirection as getReverseDirectionForAssessmentResultQuestionAttachments } from '../../utilities/useLanguage';

type AssessmentResultQuestionAttachmentsProps = {
  attachments: AnswerAttachment[];
};

const useStyles = makeStyles((theme) => ({
  answerAttachments: {
    marginTop: theme.spacing(3),
    flip: false,
    display: 'flex',
    flexDirection: 'row-reverse',
    flexWrap: 'wrap',
  },

  answerAttachment: {
    flip: false,
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    padding: '12px 16px',
    textAlign: 'center',
  },

  answerAttachmentText: {
    flip: false,
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '400px',
    alignItems: 'center',
  },

  answerAttachmentName: {
    flip: false,
    flexGrow: 1,
    marginRight: theme.spacing(2),
    fontWeight: 'bold',
  },

  iconButton: {
    flexShrink: 0,
    padding: 1,
  },
}));

export const AssessmentResultQuestionAttachments: React.FC<AssessmentResultQuestionAttachmentsProps> = ({
  attachments,
}) => {
  const classes = useStyles();
  const reverseDirection = getReverseDirectionForAssessmentResultQuestionAttachments(i18n.language);

  const getToken = () => {
    const token = localStorage.getItem('accessToken');
    return token;
  };

  const downloadAttachmentFn = (attachment: AnswerAttachment) => async () => {
    try {
      const baseUrl = process.env.REACT_APP_API_URL;
      const downloadUrl = `${baseUrl}/api/attachment/${attachment.id}/download/`;
      const token = getToken();

      const response = await axios({
        url: downloadUrl,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const blob = new Blob([response.data]);
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = attachment.name;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading the attachment:', error);
    }
  };

  return attachments.length > 0 ? (
    <div dir={reverseDirection} className={classes.answerAttachments}>
      {attachments.map((attachment) => (
        <Paper className={classes.answerAttachment} key={`result-answer-attachment${attachment.id}`} variant="outlined">
          <div className={classes.answerAttachmentText}>
            <Typography noWrap className={classes.answerAttachmentName} variant="body2">
              {attachment.name}
            </Typography>
            <IconButton className={classes.iconButton} color="secondary" onClick={downloadAttachmentFn(attachment)}>
              <SvgIcon component={DownloadIcon} />
            </IconButton>
          </div>
        </Paper>
      ))}
    </div>
  ) : null;
};
