import { makeStyles } from '@material-ui/core';
import React from 'react';

import { AppContent } from './AppContent';
import { Header } from './Header';

const useStyles = makeStyles((theme) => ({
  content: {
    width: 'auto',
    paddingBottom: theme.spacing(10),
  },
}));

export const PublicLayout: React.FunctionComponent = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <Header />
      <AppContent>{children}</AppContent>
    </div>
  );
};
