import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { AxiosRequestConfig } from 'axios';

import { Establishment, EstablishmentDto, toEstablishment } from 'types/Establishment';
import { BaseQueryError } from 'infrastructure/persistence/baseQueryWithReAuth';

import { EstablishmentApiTagType } from './establishment.endpoints.tags';
export const createEstablishmentEndpoints =
  (reducerPath: string) =>
  (
    build: EndpointBuilder<BaseQueryFn<AxiosRequestConfig, unknown, BaseQueryError, {}, {}>, string, typeof reducerPath>
  ) => ({
    getEstablishment: build.query<Establishment, number>({
      query: (establishmentId) => {
        // Validate the establishmentId to avoid invalid API calls
        if (!establishmentId || isNaN(establishmentId)) {
          throw new Error('Invalid establishmentId');
        }
        return {
          url: `/establishment/${establishmentId}/`,
        };
      },
      transformResponse: (response: EstablishmentDto) => toEstablishment(response),
      providesTags: (err, result, establishmentId) => [
        { type: EstablishmentApiTagType.EstablishmentData, id: establishmentId },
      ],
    }),
  });
