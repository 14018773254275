import { Container, makeStyles, TextField, Typography } from '@material-ui/core';
import { useForm, Controller, SubmitHandler, FieldError } from 'react-hook-form';
import { useState } from 'react';

import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { useResetUserData } from 'infrastructure/store/resetUserData.hooks';
import { Button } from 'ui/Button';
import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { BaseQueryError } from 'infrastructure/persistence/baseQueryWithReAuth';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { shdShadow } from 'ui/styles';
import {
  passwordRegex,
  upperCaseRegex,
  lowerCaseRegex,
  symbolCaseRegex,
  oneNumberRegex,
} from 'infrastructure/constants/regex.constants';
import { PublicLayout } from 'domain/common/ui/PublicLayout';
import { ValidationPassword } from 'domain/confirmation-invitation/ValidationPassword';
import { useNavigate } from 'utilities/navigate';

import { useChangeUserPasswordMutation } from '../../admin/api/admin.api';

type ChangePassword = {
  old_password: string;
  new_password: string;
  re_new_password: string;
  redirectPath: string;
};
const useStyles = makeStyles((theme) => ({
  formWrapper: {
    width: theme.breakpoints.values.md,
    maxWidth: theme.breakpoints.values.md,
    padding: '40px 120px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: shdShadow,
    marginTop: theme.spacing(6),
  },

  title: {
    marginBottom: theme.spacing(1),
    textAlign: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
  },
  input: {
    marginBottom: '32px',
  },
  continueButton: {
    height: '50px',
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  field: {
    marginTop: theme.spacing(4),
  },
  valid: {
    color: 'green',
  },
  invalid: {
    color: 'grey',
  },
}));

export const SetPassword = () => {
  const classes = useStyles();
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const translateCommon = useCreateTranslate(TranslationNamespace.common);
  const navigate = useNavigate();
  const [changePasswordInfo, { isLoading }] = useChangeUserPasswordMutation();
  const resetUserData = useResetUserData();
  const [password, setPassword] = useState(false);
  const showNotification = useNotifications();

  const [checks, setChecks] = useState({
    upperCase: false,
    lowerCase: false,
    oneNumber: false,
    characterLength: false,
    symbol: false,
  });

  const { handleSubmit, control } = useForm<ChangePassword>({
    mode: 'onChange',
  });

  const onUserSubmit: SubmitHandler<ChangePassword> = async ({
    old_password,
    new_password,
    re_new_password,
    redirectPath,
  }) => {
    if (old_password === new_password) {
      showNotification(NotificationType.Error, { message: translate('PasswordSame') });
      return;
    }
    if (new_password !== re_new_password) {
      showNotification(NotificationType.Error, { message: translate('PasswordMatch') });
      return;
    }
    if (!isLoading) {
      await changePasswordInfo({
        old_password,
        new_password,
        re_new_password,
      })
        .unwrap()
        .then(() => {
          resetUserData();
          navigate(redirectPath);
        })
        .catch((error: BaseQueryError) => {
          if (!error.data) {
            showNotification(NotificationType.Error);
          }
          showNotification(NotificationType.Error, { errorId: '4015', message: translate('PasswordCredentials') });
        });
    }
  };

  const getHelperText = (error?: FieldError) => {
    if (error) {
      if (error.type === 'required') {
        return translateCommon('fieldRequired');
      }
      return error.message;
    }
    return ' ';
  };

  const handelOnFocus = () => {
    setPassword(true);
  };

  const handleOnKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { value } = e.target as HTMLInputElement;
    const oneNumber = oneNumberRegex.test(value);
    const symbol = symbolCaseRegex.test(value);
    const characterLength = value.length >= 8;
    const lowerCase = lowerCaseRegex.test(value);
    const upperCase = upperCaseRegex.test(value);

    setChecks({
      upperCase,
      lowerCase,
      oneNumber,
      symbol,
      characterLength,
    });
  };
  return (
    <PublicLayout>
      <Container className={classes.formWrapper}>
        <Typography variant="h4" className={classes.title}>
          {translate('changePassword')}
        </Typography>

        <form className={classes.form} onSubmit={handleSubmit(onUserSubmit)}>
          <Controller
            control={control}
            name="old_password"
            rules={{
              required: true,
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                type="password"
                variant="outlined"
                className={classes.field}
                error={invalid}
                helperText={getHelperText(error)}
                label={translate('currentPassword')}
              />
            )}
          />
          <Controller
            control={control}
            name="new_password"
            rules={{
              required: true,
              pattern: {
                value: passwordRegex,
                message: translate('enterYourPassword'),
              },
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                type="password"
                variant="outlined"
                className={classes.field}
                error={invalid}
                helperText={getHelperText(error)}
                label={translate('password')}
                onFocus={handelOnFocus}
                onKeyUp={handleOnKeyUp}
              />
            )}
          />
          <Controller
            control={control}
            name="re_new_password"
            rules={{
              required: true,
              pattern: {
                value: passwordRegex,
                message: translate('enterYourPassword'),
              },
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                type="password"
                variant="outlined"
                className={classes.field}
                error={invalid}
                helperText={getHelperText(error)}
                label={translate('reEnterYourPassword')}
              />
            )}
          />

          {password ? (
            <ValidationPassword
              upperCaseFlag={checks.upperCase ? classes.valid : classes.invalid}
              lowerCaseFlag={checks.lowerCase ? classes.valid : classes.invalid}
              characterLengthFlag={checks.characterLength ? classes.valid : classes.invalid}
              symbolFlag={checks.symbol ? classes.valid : classes.invalid}
              oneNumberFlag={checks.oneNumber ? classes.valid : classes.invalid}
            />
          ) : null}

          <Button className={classes.continueButton} type="submit" variant="contained" color="secondary">
            {translateCommon('continue')}
          </Button>
        </form>
      </Container>
    </PublicLayout>
  );
};
