import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import i18n from 'i18next';

import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { CircleWrapper } from 'ui/CircleWrapper';

import { SectionTopic } from './SectionTopic';

export type SectionHeaderTopicProps = {
  sectionNumber: number;
  sectionsCount: number;
  nameAR: string; 
  nameEN: string;
  descriptionAR: string;
  descriptionEN: string;

};

const useStyles = makeStyles((theme) => ({
  main: {
    flip: false,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
}));

export const SectionHeaderTopic: React.FC<SectionHeaderTopicProps> = ({
  sectionNumber,
  sectionsCount,
  nameAR,
  nameEN,
  descriptionAR,
  descriptionEN,
}) => {
  const classes = useStyles();
  const translateSA = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const translateCommon = useCreateTranslate(TranslationNamespace.common);

  return (
    <div className={classes.main}>
      <SectionTopic
        topicHeader={`${translateSA('section')} ${sectionNumber} ${translateCommon('of')} ${sectionsCount}`}
        topic={i18n.language === 'en'? nameEN : nameAR}
        topicDescription={i18n.language === 'en'? descriptionEN : descriptionAR}
      />
      <CircleWrapper value={sectionNumber} />
    </div>
  );
};
