import React from 'react';
import { Breadcrumbs, Typography, Link, BreadcrumbsProps } from '@material-ui/core';
import NavigatePreviousIcon from '@material-ui/icons/NavigateBefore';

import { useNavigate } from 'utilities/navigate';

type ParentCrumb = {
  label: string;
  path: string;
};

type LinkBreadCrumbsProps = {
  currentLocationLabel: string;
  parentCrumbs: ParentCrumb[];
} & BreadcrumbsProps;

export const CustomBreadCrumbs: React.FC<LinkBreadCrumbsProps> = ({ parentCrumbs, currentLocationLabel, ...props }) => {
  const navigate = useNavigate();

  return (
    <Breadcrumbs {...props} separator={<NavigatePreviousIcon fontSize="small" />}>
      {parentCrumbs.map(({ label, path }) => (
        <Link
          key={`breadcrumbs-${label}-${path}`}
          color="textSecondary"
          href="#"
          onClick={(event) => {
            event.preventDefault();

            navigate(path);
          }}
        >
          {label}
        </Link>
      ))}
      <Typography color="textPrimary">{currentLocationLabel}</Typography>
    </Breadcrumbs>
  );
};
