import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
} from '@material-ui/core';
import React from 'react';
import i18n from 'i18next';

import { TranslationNamespace } from 'i18n/config';
import CustomTablePagination from 'ui/CustomTablePagination';
import { useCreateTranslate } from 'utilities/translate.hook';
import { PCRequest } from 'types/PCRequest';
import { toLocaleDate } from 'utilities/localDate';

import { getZoneName } from '../utils/getZoneName';
import { getDirection, getReverseDirection } from '../../../utilities/useLanguage';
type CompanyRepresentativePerformanceCardsTableProps = {
  data: PCRequest[];
  handleChangePage: (event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  page: number;
  pageSize: number;
  count: number;
};

export const CompanyRepresentativePerformanceCardsTable: React.FC<CompanyRepresentativePerformanceCardsTableProps> = ({
  data,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  pageSize,
  count,
}) => {
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const direction = getDirection(i18n.language);
  const reverseDirection = getReverseDirection(i18n.language);

  return (
    <TableContainer dir={direction} component={Paper}>
      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell>{translate('pcRequestId')}</TableCell>
            <TableCell>{translate('createdOn')}</TableCell>
            <TableCell>{translate('issuedOn')}</TableCell>
            <TableCell>{translate('expirationDate')}</TableCell>
            <TableCell>{translate('zone')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((pcRequest) => (
            <TableRow key={pcRequest.id}>
              <TableCell>{pcRequest.id}</TableCell>
              <TableCell>{toLocaleDate(pcRequest.createdAt)}</TableCell>
              <TableCell>{pcRequest.issuingDate ? toLocaleDate(pcRequest.issuingDate) : '-'}</TableCell>
              <TableCell>
                {pcRequest.performanceCardExpiresAt ? toLocaleDate(pcRequest.performanceCardExpiresAt) : '-'}
              </TableCell>
              <TableCell>
                {pcRequest.performanceCardPassingStatus
                  ? getZoneName(pcRequest.performanceCardPassingStatus, translate)
                  : '-'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter dir={direction}>
          <TableRow>
            <CustomTablePagination
              dir={reverseDirection}
              rowsPerPageOptions={[5, 10, 25]}
              count={count}
              rowsPerPage={pageSize}
              page={page}
              SelectProps={{
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
