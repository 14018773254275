import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';

import { shdShadow } from 'ui/styles';

import { QuestionnaireReviewTopicEditableWithStatistics } from './QuestionnaireReviewTopicEditableWithStatistics';

type QuestionnaireReviewContainerWithStatisticsProps = {
  topicLabelName: string;
  questionsNoSummary: number | undefined;
  totalWeightSummary: string | undefined;
  stepIndex: number;
  setStepIndex: React.Dispatch<React.SetStateAction<number>>;
  isEditable: boolean;
};

const useStyles = makeStyles((theme) => ({
  main: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  stepWrapper: {
    boxShadow: shdShadow,
    marginTop: theme.spacing(5),
    padding: `${theme.spacing(4)}px`,
  },
}));

export const QuestionnaireReviewContainerWithStatistics: React.FC<QuestionnaireReviewContainerWithStatisticsProps> = ({
  children,
  topicLabelName,
  questionsNoSummary,
  totalWeightSummary,
  stepIndex,
  setStepIndex,
  isEditable,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.stepWrapper}>
      <div className={classes.main}>
        <QuestionnaireReviewTopicEditableWithStatistics
          topicLabelName={topicLabelName}
          questionsNoSummary={questionsNoSummary}
          totalWeightSummary={totalWeightSummary}
          stepIndex={stepIndex}
          setStepIndex={setStepIndex}
          isEditable={isEditable}
        />
        <div>{children}</div>
      </div>
    </Paper>
  );
};
