import React, { useState } from 'react';
import { Container, makeStyles, Typography } from '@material-ui/core';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { Button } from 'ui/Button';

import { AdminLayout } from '../ui/AdminLayout';
import { UsersTable } from './UsersTable';
import { UsersCreateDialog } from './UsersCreateDialog';
import { Search } from '../../common/Search'

const useStyles = makeStyles((theme) => ({
  main: {
    padding: 0,
    marginTop: theme.spacing(15),
  },

  pageTitle: {
    marginBottom: theme.spacing(1.5),
  },

  createButton: {
    height: '50px',
    width: '200px',
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(-7),
    float: 'right',
  },

  header: {
    [theme.breakpoints.down('md')]: {
      padding: `0 ${theme.spacing(6)}px`,
    },
    marginBottom: theme.spacing(),
    marginTop: theme.spacing(10),
  },
}));

export const UsersList = () => {
  const classes = useStyles();
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const [isFormOpen, setFormOpen] = useState(false);
  const [query, setQuery] = useState('');

  const handleSearch =(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;
    setQuery(value)
  }
  return (
    <AdminLayout>
      <Container className={classes.main} maxWidth="lg">
        <div className={classes.header}>
          <Typography variant="h4" className={classes.pageTitle}>
            {translate('users')}
          </Typography>

<Search name={translate('searchUser')} onChange={(e) => handleSearch(e)}/>
        </div>

        <div>
          <Button
            onClick={() => setFormOpen(true)}
            variant="contained"
            color="secondary"
            className={classes.createButton}
            size="large"
          >
            {translate('createUser')}
          </Button>
        </div>

        <UsersTable searchUser={query} />
      </Container>
      <UsersCreateDialog isFormOpen={isFormOpen} handleClose={() => setFormOpen(false)} />
    </AdminLayout>
  );
};
