import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';

import { EstablishmentsTable } from './establishments-table/EstablishmentsTable';
import { Routes } from '../Routes';
import { Search } from '../Search';

type EstablishmentsProps = {
  getPath: (value: Routes) => string;
};

const useStyles = makeStyles((theme) => ({
  main: {
    padding: 0,
    marginTop: theme.spacing(15),
  },
  pageTitle: {
   marginBottom: theme.spacing(1.5),
  },

  search: {
    marginBottom: theme.spacing(5),
  },
  header: {
    [theme.breakpoints.down('md')]: {
      padding: `0 ${theme.spacing(6)}px`,
    },
    marginBottom: theme.spacing(),
    marginTop: theme.spacing(10),
  },
}));

export const Establishments: React.FC<EstablishmentsProps> = ({ getPath }) => {
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const classes = useStyles();
  const [query, setQuery] = useState('');

  const handleSearch =(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;
    setQuery(value)
  }

  return (
    <Container className={classes.main} maxWidth="lg">
      <div className={classes.header}>
        <Typography variant="h4" className={classes.pageTitle}>
          {translate('establishmentRegistered')}
        </Typography>
      </div>
      <div className={classes.search} >
        <Search name={translate('searchEstablishment')} onChange={(e) => handleSearch(e)}/>
      </div>
      <EstablishmentsTable searchEstablishment={query} getPath={getPath} />
    </Container>
  );
};
