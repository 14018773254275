import {
  AssessmentApiTagsType,
  assessmentApiTagsTypes,
} from 'infrastructure/services/api/assessment.endpoints.tags.api';
import {
  EstablishmentApiTagType,
  establishmentTagTypes,
} from 'infrastructure/services/api/establishment.endpoints.tags';

export enum CompanyRepresentativeApiTagType {
  EstablishmentInfo = 'EstablishmentInfo',
  PCRequest = 'PCRequest',
}

type CompanyRepresentativeApiTagTypes =
  | CompanyRepresentativeApiTagType
  | AssessmentApiTagsType
  | EstablishmentApiTagType;

export const tagTypes: CompanyRepresentativeApiTagTypes[] = [
  CompanyRepresentativeApiTagType.EstablishmentInfo,
  CompanyRepresentativeApiTagType.PCRequest,
  ...assessmentApiTagsTypes,
  ...establishmentTagTypes,
];
