import { Collapse, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import i18n from 'i18next';

import { AssessmentSection } from 'types/AssessmentSection';
import { SectionHeaderTopic } from 'ui/assessment/SectionHeaderTopic';
import { CollapseButton } from 'ui/buttons/CollapseButton';
import { collapseButtonSpaceWidth } from 'ui/styles';

import { AdminAssessmentCompareQuestions } from './AdminAssessmentCompareQuestions';
import { PcRequestAssessmentCompareInfo } from './types/PcRequestAssessmentCompareInfo';
import { getReverseDirection } from '../../../utilities/useLanguage';

type AdminCompareSectionProps = {
  section: AssessmentSection;
  sectionNumber: number;
  sectionsCount: number;
  pcAssessmentInfo: PcRequestAssessmentCompareInfo;
};

type StylesProps = {
  isOpen: boolean;
};

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  wrapperBase: {
    flip: false,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '32px',
  },

  wrapper: {
    borderBottom: ({ isOpen }) => (isOpen ? `1px solid ${theme.palette.grey[200]}` : 'none'),
  },

  collapseButtonSpace: {
    flip: false,
    flexShrink: 0,
    width: collapseButtonSpaceWidth,
    marginRight: theme.spacing(4),
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    flip: false,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px 32px',
    flex: 1,
    marginLeft: theme.spacing(-4),
  },
}));

export const AdminAssessmentCompareSection = ({
  section,
  sectionNumber,
  sectionsCount,
  pcAssessmentInfo,
}: AdminCompareSectionProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const classes = useStyles({ isOpen });

  const handleCollapse = () => {
    setIsOpen(!isOpen);
  };
  const reverseDirection = getReverseDirection(i18n.language);

  return (
    <div className={classes.main}>
      <div dir={reverseDirection} className={clsx(classes.wrapperBase, classes.wrapper)}>
        <div className={classes.collapseButtonSpace}>
          <CollapseButton onClick={handleCollapse} />
        </div>

        <div className={classes.header}>
          <SectionHeaderTopic
            sectionNumber={sectionNumber}
            sectionsCount={sectionsCount}
            nameAR={section.nameAR}
            nameEN={section.nameEN}
            descriptionAR={section.descriptionAR}
            descriptionEN={section.descriptionEN}
          />
        </div>
      </div>
      <Collapse in={isOpen} mountOnEnter unmountOnExit>
        <AdminAssessmentCompareQuestions pcAssessmentInfo={pcAssessmentInfo} sectionId={section.id} />
      </Collapse>
    </div>
  );
};
