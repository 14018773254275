import React from 'react';
import clsx from 'clsx';
import { SvgIcon, Typography, makeStyles, Theme } from '@material-ui/core';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import Curve from 'images/curve.svg';
import { CheckGreenIcon } from 'ui/icons/icons';

import { CommonLandingProps } from './types/CommonLandingProps';

const useStyles = makeStyles<Theme>((theme) => ({
  sectionTopic: {
    marginBottom: theme.spacing(3),
    lineHeight: 1.4,
    fontWeight: 300,
  },
  secondSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(5),
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
      paddingInline: 108,
      paddingBlock: 132,
    },
  },
  sectionText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 32,
    [theme.breakpoints.up('md')]: {
      maxWidth: 600,
      marginBottom: 64,
    },
  },
  sectionDesc: {
    textAlign: 'center',
    lineHeight: 1.4,
    fontWeight: 300,
  },
  secondSectionChecks: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBlock: -theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: 40,
      marginBottom: 104,
    },
  },
  secondSectionCheck: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBlock: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      margin: 0,
      '&:nth-of-type(even)': {
        marginTop: -80,
      },
      '&:nth-of-type(odd)': {
        marginBottom: -80,
      },
    },
  },
  secondSectionIconText: {
    marginTop: theme.spacing(2),
    fontSize: 14,
    fontWeight: 300,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: 88,
      width: 288,
      margin: 0,
      fontSize: 18,
    },
  },
  secondSectionCurve: {
    marginInline: 24,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  secondSectionCurveReversed: {
    transform: 'scaleX(-1)',
  },
  svgIcon: {
    height: 80,
    width: 80,
  },
}));

type SecondSectionProps = Omit<CommonLandingProps, 'onClick' | 'onClickBelow'>;
export const LandingSecondSection = React.forwardRef(
  ({ isUpMd }: SecondSectionProps, ref2: React.Ref<HTMLDivElement>) => {
    const classes = useStyles();
    const translate = useCreateTranslate(TranslationNamespace.landingPage);
    return (
      <div ref={ref2} className={classes.secondSection}>
        <div className={classes.sectionText}>
          <Typography className={classes.sectionTopic} variant={!isUpMd ? 'h5' : 'h3'}>
            {translate('secondSectionTopic')}
          </Typography>
          <Typography className={classes.sectionDesc} variant={!isUpMd ? 'body1' : 'h5'}>
            {translate('secondSectionDesc')}
          </Typography>
        </div>

        <div dir="rtl" className={classes.secondSectionChecks}>
          <div className={classes.secondSectionCheck}>
            <SvgIcon className={classes.svgIcon} component={CheckGreenIcon} />
            <Typography className={classes.secondSectionIconText} variant="subtitle1">
              {translate('secondSectionFirstItem')}
            </Typography>
          </div>

          <img src={Curve} alt="Logo-mono" className={classes.secondSectionCurve} />

          <div className={classes.secondSectionCheck}>
            <SvgIcon className={classes.svgIcon} component={CheckGreenIcon} />
            <Typography className={classes.secondSectionIconText} variant="subtitle1">
              {translate('secondSectionSecondItem')}
            </Typography>
          </div>

          <img
            src={Curve}
            alt="Logo-mono"
            className={clsx(classes.secondSectionCurve, classes.secondSectionCurveReversed)}
          />

          <div className={classes.secondSectionCheck}>
            <SvgIcon className={classes.svgIcon} component={CheckGreenIcon} />
            <Typography className={classes.secondSectionIconText} variant="subtitle1">
              {translate('secondSectionThirdItem')}
            </Typography>
          </div>

          <img src={Curve} alt="Logo-mono" className={classes.secondSectionCurve} />

          <div className={classes.secondSectionCheck}>
            <SvgIcon className={classes.svgIcon} component={CheckGreenIcon} />
            <Typography className={classes.secondSectionIconText} variant="subtitle1">
              {translate('secondSectionFourthItem')}
            </Typography>
          </div>
        </div>
      </div>
    );
  }
);
