import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useParams } from 'react-router-dom';

import { LoadingWrapper } from 'ui/LoadingWrapper';

import { PCRequestAssessmentBody } from '././PCRequestAssessmentBody';
import { useGetPCRequestQuery } from '../../admin/api/admin.api';
import { Routes } from '../Routes';

type PCRequestAssessmentProps = {
  getPath: (value: Routes) => string;
};

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const PCRequestAssessment: React.FC<PCRequestAssessmentProps> = ({ getPath }) => {
  const { pcRequestId } = useParams<{ pcRequestId: string }>();
  const classes = useStyles();
  const { data: pcRequest, isLoading, isFetching } = useGetPCRequestQuery(+pcRequestId);

  return (
    <LoadingWrapper isLoading={isLoading || isFetching}>
      <div className={classes.main}>
        {pcRequest && <PCRequestAssessmentBody getPath={getPath} pcRequest={pcRequest} />}
      </div>
    </LoadingWrapper>
  );
};