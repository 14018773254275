import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { AssessmentType } from 'types/Assessment';

import { PCRequestAssessmentPreviousAnswerValueTitle } from './PCRequestAssessmentPreviousAnswerValueTitle';

type PCRequestAssessmentPreviousAnswererSelectProps = {
  selectKey: string;
  answerers: AssessmentType[];
  selectedAnswerer: AssessmentType;
  changeAnswerer: (value: AssessmentType) => void;
};

const useStyle = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },

  selectForm: {
    marginTop: theme.spacing(2),
    alignItems: 'flex-start',
  },

  answerer: {
    marginTop: theme.spacing(4),
  },

  selectAnswerer: {
    width: '250px',
    '& .MuiInputBase-input': {
      backgroundColor: theme.palette.background.paper,
    },
  },

  formHelperText: {
    margin: '2px 0 0 0',
  },
}));

export const PCRequestAssessmentPreviousAnswererSelect: React.FC<PCRequestAssessmentPreviousAnswererSelectProps> = ({
  selectKey,
  answerers,
  selectedAnswerer,
  changeAnswerer,
}) => {
  const classes = useStyle();
  const translate = useCreateTranslate(TranslationNamespace.admin);

  const getAnswererText = (answerer: AssessmentType) => {
    switch (answerer) {
      case AssessmentType.AUDITOR:
        return translate('auditor');
      case AssessmentType.INSPECTOR:
        return translate('inspector');
      default:
        return translate('companyRepresentative');
    }
  };

  const changeAnswererFn: ({ target: { value } }: React.ChangeEvent<{ value: unknown }>) => void = ({
    target: { value },
  }) => {
    changeAnswerer(value as AssessmentType);
  };

  return (
    <div className={classes.wrapper}>
      <PCRequestAssessmentPreviousAnswerValueTitle text={translate('answerer')} className={classes.answerer} />
      <FormControl variant="outlined" className={classes.selectForm}>
        <Select className={classes.selectAnswerer} value={selectedAnswerer} onChange={changeAnswererFn}>
          {answerers.map((assessmentType) => (
            <MenuItem key={`${selectKey}-${assessmentType}`} value={assessmentType}>
              {getAnswererText(assessmentType)}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText className={classes.formHelperText}>{translate('selectAnswerer')}</FormHelperText>
      </FormControl>
    </div>
  );
};
