import React from 'react';
import { Redirect } from 'react-router-dom';

import { Role } from 'infrastructure/services/auth.type';
import { selectRole } from 'infrastructure/services/authSlice.selector';
import { Routes } from 'infrastructure/routes';
import { useAppSelector } from 'infrastructure/store/rootStore.hooks';

import { AppContentProps } from './AppContent';
import { Layout } from './Layout';

export const ProtectedLayout: React.FC<AppContentProps> = ({ children, ...props }) => {
  const currentRole = useAppSelector(selectRole);

  if (currentRole !== Role.CompanyRepresentative) {
    return <Redirect to={Routes.HOME} />;
  }

  return <Layout {...props}>{children}</Layout>;
};
