import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Redirect } from 'react-router-dom';
import i18n from 'i18next';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { ReactComponent as ActionIcon } from 'ui/icons/actions.svg';
import { useAppDispatch } from 'infrastructure/store/rootStore.hooks';
import { CustomTablePagination } from 'ui/CustomTablePagination';
import { toLocaleDate } from 'utilities/localDate';
import { backButtonBackgroundColor } from 'ui/styles';
import { CircleButton } from 'ui/buttons/CircleButton';
import { LoadingSpinner } from 'ui/LoadingSpinner';

import { DeleteDialog } from './DeleteDialog';
import { TemplateTableNoData } from './TemplateTableNoData';
import { TemplateTableMenuDialog } from './TemplateTableMenuDialog';
import { TemplateTableHead } from './TemplateTableHead';
import { toStatusDisplay } from '../types/AssessmentTemplateStatus';
import { useGetTemplateListQuery } from '../api/admin.api';
import { TemplateTableStatusTableCell } from './TemplateTableStatusTableCell';
import { getAdminPath } from '../AdminRoutes';
import { setQuestionaryId } from '../admin-questionnaire/AdminQuestionnaire.slice';
import { Routes } from '../../common/Routes';
import { getReverseDirection, getDirection, isEngLang } from '../../../utilities/useLanguage';
import { SelectTableCell } from './SelectTableCell';

const useStyle = makeStyles((theme) => ({
  table: {
    minWidth: theme.breakpoints.values.lg,
  },

  actionCell: {
    padding: '6px',
  },

  menuButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },

  menuButton: {
    padding: 0,
    margin: 0,
  },

  svgIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    width: '40px',
    backgroundColor: backButtonBackgroundColor,
  },
  cell: {
    padding: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  cellNo: {
    paddingRight: '130px',
    paddingLeft: '90px',
  },
  right: {
    textAlign: 'left',
  },
}));

type TemplateSearchProps = {
  searchTemplate: string;
};

export const TemplateTable: React.FC<TemplateSearchProps> = ({ searchTemplate }) => {
  const classes = useStyle();
  const translate = useCreateTranslate(TranslationNamespace.admin);

  const [page, setPage] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement | undefined>(null);
  const [questionnaireStatus, setQuestionnaireStatus] = React.useState<undefined | number>();
  const [redirectToCreate, setRedirectToCreate] = React.useState(false);
  const [isDeleteOpen, setDeleteOpen] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(5);
  const { data: { count, results } = { count: 0, results: [] }, isLoading } = useGetTemplateListQuery({
    parameters: { page: page + 1, pageSize },
    param: { searchInfo: [searchTemplate] },
  });
  const dispatch = useAppDispatch();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    const parentElement = event.currentTarget?.parentElement?.parentElement;
    setAnchorEl(parentElement || null);
  };

  useEffect(() => {
    if (anchorEl) {
      const questionnaireId = anchorEl.getAttribute('data-id');
      if (questionnaireId) {
        const questionnaire = results.find((result) => result.id === +questionnaireId);
        setQuestionnaireStatus(questionnaire?.status);
      }
    }
  }, [anchorEl, results]);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleEditQuestionnaire = () => {
    if (anchorEl) {
      const questionnaireId = anchorEl.getAttribute('data-id');

      if (questionnaireId) {
        dispatch(setQuestionaryId(+questionnaireId));

        setRedirectToCreate(true);
      }
    }
  };
  const handleDeleteQuestionnaire = () => {
    setDeleteOpen(true);
  };
  
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };
  if (redirectToCreate) {
    return <Redirect to={getAdminPath(Routes.questionnaire)} />;
  }

  const reverseDirection = getReverseDirection(i18n.language);
  const direction = getDirection(i18n.language);

  // @ts-ignore
  return results.length > 0 ? (
    <div dir={reverseDirection}>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="medium">
          <TemplateTableHead />
          <TableBody>
            {results.map((template) => (
              <TableRow tabIndex={-1} key={`${template.nameAR}-${template.nameEN}-${template.version}`}>
                <TableCell className={classes.actionCell} data-id={template.id}>
                  <div className={classes.menuButtonWrapper}>
                    <CircleButton
                      size={20}
                      icon={ActionIcon}
                      className={classes.svgIcon}
                      viewBoxWidth={22}
                      viewBoxHeight={16}
                      onClick={handleClick}
                    />
                  </div>
                </TableCell>
                <TemplateTableStatusTableCell
                  className={classes.cell}
                  style={{ textAlign: 'right' }}
                  status={template.status}
                  publishDate={template.publishDate}
                >
                  {translate(toStatusDisplay(template.status))}
                </TemplateTableStatusTableCell>

                <TableCell className={classes.cell}>{toLocaleDate(template.createdAt)}</TableCell>
                <TableCell className={classes.right}>{template.version}</TableCell>
                <TableCell className={classes.cell}>{template.economicActivity.nameAr}</TableCell>
                <SelectTableCell
                  nameAR={template.nameAR}
                  nameEN={template.nameEN}
                  isEngLang={isEngLang(i18n.language)}
                  className={classes.cell}
                />
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <CustomTablePagination
                dir={direction}
                rowsPerPageOptions={[5, 10, 25]}
                count={count}
                rowsPerPage={pageSize}
                page={page}
                SelectProps={{
                  native: true,
                }}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onPageChange={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {anchorEl && questionnaireStatus && (
        <TemplateTableMenuDialog
          anchorEl={anchorEl}
          questionnaireStatus={questionnaireStatus}
          handleCloseMenu={handleCloseMenu}
          handleEditQuestionnaire={handleEditQuestionnaire}
          handleDeleteQuestionnaire={handleDeleteQuestionnaire}
        />
      )}
      {anchorEl && isDeleteOpen && (
        <DeleteDialog open={isDeleteOpen} anchorEl={anchorEl} handleClose={() => setDeleteOpen(false)} />
      )}
    </div>
  ) : isLoading ? (
    <LoadingSpinner type='admin' />
  ) : (
    <TemplateTableNoData />
  );
};
