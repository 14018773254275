import React from 'react';
import { makeStyles } from '@material-ui/core';

import { TranslationNamespace } from 'i18n/config';
import { Establishment } from 'types/Establishment';
import { PaperCard } from 'ui/PaperCard';
import { useCreateTranslate } from 'utilities/translate.hook';
import { Map } from 'ui/Map';
import { notAvailableInfo } from 'utilities/notAvailableInfo';

import { FormValues } from '../types/MissingInfo';

const useStyle = makeStyles((theme) => ({
  details: {
    flip: false,
    marginTop: theme.spacing(4),
  },
}));

type CREstablishmentDetailsBodyProps = {
  establishment: Establishment;
  formValues: Partial<FormValues>;
};

export const CREstablishmentDetailsBody = ({ establishment, formValues }: CREstablishmentDetailsBodyProps) => {
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const classes = useStyle();

  const geolocation = {
    lat: Number(establishment.latitude),
    lng: Number(establishment.longitude),
  };

  const companyRepresentativeDetails = [
    {
      name: translate('CRName'),
      value: establishment.companyRepresentativeName,
    },
    {
      name: translate('email'),
      value: establishment.email,
    },
    {
      name: translate('phoneNumber'),
      value: establishment.phoneNumber,
    },
    {
      name: translate('phoneNumberAdditional'),
      value: notAvailableInfo(establishment.additionalPhoneNumber),
    },
  ];

  const companyDetails = [
    {
      name: translate('companyNameEn'),
      value: establishment.nameEn,
    },
    {
      name: translate('companyNameAr'),
      value: establishment.nameAr,
    },
    {
      name: translate('taxNumber'),
      value: establishment.taxNumber,
    },
    {
      name: translate('economicActivity'),
      value: establishment.economicActivityNameAr,
    },
    {
      name: translate('phoneNumber'),
      value: establishment.phoneNumber,
    },
  ];

  const companyAddress = [
    {
      name: translate('region'),
      value: establishment.regionNameAr,
    },
    {
      name: translate('city'),
      value: establishment.cityNameAr,
    },
    {
      name: translate('district'),
      value: establishment.district,
    },
    {
      name: translate('street'),
      value: notAvailableInfo(establishment.street),
    },
  ];

  const socialMedia = [
    {
      name: translate('linkedinLink'),
      value: notAvailableInfo(establishment.linkedinUrl),
    },
    {
      name: translate('twitterLink'),
      value: notAvailableInfo(establishment.twitterUrl),
    },
    {
      name: translate('facebookLink'),
      value: notAvailableInfo(establishment.facebookUrl),
    },
  ];
  return (
    <div>
      <PaperCard title={translate('CRDetails')} data={companyRepresentativeDetails} formValues={formValues} />
      <PaperCard title={translate('companyDetails')} data={companyDetails} isEditable={true} formValues={formValues} />
      <PaperCard title={translate('companyAddress')} data={companyAddress} formValues={formValues} />
      <PaperCard title={translate('googleLocation')} formValues={formValues}>
        <div className={classes.details}>
          <Map markerPosition={geolocation} disabled={true} />
        </div>
      </PaperCard>
      <PaperCard title={translate('socialMediaLinks')} data={socialMedia} formValues={formValues} />
    </div>
  );
};
