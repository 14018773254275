import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { Theme } from '@material-ui/core';
import i18n from 'i18next';

import { AssessmentAnswer } from 'types/AssessmentAnswer';
import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { AssessmentType } from 'types/Assessment';
import { AssessmentResultQuestionAttachments } from 'ui/assessment/AssessmentResultQuestionAttachments';
import { useAssessmentAnswerTypeText } from 'utilities/useAssessmentAnswerTypeText.hook';

import { PCRequestAssessmentPreviousAnswerValueTitle } from './PCRequestAssessmentPreviousAnswerValueTitle';
import { PCRequestAssessmentAnswerValue } from './PCRequestAssessmentAnswerValue';
import { getDirection } from '../../../utilities/useLanguage';

type PCRequestAssessmentPreviousAnswerContentProps = {
  answer?: AssessmentAnswer;
  answerer: AssessmentType;
};

const useStyle = makeStyles<Theme, { hasAttachments: boolean }>((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    backgroundColor: 'inherit',
    flex: '1',
  },

  valueTitle: {
    fontWeight: 'bold',
  },

  answer: {
    marginTop: theme.spacing(3),
  },

  uploadedFiles: {
    marginTop: theme.spacing(2),
  },

  uploadedFilesWrapper: {
    marginTop: ({ hasAttachments }) => (hasAttachments ? `-${theme.spacing(3)}px` : ''),
  },
}));

export const PCRequestAssessmentPreviousAnswerContent: React.FC<PCRequestAssessmentPreviousAnswerContentProps> = ({
  answer,
  answerer,
}) => {
  const hasAttachments = Boolean(answer?.attachments && answer.attachments.length > 0);
  const classes = useStyle({ hasAttachments });

  const getAnswerText = useAssessmentAnswerTypeText();
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const direction = getDirection(i18n.language);

  return (
    <div dir={direction} className={classes.main}>
      <PCRequestAssessmentPreviousAnswerValueTitle text={translate('answer')} className={classes.answer} />
      {answer && <PCRequestAssessmentAnswerValue>{getAnswerText(answer.type)}</PCRequestAssessmentAnswerValue>}
      <PCRequestAssessmentPreviousAnswerValueTitle text={translate('comment')} className={classes.answer} />
      {answer && <PCRequestAssessmentAnswerValue>{answer.specialistComment || '-'}</PCRequestAssessmentAnswerValue>}
      {answerer !== AssessmentType.AUDITOR && (
        <PCRequestAssessmentPreviousAnswerValueTitle
          text={translate('uploadedFiles')}
          className={classes.uploadedFiles}
        />
      )}
      {answer && answerer !== AssessmentType.AUDITOR && (
        <div className={classes.uploadedFilesWrapper}>
          {hasAttachments ? (
            <AssessmentResultQuestionAttachments attachments={answer.attachments} />
          ) : (
            <Typography variant="body1">-</Typography>
          )}
        </div>
      )}
    </div>
  );
};
