import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import clsx from 'clsx';
import { Theme, Chip } from '@material-ui/core';

import { toLocaleDate } from 'utilities/localDate';

import { AssessmentTemplateStatus } from '../types/AssessmentTemplateStatus';

type CellProps = TableCellProps & { status: AssessmentTemplateStatus; publishDate: Date | undefined };

type CellCustomProps = { status: AssessmentTemplateStatus | null };

const useStatusIconStyles = makeStyles<Theme, CellCustomProps>(({ palette }) => ({
  root: {
    fontWeight: 700,
    color: (props) => {
      if (props.status === AssessmentTemplateStatus.Draft) {
        return palette.success.dark;
      } else {
        return palette.text.primary;
      }
    },
  },
}));

export const TemplateTableStatusTableCell = (props: CellProps) => {
  const { status, publishDate, children, className, ...other } = props;
  const classes = useStatusIconStyles({ status });

  const getTemplateTableStatus = () => {
    switch (status) {
      case AssessmentTemplateStatus.Draft:
        return <Chip label={children} style={{ backgroundColor: '#e3f2fd', color: '#2196F3', width: '80%' }} />;

      case AssessmentTemplateStatus.Published:
        return <Chip label={children} style={{ backgroundColor: '#e8f5e9', color: '#3B873E', width: '80%' }} />;

      case AssessmentTemplateStatus.Archived:
        return <Chip label={children} style={{ backgroundColor: '#eaeaea', color: '#696969', width: '80%' }} />;
      default:
        return <Chip label={children} style={{ backgroundColor: '#fcedc1', color: '#856620', width: '80%' }} />;
    }
  };

  return (
    <TableCell className={clsx(className)} {...other}>
      {status === AssessmentTemplateStatus.Published ? (
        <Tooltip title={`${publishDate ? toLocaleDate(publishDate) : ''}`} arrow>
          <span className={classes.root}>{getTemplateTableStatus()}</span>
        </Tooltip>
      ) : (
        <span className={classes.root}>{getTemplateTableStatus()}</span>
      )}
    </TableCell>
  );
};
