import React from 'react';

import { AppContent, AppContentProps } from './AppContent';
import { Header } from './Header';

export const Layout: React.FC<AppContentProps> = ({ maxWidth = 'md', children }) => (
  <div>
    <Header />
    <AppContent maxWidth={maxWidth}>{children}</AppContent>
  </div>
);
