import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import i18n from 'i18next';

import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { CheckGreenIcon } from 'ui/icons/icons';
import { labelCardColor } from 'ui/styles';

import { BottomBorderWrapper } from '../../../ui/BottomBorderWrapper';
import { getDirection } from '../../../utilities/useLanguage';

type CompanyRepresentativeSASummaryHeaderProps = {
  validSectionsCount: number;
  sectionsCount: number;
};

const useStyles = makeStyles((theme) => ({
  header: {
    flip: true,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px 104px 40px 32px',
  },

  completedSection: {
    flexShrink: 0,
    padding: 0,
  },

  checkIcon: {
    flip: true,
    marginRight: '12px',
    marginLeft: '12px',
  },

  values: {
    flip: true,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },

  emptySpace: {
    flexShrink: 0,
    width: '280px',
  },

  topic: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  topicHeader: {
    fontWeight: 'bold',
    color: labelCardColor,
  },

  typography: {
    marginTop: theme.spacing(1),
  },

  description: {
    color: labelCardColor,
  },
}));

export const CompanyRepresentativeSASummaryHeader: React.FC<CompanyRepresentativeSASummaryHeaderProps> = ({
  validSectionsCount,
  sectionsCount,
}) => {
  const classes = useStyles();
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const direction = getDirection(i18n.language);

  return (
    <BottomBorderWrapper>
      <div className={classes.header}>
        <div className={`${classes.header} ${classes.completedSection}`}>
          <SvgIcon className={classes.checkIcon} component={CheckGreenIcon} />
          <Typography className={classes.values} variant="h6">{`${validSectionsCount}/${sectionsCount}`}</Typography>
          <Typography variant="body1">{translate('completedSections')}</Typography>
        </div>
        <div dir={direction} className={classes.emptySpace} />
        <div dir={direction} className={classes.topic}>
          <Typography className={classes.topicHeader} variant="body2">
            {translate('checklist')}
          </Typography>
          <Typography className={classes.typography} variant="h5">
            {translate('saSummary')}
          </Typography>
          <Typography className={`${classes.typography} ${classes.description}`} variant="body1">
            {translate('beforeSubmitting')}
          </Typography>
        </div>
      </div>
    </BottomBorderWrapper>
  );
};
