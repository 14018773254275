import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootStoreActionType } from 'infrastructure/store/rootStore.actions';

import {
  authenticateAdmin,
  loginAdmin,
  loginUser,
  refreshToken,
  confirmationInvitation,
  checkInvitation,
} from './auth.api';
import {
  Role,
  TokenData,
  UserData,
  UserTowFactorResponse,
  InvitationConfirmationResponse,
  CheckInvitationResponse,
} from './auth.type';

type AuthState = TokenData & {
  tokenData: TokenData | null;
  role: Role | null;
  showPlatform: boolean;
  isLoginInProgress: boolean;
  loginError: Error | null;
  isRefreshingToken: boolean;
  userTowFactorResponse: UserTowFactorResponse | null;
  invitationConfirmationResponse: InvitationConfirmationResponse | null;
  isInvitationConfirmationProgress: boolean;
  invitationConfirmationError: Error | null;
  checkInvitationResponse: CheckInvitationResponse | null;
  isCheckInvitationProgress: boolean;
  checkInvitationError: Error | null;
};

const initialState = { tokenData: null, role: null } as AuthState;

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserCredentials: (state, { payload: { tokenData, role } }: PayloadAction<UserData>) => {
      state.tokenData = tokenData;
      state.role = role;
    },
    setIsRefreshingToken: (state, { payload }: PayloadAction<boolean>) => {
      state.isRefreshingToken = payload;
    },
    setIsLoginInProgress: (state) => {
      state.isLoginInProgress = true;
    },
  },
  extraReducers: (builder) => {
    // login user
    builder.addCase(loginUser.fulfilled, (state, { payload: { tokenData, role } }) => {
      state.tokenData = tokenData;
      state.role = role;
      state.isLoginInProgress = false;
    });
    builder.addCase(loginUser.pending, (state) => {
      state.isLoginInProgress = true;
      state.loginError = null;
      state.role = null;
    });
    builder.addCase(loginUser.rejected, (state, { error }) => {
      state.loginError = error as Error;
      state.isLoginInProgress = false;
    });

    // login admin
    builder.addCase(
      loginAdmin.fulfilled,
      (state, { payload: { email, firstLogin, googleAuthenticatorQRImage, googleAuthenticatorToken } }) => {
        let data = {
          email: email,
          first_login: firstLogin,
          google_authenticator_qr_image: googleAuthenticatorQRImage,
          google_authenticator_token: googleAuthenticatorToken,
        } as UserTowFactorResponse;
        state.userTowFactorResponse = data;
        state.isLoginInProgress = false;
        state.loginError = null;
      }
    );
    builder.addCase(loginAdmin.pending, (state) => {
      state.isLoginInProgress = true;
      state.loginError = null;
      state.role = null;
    });
    builder.addCase(loginAdmin.rejected, (state, { error }) => {
      state.loginError = error as Error;
      state.isLoginInProgress = false;
    });

    // login authenticate
    builder.addCase(authenticateAdmin.fulfilled, (state, { payload: { tokenData, role, showPlatform } }) => {
      state.tokenData = tokenData;
      state.role = role;
      state.showPlatform = showPlatform;
      state.isLoginInProgress = false;
      state.loginError = null;
    });
    builder.addCase(authenticateAdmin.pending, (state) => {
      state.isLoginInProgress = true;
      state.loginError = null;
      state.role = null;
    });
    builder.addCase(authenticateAdmin.rejected, (state, { error }) => {
      state.loginError = error as Error;
      state.isLoginInProgress = false;
    });

    // check invitation  confirmation
    builder.addCase(checkInvitation.fulfilled, (state, { payload: { validate_success, confirmed } }) => {
      state.checkInvitationResponse = { validate_success: validate_success, confirmed: confirmed };
      state.isCheckInvitationProgress = false;
      state.checkInvitationError = null;
    });
    builder.addCase(checkInvitation.pending, (state) => {
      state.isCheckInvitationProgress = true;
      state.checkInvitationError = null;
      state.checkInvitationResponse = null;
    });
    builder.addCase(checkInvitation.rejected, (state, { error }) => {
      state.checkInvitationError = error as Error;
      state.isCheckInvitationProgress = false;
    });

    // invitation confirmation
    builder.addCase(confirmationInvitation.fulfilled, (state, { payload: { link, confirmed } }) => {
      state.invitationConfirmationResponse = { link: link, confirmed: confirmed };
      state.isInvitationConfirmationProgress = false;
      state.invitationConfirmationError = null;
    });
    builder.addCase(confirmationInvitation.pending, (state) => {
      state.isInvitationConfirmationProgress = true;
      state.invitationConfirmationError = null;
      state.invitationConfirmationResponse = null;
    });
    builder.addCase(confirmationInvitation.rejected, (state, { error }) => {
      state.invitationConfirmationError = error as Error;
      state.isInvitationConfirmationProgress = false;
    });

    // refresh token action
    builder.addCase(refreshToken.pending, (state) => {
      state.isRefreshingToken = true;
      state.role = null;
    });
    builder.addCase(refreshToken.rejected, (state) => {
      state.isRefreshingToken = false;
    });
    builder.addCase(refreshToken.fulfilled, (state, { payload: { tokenData, role } }) => {
      state.tokenData = tokenData;
      state.role = role;
      state.isRefreshingToken = false;
    });
    builder.addCase(RootStoreActionType.resetState, () => initialState);
  },
});

export const { setUserCredentials, setIsRefreshingToken } = authSlice.actions;
