import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { Routes } from 'infrastructure/routes';

const useStyles = makeStyles<Theme>((style) => ({
  footer: {
    position: 'fixed',
    flexDirection: 'row',
    bottom: 0,
    width: '100%',
    backgroundColor: '#2D3748',
    color: '#FFF',
    padding: 15,
    zIndex: 1000,
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    fontFamily: "'Cairo', sans-serif",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  button: {
    color: style.palette.common.white,
    display: 'flex',
    width: 104,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    flexShrink: 0,
    borderRadius: 5,
  },
  footerText: {
    fontSize: '1rem',
    lineHeight: 1.5,
    maxWidth: 890,
    fontFamily: 'Effra,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif',
  },
  textStyle: {
    color: '#FFF',
    textAlign: 'justify',
    fontFamily: 'Frutiger LT Arabic',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
}));

const CookiePolicyMessage: React.FC = () => {
  const [agreed, setAgreed] = useState<boolean>(() => {
    return localStorage.getItem('cookiePolicyAgreed') === 'true';
  });

  const classes = useStyles();
  const translate = useCreateTranslate(TranslationNamespace.landingPage);

  const handleAgree = () => {
    setAgreed(true);
    localStorage.setItem('cookiePolicyAgreed', 'true');
  };

  if (agreed) {
    return null;
  }

  return (
    <footer className={classes.footer}>
      <div className={classes.footerContent}>
        <p className={classes.footerText}>
          {translate('cookiesPolicyMessage')}
          <a href={Routes.PRIVACY_POLICY} style={{ color: '#fff', fontWeight: 'bold' }}>
            {translate('privacyPolicy')}
          </a>
        </p>
        <Button
          variant="contained"
          color="secondary"
          className={clsx(classes.button, classes.thirdSectionButton)}
          size="medium"
          onClick={handleAgree}
        >
          {translate('agreeToAll')}
        </Button>
      </div>
    </footer>
  );
};

export default CookiePolicyMessage;
