import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';

import { ReactComponent as EditCircleIcon } from 'ui/icons/edit_circle.svg';

import { AdminQuestionnaireStepTopic } from '../AdminQuestionnaireStepTopic';

type QuestionnaireReviewTopicEditableProps = {
  topicLabelName: string;
  stepIndex: number;
  setStepIndex: React.Dispatch<React.SetStateAction<number>>;
  isEditable: boolean;
};

const useStyles = makeStyles((theme) => ({
  topicEditable: {
    flip: false,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  topicIcon: {
    flip: false,
    marginLeft: theme.spacing(2),
    width: '48px',
    height: '48px',
    fill: 'none',
    cursor: 'pointer',
  },
}));

export const QuestionnaireReviewTopicEditable: React.FC<QuestionnaireReviewTopicEditableProps> = ({
  topicLabelName,
  setStepIndex,
  stepIndex,
  isEditable,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.topicEditable}>
      <AdminQuestionnaireStepTopic topicLabelName={topicLabelName} />
      {isEditable && (
        <SvgIcon
          onClick={() => setStepIndex(stepIndex)}
          className={classes.topicIcon}
          component={EditCircleIcon}
          viewBox="0 0 48 48"
        />
      )}
    </div>
  );
};
