import React from 'react';

import { EstablishmentDetails as CommonEstablishmentDetails } from '../../../common/establishments/establishments-details/EstablishmentDetails';
import { InspectorLayout } from '../../ui/InspectorLayout';
import { getInspectorPath } from '../../InspectorRoutes';

export const InspectorEstablishmentDetails: React.FC = () => {
  return (
    <InspectorLayout>
      <CommonEstablishmentDetails getPath={getInspectorPath} />
    </InspectorLayout>
  );
};
