import { adminApi } from 'domain/admin/api/admin.api';
import { companyRepresentativeApi } from 'domain/company-representative/api/companyRepresentative.api';
import { createAxiosInstanceWithRefreshInterceptor } from 'infrastructure/persistence/axiosInstanceWithInterceptor';
import { session, SessionStorageKey, getSessionValueByKey } from 'infrastructure/services/storage';
import { userApi } from 'infrastructure/services/user.api';
import { RootState } from "infrastructure/store/rootStore.types"

import { RootStoreResetStateAction } from './rootStore.actions';
import { AppDispatch } from './rootStore.types';
export const resetUserData = (dispatch: AppDispatch) => {
  session.removeItem(SessionStorageKey.refreshKey);
  session.removeItem(SessionStorageKey.expiryKey);

  [
    RootStoreResetStateAction,
    adminApi.util.resetApiState(),
    companyRepresentativeApi.util.resetApiState(),
    userApi.util.resetApiState(),
  ].forEach(dispatch);
};

export const blacklistRefreshToken = async (
  dispatch: AppDispatch,
  getState: () => RootState // تحديث نوع getState
) => {
  await createAxiosInstanceWithRefreshInterceptor(dispatch, getState)
    .post<{ refresh: string | null }>(`/user/token/blacklist/`, {
      refresh: getSessionValueByKey(SessionStorageKey.refreshKey),
    })
    .catch((error) => {
      resetUserData(dispatch);

      throw error;
    });
};
