import React from 'react';
import TablePagination, { TablePaginationProps } from '@material-ui/core/TablePagination';
import { TableCellProps } from '@material-ui/core';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';

export const CustomTablePagination: React.FC<TablePaginationProps & Pick<TableCellProps, 'classes' | 'component'>> = ({
  rowsPerPageOptions = [5, 10, 25],
  ...props
}) => {
  const translateCommon = useCreateTranslate(TranslationNamespace.common);

  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      {...props}
      labelRowsPerPage={translateCommon('rowsPerPage')}
      labelDisplayedRows={({ from, to, count }) =>
        `${to}-${from} ${translateCommon('of')} ${count !== -1 ? count : to}`
      }
    />
  );
};

export default CustomTablePagination;
