/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { Theme } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { debounce } from 'lodash-es';
import i18n from 'i18next';

import { TranslationNamespace } from 'i18n/config';
import { collapseButtonSpaceWidth, labelCardColor } from 'ui/styles';
import { useCreateTranslate } from 'utilities/translate.hook';
import { BaseQueryError } from 'infrastructure/persistence/baseQueryWithReAuth';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { AssessmentQuestionText } from 'ui/assessment/AssessmentQuestionText';
import { AssessmentAnswerRadioGroup } from 'ui/assessment/AssessmentAnswerRadioGroup';
import { AsyncFn } from 'types/AsyncFn';
import { Hint } from 'ui/Hint';
import { CreateAnswerInput, AssessmentAnswer, AssessmentAnswerType, UpdateAnswerInput } from 'types/AssessmentAnswer';
import { AssessmentQuestion } from 'types/AssessmentQuestion';
import { AssessmentAnswerAttachments } from 'ui/assessment/AssessmentAnswerAttachments';

import {
  useCreateSAAnswerMutation,
  useDeleteSelfAssessmentAttachmentMutation,
  useUpdateSAAnswerMutation,
  useUploadSelfAssessmentAttachmentMutation,
} from '../api/companyRepresentative.api';
import { getDirection } from '../../../utilities/useLanguage';

type CompanyRepresentativeSAQuestionProps = {
  assessmentId: number;
  question: AssessmentQuestion;
  questionNo: number;
  answer?: AssessmentAnswer;
};

const useStyles = makeStyles<Theme, { isOpen: boolean }>((theme) => ({
  wrapper: {
    flip: false,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '18px 56px 18px 32px',
  },

  mainSection: {
    flip: false,
    display: 'flex',
    flexDirection: 'row',
  },

  collapseButtonSpace: {
    flip: false,
    flexShrink: 0,
    width: collapseButtonSpaceWidth,
  },

  questionSection: {
    alignSelf: 'center',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  questionText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  answerSectionWrapper: {
    flip: false,
  },

  answerSection: {
    flip: false,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  questionDescription: {
    color: labelCardColor,
    marginBottom: theme.spacing(4),
  },

  notApplicableWrapper: {
    marginTop: '40px',
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  notApplicableDescription: {
    marginTop: theme.spacing(1),
    color: labelCardColor,
  },

  notApplicableInput: {
    marginTop: theme.spacing(3),
  },

  statusSpace: ({ isOpen }) => ({
    display: 'flex',
    justifyContent: 'center',
    flexShrink: 0,
    width: '48px',
    paddingRight: theme.spacing(3),
  }),
}));

function debounceFn<T>(func: AsyncFn<T>) {
  return debounce(func, 500);
}

export const CompanyRepresentativeSAQuestion: React.FC<CompanyRepresentativeSAQuestionProps> = ({
  question,
  questionNo,
  answer,
  assessmentId,
}) => {
  const [isOpenQuestion] = React.useState(false);
  const classes = useStyles({ isOpen: isOpenQuestion });
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const showNotification = useNotifications();
  const direction = getDirection(i18n.language);

  // update/create data
  const [answerType, setAnswerType] = React.useState<AssessmentAnswerType | null>(answer?.type || null);
  const [answerText, setAnswerText] = React.useState(answer?.text);
  const [needUpdate, setNeedUpdate] = React.useState(false);
  const [createAnswer, { isLoading: isLoadingCreateAnswer }] = useCreateSAAnswerMutation();
  const [updateAnswer, { isLoading: isLoadingUpdateAnswer }] = useUpdateSAAnswerMutation();

  const [uploadSelfAssessmentAttachment, { isLoading: isUploading }] = useUploadSelfAssessmentAttachmentMutation();
  const [delateSelfAssessmentAttachment] = useDeleteSelfAssessmentAttachmentMutation();
  const [questionType, setQuestionType] = React.useState(question?.type || null);

  const resetAnswer = () => {
    setAnswerType(answer?.type || null);
    setAnswerText(answer?.text);
  };

  const createAnswerRef = useRef(
    debounceFn<CreateAnswerInput>(async (data) => {
      if (!isLoadingCreateAnswer) {
        await createAnswer(data)
          .unwrap()
          .catch((error: BaseQueryError) => {
            showNotification(NotificationType.Error, { errorId: error.data.errorCode });
            resetAnswer();
          });
      }
    })
  );

  const updateAnswerRef = useRef(
    debounceFn<UpdateAnswerInput>(async (data) => {
      if (!isLoadingUpdateAnswer) {
        await updateAnswer(data)
          .unwrap()
          .catch((error: BaseQueryError) => {
            showNotification(NotificationType.Error, { errorId: error.data.errorCode });
            resetAnswer();
          });
      }
    })
  );

  useEffect(() => {
    if (answerType && needUpdate) {
      if (answer) {
        updateAnswerRef
          .current({
            assessmentSectionId: question.sectionId,
            data: {
              id: answer.id,
              question_id: answer.questionId,
              self_assessment_id: answer.assessmentId,
              text: answerText,
              type: answerType,
            },
          })
          ?.then(() => {
            setNeedUpdate(false);
          });
      } else {
        createAnswerRef
          .current({
            assessmentSectionId: question.sectionId,
            data: {
              question_id: question.id,
              self_assessment_id: assessmentId,
              type: answerType,
              text: answerText,
            },
          })
          ?.then(() => {
            setNeedUpdate(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerType, answerText]);

  useEffect(() => {
    setQuestionType(question?.type || null);
    return () => {
      updateAnswerRef.current.cancel();
      createAnswerRef.current.cancel();
    };
  }, []);

  const changeAnswerType = async (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setAnswerType(+value);
    setNeedUpdate(true);
  };

  const onChangeText: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = async (event) => {
    if (event.target.value.length <= 500) {
      setAnswerText(event.target.value);
      setNeedUpdate(true);
    }
  };
  return (
    <div className={classes.wrapper}>
      <div dir="ltr" className={classes.mainSection}>
        <div className={classes.questionSection}>
          <AssessmentQuestionText question={question} questionNo={questionNo} />
          <div>
            <div className={classes.answerSectionWrapper}>
              <div dir={direction} className={classes.answerSection}>
                <Typography variant="body2" className={classes.questionDescription}>
                  {i18n.language === 'en' ? question.descriptionEN : question.descriptionAR}
                </Typography>
                <AssessmentAnswerRadioGroup
                  questionType={questionType || null}
                  answerType={answerType}
                  changeAnswerType={changeAnswerType}
                />
                <Collapse in={answerType === AssessmentAnswerType.Yes}>
                  <AssessmentAnswerAttachments
                    answer={answer}
                    assessmentSectionId={question.sectionId}
                    isUploading={isUploading}
                    uploadSelfAssessmentAttachment={uploadSelfAssessmentAttachment}
                    delateSelfAssessmentAttachment={delateSelfAssessmentAttachment}
                  />
                </Collapse>
                <Collapse in={answerType === AssessmentAnswerType.NotApplicable}>
                  <div className={classes.notApplicableWrapper}>
                    <Typography variant="h6">{translate('whyNotApply')}</Typography>
                    <Typography variant="body1" className={classes.notApplicableDescription}>
                      {translate('addTheComment')}
                    </Typography>
                    <TextField
                      className={classes.notApplicableInput}
                      onChange={onChangeText}
                      value={answerText}
                      type="text"
                      variant="outlined"
                      placeholder={translate('writeCommentHere')}
                      multiline
                    />
                    <Hint text={translate('lengthOfComment')} />
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
