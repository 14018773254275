import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { Role } from 'infrastructure/services/auth.type';
import { getAdminPath } from 'domain/admin/AdminRoutes';
import { getInspectorPath } from 'domain/inspector/InspectorRoutes';
import { getAuditorPath } from 'domain/auditor/AuditorRoutes';
import { Routes as UserRoutes } from 'domain/common/Routes';
import { Routes } from 'infrastructure/routes';
import { useAuth } from 'infrastructure/services/auth.hooks';
import { getSessionValueByKey, SessionStorageKey } from 'infrastructure/services/storage';
import { RefreshComponent } from 'ui/RefreshComponent';

interface ProtectedRouteProps extends RouteProps {
  role: Role;
  redirectPath: string;
}

type PrivateRouteFC = React.FC<ProtectedRouteProps>;

export const PrivateRoute: PrivateRouteFC = ({ children, role, redirectPath, ...rest }) => {
  const auth = useAuth(role);
  const isOpen = auth.isLoggedIn && auth.isAllowed;
  const isAllowedToRefreshToken =
    !auth.isLoggedIn && (getSessionValueByKey(SessionStorageKey.expiryKey) || 0) > new Date().getMilliseconds();

  if (isOpen) {
    return <Route {...rest}>{children}</Route>;
  }

  if (isAllowedToRefreshToken) {
    return <RefreshComponent redirectPath={redirectPath} />;
  }

  return (
    <Route {...rest}>
      <Redirect to={redirectPath} />
    </Route>
  );
};

export const CompanyRepresentativeRoute: React.FC<RouteProps> = ({ children, ...rest }) => (
  <PrivateRoute role={Role.CompanyRepresentative} redirectPath={Routes.HOME} {...rest}>
    {children}
  </PrivateRoute>
);

export const AdminRoute: React.FC<RouteProps> = ({ children, ...rest }) => (
  <PrivateRoute role={Role.Admin} redirectPath={getAdminPath(UserRoutes.login)} {...rest}>
    {children}
  </PrivateRoute>
);

export const InspectorRoute: React.FC<RouteProps> = ({ children, ...rest }) => (
  <PrivateRoute role={Role.Inspector} redirectPath={getInspectorPath(UserRoutes.login)} {...rest}>
    {children}
  </PrivateRoute>
);

export const AuditorRoute: React.FC<RouteProps> = ({ children, ...rest }) => (
  <PrivateRoute role={Role.Auditor} redirectPath={getAuditorPath(UserRoutes.login)} {...rest}>
    {children}
  </PrivateRoute>
);

export const RedirectToAllowedRoute: PrivateRouteFC = ({ children, role, redirectPath, ...rest }) => {
  const auth = useAuth(role);

  return <Route {...rest}>{auth.isAllowed ? <Redirect to={redirectPath} /> : children}</Route>;
};
