import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { axiosInstance } from 'infrastructure/persistence/axiosInstance';

import { AuthActionType, logoutAction } from './auth.action';
import {
  AdminAuthenticateCredentials,
  AdminLoginCredentials,
  CheckInvitationResponse,
  ConfirmationInvitationCredentials,
  InvitationConfirmationResponse,
  TokenResponse,
  UserData,
  UserTowFactorInfo,
  UserTowFactorResponse,
} from './auth.type';
import {
  processAuthenticateResponse,
  processCheckInvitationResponse,
  processConfirmationResponse,
  processRefreshTokenResponse,
  processTokenResponse,
} from './auth.utils';
import { session, SessionStorageKey } from './storage';

type CreateTokenDto = { id: string };

type RefreshTokenDto = { refresh: string | null };

export const loginAdmin = createAsyncThunk(
  AuthActionType.loginAdmin,
  async (credentials: AdminLoginCredentials): Promise<UserTowFactorInfo> => {
    const { data } = await axiosInstance.post<AdminLoginCredentials, AxiosResponse<UserTowFactorResponse>>(
      `/user/token/admin/create/`,
      credentials
    );
    const userAuthenticateData = processAuthenticateResponse(data);

    return userAuthenticateData;
  }
);

export const authenticateAdmin = createAsyncThunk(
  AuthActionType.authenticateAdmin,
  async (credentials: AdminAuthenticateCredentials): Promise<UserData> => {
    const { data } = await axiosInstance.post<AdminAuthenticateCredentials, AxiosResponse<TokenResponse>>(
      `/user/token/admin/authenticate/`,
      credentials
    );
    const userData = processTokenResponse(data);

    return userData;
  }
);

export const loginUser = createAsyncThunk(
  AuthActionType.loginUser,
  async (createTokenDto: CreateTokenDto): Promise<UserData> => {
    const { data } = await axiosInstance.post<CreateTokenDto, AxiosResponse<TokenResponse>>(
      `/user/token/create/`,
      createTokenDto
    );
    const userData = processTokenResponse(data);

    return userData;
  }
);

export const refreshToken = createAsyncThunk<UserData>(
  AuthActionType.refreshToken,
  async (_, { dispatch, getState }): Promise<UserData> => {
    const refresh = session.getItem(SessionStorageKey.refreshKey);
    if (!refresh) {
      console.error('Refresh token is missing or empty.');
      dispatch(logoutAction());
      return {} as UserData;
    }
    const refreshDto: RefreshTokenDto = { refresh };

    const { data } = await axiosInstance
      .post<RefreshTokenDto, AxiosResponse<TokenResponse>>(`/user/token/refresh/`, refreshDto)
      .catch((error) => {
        // Unable to refresh token logout user :)
        dispatch(logoutAction());

        throw error;
      });

    const userData = processRefreshTokenResponse(data);

    return userData;
  }
);

export const confirmationInvitation = createAsyncThunk(
  AuthActionType.invitationConfirmation,
  async (credentials: ConfirmationInvitationCredentials): Promise<InvitationConfirmationResponse> => {
    const { data } = await axiosInstance.post<
      ConfirmationInvitationCredentials,
      AxiosResponse<InvitationConfirmationResponse>
    >(`/user/invitation/confirm/`, credentials);
    const userConfirmationData = processConfirmationResponse(data);

    return userConfirmationData;
  }
);

export const checkInvitation = createAsyncThunk(
  AuthActionType.checkInvitation,
  async (formId: string): Promise<CheckInvitationResponse> => {
    const { data } = await axiosInstance.get<string, AxiosResponse<CheckInvitationResponse>>(
      `/user/invitation/check?form_id=${formId}`
    );
    const checkResponse = processCheckInvitationResponse(data);
    return checkResponse;
  }
);

export const loginInspecor = createAsyncThunk(
  AuthActionType.loginAdmin,
  async (credentials: AdminLoginCredentials): Promise<UserTowFactorInfo> => {
    const { data } = await axiosInstance.post<AdminLoginCredentials, AxiosResponse<UserTowFactorResponse>>(
      `/user/token/inspector/create/`,
      credentials
    );
    const userAuthenticateData = processAuthenticateResponse(data);

    return userAuthenticateData;
  }
);

export const authenticateInspector = createAsyncThunk(
  AuthActionType.authenticateAdmin,
  async (credentials: AdminAuthenticateCredentials): Promise<UserData> => {
    const { data } = await axiosInstance.post<AdminAuthenticateCredentials, AxiosResponse<TokenResponse>>(
      `/user/token/inspector/authenticate/`,
      credentials
    );
    const userData = processTokenResponse(data);

    return userData;
  }
);

export const loginAuditor = createAsyncThunk(
  AuthActionType.loginAdmin,
  async (credentials: AdminLoginCredentials): Promise<UserTowFactorInfo> => {
    const { data } = await axiosInstance.post<AdminLoginCredentials, AxiosResponse<UserTowFactorResponse>>(
      `/user/token/auditor/create/`,
      credentials
    );
    const userAuthenticateData = processAuthenticateResponse(data);

    return userAuthenticateData;
  }
);

export const authenticateAuditor = createAsyncThunk(
  AuthActionType.authenticateAdmin,
  async (credentials: AdminAuthenticateCredentials): Promise<UserData> => {
    const { data } = await axiosInstance.post<AdminAuthenticateCredentials, AxiosResponse<TokenResponse>>(
      `/user/token/auditor/authenticate/`,
      credentials
    );
    const userData = processTokenResponse(data);

    return userData;
  }
);
