import { TranslateFn } from 'utilities/translate.hook';

import { SelfAssessmentPassingStatus } from '../../../types/Assessment';

export const getResultMessageFn =
  (translate: TranslateFn) =>
  (passingStatus: SelfAssessmentPassingStatus | null, minSuccessfulScore: number = 0) => {
    switch (passingStatus) {
      case SelfAssessmentPassingStatus.BELOW_PASSING_SCORE:
        return translate('bellowPassingScore', {
          passingScore: `${minSuccessfulScore}`,
        });
      case SelfAssessmentPassingStatus.ABOVE_HIGH_SCORE_VALUE:
        return translate('aboveHighScore');
      case SelfAssessmentPassingStatus.BELOW_HIGH_SCORE_VALUE:
        return translate('bellowHighScore', {
          passingScore: `${minSuccessfulScore}`,
        });
      default:
        throw Error('Unsupported passing SA status');
    }
  };
