import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import i18n from 'i18next';

import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { useAppSelector } from 'infrastructure/store/rootStore.hooks';

import { selectQuestionnaireId } from '../AdminQuestionnaire.slice';
import { AssessmentTemplate } from '../../types/AssessmentTemplate';
import { QuestionnaireReviewContainerWithStatistics } from './QuestionnaireReviewContainerWithStatistics';
import { useGetSectionsByQuestionnaireIdQuery } from '../../api/admin.api';

type QuestionnaireReviewSectionsProps = {
  setStepIndex: React.Dispatch<React.SetStateAction<number>>;
  questionnaire: AssessmentTemplate;
  isEditable: boolean;
};

const useStyles = makeStyles((theme) => ({
  body2: {
    opacity: '0.6',
    marginTop: theme.spacing(2),
  },
  body2Bold: {
    opacity: '0.6',
    fontWeight: 700,
  },
  body1: {
    opacity: '0.6',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  infoWrapper: {
    marginTop: theme.spacing(8),
  },
  itemWrapper: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(6),
    '&:last-of-type': {
      marginBottom: theme.spacing(2),
    },
  },
  descriptionContainer: {
    display: 'flex',
  },
  descriptionPointer: {
    width: '48px',
    height: '48px',
    borderRadius: '50%',
    border: `1px solid ${theme.palette.secondary.main}`,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  h5: {
    color: theme.palette.secondary.main,
  },
  statisticsContainer: {
    marginRight: theme.spacing(2),
  },
  statisticsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
  },
}));

export const QuestionnaireReviewSections: React.FC<QuestionnaireReviewSectionsProps> = ({
  setStepIndex,
  questionnaire,
  isEditable,
}) => {
  let questionnaireId = 0;
  const stepIndex = 1;
  const classes = useStyles();
  const translateQuestionnaire = useCreateTranslate(TranslationNamespace.admin);
  const selectedQuestionnaireId = useAppSelector(selectQuestionnaireId);
  if (selectedQuestionnaireId !== null) {
    questionnaireId = selectedQuestionnaireId;
  }
  const { data: sections } = useGetSectionsByQuestionnaireIdQuery({ questionnaireId });
  const sectionIndexInfo = (index: number) =>
    `${translateQuestionnaire('section')} ${index + 1} ${translateQuestionnaire('of')} ${
      sections?.length
    }`.toUpperCase();

  return (
    <QuestionnaireReviewContainerWithStatistics
      topicLabelName="sectionAndQuestions"
      questionsNoSummary={questionnaire.questionsNoSummary}
      totalWeightSummary={questionnaire.totalWeightSummary}
      stepIndex={stepIndex}
      setStepIndex={setStepIndex}
      isEditable={isEditable}
    >
      <div  className={classes.infoWrapper}>
        {sections &&
          sections.map((section, idx) => (
            <div  className={classes.itemWrapper} key={section.id}>
              <div className={classes.descriptionContainer}>
                <div>
                  <div className={classes.descriptionPointer}>
                    <Typography variant="h5" className={classes.h5}>
                      {idx + 1}
                    </Typography>
                  </div>
                </div>
                <div>
                  <Typography variant="body2" className={classes.body2Bold}>
                    {sectionIndexInfo(idx)}
                  </Typography>
                  <Typography  variant="h6">
                    {i18n.language === 'en' ? section.nameEN : section.nameAR}
                  </Typography>
                  <Typography variant="body2" className={classes.body2}>
                    {i18n.language === 'en' ? section.descriptionEN : section.descriptionAR}
                  </Typography>
                </div>
              </div>
              <div className={classes.statisticsContainer}>
                <div className={classes.statisticsWrapper}>
                  <Typography variant="body1" className={classes.body1}>
                    {translateQuestionnaire('numberOfQuestions')}
                  </Typography>
                  <Typography variant="h6">{section.questionsNo}</Typography>
                </div>
                <div className={classes.statisticsWrapper}>
                  <Typography variant="body1" className={classes.body1}>
                    {translateQuestionnaire('overallSectionWeight')}
                  </Typography>
                  <Typography variant="h6">{Number(section.totalWeight).toFixed(2)}</Typography>
                </div>
              </div>
            </div>
          ))}
      </div>
    </QuestionnaireReviewContainerWithStatistics>
  );
};
