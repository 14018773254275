import { PCRequest, PCRequestStatus } from 'types/PCRequest';
import { AssessmentType } from 'types/Assessment';
import { getAssessmentByAssessmentType } from 'domain/admin/utils/getAssessmentByAssessmentType';

export const getCurrentAssessment = (pcRequest: PCRequest) => {
  switch (pcRequest.status) {
    case PCRequestStatus.AuditorStarted:
      return getAssessmentByAssessmentType(pcRequest.assessments, AssessmentType.AUDITOR);
    case PCRequestStatus.InspectorStarted:
      return getAssessmentByAssessmentType(pcRequest.assessments, AssessmentType.INSPECTOR);
    default:
      throw Error('Only statuses AuditorStarted and InspectorStarted should be considered here');
  }
};
