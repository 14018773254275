import { AssessmentType } from 'types/Assessment';
import { PCRequest } from 'types/PCRequest';
import { isValue } from 'utilities/isValue';

import { getAssessmentByAssessmentType } from './getAssessmentByAssessmentType';

const compareValues = (value?: number | null, secondValue?: number | null) => {
  return {
    isHigher: isValue(value) && isValue(secondValue) && value > secondValue,
    isEqual: value === secondValue,
  };
};

const calculateDifference = (value?: number | null, secondValue?: number | null) =>
  isValue(value) && isValue(secondValue) ? Math.abs(value - secondValue) : null;

export const compareAssessmentScores = (pcRequest: PCRequest) => {
  const crAssessmentScore = getAssessmentByAssessmentType(
    pcRequest.assessments,
    AssessmentType.COMPANY_REPRESENTATIVE
  )?.score;

  const audtiorAssessmentScore = getAssessmentByAssessmentType(pcRequest.assessments, AssessmentType.AUDITOR)?.score;

  const inspectorAssessmentScore = getAssessmentByAssessmentType(
    pcRequest.assessments,
    AssessmentType.INSPECTOR
  )?.score;

  return {
    crAssessmentScore,
    audtiorAssessmentScore,
    inspectorAssessmentScore,
    isInspectorEqualAuditor: compareValues(inspectorAssessmentScore, audtiorAssessmentScore).isEqual,

    isAuditorEqualCr: compareValues(crAssessmentScore, audtiorAssessmentScore).isEqual,

    differenceCrAuditor: calculateDifference(crAssessmentScore, audtiorAssessmentScore),

    differenceInspectorAuditor: calculateDifference(audtiorAssessmentScore, inspectorAssessmentScore),

    isAuditorHigherThanCr: compareValues(audtiorAssessmentScore, crAssessmentScore).isHigher,

    isInspectorHigherThanAuditor: compareValues(inspectorAssessmentScore, audtiorAssessmentScore).isHigher,
  };
};
