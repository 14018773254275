import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { TranslationNamespace } from 'i18n/config';
import { useNavigate } from 'utilities/navigate';
import { BaseQueryError } from 'infrastructure/persistence/baseQueryWithReAuth';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { FinishedAssessment } from 'ui/FinishedAssessment';
import rootStore from 'infrastructure/store/rootStore';

import { selectEstablishmentInfo } from '../company-representative-login/selectors/establishmentInfo.selector';
import { useRedoAssessmentMutation } from '../api/companyRepresentative.api';
import { CompanyRepresentativeRoutes, getCompanyRepresentativePath } from '../CompanyRepresentativeRoutes';

export const CompanyRepresentativeSAFinished: React.FC = () => {
  const { id: assessmentId } = useParams<{ id: string }>();
  const [redoSelfAssessment, { isLoading: isLoadingReDo }] = useRedoAssessmentMutation();

  const [establishmentId, setEstablishmentId] = useState('');
  const navigate = useNavigate();
  const showNotification = useNotifications();

  useEffect(() => {
    const fetchEstablishmentId = () => {
      const id = selectEstablishmentInfo(rootStore.getState())?.establishmentId;
      if (id) {
        setEstablishmentId(id.toString());
      }
    };

    fetchEstablishmentId();

    const unsubscribe = rootStore.subscribe(fetchEstablishmentId);
    return () => unsubscribe();
  }, []);

  const backNavigate = () => {
    if (establishmentId) {
      navigate(`${getCompanyRepresentativePath(CompanyRepresentativeRoutes.osh)}/${establishmentId}`);
    }
  };

  const handleRetry = async () => {
    if (!isLoadingReDo) {
      await redoSelfAssessment(+assessmentId)
        .unwrap()
        .then(({ id: newSelfAssessmentId }) => {
          navigate(`${getCompanyRepresentativePath(CompanyRepresentativeRoutes.assessment)}/${newSelfAssessmentId}`);
        })
        .catch((err: BaseQueryError) => {
          if (typeof err.data === 'object' && err.data !== null) {
            showNotification(NotificationType.Error, { errorId: err.data.errorCode });
          }
        });
    }
  };

  return (
    <FinishedAssessment
      assessmentId={+assessmentId}
      handleRetry={handleRetry}
      translationNamespace={TranslationNamespace.companyRepresentative}
      handleBackNavigate={backNavigate}
    />
  );
};
