import { FormControl, FormHelperText, makeStyles, MenuItem, Select, Typography } from '@material-ui/core';
import React from 'react';

import { Hint } from 'ui/Hint';
import { Button } from 'ui/Button';
import { Dialog } from 'ui/Dialog';
import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { Role } from 'infrastructure/services/auth.type';
import { useGetMeQuery } from 'infrastructure/services/user.api';
import { LoadingWrapper } from 'ui/LoadingWrapper';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { BaseQueryError } from 'infrastructure/persistence/baseQueryWithReAuth';
import { PCRequestStatus } from 'types/PCRequest';

import { useAssignSafetySpecialistMutation, useGetUsersQuery } from '../../admin/api/admin.api';

const useStyles = makeStyles((theme) => ({
  description: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(6),
  },

  buttons: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  submitButton: {
    height: 40,
    flex: 1,
  },

  cancelButton: {
    height: 40,
    flex: 1,
    marginRight: theme.spacing(2),
  },
}));

type PCRequestSpecialistDialogProps = {
  open: boolean;
  handleClose: () => void;
  pcRequestId: number;
  auditorId: number | null;
  pcStatus: PCRequestStatus;
};

export const PCRequestSpecialistDialog: React.FC<PCRequestSpecialistDialogProps> = ({
  open,
  handleClose,
  pcRequestId,
  auditorId,
  pcStatus,
}) => {
  const classes = useStyles();
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const { data: { results } = { results: [] } } = useGetUsersQuery({
    accountTypes: [Role.Admin, Role.Auditor, Role.Inspector],
    parameters: { page: 1, pageSize: 100 },
    param: { searchInfo: [''] },
  });
  const [selectedSpecialistId, setSelectedSpecialistId] = React.useState<null | number>(null);
  const { data: me } = useGetMeQuery();
  const showNotification = useNotifications();
  const [assignSafetySpecialist, { isLoading }] = useAssignSafetySpecialistMutation();

  const assignText =
    pcStatus === PCRequestStatus.Unassigned ? translate('assignAuditor') : translate('assignInspector');
  const getSpecialists =
    pcStatus === PCRequestStatus.Unassigned
      ? results.filter(
          (specialist) => specialist.id !== auditorId && [Role.Admin, Role.Auditor].includes(specialist.accountType)
        )
      : results.filter(
          (specialist) => specialist.id !== auditorId && [Role.Admin, Role.Inspector].includes(specialist.accountType)
        );

  const handleAssignSpecialist = () => {
    if (selectedSpecialistId) {
      assignSafetySpecialist({ pcRequestId, userId: selectedSpecialistId })
        .unwrap()
        .then(() => {
          showNotification(NotificationType.Success, { message: translate('auditorAssignedSuccess') });
          handleClose();
        })
        .catch((err: BaseQueryError) => {
          if (!err.data) {
            showNotification(NotificationType.Error);
          }

          if (typeof err.data === 'object' && err.data !== null) {
            showNotification(NotificationType.Error, { errorId: err.data.errorCode });
          }
        });
    }
  };

  return (
    <Dialog open={open} handleClose={handleClose}>
      <Typography data-testid="pc-request-specialist-dialog-title" variant="h6">
        {assignText}
      </Typography>
      <Typography variant="body2" className={classes.description}></Typography>
      <FormControl>
        <Select
          variant="outlined"
          value={selectedSpecialistId || ''}
          onChange={(e) => setSelectedSpecialistId(e.target.value as number)}
          displayEmpty
        >
          <MenuItem key="specialistSelect-0" disabled value="">
            {pcStatus === PCRequestStatus.Unassigned
              ? translate('selectPlaceholder')
              : translate('selectInspectorPlaceholder')}
          </MenuItem>
          {getSpecialists.map((specialist) => (
            <MenuItem key={`specialistSelect-${specialist.id}`} value={specialist.id}>
              <span>{specialist.firstName}</span>
              &nbsp;
              <span>
                {`${specialist.lastName}`} {specialist.id === me?.id && `- ${translate('you')}`}
              </span>
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          {pcStatus === PCRequestStatus.Unassigned ? translate('selectTip') : translate('selectTipInspector')}
        </FormHelperText>
      </FormControl>

      <Hint
        text={
          pcStatus === PCRequestStatus.Unassigned
            ? translate('assignSpecialistTip')
            : translate('assignSpecialistInspector')
        }
      />

      <div className={classes.buttons}>
        <Button
          variant="outlined"
          size="medium"
          color="secondary"
          className={classes.cancelButton}
          onClick={handleClose}
        >
          {translate('cancel')}
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="secondary"
          className={classes.submitButton}
          onClick={handleAssignSpecialist}
          disabled={isLoading}
        >
          <LoadingWrapper isLoading={isLoading}>{assignText}</LoadingWrapper>
        </Button>
      </div>
    </Dialog>
  );
};
