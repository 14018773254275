import { AssessmentDto, Assessment, SelfAssessmentPassingStatus } from 'types/Assessment';
import { toUser, User, UserDto } from 'types/User';
import { Establishment, EstablishmentDto } from 'types/Establishment';

export enum PCRequestStatus {
  Unassigned = 1,
  auditorAssigned,
  AuditorStarted,
  AuditorFinished,
  AuditorRejected,
  AuditorApproved,
  InspectorAssigned,
  InspectorStarted,
  InspectorFinished,
  InspectorRejected,
  InspectorApproved,
  PCIssued,
}

type PcAssessmentDto = Pick<
  AssessmentDto,
  | 'id'
  | 'created_at'
  | 'submitted_at'
  | 'score'
  | 'passing_status'
  | 'type'
  | 'performance_card_request_id'
  | 'specialist_comment'
>;
export type PcAssessment = Pick<
  Assessment,
  'id' | 'createdAt' | 'type' | 'submittedAt' | 'passingStatus' | 'pcCardRequestId' | 'score' | 'specialistComment'
>;

export type PCRequestDto = {
  id: number;
  created_at: string;
  status: number;
  auditor?: UserDto;
  inspector?: UserDto;
  number_of_performance_card_requests: number;
  issuing_date?: string;
  establishment: Pick<EstablishmentDto, 'id' | 'name_ar' | 'name_en' | 'performance_card_request_id_osh' | 'performance_card_request_id_ca'>;
  assessments: PcAssessmentDto[];
  performance_card_expires_at?: string;
  performance_card_passing_status?: number;
  performance_card_score?: number;
  performance_card_next_attempt_possible_at: string | null;
};

export type PCRequest = {
  id: number;
  createdAt: Date;
  status: number;
  auditor?: User | null;
  inspector?: User | null;
  numberOfRequests: number;
  issuingDate?: Date;
  establishment: Pick<Establishment, 'id' | 'nameAr' | 'nameEn'>;
  assessments: PcAssessment[];
  performanceCardExpiresAt?: Date;
  performanceCardPassingStatus?: SelfAssessmentPassingStatus;
  performanceCardScore?: number;
  nextAttemptPossibleAt: string | null;
};

export const toPCRequest = ({
  created_at,
  id,
  status,
  auditor,
  inspector,
  number_of_performance_card_requests,
  issuing_date,
  establishment,
  assessments,
  performance_card_expires_at,
  performance_card_score,
  performance_card_passing_status,
  performance_card_next_attempt_possible_at,
}: PCRequestDto): PCRequest => ({
  id,
  createdAt: new Date(created_at),
  status,
  auditor: auditor && toUser(auditor),
  inspector: inspector && toUser(inspector),
  numberOfRequests: number_of_performance_card_requests,
  issuingDate: issuing_date ? new Date(issuing_date) : undefined,
  establishment: {
    id: establishment.id,
    nameAr: establishment.name_ar,
    nameEn: establishment.name_en,
  },
  assessments: assessments.map(
    ({
      created_at: assessment_created_at,
      id: assessment_id,
      passing_status,
      performance_card_request_id,
      score,
      submitted_at,
      type,
      specialist_comment,
    }) => ({
      createdAt: assessment_created_at,
      id: assessment_id,
      passingStatus: passing_status,
      score,
      type,
      pcCardRequestId: performance_card_request_id,
      submittedAt: submitted_at,
      specialistComment: specialist_comment,
    })
  ),
  performanceCardExpiresAt: performance_card_expires_at ? new Date(performance_card_expires_at) : undefined,
  performanceCardPassingStatus: performance_card_passing_status,
  performanceCardScore: performance_card_score,
  nextAttemptPossibleAt: performance_card_next_attempt_possible_at,
});
