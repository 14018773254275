import { makeStyles } from '@material-ui/core';
import i18n from 'i18next';

import { PCRequest } from 'types/PCRequest';

import CompanyRepresentativePcCard from './CompanyRepresentativePcCard';
import { getDirection } from '../../../utilities/useLanguage';

const useStyles = makeStyles((theme) => ({
  single: {
    gridColumn: '1/-1',
  },
}));

type CompanyRepresentativePcCardsProps = {
  newestPcRequests: PCRequest[];
};

export const CompanyRepresentativeNewestPc = ({ newestPcRequests }: CompanyRepresentativePcCardsProps) => {
  const classes = useStyles();
  const direction = getDirection(i18n.language);

  if ( newestPcRequests.length === 1) {
    return (
      <div className={classes.single} dir={direction}>
        <CompanyRepresentativePcCard pcRequest={newestPcRequests[0]} />
      </div>
    );
  }

  return (
    <>
      {newestPcRequests.map((pcRequest) => (
        <div key={pcRequest.id} dir={direction}>
          <CompanyRepresentativePcCard pcRequest={pcRequest} isDownloadDropdown />
        </div>
      ))}
    </>
  );
};
