import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import i18n from 'i18next';

import { AssessmentAnswerType } from 'types/AssessmentAnswer';
import { useAssessmentAnswerTypeText } from 'utilities/useAssessmentAnswerTypeText.hook';

import { getDirection } from '../../utilities/useLanguage';

type AssessmentAnswerRadioGroupProps = {
  questionType: number | null;
  answerType: AssessmentAnswerType | null;
  changeAnswerType: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
};

const answerChoicesYesNo = [AssessmentAnswerType.Yes, AssessmentAnswerType.No];
const answerChoicesALl = [AssessmentAnswerType.Yes, AssessmentAnswerType.No, AssessmentAnswerType.NotApplicable];

export const AssessmentAnswerRadioGroup: React.FC<AssessmentAnswerRadioGroupProps> = ({
  answerType,
  questionType,
  changeAnswerType,
}) => {
  const getAssessmentAnswerTypeText = useAssessmentAnswerTypeText();
  const direction = getDirection(i18n.language);
  const answerChoices = questionType === 2 ? answerChoicesYesNo : answerChoicesALl;
  return (
    <FormControl component="fieldset">
      <RadioGroup
        dir={direction}
        aria-label="gender"
        name="answerType"
        value={`${answerType}`}
        onChange={changeAnswerType}
      >
        {answerChoices.map((answerTypeChoice) => (
          <FormControlLabel
            key={`assessment-answer-${answerTypeChoice}`}
            value={`${answerTypeChoice}`}
            control={<Radio />}
            label={getAssessmentAnswerTypeText(answerTypeChoice)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
