export type EconomicActivityDto = {
  id: number;
  ext_id: string;
  name_ar: string;
  name_en: string;
};

export type EconomicActivity = {
  id: number;
  extId?: string;
  nameAr: string;
  nameEn: string;
};

export const toEconomicActivity = ({ ext_id, id, name_ar, name_en }: EconomicActivityDto): EconomicActivity => ({
  id,
  extId: ext_id,
  nameAr: name_ar,
  nameEn: name_en,
});
