import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { isNull } from 'lodash-es';

import { TranslationNamespace } from 'i18n/config';
import { FieldData } from 'types/FieldData';
import { PCRequest } from 'types/PCRequest';
import { PaperCard } from 'ui/PaperCard';
import { useCreateTranslate } from 'utilities/translate.hook';

import { compareAssessmentScores } from '../utils/compareAssessmentScores';

const useStyles = makeStyles((theme) => ({
  red: {
    color: theme.palette.error.dark,
  },
  green: {
    color: theme.palette.success.main,
  },
  black: {
    color: theme.palette.text.primary,
  },
}));

type ScoringProps = {
  pcRequest: PCRequest;
};

export const Scoring = ({ pcRequest }: ScoringProps) => {
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const classes = useStyles();

  const {
    crAssessmentScore,
    audtiorAssessmentScore,
    inspectorAssessmentScore,
    differenceCrAuditor,
    differenceInspectorAuditor,
    isInspectorEqualAuditor,
    isAuditorEqualCr,
    isInspectorHigherThanAuditor,
    isAuditorHigherThanCr,
  } = useMemo(() => compareAssessmentScores(pcRequest), [pcRequest]);

  const scoringData: FieldData[] = [
    {
      name: translate('pcDetailsCrScore'),
      value: crAssessmentScore || crAssessmentScore === 0 ? `${crAssessmentScore}%` : '-',
    },
    {
      name: translate('auditorScore'),
      value: audtiorAssessmentScore || audtiorAssessmentScore === 0 ? `${audtiorAssessmentScore}%` : '-',
    },
    {
      name: translate('auditorCrDifference'),
      value: !isNull(differenceCrAuditor) ? `${differenceCrAuditor} ${translate('percentagePoints')}` : '-',
      className:
        !audtiorAssessmentScore || isAuditorEqualCr
          ? classes.black
          : isAuditorHigherThanCr
          ? classes.green
          : classes.red,
    },
    {
      name: translate('inspectorScore'),
      value: inspectorAssessmentScore || inspectorAssessmentScore === 0 ? `${inspectorAssessmentScore}%` : '-',
    },
    {
      name: translate('auditorInspectorDifference'),
      value: !isNull(differenceInspectorAuditor)
        ? `${differenceInspectorAuditor} ${translate('percentagePoints')}`
        : '-',
      className:
        !inspectorAssessmentScore || isInspectorEqualAuditor
          ? classes.black
          : isInspectorHigherThanAuditor
          ? classes.green
          : classes.red,
    },
  ];

  return <PaperCard title={translate('scoring')} data={scoringData} />;
};
