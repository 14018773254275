import React from 'react';
/* eslint-disable import/no-duplicates */

import { InspectorLayout } from 'domain/inspector/ui/InspectorLayout';

import { PCRequestDetails as CommonPCRequestDetails } from '../../../common/pcrequests/pcrequests-details/PCRequestDetails';
import { getInspectorPath } from '../../InspectorRoutes';

export const PCRequestDetails = () => {
  return (
    <InspectorLayout>
        <CommonPCRequestDetails getPath={getInspectorPath} />
    </InspectorLayout>
  );
};
