import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import i18n from 'i18next';

import { TranslationNamespace } from 'i18n/config';
import { AssessmentStatus } from 'types/AssessmentStatus';
import { Establishment, establishmentToEstablishmentInfo } from 'types/Establishment';
import { useCreateTranslate } from 'utilities/translate.hook';
import { useNavigate } from 'utilities/navigate';
import { TypeOfPlatform } from 'infrastructure/services/auth.type';
import {
  useCreateAssessmentMutation,
  useGetEstablishmentInfoQuery,
}from 'domain/company-representative/api/companyRepresentative.api';

import { CompanyRepresentativeTooltipRetryButton } from '../company-representative-login/CompanyRepresentativeTooltipRetryButton';
import { CompanyRepresentativeRoutes, getCompanyRepresentativePath } from '../CompanyRepresentativeRoutes';
import { getDirection } from '../../../utilities/useLanguage';

const useStyles = makeStyles({
  button: {
    flexShrink: 0,
    fontWeight: 'bold',
  },
});

type CompanyRepresentativeSaStatusBtnProps = {
  establishment: Establishment;
  onRetryButtonClick: () => void;
  platform:TypeOfPlatform.OSH;
};

export const CompanyRepresentativeSaStatusBtn = ({
  establishment,
  onRetryButtonClick,
  platform,
}: CompanyRepresentativeSaStatusBtnProps) => {
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const navigate = useNavigate();
  const classes = useStyles();
  const establishmentInfo = establishmentToEstablishmentInfo(establishment);
  const direction = getDirection(i18n.language);
  const [createSelfAssessment, { isLoading: isCreating }] = useCreateAssessmentMutation();
  const { data: establishmentInfoTest } = useGetEstablishmentInfoQuery(establishment.extId);

  const startSelfAssessment = () => {
    if (establishmentInfoTest?.id) {
      createSelfAssessment({ establishment_id: establishmentInfoTest.id, platform: platform })
        .unwrap()
        .then((assessment) =>
          navigate(`${getCompanyRepresentativePath(CompanyRepresentativeRoutes.assessment)}/${assessment.id}`)
        )
        // eslint-disable-next-line no-console
        .catch((err) => console.error(err));
    }
  };

  const handleContinue = () => {
    platform === TypeOfPlatform.OSH ?
      navigate(
        `${getCompanyRepresentativePath(CompanyRepresentativeRoutes.assessment)}/${
            establishment.assessmentCurrentObjIdOSH
           }`
      ):
      navigate(
        `${getCompanyRepresentativePath(CompanyRepresentativeRoutes.assessment)}/${
            establishment.assessmentCurrentObjIdCA
          }`
      );
  }

 switch (platform === TypeOfPlatform.OSH ?
  establishment.assessmentCurrentObjStatusOSH : establishment.assessmentCurrentObjStatusCA) {
    case AssessmentStatus.Failed:
      return (
        <CompanyRepresentativeTooltipRetryButton
          establishment={establishmentInfo}
          label={translate('retry')}
          onClick={onRetryButtonClick}
          platform={platform}
        />
      );
    case AssessmentStatus.Draft:
      return (
        <Button
          dir={direction}
          variant="contained"
          size="large"
          color="secondary"
          className={classes.button}
         onClick={handleContinue}
          >
          {translate('continueSelfAssessment')}
       </Button>

          )
    case AssessmentStatus.NotStarted:
      return (
        <Button
          dir={direction}
          variant="contained"
          size="large"
          color="secondary"
          className={classes.button}
          onClick={() => !isCreating && startSelfAssessment()}
        >
        {platform === TypeOfPlatform.OSH ?
          translate('startSelfAssessmentOSH') : translate('startSelfAssessmentCA')
        }
        </Button>
      );
    default:
      return <></>;
  }
};
