import React from 'react';
import { Redirect } from 'react-router-dom';

import { selectRole } from 'infrastructure/services/authSlice.selector';
import { useAppSelector } from 'infrastructure/store/rootStore.hooks';
import { Role } from 'infrastructure/services/auth.type';

import { PublicLayout } from '../../common/ui/PublicLayout';
import { getAdminPath } from '../AdminRoutes';
import { Routes } from '../../common/Routes';

// Additional security check to redirect user after refresh token (It can happen only when user relog from CR to Admin)
export const AdminLayout: React.FunctionComponent = ({ children }) => {
  const currentRole = useAppSelector(selectRole);

  if (currentRole !== Role.Admin) {
    return <Redirect to={getAdminPath(Routes.login)} />;
  }

  return <PublicLayout>{children}</PublicLayout>;
};
