import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core';

import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';

const useStyles = makeStyles((theme) => ({
  cellPadding: {
    padding: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  action: {
    textAlign: 'center',
  },
}));

export const TemplateTableHead: React.FC = () => {
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.action} style={{ width: '98px', maxWidth: '98px' }}>
          {translate('actions')}
        </TableCell>
        <TableCell className={classes.action} style={{ width: '98px', maxWidth: '98px' }}>
          {translate('status')}
        </TableCell>
        <TableCell className={classes.cellPadding} style={{ width: '98px', maxWidth: '98px' }}>
          {translate('creationDate')}
        </TableCell>
        <TableCell className={classes.cellPadding} style={{ width: '98px', maxWidth: '98px' }}>
          {translate('versionNumber')}
        </TableCell>
        <TableCell className={classes.cellPadding} style={{ width: '98px', maxWidth: '98px' }}>
          {translate('economicActivity')}
        </TableCell>
        <TableCell className={classes.cellPadding} style={{ width: '98px', maxWidth: '98px' }}>
          {translate('selfAssessmentName')}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
