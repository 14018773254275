import { Box } from '@material-ui/core';
import React from 'react';

import { TranslationNamespace } from 'i18n/config';
import { CustomBreadCrumbs } from 'ui/CustomBreadCrumbs';
import { useCreateTranslate } from 'utilities/translate.hook';
import { DetailsHeader } from 'ui/DetailsHeader';
import { BackButton } from 'ui/buttons/BackButton';
import { useNavigate } from 'utilities/navigate';

import { getAdminPath } from '../AdminRoutes';
import { Routes } from '../../common/Routes';

type AdminCompareHeaderProps = {
  pcRequestId: number;
};

export const AdminAssessmentCompareHeader = ({ pcRequestId }: AdminCompareHeaderProps) => {
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const navigate = useNavigate();

  const pcRequestDetailsUrl = `${getAdminPath(Routes.pcRequests)}/${pcRequestId}`;
  return (
    <div>
      <Box display="flex" alignItems="flex-end">
        <CustomBreadCrumbs
          currentLocationLabel={translate('compareAssessments')}
          parentCrumbs={[
            {
              label: translate('pcRequests'),
              path: getAdminPath(Routes.pcRequests),
            },
            {
              label: translate('pcDetailsCrumb'),
              path: pcRequestDetailsUrl,
            },
          ]}
        />
      </Box>
      <DetailsHeader title={translate('compareAssessments')} subtitle={translate('compareAssessmentsSubtitle')}>
        <BackButton onClick={() => navigate(pcRequestDetailsUrl)} />
      </DetailsHeader>
    </div>
  );
};
