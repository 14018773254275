import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

import { adminQuestionnaireSlice } from 'domain/admin/admin-questionnaire/AdminQuestionnaire.slice';
import { companyRepresentativeLoginSlice } from 'domain/company-representative/company-representative-login/CompanyRepresentativeLogin.slice';
import { authSlice } from 'infrastructure/services/authSlice';
import { adminApi } from 'domain/admin/api/admin.api';
import { landingPageApi } from 'domain/landing-page/api/landingPage.api';
import { userApi } from 'infrastructure/services/user.api';
import { companyRepresentativeApi } from 'domain/company-representative/api/companyRepresentative.api';

export const rootReducer = combineReducers({
  // reducers
  auth: authSlice.reducer,
  companyRepresentativeLogin: companyRepresentativeLoginSlice.reducer,
  adminQuestionnaire: adminQuestionnaireSlice.reducer,
  // apis
  [companyRepresentativeApi.reducerPath]: companyRepresentativeApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
  [landingPageApi.reducerPath]: landingPageApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
});

// Parameter is typed here to avoid dependency cycle from rootStore.types.ts
export const setupStore = (preloadedState?: PreloadedState<Partial<ReturnType<typeof rootReducer>>>) =>
  configureStore({
    reducer: rootReducer,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(
        adminApi.middleware,
        landingPageApi.middleware,
        companyRepresentativeApi.middleware,
        adminApi.middleware,
        userApi.middleware
      ),
    preloadedState,
  });

const rootStore = setupStore();

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(rootStore.dispatch);

export default rootStore;
