import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { useParams } from 'react-router-dom';

import { useGetEstablishmentQuery } from 'domain/company-representative/api/companyRepresentative.api';
import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { Button } from 'ui/Button';
import OshStripes from 'images/osh-stripes.svg';
import LogoColor from 'images/logo-color.svg';
import LogoColorEng from 'ui/icons/LogoColorEng.svg';
import { Routes } from 'infrastructure/routes';
import { LogoutButton } from 'ui/buttons/LogoutButton';
import { CompanyRepresentativeHeader } from 'domain/company-representative/CompanyRepresentativeHeader';
import {
  isAdmin as isAdminCheck,
  isInspector as isInspectorCheck,
  isAuditor as isAuditorCheck,
} from 'infrastructure/services/auth.utils';
import { useAppSelector } from 'infrastructure/store/rootStore.hooks';
import { selectRole } from 'infrastructure/services/authSlice.selector';
import { AdminHeaderMenu } from 'domain/admin/ui/AdminHeaderMenu';
import { AuditorHeaderMenu } from 'domain/auditor/ui/AuditorHeaderMenu';
import { InspectorHeaderMenu } from 'domain/inspector/ui/InspectorHeaderMenu';

import { getReverseDirection as getReverseDirectionForHeader } from '../utilities/useLanguage';
import { useChangeLangMutation, useGetMeQuery } from '../infrastructure/services/user.api';
import { ChangeLangInput } from '../types/User';
import { BaseQueryError } from '../infrastructure/persistence/baseQueryWithReAuth';
import { NotificationType } from '../infrastructure/notifications/NotificationType.enum';
import { useNotifications } from '../infrastructure/notifications/NotificationsHandler';

const useStyles = makeStyles((theme) => ({
  header: {
    flip: false,
    backgroundColor: theme.palette.primary.main,

    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(8),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(10),
    },
  },
  language: {
    color: theme.palette.common.white,
  },
  main: {
    flip: false,
    justifyItems: '',
    padding: '0',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '320px',
      padding: `0 ${theme.spacing(2)}px`,
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 108px',
      maxWidth: '100%',
    },
  },

  logout: {
    flip: false,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  middlePart: {
    display: 'flex',
    width: '100%',
    flexGrow: 1,
  },
  changeLanguage: {
    color: theme.palette.common.white,
    marginLeft: '42px',
  },

  logo: {
    marginRight: 30,
    height: 52,
  },
}));

export const Header = () => {
  const classes = useStyles();
  const [language, setLanguage] = useState(i18n.language);
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const { data: currentUser, refetch } = useGetMeQuery();
  const showNotification = useNotifications();
  const [changeLang] = useChangeLangMutation();
  const { establishmentId } = useParams<{ establishmentId: string }>();
  const { data: establishment } = useGetEstablishmentQuery(+establishmentId);
  const isAdminLogged = isAdminCheck(useAppSelector(selectRole));
  const isInspectorLogged = isInspectorCheck(useAppSelector(selectRole));
  const isAuditorLogged = isAuditorCheck(useAppSelector(selectRole));
  useEffect(() => {
    if (currentUser?.lang !== i18n.language) {
      refetch();
    }
    const currentLang = currentUser?.lang;
    i18n.changeLanguage(currentLang);
  }, [currentUser, refetch]);

  const handleOnclick = async () => {
    const newLang = i18n.language === 'ar' ? 'en' : 'ar';
    await changeLang({ lang: newLang } as ChangeLangInput)
      .unwrap()
      .catch((error: BaseQueryError) => {
        showNotification(NotificationType.Error, { errorId: error.data.errorCode });
      });
    i18n.changeLanguage(newLang);
    setLanguage(newLang);
  };
  const reverseDirection = getReverseDirectionForHeader(i18n.language);

  const renderHeaderMenu = () => {
    if (isAdminLogged) return <AdminHeaderMenu />;
    if (isAuditorLogged) return <AuditorHeaderMenu />;
    if (isInspectorLogged) return <InspectorHeaderMenu />;
    if (establishment) return <CompanyRepresentativeHeader establishment={establishment} />;
    return null;
  };

  return (
    <header className={classes.header} dir={reverseDirection}>
      <Container className={classes.main}>
        <div className={classes.logout}>
          <LogoutButton redirectPath={Routes.HOME} />
        </div>
        <Button className={classes.language} onClick={handleOnclick}>
          {language === 'ar' // you could combine some logic here
            ? translate('english')
            : translate('arabic')}
        </Button>
        <div className={classes.middlePart} />
        {renderHeaderMenu()}
        <Hidden smDown>
          <img alt="osh" src={OshStripes} />
        </Hidden>
        {i18n.language === 'ar' ? (
          <img src={LogoColor} alt="Logo-color" className={classes.logo} />
        ) : (
          <img src={LogoColorEng} alt="Logo-color" className={classes.logo} />
        )}
      </Container>
    </header>
  );
};
