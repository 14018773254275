export type Coords = {
  lat: number;
  lng: number;
};

export type RegionDto = {
  id: number;
  ext_id: number;
  name_ar: string;
  name_en: string;
};

export type CityDto = {
  id: number;
  ext_id: number;
  region_ext_id: number;
  name_ar: string;
  name_en: string;
};

export type Region = {
  id: number;
  extId?: number;
  nameAr: string;
  nameEn: string;
};

export type City = {
  id: number;
  extId?: number;
  regionExtId?: number;
  nameAr: string;
  nameEn: string;
};

export const toRegions = (regionsDto: RegionDto[]): Region[] =>
  regionsDto.map((regionDto) => ({
    id: regionDto.id,
    extId: regionDto.ext_id,
    nameAr: regionDto.name_ar,
    nameEn: regionDto.name_en,
  }));

export const toCities = (citiesDto: CityDto[]): City[] =>
  citiesDto.map((cityDto) => ({
    id: cityDto.id,
    extId: cityDto.ext_id,
    regionExtId: cityDto.region_ext_id,
    nameAr: cityDto.name_ar,
    nameEn: cityDto.name_en,
  }));
