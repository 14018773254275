import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { Button } from 'ui/Button';
import { BaseQueryError } from 'infrastructure/persistence/baseQueryWithReAuth';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { useNavigate } from 'utilities/navigate';
import { Dialog } from 'ui/Dialog';

import { FormValues } from '../types/MissingInfo';
import { useSubmitAssessmentMutation } from '../api/companyRepresentative.api';
import { CompanyRepresentativeRoutes, getCompanyRepresentativePath } from '../CompanyRepresentativeRoutes';

type CompanyRepresentativeSASubmitDialogProps = {
  open: boolean;
  handleClose: () => void;
  assessmentId: number;
  notApplicableRatioExceeded?: boolean;
};

const useStyles = makeStyles((theme) => ({
  description: {
    marginTop: theme.spacing(1),
    minHeight: '40px',
  },

  buttons: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  error: {
    color: theme.palette.error.main,
  },
  terms: {
    fontSize: 14,
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
    },
  },
  submitButton: {
    flex: 1,
  },

  cancelButton: {
    flex: 1,
    flip: false,
    marginLeft: theme.spacing(2),
  },
}));

export interface FillMissingInfoFormProps {
  formValues: Partial<FormValues>;
}

export const CompanyRepresentativeSASubmitDialog: React.FC<CompanyRepresentativeSASubmitDialogProps> = ({
  open,
  handleClose,
  assessmentId,
  notApplicableRatioExceeded,
}) => {
  const classes = useStyles();
  const translateSA = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const translateCommon = useCreateTranslate(TranslationNamespace.common);
  const showNotification = useNotifications();
  const navigate = useNavigate();
  const [submitAssessment, { isLoading }] = useSubmitAssessmentMutation();
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    mode: 'onTouched',
  });

  const onSubmit: SubmitHandler<FormValues> = (v) => {
    if (!isLoading) {
      submitAssessment({ assessmentId })
        .unwrap()
        .then(() => {
          navigate(
            `${getCompanyRepresentativePath(CompanyRepresentativeRoutes.assessment)}/${assessmentId}${
              CompanyRepresentativeRoutes.finished
            }`
          );
          window.location.reload();
        })
        .catch((err: BaseQueryError) => {
          if (typeof err.data === 'object' && err.data !== null) {
            showNotification(NotificationType.Error, { errorId: err.data.errorCode });
            handleClose();
          }
        });
    }
  };

  return (
    <Dialog open={open} handleClose={handleClose}>
      <Typography variant="h6">{translateSA('areYouSureSubmit')}</Typography>
      <Typography variant="body2" className={classes.description} color="secondary">
        {notApplicableRatioExceeded ? translateSA('youHaveMoreThan50') : translateSA('youWantBeAbleToMakeChanges')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <FormControl error={!!errors.areTermsAccepted?.type}>
            <FormControlLabel
              control={
                <Controller
                  name="areTermsAccepted"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Checkbox
                      className={`${classes.terms} ${errors.areTermsAccepted?.type ? classes.error : ''}`}
                      {...field}
                    />
                  )}
                />
              }
              label={
                <span className={`${classes.terms} ${errors.areTermsAccepted?.type ? classes.error : ''}`}>
                  <span>{translate('termsAndConditionsPrefix')}</span>
                  <a href="/terms">{translate('termsAndConditions')}</a>
                  <span>{translate('termsAndConditionsPostfix')}</span>
                </span>
              }
              labelPlacement="end"
            />
          </FormControl>
        </div>
        <div dir="ltr" className={classes.buttons}>
          <Button variant="contained" size="medium" color="secondary" className={classes.submitButton} type="submit">
            {translateSA('submit')}
          </Button>
          <Button
            variant="outlined"
            size="medium"
            color="secondary"
            className={classes.cancelButton}
            onClick={handleClose}
          >
            {translateCommon('cancel')}
          </Button>
        </div>
      </form>
    </Dialog>
  );
};
