import React, { useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Container from '@material-ui/core/Container';
import { SubmitHandler, useForm, Controller, FieldError } from 'react-hook-form';
import i18n from 'i18next';

import { enInputRegex, arInputRegex } from 'infrastructure/constants/regex.constants';
import { QuestionnaireSection } from 'types/QuestionnaireSection';
import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { backButtonBackgroundColor, mainBackground, shdShadow } from 'ui/styles';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { BaseQueryError } from 'infrastructure/persistence/baseQueryWithReAuth';
import { Transition } from 'ui/Transition';

import { AdminQuestionnaireStepTopic } from '../AdminQuestionnaireStepTopic';
import { useCreateSectionMutation, useUpdateSectionMutation } from '../../api/admin.api';
import { getReverseDirection as getReverseDirectionForAdminQuestionnaireSectionsDialog } from '../../../../utilities/useLanguage';

type CreateSectionInfo = {
  nameAR: string;
  nameEN: string;
  descriptionAR: string;
  descriptionEN: string;
};

type AdminQuestionnaireSectionsDialogProps = {
  questionnaireId: number;
  open: boolean;
  section?: QuestionnaireSection;
  handleClose: () => void;
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: '#FAFAFA',
    ...mainBackground,
  },

  containerNoPadding: {
    padding: 0,
  },

  appBar: {
    position: 'relative',
  },

  toolBar: {
    flip: false,
    padding: '5px 0',
    display: 'flex',
    alignItems: 'middle',
  },

  dialogButton: {
    flip: false,
    width: '187px',
    height: '50px',
    marginRight: theme.spacing(2),
  },

  dialogButtonCancel: {
    backgroundColor: backButtonBackgroundColor,
  },

  emptySpace: {
    flexGrow: 1,
  },

  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flip: false,
  },

  dialogTitleText: {
    flip: false,
    marginRight: theme.spacing(3),
    marginLeft: 20,
  },

  closeButton: {
    flip: false,
    padding: 0,
  },

  editForm: {
    marginTop: theme.spacing(5),
    boxShadow: shdShadow,
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },

  formWrapper: {
    width: '100%',
  },

  field: {
    marginTop: theme.spacing(3),
    borderRadius: theme.spacing(0.5),
  },

  description: {
    height: '187px',
    minHeight: '187px',
  },
}));

const SECTION_FORM_ID = 'createSection';

export const AdminQuestionnaireSectionsDialog: React.FC<AdminQuestionnaireSectionsDialogProps> = ({
  open,
  handleClose,
  section,
  questionnaireId,
}) => {
  const classes = useStyles();
  const showNotification = useNotifications();
  const translateQuestionnaire = useCreateTranslate(TranslationNamespace.admin);
  const [createSection, { isLoading }] = useCreateSectionMutation();
  const [updateSection, { isLoading: isUpdateLoading }] = useUpdateSectionMutation();
  const { handleSubmit, control, reset: resetForm } = useForm<CreateSectionInfo>();

  const handleResetAndClose = () => {
    resetForm();
    handleClose();
  };

  useEffect(() => {
    const initialValues = {
      nameAR: section?.nameAR,
      nameEN: section?.nameEN,
      descriptionAR: section?.descriptionAR,
      descriptionEN: section?.descriptionEN,
    };
    resetForm(initialValues);
  }, [resetForm, section]);

  const onSectionSubmit: SubmitHandler<CreateSectionInfo> = async (data) => {
    const sectionUpdateOrCreate = async () => {
      section
        ? await updateSection({
            id: section.id,
            data: {
              questionnaire_id: questionnaireId,
              ...data,
            },
          }).unwrap()
        : await createSection({
            questionnaire_id: questionnaireId,
            ...data,
          }).unwrap();
    };

    if (!isLoading && !isUpdateLoading) {
      await sectionUpdateOrCreate()
        .then(() => {
          handleResetAndClose();
        })
        .catch((error: BaseQueryError) => {
          showNotification(NotificationType.Error, { errorId: error.data.errorCode });
        });
    }
  };
  const getHelperText = (error?: FieldError) => {
    if (error) {
      if (error.type === 'required') {
        return translateQuestionnaire('fieldRequired');
      }
      return error.message;
    }
    return ' ';
  };
  const reverseDirection = getReverseDirectionForAdminQuestionnaireSectionsDialog(i18n.language);
  const RoleSectionAr = {
    required: true,
    maxLength: 300,
    minLength: 1,
    pattern: {
    value: arInputRegex,
    message: translateQuestionnaire('arInputMassage'),
    },
  }

  const RoleSectionEn = {
    required: true,
    maxLength: 300,
    minLength: 1,
    pattern: {
    value: enInputRegex,
    message: translateQuestionnaire('enInputMassage'),
    },
  }

  return (
    <Dialog
      PaperProps={{ className: `${classes.dialog}` }}
      fullScreen
      open={open}
      onClose={handleResetAndClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Container maxWidth="lg" className={classes.containerNoPadding}>
          <Toolbar dir={reverseDirection} className={classes.toolBar}>
            <Button
              className={classes.dialogButton}
              variant="contained"
              size="large"
              type="submit"
              color="secondary"
              form={SECTION_FORM_ID}
            >
              {translateQuestionnaire('save')}
            </Button>
            <Button
              className={`${classes.dialogButton} ${classes.dialogButtonCancel}`}
              variant="outlined"
              size="large"
              onClick={handleResetAndClose}
              color="secondary"
            >
              {translateQuestionnaire('cancel')}
            </Button>
            <div className={classes.emptySpace} />
            <div className={classes.dialogTitle}>
              <Typography variant="h4" className={classes.dialogTitleText}>
                {translateQuestionnaire(section ? 'sectionDetails' : 'newSection')}
              </Typography>
              <IconButton className={classes.closeButton} edge="start" color="inherit" onClick={handleResetAndClose}>
                <CloseIcon style={{ width: '40px', height: '40px' }} />
              </IconButton>
            </div>
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth="lg" className={classes.containerNoPadding}>
        <Paper className={classes.editForm}>
          <AdminQuestionnaireStepTopic topicLabelName={'sectionDetails'} />
          <form id={SECTION_FORM_ID} onSubmit={handleSubmit(onSectionSubmit)}>
            <FormControl className={classes.formWrapper}>
              <Controller
                control={control}
                name="nameAR"
                rules={ RoleSectionAr }
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    type="text"
                    variant="outlined"
                    className={classes.field}
                    error={invalid}
                    label={translateQuestionnaire('sectionName')}
                    helperText={getHelperText(error)}
                  />
                )}
              />
              <Controller
                control={control}
                name="nameEN"
                rules={ RoleSectionEn }
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    type="text"
                    variant="outlined"
                    className={classes.field}
                    error={invalid}
                    label={translateQuestionnaire('sectionEnName')}
                    helperText={getHelperText(error)}
                  />
                )}
              />
              <Controller
                name="descriptionAR"
                control={control}
                rules={ RoleSectionAr }
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    type="text"
                    variant="outlined"
                    className={classes.field}
                    multiline
                    rows={6}
                    rowsMax={6}
                    error={invalid}
                    label={translateQuestionnaire('sectionDescription')}
                    helperText={getHelperText(error)}
                  />
                )}
              />
              <Controller
                name="descriptionEN"
                control={control}
                rules={ RoleSectionEn }
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    type="text"
                    variant="outlined"
                    className={ classes.field }
                    multiline
                    rows={6}
                    rowsMax={6}
                    error={invalid}
                    label={translateQuestionnaire('sectionEnDescription')}
                    helperText={getHelperText(error)}
                  />
                )}
              />
            </FormControl>
          </form>
        </Paper>
      </Container>
    </Dialog>
  );
};
