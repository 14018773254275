import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import i18n from 'i18next';

import { getReverseDirection as getReverseDirectionForBottomBorderWrapper } from '../utilities/useLanguage';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
}));

export const BottomBorderWrapper: React.FC = ({ children }) => {
  const classes = useStyles();
  const reverseDirection = getReverseDirectionForBottomBorderWrapper(i18n.language);

  return (
    <div dir={reverseDirection} className={classes.wrapper}>
      {children}
    </div>
  );
};
