import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'infrastructure/store/rootStore.types';
import { RootStoreActionType } from 'infrastructure/store/rootStore.actions';

import { AssessmentTemplate } from '../types/AssessmentTemplate';

type SliceState = {
  questionnaireId: number | null;
  questionnaire: AssessmentTemplate | null;
};

const initialState: SliceState = {
  questionnaireId: null,
  questionnaire: null,
};

export const adminQuestionnaireSlice = createSlice({
  name: 'adminQuestionnaire',
  initialState,
  reducers: {
    setQuestionaryId: (state, { payload }: PayloadAction<number | null>) => {
      state.questionnaireId = payload;
    },
    reset: (state, action: PayloadAction<void>) => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(RootStoreActionType.resetState, () => initialState);
  },
});

export const { setQuestionaryId, reset } = adminQuestionnaireSlice.actions;

export const selectQuestionnaireId = (state: RootState) => state.adminQuestionnaire.questionnaireId;
