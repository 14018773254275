import React from 'react';
import { useHistory, Redirect } from 'react-router-dom';

import { refreshToken } from 'infrastructure/services/auth.api';
import { useAppDispatch } from 'infrastructure/store/rootStore.hooks';

type RefreshComponentProps = {
  redirectPath: string;
};

export const RefreshComponent: React.FC<RefreshComponentProps> = ({ redirectPath }) => {
  const [redirectToPrevPage, setRedirectToPrevPage] = React.useState(false);
  const [redirectToPath, setRedirectToPath] = React.useState(false);
  const [refreshTokenOnce, setRefreshTokenOnce] = React.useState(true);
  const history = useHistory();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (refreshTokenOnce) {
      dispatch(refreshToken())
        .unwrap()
        .then(() => {
          setRedirectToPrevPage(true);
        })
        .catch(() => {
          setRedirectToPath(true);
        });

      setRefreshTokenOnce(false);
    }
  }, [dispatch, refreshTokenOnce]);

  if (redirectToPrevPage) {
    history.replace(history.location);
  }

  if (redirectToPath) {
    return <Redirect to={redirectPath} />;
  }

  return <></>;
};

export default RefreshComponent;
