import { TypeOfPlatform } from '../../infrastructure/services/auth.type';
export enum Ordering {
  CreatedAt = '-created_at',
}

export type Page = {
  page?: number;
  pageSize?: number;
  ordering?: Ordering | string;
};
export type Search = {
  searchInfo: string[] | TypeOfPlatform[];
};

export type AxiosErrorResponseData = {
  detail?: string[];
  file?: string[];
  error_code?: string | string[];
};
