import { TypeOfPlatform } from '../infrastructure/services/auth.type';

export enum SelfAssessmentPassingStatus {
  BELOW_PASSING_SCORE = 0,
  BELOW_HIGH_SCORE_VALUE = 1,
  ABOVE_HIGH_SCORE_VALUE = 2,
}

export enum AssessmentType {
  COMPANY_REPRESENTATIVE,
  AUDITOR,
  INSPECTOR,
}

export type AssessmentDto = {
  id: number;
  establishment_id: number;
  questionnaire_id: number;
  created_at: string;
  modified_at: string;
  submitted_at?: string;
  not_applicable_ratio_exceeded: true;
  passing_status: number | null;
  score?: number;
  type: number;
  performance_card_request_id: number;
  submitted_by_id: number | null;
  specialist_comment: string;
};

export type Assessment = {
  id: number;
  establishmentId: number;
  questionnaireId: number;
  createdAt: string;
  modifiedAt: string;
  submittedAt?: string;
  notApplicableRatioExceeded: boolean;
  passingStatus: SelfAssessmentPassingStatus | null;
  isPassed: boolean;
  score?: number;
  type: AssessmentType;
  pcCardRequestId: number;
  submittedById: number | null;
  specialistComment: string;
};

export type CreateSelfAssessmentInput = {
  establishment_id: number;
  platform: TypeOfPlatform.OSH;
};

export type PublishSelfAssessmentInput = Pick<Assessment, 'id' | 'establishmentId'>;

export type AssessmentUpdateDto = Pick<Partial<AssessmentDto>, 'specialist_comment'>;

export type AssessmentUpdateInput = {
  assessmentId: number;
  data: AssessmentUpdateDto;
};

export const toAssessment = (dto: AssessmentDto): Assessment => ({
  id: dto.id,
  questionnaireId: dto.questionnaire_id,
  establishmentId: dto.establishment_id,
  createdAt: dto.created_at,
  modifiedAt: dto.modified_at,
  submittedAt: dto.submitted_at,
  notApplicableRatioExceeded: dto.not_applicable_ratio_exceeded,
  passingStatus: dto.passing_status,
  isPassed: dto.passing_status !== null && dto.passing_status > SelfAssessmentPassingStatus.BELOW_PASSING_SCORE,
  score: dto.score || 0,
  type: dto.type,
  pcCardRequestId: dto.performance_card_request_id,
  submittedById: dto.submitted_by_id,
  specialistComment: dto.specialist_comment,
});
