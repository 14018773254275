import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import i18n from 'i18next';

import { WithChildren } from 'interfaces/WithChildren';

import { getReverseDirection as getReverseDirectionForDetailsHeader } from '../utilities/useLanguage';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
  },
  headerSubTitle: {
    marginLeft: 'auto',
    color: theme.palette.text.secondary,
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: `0 -${theme.spacing(1)}px`,

    '& > *': {
      margin: `0 ${theme.spacing(1)}px`,
    },
  },
}));

type AdminDetailsHeaderProps = {
  title: string;
  subtitle?: string;
};

export const DetailsHeader = ({ title, subtitle, children }: WithChildren<AdminDetailsHeaderProps>) => {
  const classes = useStyles();
  const reverseDirection = getReverseDirectionForDetailsHeader(i18n.language);

  return (
    <div>
      <div className={classes.header} dir={reverseDirection}>
        <div className={classes.actionContainer}>{children}</div>
        <Typography variant="h4">{title}</Typography>
      </div>
      {subtitle && (
        <Typography className={classes.headerSubTitle} variant="body2">
          {subtitle}
        </Typography>
      )}
    </div>
  );
};
