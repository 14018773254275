import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import i18n from 'i18next';

import { useCreateTranslate } from 'utilities/translate.hook';
import { useNavigate } from 'utilities/navigate';
import { BackButton } from 'ui/buttons/BackButton';
import { TranslationNamespace } from 'i18n/config';

import { getAdminPath } from '../../AdminRoutes';
import { Routes } from '../../../common/Routes';
import { getDirection, getReverseDirection } from '../../../../utilities/useLanguage';

type AdminSAResultHeaderProps = {
  id: string;
};

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
  },

  backButton: {
    alignSelf: 'flex-end',
  },

  texts: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  description: {
    marginTop: '12px',
  },
}));

export const AdminSAResultHeader: React.FC<AdminSAResultHeaderProps> = ({ id }) => {
  const classes = useStyles();
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const adminTranslate = useCreateTranslate(TranslationNamespace.admin);
  const navigate = useNavigate();
  const direction = getDirection(i18n.language);
  const reverseDirection = getReverseDirection(i18n.language);

  return (
    <div dir={reverseDirection} className={classes.main}>
      <BackButton
        className={classes.backButton}
        onClick={() => {
          navigate(`${getAdminPath(Routes.establishment)}/${id}`);
        }}
      />
      <div dir={direction} className={classes.texts}>
        <Typography variant="h4">{translate('detailsOfSA')}</Typography>
        <Typography variant="body2" className={classes.description}>
          {adminTranslate('saResultDescription')}
        </Typography>
      </div>
    </div>
  );
};
