import { AssessmentSectionDto } from 'types/AssessmentSection';
import { EconomicActivity, EconomicActivityDto, toEconomicActivity } from 'types/EconomicActivity';

import { AssessmentTemplateStatus } from './AssessmentTemplateStatus';

export type AssessmentTemplateDto = {
  id: number;
  nameAR: string;
  nameEN: string;
  version: number;
  status: AssessmentTemplateStatus;
  publish_date?: string | null;
  disabled_date?: string | null;
  min_successful_score: number;
  economic_activity_id: number;
  economic_activity: EconomicActivityDto;
  created_at: string;
  total_weight: string;
  questions_no: number;
  sections: AssessmentSectionDto[];
};

export type AssessmentTemplate = {
  id: number;
  nameAR: string;
  nameEN: string;
  version: number;
  status: AssessmentTemplateStatus;
  publishDate?: Date;
  disabledDate?: Date;
  minSuccessfulScore: number;
  economicActivityId: number;
  economicActivity: EconomicActivity;
  createdAt: Date;
  totalWeightSummary: string;
  questionsNoSummary: number;
};

export type AssessmentTemplateCreateDto = Pick<AssessmentTemplateDto, 'nameAR' | 'nameEN' | 'economic_activity_id'>;

export type AssessmentTemplateUpdateDto = Pick<
  AssessmentTemplateDto,
  | 'id'
  | 'nameAR'
  | 'nameEN'
  | 'version'
  | 'status'
  | 'publish_date'
  | 'disabled_date'
  | 'min_successful_score'
  | 'economic_activity_id'
>;

export type AssessmentTemplateScoringPatchDto = Pick<AssessmentTemplateDto, 'id' | 'min_successful_score'>;
export type AssessmentTemplateQuestionnairePatchDto = Pick<AssessmentTemplateDto, 'id'>;

export const toAssessmentTemplateUpdateDto = (template: AssessmentTemplate): AssessmentTemplateUpdateDto => ({
  id: template.id,
  nameAR: template.nameAR,
  nameEN: template.nameEN,
  version: template.version,
  status: template.status,
  publish_date: template.publishDate?.toISOString(),
  disabled_date: template.disabledDate?.toISOString(),
  min_successful_score: template.minSuccessfulScore,
  economic_activity_id: template.economicActivityId,
});

export const toAssessmentTemplate = ({
  created_at,
  economic_activity,
  id,
  nameAR,
  nameEN,
  status,
  version,
  economic_activity_id,
  disabled_date,
  publish_date,
  min_successful_score,
  total_weight,
  questions_no,
}: AssessmentTemplateDto): AssessmentTemplate => ({
  id,
  status,
  createdAt: new Date(created_at),
  economicActivity: toEconomicActivity(economic_activity),
  nameAR,
  nameEN,
  version,
  economicActivityId: economic_activity_id,
  disabledDate: disabled_date ? new Date(disabled_date) : undefined,
  publishDate: publish_date ? new Date(publish_date) : undefined,
  minSuccessfulScore: min_successful_score,
  totalWeightSummary: total_weight,
  questionsNoSummary: questions_no,
});
