import { Container, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  main: {
    padding: 0,
  },
}));

export const AppContent: React.FunctionComponent = ({ children }) => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.main}>
      <div>{children}</div>
    </Container>
  );
};
