import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

import { TranslationNamespace } from 'i18n/config';
import { Button } from 'ui/Button';
import { Dialog } from 'ui/Dialog';
import { useCreateTranslate } from 'utilities/translate.hook';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { BaseQueryError } from 'infrastructure/persistence/baseQueryWithReAuth';
import { User } from 'types/User';

import { useTwoFactorAuthenticationMutation } from '../api/admin.api';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
    '& button:first-child': {
      flip: false,
      marginRight: theme.spacing(2),
    },
  },
  button: {
    flex: 1,
  },
  header: {
    textAlign: 'center',
  },
}));

type TwoFactorAuthenticationDialogProps = {
  open: boolean;
  handleClose: () => void;
  user: User;
};

export const TwoFactorAuthenticationDialog = ({ open, handleClose, user }: TwoFactorAuthenticationDialogProps) => {
  const classes = useStyles();
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const [twoFactorAuthentication, { isLoading }] = useTwoFactorAuthenticationMutation();
  const showNotification = useNotifications();
  const twoFA = async () => {
    if (!user) return;

    await twoFactorAuthentication({ user_id: user.id })
      .unwrap()
      .then(() => {
        handleClose();
        showNotification(NotificationType.Success, {
          message: translate('twoFactorAuthenticationSuccess', { username: `${user.firstName} ${user.lastName}` }),
        });
      })
      .catch((error: BaseQueryError) => {
        showNotification(NotificationType.Error, { errorId: error.data.errorCode });
      });
  };

  return (
    <Dialog open={open} handleClose={handleClose}>
      <Typography variant="h6" className={classes.header}>
        {translate('twoFADialogTitle', { username: `${user.firstName} ${user.lastName}` })}
      </Typography>
      <div className={classes.buttons} dir="ltr">
        <Button
          onClick={twoFA}
          variant="contained"
          size="large"
          color="secondary"
          disabled={isLoading}
          className={classes.button}
        >
          {translate('FA')}
        </Button>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          disabled={isLoading}
          className={classes.button}
          onClick={handleClose}
        >
          {translate('cancel')}
        </Button>
      </div>
    </Dialog>
  );
};
