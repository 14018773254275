import React, { useEffect } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Container from '@material-ui/core/Container';
import { SubmitHandler, useForm, Controller, FieldError } from 'react-hook-form';
import i18n from 'i18next';

import { enInputRegex, arInputRegex, scoreRegex } from 'infrastructure/constants/regex.constants';
import { TranslationNamespace } from 'i18n/config';
import { useCreateTranslate } from 'utilities/translate.hook';
import { backButtonBackgroundColor, mainBackground, shdShadow } from 'ui/styles';
import { useNotifications } from 'infrastructure/notifications/NotificationsHandler';
import { NotificationType } from 'infrastructure/notifications/NotificationType.enum';
import { BaseQueryError } from 'infrastructure/persistence/baseQueryWithReAuth';
import { QuestionnaireQuestion, QuestionType, QuestionTypes } from 'types/QuestionnaireQuestion';
import { Transition } from 'ui/Transition';
import { useFormStyles } from 'styles/form';

import { AdminQuestionnaireStepTopic } from '../AdminQuestionnaireStepTopic';
import { useCreateQuestionMutation, useUpdateQuestionMutation } from '../../api/admin.api';
import { getReverseDirection, getDirection } from '../../../../utilities/useLanguage';

type CreateSectionInfo = {
  textAR: string;
  textEN: string;
  descriptionAR: string;
  descriptionEN: string;
  weight: number;
  type: QuestionType;
};

type AdminQuestionnaireQuestionDialogProps = {
  sectionId: number;
  open: boolean;
  handleClose: () => void;
  questionnaireId: number;
  question?: QuestionnaireQuestion;
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: '#FAFAFA',
    ...mainBackground,
  },

  containerNoPadding: {
    padding: 0,
  },

  appBar: {
    position: 'relative',
  },
  select: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(4),
    textAlign: 'left',
  },
  toolBar: {
    flip: false,
    padding: '15px 0',
    display: 'flex',
    alignItems: 'middle',
  },

  dialogButton: {
    flip: false,
    width: '187px',
    height: '50px',
    marginRight: theme.spacing(2),
  },

  dialogButtonCancel: {
    backgroundColor: backButtonBackgroundColor,
  },

  emptySpace: {
    flexGrow: 1,
  },

  dialogTitleQuestion: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flip: false,
  },

  dialogTitleTextQuestion: {
    flip: false,
    marginRight: theme.spacing(3),
    marginLeft: 20,
  },

  closeButtonQuestion: {
    flip: false,
    padding: 0,
  },

  editFormQuestion: {
    marginTop: theme.spacing(5),
    boxShadow: shdShadow,
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },

  formWrapper: {
    width: '100%',
  },

  field: {
    flip: false,
    marginTop: theme.spacing(3),
    borderRadius: theme.spacing(0.5),
  },
}));

export const AdminQuestionnaireQuestionDialog: React.FC<AdminQuestionnaireQuestionDialogProps> = ({
  open,
  handleClose,
  sectionId,
  questionnaireId,
  question,
}) => {
  const classes = useStyles();
  const formClasses = useFormStyles();
  const translateQuestionnaire = useCreateTranslate(TranslationNamespace.admin);
  const [createQuestion, { isLoading }] = useCreateQuestionMutation();
  const [updateQuestion, { isLoading: isUpdateLoading }] = useUpdateQuestionMutation();
  const showNotification = useNotifications();
  const questionFormId = `createQuestion-${sectionId}`;

  const {
    handleSubmit,
    control,
    reset: resetForm,
  } = useForm<CreateSectionInfo>({
    defaultValues: {
      weight: 1,
    },
  });

  const handleResetAndClose = () => {
    resetForm();
    handleClose();
  };

  useEffect(() => {
    const initialValues = {
      descriptionAR: question?.descriptionAR,
      descriptionEN: question?.descriptionEN,
      weight: question?.weight,
      textAR: question?.textAR,
      textEN: question?.textEN,
    };
    resetForm(initialValues);
  }, [resetForm, question]);

  const onQuestionSubmit: SubmitHandler<CreateSectionInfo> = async (data) => {
    const questionUpdateOrCreate = async () => {
      const { weight, ...rest } = data;
      question
        ? await updateQuestion({
            id: question.id,
            questionnaireId: +questionnaireId,
            data: {
              section_id: sectionId,
              ...rest,
              weight: Number(weight).toString(),
            },
          }).unwrap()
        : await createQuestion({
            questionnaireId: +questionnaireId,
            data: {
              section_id: sectionId,
              ...rest,
              weight: Number(weight).toString(),
            },
          }).unwrap();
    };

    if (!isLoading && !isUpdateLoading) {
      await questionUpdateOrCreate()
        .then(() => {
          handleResetAndClose();
        })
        .catch((error: BaseQueryError) => {
          showNotification(NotificationType.Error, { errorId: error.data.errorCode });
        });
    }
  };
  const getHelperText = (error?: FieldError) => {
    if (error) {
      if (error.type === 'required') {
        return translateQuestionnaire('fieldRequired');
      }
      return error.message;
    }
    return ' ';
  };
  const reverseDirection = getReverseDirection(i18n.language);
  const direction = getDirection(i18n.language);

  return (
    <Dialog
      PaperProps={{ className: `${classes.dialog}` }}
      fullScreen
      open={open}
      onClose={handleResetAndClose}
      TransitionComponent={Transition}
      closeAfterTransition
    >
      <AppBar className={classes.appBar}>
        <Container maxWidth="lg" className={classes.containerNoPadding}>
          <Toolbar dir={reverseDirection} className={classes.toolBar}>
            <Button
              className={classes.dialogButton}
              variant="contained"
              size="large"
              type="submit"
              color="secondary"
              form={questionFormId}
            >
              {translateQuestionnaire('save')}
            </Button>
            <Button
              className={`${classes.dialogButton} ${classes.dialogButtonCancel}`}
              variant="outlined"
              size="large"
              onClick={handleResetAndClose}
              color="secondary"
            >
              {translateQuestionnaire('cancel')}
            </Button>
            <div className={classes.emptySpace} />
            <div className={classes.dialogTitleQuestion}>
              <Typography variant="h4" className={classes.dialogTitleTextQuestion}>
                {translateQuestionnaire(question ? 'questionDetails' : 'newQuestion')}
              </Typography>
              <IconButton className={classes.closeButtonQuestion} edge="start" color="inherit" onClick={handleResetAndClose}>
                <CloseIcon style={{ width: '40px', height: '40px' }} />
              </IconButton>
            </div>
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth="lg" className={classes.containerNoPadding}>
        <Paper className={classes.editFormQuestion}>
          <AdminQuestionnaireStepTopic topicLabelName={'questionDetails'} />
          <form id={questionFormId} onSubmit={handleSubmit(onQuestionSubmit)}>
            <FormControl className={classes.formWrapper}>
              <Controller
                name="type"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    select
                    SelectProps={{ dir:direction }}
                    type="text"
                    error={invalid}
                    className={classes.select}
                    variant="outlined"
                    label={translateQuestionnaire('questiontypeselect')}
                    helperText={getHelperText(error)}
                  >
                    {QuestionTypes.map((type) => (
                      <MenuItem key={type.value} value={type.value}>
                        {translateQuestionnaire(type.name)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                control={control}
                name="textAR"
                rules={{
                  required: true,
                  maxLength: 300,
                  minLength: 1,
                  pattern: {
                    value: arInputRegex,
                    message: translateQuestionnaire('arInputMassage'),
                  },
                }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    type="text"
                    variant="outlined"
                    className={classes.field}
                    error={invalid}
                    label={translateQuestionnaire('questionName')}
                    helperText={getHelperText(error)}
                  />
                )}
              />
              <Controller
                control={control}
                name="textEN"
                rules={{
                  required: true,
                  maxLength: 300,
                  minLength: 1,
                  pattern: {
                    value: enInputRegex,
                    message: translateQuestionnaire('enInputMassage'),
                  },
                }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    type="text"
                    variant="outlined"
                    className={classes.field}
                    error={invalid}
                    label={translateQuestionnaire('questionEnName')}
                    helperText={getHelperText(error)}
                  />
                )}
              />
              <Controller
                name="descriptionAR"
                control={control}
                rules={{
                  required: false,
                  maxLength: 1000,
                  minLength: 1,
                  pattern: {
                    value: arInputRegex,
                    message: translateQuestionnaire('arInputMassage'),
                  },
                }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    type="text"
                    variant="outlined"
                    className={classes.field}
                    multiline
                    rows={6}
                    rowsMax={6}
                    error={invalid}
                    label={translateQuestionnaire('questionArDescription')}
                    helperText={getHelperText(error)}
                  />
                )}
              />
              <Controller
                name="descriptionEN"
                control={control}
                rules={{
                  required: false,
                  maxLength: 1000,
                  minLength: 1,
                  pattern: {
                    value: enInputRegex,
                    message: translateQuestionnaire('enInputMassage'),
                  },
                }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    type="text"
                    variant="outlined"
                    className={classes.field}
                    multiline
                    rows={6}
                    rowsMax={6}
                    error={invalid}
                    label={translateQuestionnaire('questionEnDescription')}
                    helperText={getHelperText(error)}
                  />
                )}
              />
              <Controller
                control={control}
                name="weight"
                rules={{ required: true, validate: (value) => Number(value) > 0 }}
                render={({ field: { name, onBlur, onChange, ref, value }, fieldState: { invalid, error } }) => (
                  <TextField
                    name={name}
                    onBlur={onBlur}
                    onChange={(event) => {
                      if (event.target.value.length === 0 || scoreRegex.test(event.target.value)) {
                        onChange(event);
                      }
                    }}
                    ref={ref}
                    value={value}
                    type="number"
                    variant="outlined"
                    className={classes.field}
                    error={invalid}
                    InputProps={{
                      classes: {
                        input: formClasses.numberInput,
                      },
                    }}
                    label={translateQuestionnaire('questionWeight')}
                    helperText={getHelperText(error)}
                  />
                )}
              />
            </FormControl>
          </form>
        </Paper>
      </Container>
    </Dialog>
  );
};
