import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';

import { CollapseIcon } from 'ui/icons/icons';
import { backButtonBackgroundColor } from 'ui/styles';
import { CircleButton } from 'ui/buttons/CircleButton';

type PropTypes = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const useStyles = makeStyles((theme) => ({
  collapseButton: {
    flip: false,
    marginRight: theme.spacing(3),
    padding: 0,
    margin: 0,
    backgroundColor: backButtonBackgroundColor,
  },
}));

export const CollapseButton: React.FC<PropTypes> = ({ onClick }) => {
  const classes = useStyles();

  return (
    <CircleButton size={48} icon={CollapseIcon} className={classes.collapseButton} viewBoxSize={49} onClick={onClick} />
  );
};
