import { Tooltip, makeStyles } from '@material-ui/core';
import React from 'react';
import Paper from '@material-ui/core/Paper';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import { isBefore } from 'date-fns';

import { CompanyRepresentativeSARetryDialog } from 'domain/company-representative/company-representative-login/CompanyRepresentativeSARetryDialog';
import { establishmentToEstablishmentInfo, Establishment } from 'types/Establishment';
import { TranslationNamespace } from 'i18n/config';
import { BackButton } from 'ui/buttons/BackButton';
import { useNavigate } from 'utilities/navigate';
import { useCreateTranslate } from 'utilities/translate.hook';
import { shdShadow } from 'ui/styles';
import { BuildingSafetyClickIcon, WinnersBroIcon } from 'ui/icons/icons';
import { CompanyRepresentativeSaStatusBtn } from 'domain/company-representative/company-representative-sa/CompanyRepresentativeSaStatusBtn';
import { Button } from 'ui/Button';
import { toLocaleDate } from 'utilities/localDate';

import { CompanyRepresentativeRoutes, getCompanyRepresentativePath } from './CompanyRepresentativeRoutes';
import { TypeOfPlatform } from '../../infrastructure/services/auth.type';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    textAlign: 'center',
    marginLeft: '985px',
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    color: '#00435F',
    marginLeft: '30px',
    marginTop: '12px',
    width: '17%',
  },
  buttonRetry: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    color: '#00435F',
    marginLeft: '20px',
    marginTop: '-46px',
    width: '90%',
  },
  buttonRetryFailed: {
    marginLeft: '995px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    color: '#00435F',
    marginTop: '-46px',
    width: '15%',
  },
  paper: {
    display: 'flex',
    backgroundColor: '#E2F1F1',
    boxShadow: shdShadow,
    flexDirection: 'column',
    height: '200px',
  },
  BuildingIcon: {
    display: 'flex',
    height: '200px',
    width: '600px',
    marginLeft: 'auto',
    marginRight: '-190px',
    marginTop: '-135px',
  },
  WinnerIcon: {
    display: 'flex',
    height: '200px',
    width: '600px',
    marginLeft: 'auto',
    marginRight: '-190px',
    marginTop: '-185px',
    marginBottom: '45px',
  },
  text: {
    fontSize: '20px',
    color: '#148287',
    marginLeft: '30px',
    width: '100%',
  },
  title: {
    marginBottom: theme.spacing(0),
    textAlign: 'left',
    fontSize: '30px',
    marginLeft: '2px',
    width: '100%',
  },
  description: {
    marginTop: theme.spacing(2),
    minHeight: '40px',
    fontSize: '20px',
    color: '#148287',
    marginLeft: '30px',
    width: '100%',
  },
  descriptionTitle: {
    marginTop: theme.spacing(2),
    marginBottom: '20px',
    minHeight: '40px',
    fontSize: '20px',
    color: 'black',
    marginLeft: '2px',
    width: '100%',
  },
}));

type CompanyRepresentativeCompanyHeaderProps = {
  establishment: Establishment;
  platform: TypeOfPlatform.OSH;
};

export const CompanyRepresentativeCompanyHeader: React.FC<CompanyRepresentativeCompanyHeaderProps> = ({
  establishment,
  platform,
}) => {
  const translate = useCreateTranslate(TranslationNamespace.companyRepresentative);
  const navigate = useNavigate();
  const classes = useStyles();
  const establishmentInfo = establishmentToEstablishmentInfo(establishment);
  const [showRetryDialog,  setShowRetryDialog] = React.useState(false);

  const navigateToCompanySelection = () => {
    navigate(getCompanyRepresentativePath(CompanyRepresentativeRoutes.companySelect));
  };


  const closeRetryDialog = () => {
    setShowRetryDialog(false);
  };

  const isBeforeRetry = (dateNextAttempt:string | null) => {
     return dateNextAttempt
       ? isBefore(new Date(), new Date(dateNextAttempt))
       : false;
    }

  const handleIsRetry = (dateNextAttemptPlatform:string|null) => {
    if (dateNextAttemptPlatform) {
      if (isBeforeRetry(dateNextAttemptPlatform)) {
        return <Tooltip
            className={classes.tooltip}
            arrow
            title={translate('retryPcTooltip', {
              retryDate: toLocaleDate(new Date(dateNextAttemptPlatform)),
               })}
           >
            <div>
              <Button className={classes.buttonRetry} disabled={true} size="large" variant="contained" color="secondary">
                {translate('retry')}
              </Button>
            </div>
          </Tooltip>
     }
      return <Button
          className={classes.buttonRetryFailed}
          size="large"
          disabled={true}
          variant="contained"
          color="secondary"
          onClick={() => {
            console.log('Retry click');
            }}
        >
          {translate('retry')}
        </Button>;
    }
  };

  return (
    <>
    <h3 className={classes.title}>
         {platform === TypeOfPlatform.OSH ?
         translate('selfAssessment') : translate('ChampionsAward') }
    </h3>
     <Typography variant="body2" className={classes.descriptionTitle}>
         {platform === TypeOfPlatform.OSH ?
         null : translate('championsAwardTitleDescription') }
    </Typography>
      <Paper className={classes.paper}>
      <h3 className={classes.text}>
         {platform === TypeOfPlatform.OSH ?
         translate('selfAssessment'): translate('championsAwardOSH') }
      </h3>
      <Typography variant="body2" className={classes.description}>
      {platform === TypeOfPlatform.OSH ?
      translate('startSelfAssessmentDescription') : translate('championsAwardDescription') }
      </Typography>
        <div className={classes.button}>
          <CompanyRepresentativeSaStatusBtn
            establishment={establishment}
            onRetryButtonClick={() => {
              setShowRetryDialog(true);
            }}
            platform={platform}
            />
        </div>
         {establishment && (
        <CompanyRepresentativeSARetryDialog
          open={showRetryDialog}
          handleClose={closeRetryDialog}
          establishmentInfo={establishmentInfo}
          platform={platform}
        />
      )}
      </Paper>
            {platform === TypeOfPlatform.OSH ? (
              <SvgIcon className={`${classes.BuildingIcon}`} component={BuildingSafetyClickIcon} viewBox="90 0 100 260" />
            ) : (
              <SvgIcon className={classes.WinnerIcon} component={WinnersBroIcon} viewBox="90 0 100 260" />
            )}
          <BackButton onClick={navigateToCompanySelection} />
          { platform === TypeOfPlatform.OSH ?
             handleIsRetry(establishment.pcNextAttemptPossibleAtOSH) :
             handleIsRetry(establishment.pcNextAttemptPossibleAtCA)}
    </>
  );
};
