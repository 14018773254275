import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
/* eslint-disable import/no-duplicates */
import { formatDistance } from 'date-fns';
import { arSA } from 'date-fns/locale';
import { useParams } from 'react-router-dom';

import { useGetPCRequestQuery } from 'domain/admin/api/admin.api';
import { LoadingWrapper } from 'ui/LoadingWrapper';
import { PaperCard } from 'ui/PaperCard';
import { useCreateTranslate } from 'utilities/translate.hook';
import { TranslationNamespace } from 'i18n/config';
import { toLocaleDate } from 'utilities/localDate';
import { getPcRequestStatus } from 'domain/admin/utils/getPcRequestStatus';
import { AssessmentType } from 'types/Assessment';
import { FieldData } from 'types/FieldData';
import { Button } from 'ui/Button';
import { useNavigate } from 'utilities/navigate';
import { Scoring } from 'domain/admin/ui/Scoring';

import { PCRequestDetailsHeader } from './PCRequestDetailsHeader';
import { getAssessmentByAssessmentType } from '../../utils/getAssessmentByAssessmentType';
import { Routes } from '../../../common/Routes';

type PCRequestDetailsProps = {
  getPath: (value: Routes) => string;
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginTop: theme.spacing(10.5),
  },
}));

export const PCRequestDetails: React.FC<PCRequestDetailsProps> = ({ getPath }) => {
  const classes = useStyles();
  const { pcRequestId } = useParams<{ pcRequestId: string }>();
  const translate = useCreateTranslate(TranslationNamespace.admin);
  const { data, isLoading } = useGetPCRequestQuery(Number(pcRequestId));
  const navigate = useNavigate();

  const auditorAssessment = data && getAssessmentByAssessmentType(data.assessments, AssessmentType.AUDITOR);
  const inspectorAssessment = data && getAssessmentByAssessmentType(data.assessments, AssessmentType.INSPECTOR);
  const auditorFullName = data?.auditor && `${data.auditor.firstName} ${data.auditor.lastName}`;
  const inspectorFullName = data?.inspector && `${data.inspector.firstName} ${data.inspector.lastName}`;

  const requestDetails: FieldData[] | undefined = data && [
    {
      name: translate('pcRequestId'),
      value: data?.id,
    },
    {
      name: translate('establishmentName'),
      value: data.establishment.nameAr,
    },
    {
      name: translate('requestsNumber'),
      value: data.numberOfRequests,
    },
    {
      name: translate('createdOn'),
      value: toLocaleDate(data.createdAt),
    },
    {
      name: translate('issuedOn'),
      value: data.issuingDate ? toLocaleDate(data.issuingDate) : '-',
    },
    {
      name: translate('pcCorrectedOn'),
      value: data.nextAttemptPossibleAt ? toLocaleDate(new Date(data.nextAttemptPossibleAt)) : null,
    },
    {
      name: translate('assignedTo'),
      value: inspectorFullName || auditorFullName || '-',
    },
    {
      name: translate('pcDetailsCrScore'),
      value: data.performanceCardScore ? `${data.performanceCardScore}%` : null,
    },
    {
      name: translate('pcRequestStatus'),
      value: translate(getPcRequestStatus(data.status)),
    },
  ];

  const auditDetails: FieldData[] | undefined = data && [
    { name: translate('auditorLabel'), value: auditorFullName || '-' },
    {
      name: translate('auditStartDate'),
      value: auditorAssessment ? toLocaleDate(new Date(auditorAssessment.createdAt)) : '-',
    },
    {
      name: translate('auditEndDate'),
      value:
        auditorAssessment && auditorAssessment.submittedAt
          ? toLocaleDate(new Date(auditorAssessment.submittedAt))
          : '-',
    },
    {
      name: translate('durationOfAudit'),
      value: auditorAssessment
        ? formatDistance(new Date(auditorAssessment.createdAt), new Date(auditorAssessment.submittedAt || Date.now()), {
            locale: arSA,
          })
        : '-',
    },
  ];

  const inspectionDetails: FieldData[] | undefined = data && [
    { name: translate('inspectorLabel'), value: inspectorFullName || '-' },
    {
      name: translate('inspectionStartDate'),
      value: inspectorAssessment ? toLocaleDate(new Date(inspectorAssessment.createdAt)) : '-',
    },
    {
      name: translate('inspectionEndDate'),
      value:
        inspectorAssessment && inspectorAssessment.submittedAt
          ? toLocaleDate(new Date(inspectorAssessment.submittedAt))
          : '-',
    },
    {
      name: translate('durationOfInspection'),
      value: inspectorAssessment
        ? formatDistance(
            new Date(inspectorAssessment.createdAt),
            new Date(inspectorAssessment.submittedAt || Date.now()),
            {
              locale: arSA,
            }
          )
        : '-',
    },
  ];

  const compareButton =
    data?.assessments.some(({ type }) => type === AssessmentType.COMPANY_REPRESENTATIVE) &&
    data?.assessments.some(({ type }) => type === AssessmentType.AUDITOR) ? (
      <Button
        onClick={() => navigate(`${getPath(Routes.pcRequests)}/${pcRequestId}${Routes.compare}`)}
        variant="outlined"
        color="secondary"
        size="large"
      >
        {translate('compareAssessments')}
      </Button>
    ) : undefined;

  return (
      <Container className={classes.container} maxWidth="lg">
        <LoadingWrapper isLoading={isLoading}>
          {data && (
            <>
              <PCRequestDetailsHeader getPath = {getPath} pcRequest={data} />
              <PaperCard
                data-testid="pc-request-details-card"
                title={translate('requestDetailsCardTitle')}
                data={requestDetails}
                headerNode={compareButton}
              />
              <Scoring pcRequest={data} />
              <PaperCard
                data-testid="pc-request-auditor-details-card"
                title={translate('auditDetailsCardTitle')}
                data={auditDetails}
              />
              <PaperCard
                title={translate('inspectionDetailsCardTitle')}
                data={inspectionDetails}
                data-testid="pc-request-inspector-details-card"
              />
            </>
          )}
        </LoadingWrapper>
      </Container>
  );
};
